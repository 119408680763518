import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkSpaceDetailStoreService } from '@penji/team/workspace/data-access';
import { Observable } from 'rxjs';
import { Team } from '@penji/shared/data-access';
import { NzProgressModule } from 'ng-zorro-antd/progress';

@Component({
  selector: 'penji-workspace-project-active',
  standalone: true,
  imports: [
    CommonModule,
    NzProgressModule
  ],
  templateUrl: './workspace-project-active.component.html',
  styleUrls: ['./workspace-project-active.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceProjectActiveComponent {

  @Input() client_team_id?: string;

  private readonly wspDetailStore = inject(WorkSpaceDetailStoreService);

  workspace_data$?: Observable<Team>;

  ngOnChanges(): void {
    if (this.client_team_id) {
      this.workspace_data$ = this.wspDetailStore.data$(this.client_team_id);
    }
  }


}
