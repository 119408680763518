/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { CheckinService } from '@penji/shared/data-access';
import { MemberCheckinStatusComponent } from '@penji/team/member/member-ui/member-checkin-status';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Component({
  selector: 'penji-member-checkin-status-by-uid',
  standalone: true,
  imports: [CommonModule, MemberCheckinStatusComponent],
  templateUrl: './member-checkin-status-by-uid.component.html',
  styleUrls: ['./member-checkin-status-by-uid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberCheckinStatusByUidComponent implements OnChanges {
  @Input() uid!: string;
  @Input() disabled = false;
  @Input() type: 'dot' | 'text' | 'full' | 'default' = 'default';
  @Output() checkin_status_output = new EventEmitter();

  private checkinSV = inject(CheckinService);

  checkin_status$: Observable<number> | undefined;
  checkin_time$: BehaviorSubject<any> = new BehaviorSubject(null);
  checkout_time$: BehaviorSubject<any> = new BehaviorSubject(null);

  ngOnChanges() {
    if (this.uid) {
      this.checkin_status$ = this.checkinSV.getCheckinDetailByUID(this.uid).pipe(
        tap(checkin => {
          if (checkin) {
            this.checkin_time$.next(checkin.checkin_at);
            this.checkout_time$.next(checkin.checkout_at);
          }
        }),
        map(item => {
          let checkin_status = 0;
          if (this.disabled) {
            checkin_status = 6;
          } else if (item) {
            if (item.checkin_at) {
              checkin_status = 3;
              if (item.lateness) {
                checkin_status = 4;
              }
            }
            if (item.checkout_at) {
              checkin_status = 5;
            }
            if (item.pto && item.pto != 0) {
              checkin_status = item.pto;
            }
          }
          this.checkin_status_output.emit(checkin_status);
          return checkin_status;
        }));
    }
  }
}
