import { UserPopoverInfoComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-popover-info';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { AuthStore } from '@penji/shared/auth/data-access';
import * as userActions from '@penji/shared/data-access';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { ImageSize, UserSimple } from '@penji/shared/data-access';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'penji-user-info-item',
  standalone: true,
  imports: [
    CommonModule,
    UserItemCompressComponent,
    NzPopoverModule,
    UserPopoverInfoComponent,
  ],
  templateUrl: './user-info-item.component.html',
  styleUrls: ['./user-info-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoItemComponent {

  @Input() user_id?: string;
  @Input() show_user_name = false;
  @Input() show_user_avatar = false;
  @Input() show_user_role = false;
  @Input() show_user_email = false;
  @Input() show_user_country = false;
  @Input() show_user_available_status = false;
  @Input() user_classes = '';

  @Input() user_image_size: ImageSize = 'sm';
  @Input() clickable = false;



  private readonly store = inject(Store);
  private readonly authStore = inject(AuthStore);
  modal = inject(NzModalService);

  auth_profile$ = this.authStore.authProfile$;
  user$?: Observable<UserSimple> | undefined;
  take$ = new Subject<void>();

  ngOnChanges() {
    if (this.user_id) {
      this.store.dispatch(userActions.loadUserDB({ uid: this.user_id ?? '' }));
      this.user$ = this.store.pipe(
        select(userActions.getUser(this.user_id)),
        takeUntil(this.take$),
        map(user => {
          if (user) this.take$.complete();
          return user;
        })
      );
    }
  }
}
