import { ErrorHandler, Injectable } from "@angular/core";
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({ providedIn: 'root' })
export class PenjiErrorHandler implements ErrorHandler {
  constructor(private message: NzMessageService) { }
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log('error:', errorMessage);
    // if(error.status)
    this.message.error(errorMessage);
    throw errorMessage;
  }
}