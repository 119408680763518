import { DesignerTeamStoreService } from './../../../../../../../designer-team/data-access/src/lib/store/designer-team-store.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DesignerTeam, SquadGroup } from '@penji/shared/data-access';
import { ContentHeightDirective, PermissionDirective } from '@penji/shared/directives';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import {
  DesignerTeamTotalActiveCustomerComponent,
} from '@penji/team/designer-team/designer-team-ui/designer-team-total-active-customer';
import {
  DesignerTeamTotalHibernatingCustomerComponent,
} from '@penji/team/designer-team/designer-team-ui/designer-team-total-hibernating-customer';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { SquadGroupStoreService } from '@penji/team/squad-group/data-access';
import { SquadGroupAttendanceComponent } from '@penji/team/squad-group/squad-group-ui/squad-group-attendance';
import { SquadGroupNameComponent } from '@penji/team/squad-group/squad-group-ui/squad-group-name';
import { SquadGroupWorkloadComponent } from '@penji/team/squad-group/squad-group-ui/squad-group-workload';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'penji-w-squad-by-team',
  standalone: true,
  imports: [
    CommonModule,
    SquadGroupAttendanceComponent,
    SquadGroupWorkloadComponent,
    NzTableModule,
    ContentHeightDirective,
    InViewportDirective,
    UserItemCompressComponent,
    RouterModule,
    DesignerTeamNameComponent,
    NzSpinModule,
    NzSkeletonModule,
    NzToolTipModule,
    NzButtonModule,
    NzSwitchModule,
    FormsModule,
    NzPopconfirmModule,
    SquadGroupNameComponent,
    DesignerTeamTotalActiveCustomerComponent,
    DesignerTeamTotalHibernatingCustomerComponent,
    PermissionDirective,
  ],
  templateUrl: './w-squad-by-team.component.html',
  styleUrls: ['./w-squad-by-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WSquadByTeamComponent {

  @Input() designer_team_id!: string;

  SquadGroupStoreService = inject(SquadGroupStoreService);
  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  list_squad_group$!: Observable<SquadGroup[]>;
  isLoading$ = this.SquadGroupStoreService.loading$;
  error$ = this.SquadGroupStoreService.error$;

  total_team_sum: any = {
    'checkin': {},
    'checkout': {},
    'off': {},
    'workload': {},
    'output': {},
    'total_user': {}
  };
  total_team_checkin: any = 0;
  total_team_checkout: any = 0;
  total_team_off: any = 0;

  container_height = 0;
  show_view_port: any = {};
  total_team_workload: any = 0;
  total_team_output: any = 0;

  current_designer_team$!: Observable<DesignerTeam>;
  isLoadingTeam$ = this.DesignerTeamStoreService.loading$;


  ngOnChanges(): void {
    if(this.designer_team_id){
      this.list_squad_group$ = this.SquadGroupStoreService.getFilterSquadGroup({team_designer_id: this.designer_team_id});
      this.current_designer_team$ = this.DesignerTeamStoreService.getOneDesignerTeam(this.designer_team_id);
    }
  }
  calTotalCheckin(sum: any, squad_id: string) {
    this.total_team_sum.checkin[squad_id] = sum.sum_checkin;
    this.total_team_sum.checkout[squad_id] = sum.sum_checkout;
    this.total_team_sum.off[squad_id] = sum.sum_off;


    this.total_team_checkin = Object.values(this.total_team_sum.checkin).reduce((acc: any, val) => acc + val,0);
    this.total_team_checkout = Object.values(this.total_team_sum.checkout).reduce((acc: any, val) => acc + val,0);
    this.total_team_off = Object.values(this.total_team_sum.off).reduce((acc: any, val) => acc + val,0);
  }
  calTotalWorkload(sum: any, squad_id: string) {
    this.total_team_sum.workload[squad_id] = sum.sum_active_project;
    this.total_team_sum.output[squad_id] = sum.sum_output_project;
    this.total_team_sum.total_user[squad_id] = sum.total_user;

    this.total_team_workload =  Object.values(this.total_team_sum.workload).reduce((acc: any, val) => acc + val,0);
    this.total_team_output = Object.values(this.total_team_sum.output).reduce((acc: any, val) => acc + val,0);

  }

  getHeight(height: any) {
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if (event.visible) {
      this.show_view_port[id] = true;
    }
  }
  trackBy(index: any, item: any) {
    return item.id;
  }
  confirm(designer_team: DesignerTeam){

    let data = designer_team;
    data.auto_assign = !data.auto_assign;
    this.DesignerTeamStoreService.updateDesignerTeam$(data);

  }
  cancel(){
    //
  }

}
