import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { ComponentsComponent } from '@penji/client-v3/shared/ui/components';
import { HeaderComponent } from '@penji/client-v3/shared/ui/header';
import { SiderComponent } from '@penji/client-v3/shared/ui/sider';
import { UserItemComponent } from '@penji/client-v3/shared/ui/user-item';
import { WorkspaceDropdownLayoutComponent } from '@penji/client-v3/workspace/ui/workspace-dropdown-layout';
import { AuthStore } from '@penji/shared/auth/data-access';
import { TeamService } from '@penji/shared/data-access';
import { SupportFormComponent } from '@penji/shared/ui/element/support-form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { QuillModule } from 'ngx-quill';
import { BehaviorSubject, combineLatest, filter, map, take, tap } from 'rxjs';

@Component({
  selector: 'penji-layout',
  standalone: true,
  imports: [
    CommonModule,
    ComponentsComponent,
    HeaderComponent,
    SiderComponent,
    NzLayoutModule,
    RouterModule,
    NzPopoverModule,
    NzButtonModule,
    UserItemComponent,
    FormsModule,
    NzFormModule,
    NzSelectModule,
    QuillModule,
    ReactiveFormsModule,
    NzMenuModule,
    WorkspaceDropdownLayoutComponent,
    NzToolTipModule,
    SupportFormComponent
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {

  private readonly active_router = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly customerTeamStore = inject(CustomerTeamStoreService);
  private readonly fb = inject(FormBuilder);
  private readonly teamSV = inject(TeamService);
  private readonly authStore = inject(AuthStore);

  current_step$ = new BehaviorSubject(1);
  team_data$ = this.customerTeamStore.team_data$;
  last_login$ = combineLatest(this.team_data$, this.authStore.auth_id$).pipe(
    take(1),
    tap(([team_data, auth_id]) => {
      if (team_data.id && auth_id)
        this.teamSV.updateLastLogin(team_data.id, auth_id);
    })
  )

  title: any;
  visible = false;
  loading_submit = false;
  text = '';
  siderCollapsed = false;

  show_sider$ = this.router.events.pipe(filter((e:any)=> {
    return e['routerEvent'] instanceof NavigationEnd;
  })).pipe(
    map(
      () => {
        if(
          // this.active_router.firstChild?.routeConfig?.path == 'settings'
          // || 
          this.active_router.firstChild?.firstChild?.firstChild?.routeConfig?.path == 'create/:category_id'
        ){
          return false;
        }else 
          if(
            this.active_router.firstChild?.firstChild?.firstChild?.routeConfig?.path == 'project-detail/:project_id' 
            || this.active_router.firstChild?.routeConfig?.path == 'project-detail/:project_id')
            {
            this.siderCollapsed = true;
          }else{
            return true
          }
          return true
       
      }
    )
  );

  validateForm: UntypedFormGroup = this.fb.group({
    project_id: [null, [Validators.required]],
    description: [null, [Validators.required]],
  });

  constructor(@Inject(PLATFORM_ID) private platformId: any){}

  ngOnInit(): void {
    this.team_data$.subscribe(console.log);
    this.siderCollapsed = localStorage.getItem('siderCollapsed')  === 'true' || false;;
  }
  onSubmit(){
    if (this.validateForm.valid) {
      // console.log(this.validateForm.value);
      this.nextstep();
    }
  }
  nextstep(){
    const current_step = this.current_step$.getValue();
    this.current_step$.next(current_step+1);
  }

  close(): void {
    this.visible = false;
  }

  updateSiderCollapsed() {
    this.siderCollapsed = !this.siderCollapsed;
    // console.log(this.siderCollapsed)
    localStorage.setItem('siderCollapsed', String(this.siderCollapsed));
    if (isPlatformBrowser(this.platformId)) {
      window.dispatchEvent(new Event('resize'));
    }
  }
}
