import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageSize, Team } from '@penji/shared/data-access';
import { MediaRefComponent } from '@penji/team/media/media-ui/media-ref';
import { WorkSpaceDetailStoreService } from '@penji/team/workspace/data-access';
import { Observable } from 'rxjs';

const ImageSizesInPx: { [key in ImageSize]: number } = {
  'xxs': 16,
  'xs': 24, // +8
  'sm': 32, // +8
  'md': 40, // +8
  'lg': 48, // +8
  'xl': 56, // +8
  '2xl': 72, // +16
  '3xl': 88, // +16
  '4xl': 104, // +16
  '5xl': 120, // +16
};

@Component({
  selector: 'penji-workspace-avatar',
  standalone: true,
  imports: [CommonModule, MediaRefComponent],
  templateUrl: './workspace-avatar.component.html',
  styleUrls: ['./workspace-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceAvatarComponent {

  @Input() client_team_id?: string;
  @Input() avatar_ref: any;
  @Input() avatar_size: "medium" | "thumb" | "avatar" | "original" = "avatar";
  @Input() avatar_shape: string | "square" | "circle" | "custom" = "circle";
  @Input() image_size: ImageSize = 'xs';
  @Input() clickable = false;

  private readonly wspDetailStore = inject(WorkSpaceDetailStoreService);

  workspace_data$?: Observable<Team>;

  ngOnChanges(): void {
    if (this.client_team_id) {
      this.workspace_data$ = this.wspDetailStore.data$(this.client_team_id);
    }
  }

  get setImageWidth(): number {
    return ImageSizesInPx[this.image_size];
  }
}
