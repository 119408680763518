<ng-container *ngIf="{workspace_data:workspace_data$ | async} as vm">
    <div *ngIf="vm.workspace_data"
        class="inline-flex items-center w-full gap-3.5 relative {{show_workspace_status?'pr-8':''}}
        {{
            (show_border_box_workspace && vm?.workspace_data?.label_info?.account_level===3)
            ? padding_border+' rounded-[4px] bg-green-50 hover:bg-green-100 focus:bg-green-100 hover:cursor-pointer'
            : (show_border_box_workspace && vm?.workspace_data?.label_info?.account_level===2)
            ? padding_border+' rounded-[4px] bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 hover:cursor-pointer'
            : (show_border_box_workspace && vm?.workspace_data?.label_info?.account_level===1)
            ? padding_border+' rounded-[4px] bg-red-50 hover:bg-red-100 focus:bg-red-100 hover:cursor-pointer'
            : show_border_box_workspace
            ? padding_border+' rounded-[4px] bg-gray-50 hover:bg-gray-100 focus:bg-gray-100 hover:cursor-pointer'
            : ''
        }}"
        nz-popover
        nzPopoverPlacement="right"
        [nzPopoverContent]="!hide_workspace_popover?popoverInfo:undefined"
        nzPopoverTrigger="hover"
        [nzPopoverMouseEnterDelay]="0.25"
        [nzPopoverMouseLeaveDelay]="0.15"
        [nzPopoverOverlayClassName]="'[&_.ant-popover-inner]:w-[350px] [&_.ant-popover-inner-content]:max-h-[400px] [&_.ant-popover-inner-content]:!pt-0 [&_.ant-popover-inner-content]:overflow-auto'"
    >
        <div *ngIf="show_workspace_avatar" class="flex-none">
            <ng-container *ngIf="clickable; else clickdisable">
                <a (click)="openDrawer($event, vm.workspace_data.id!)" [href]="'/workspace/'+vm.workspace_data.id" class="relative">
                    <penji-workspace-avatar [client_team_id]="vm.workspace_data.id" [avatar_ref]="vm.workspace_data.logo" [image_size]="workspace_image_size" avatar_shape="square"></penji-workspace-avatar>
                </a>
            </ng-container>
            <ng-template #clickdisable>
                <span nz-tooltip [nzTooltipTitle]="vm.workspace_data.title" class="relative">
                    <penji-workspace-avatar [client_team_id]="vm.workspace_data.id" [avatar_ref]="vm.workspace_data.logo" [image_size]="workspace_image_size" avatar_shape="square"></penji-workspace-avatar>
                </span>
            </ng-template>
        </div>
        <div class="flex-auto">
            <div class="flex items-center">
                <ng-container *ngIf="clickable; else clickdisable">
                    <a (click)="openDrawer($event, vm.workspace_data.id!)" [href]="'/workspace/'+vm.workspace_data.id">
                      <penji-workspace-name [client_team_id]="vm.workspace_data.id" [classes]="workspace_classes"></penji-workspace-name>
                    </a>
                </ng-container>
                <ng-template #clickdisable>
                    <penji-workspace-name [client_team_id]="vm.workspace_data.id"  [classes]="workspace_classes"></penji-workspace-name>
                </ng-template>
                <ng-container *ngIf="show_workspace_level && show_workspace_level_on_left">
                    <div class="flex items-center ml-2 gap-1">
                        <penji-workspace-level-v2 [client_team_id]="vm.workspace_data.id" classes="[&_.class-label]:!w-5 [&_.class-label]:!h-5"></penji-workspace-level-v2>
                        <penji-workspace-customer-class-tooltip *ngIf="show_workspace_level_tooltip" [icon_size]="12"></penji-workspace-customer-class-tooltip>
                    </div>
                </ng-container>
                <ng-container *ngIf="show_copy_workspace">
                    <div class="ml-2 dark:[&_.ant-typography-copy]:text-white dark:hover:[&_.ant-typography-copy]:text-purple-600">
                        <penji-copy-item [id]="vm.workspace_data.id" copy_type="workspace" [link_title]="vm.workspace_data.title"></penji-copy-item>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="show_workspace_level && !show_workspace_level_on_left">
                <div class="flex items-center pt-1">
                    <penji-workspace-level-v2 [client_team_id]="vm.workspace_data.id" classes="[&_.class-label]:!w-5 [&_.class-label]:!h-5"></penji-workspace-level-v2>
                    <!-- <penji-workspace-customer-class-tooltip *ngIf="show_workspace_level_tooltip" [icon_size]="12"></penji-workspace-customer-class-tooltip> -->
                    <nz-divider nzType="vertical" class="border-gray-300 h-[28px] !mx-3 !top-0"></nz-divider>
                    <penji-workspace-status [workspace]="vm.workspace_data"></penji-workspace-status>
                </div>
            </ng-container>
            <ng-container *ngIf="client_id">
                <p class="flex items-center text-gray-500 text-sm leading-4 gap-1 [&>svg]:w-4 [&>svg]:h-4">
                    <ng-container *ngIf="vm.workspace_data.owner_id === client_id; else member">
                        Account Owner
                        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.91667 9.33398L1.75 2.91732L4.95833 5.83398L7 2.33398L9.04167 5.83398L12.25 2.91732L11.0833 9.33398H2.91667ZM11.0833 11.084C11.0833 11.434 10.85 11.6673 10.5 11.6673H3.5C3.15 11.6673 2.91667 11.434 2.91667 11.084V10.5007H11.0833V11.084Z" fill="#EAB308"/>
                        </svg>
                    </ng-container>
                    <ng-template #member>
                        Member
                        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33325 6.41602C10.3016 6.41602 11.0774 5.63435 11.0774 4.66602C11.0774 3.69768 10.3016 2.91602 9.33325 2.91602C8.36492 2.91602 7.58325 3.69768 7.58325 4.66602C7.58325 5.63435 8.36492 6.41602 9.33325 6.41602ZM4.66659 6.41602C5.63492 6.41602 6.41075 5.63435 6.41075 4.66602C6.41075 3.69768 5.63492 2.91602 4.66659 2.91602C3.69825 2.91602 2.91659 3.69768 2.91659 4.66602C2.91659 5.63435 3.69825 6.41602 4.66659 6.41602ZM4.66659 7.58268C3.30742 7.58268 0.583252 8.26518 0.583252 9.62435V11.0827H8.74992V9.62435C8.74992 8.26518 6.02575 7.58268 4.66659 7.58268ZM9.33325 7.58268C9.16409 7.58268 8.97159 7.59435 8.76742 7.61185C9.44409 8.10185 9.91659 8.76102 9.91659 9.62435V11.0827H13.4166V9.62435C13.4166 8.26518 10.6924 7.58268 9.33325 7.58268Z" fill="#94A3B8"/>
                        </svg>
                    </ng-template>
                </p>
            </ng-container>
        </div>
        <ng-container *ngIf="show_workspace_status">
            <penji-workspace-status [workspace]="vm.workspace_data" [move_right]="true"></penji-workspace-status>
        </ng-container>
    </div>
    <ng-template #popoverInfo>
        <penji-workspace-popover [workspace_data]="vm.workspace_data!"></penji-workspace-popover>
    </ng-template>
</ng-container>
