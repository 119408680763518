/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { SquadGroup, SquadGroupService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';

interface SquadGroupState extends EntityState<any> {
  loading: boolean,
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: SquadGroupState = adapter.getInitialState({
  loading: false,
  error: ''
})
const { selectAll } = adapter.getSelectors();

@Injectable({
  providedIn: 'root'
})
export class SquadGroupStoreService extends ComponentStore<SquadGroupState> {

  SquadGroupSV = inject(SquadGroupService);

  constructor() { super(initialState); }
  data$ = this.select(s=> Object.values(s.entities) as SquadGroup[]);
  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);
  currentSquadGroup$ = (squad_group_id: string) => {
    return this.select((s) => s.entities[squad_group_id] as SquadGroup);
  }
  loadSquadGroup$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap(() => {
        return this.SquadGroupSV.listSquadGroup(-1);
      }),
      map((final_list: SquadGroup[]) => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  createSquadGroup$ = this.effect((squad_group$: Observable<SquadGroup>) => {
    return squad_group$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((squad_group: SquadGroup) => {
        return this.SquadGroupSV.addSquadGroup(squad_group);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateSquadGroup$ = this.effect((squad_group$: Observable<SquadGroup>) => {
    return squad_group$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((squad_group: SquadGroup) => {
        return combineLatest(this.SquadGroupSV.updateSquadGroup(squad_group), of(squad_group));
      }),
      map(([rs, squad_group]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({id: squad_group.id, changes: squad_group}, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteSquadGroup$ = this.effect((squad_group_id$: Observable<string>) => {
    return squad_group_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((squad_group_id: string) => {
        return combineLatest(this.SquadGroupSV.deleteSquadGroup(squad_group_id), of(squad_group_id));
      }),
      map(([rs, squad_group_id]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(squad_group_id, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
  getOneSquadGroup(squad_group_id: string) {

    return this.select((s) => s.entities[squad_group_id] as SquadGroup);
  }

  getFilterSquadGroup(params: Params) {
    return this.select(this.data$,
      (rs) => {
        let temp = [...rs] as SquadGroup[];
        for (const k in params) {
          temp = temp.filter((t: any) => t[k] == params[k]);
        }
        return temp.sort((a: any, b:any)=>( a.created_at < b.created_at)?1:-1);
      }
    );
  }
}
