import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryStoreService } from '@penji/client-v3/categories/data-access';
import { Observable } from 'rxjs';
import { CategoryV2 } from '@penji/shared/data-access';

@Component({
  selector: 'penji-category-name-by-id',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './category-name-by-id.component.html',
  styleUrls: ['./category-name-by-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryNameByIdComponent {
  @Input() cate_id?: string;

  private readonly cateStore = inject(CategoryStoreService);
  category$?: Observable<CategoryV2 | undefined>;

  ngOnChanges() {
    if(this.cate_id)
      this.category$ = this.cateStore.cate_detail(this.cate_id);
  }
}
