import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'penji-user-name',
  standalone: true,
  imports: [
    CommonModule,
    NzToolTipModule
  ],
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNameComponent {
  @Input() user_name?: string;
  @Input() classes? = 'text-gray-600';
  @Input() disabled = false;
}
