
<ng-container *ngIf="workspace_data$ | async as workspace_data">
  <ng-container *ngIf="workspace_data">
    <p
      class="capitalize truncate font-semibold max-w-[130px] {{workspace_data?.label_info?.account_level===3?'!text-green-500':workspace_data?.label_info?.account_level===2?'!text-blue-500':workspace_data?.label_info?.account_level===1?'text-red-500':workspace_data?.label_info?.account_level===0?'text-gray-600':'text-gray-600'}} {{classes || ''}} {{workspace_data.membership && (workspace_data.membership.length >0 && workspace_data.membership[0].active === 0 || workspace_data.membership.length === 0)?'line-through':''}}"
      nz-tooltip
      [nzTooltipTitle]="( workspace_data.title && workspace_data.title.length > 15)?workspace_data.title:undefined">
        {{workspace_data.title}}
    </p>
  </ng-container>
</ng-container>
