/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DahboardStoreService } from '@penji/team/dashboard/data-access';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SettingsFormComponent } from './settings-form/settings-form.component';
import { of } from 'rxjs';
import { Widget } from '@penji/shared/data-access';

@Component({
  selector: 'penji-w-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    SettingsFormComponent
  ],
  templateUrl: './w-form.component.html',
  styleUrls: ['./w-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WFormComponent {
  @Input() widget_data: Widget | undefined;
  ref = inject(NzModalRef);
  modal = inject(NzModalService);
  store = inject(DahboardStoreService);
  widget_form = new UntypedFormGroup({});
  ngOnInit(): void {}
  onChangeForm() {
    const data = { ...this.widget_data, ...this.widget_form.value } as Widget;
    this.store.addWidget$(of({ ...data }));
    this.ref.destroy();
    this.modal.closeAll();
  }
}
