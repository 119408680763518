<div [ngClass]="['relative flex items-center', container_class_name]">
  <input nz-input #input [formControl]="control" [placeholder]="placeholder" [ngClass]="input_class_name" [readonly]="readonly" />

  <ng-container *ngIf="showClearButton">
    <span class="material-icons-outlined text-gray-300 hover:text-gray-400 cursor-pointer absolute z-20 right-2 top-1/2 -translate-y-1/2 {{class_icon_size}}" (click)="clear()">
    cancel
    </span>
  </ng-container>
    <ng-container *ngIf="icon">
      <span class="material-icons-outlined icon-search text-gray-400 absolute z-10 left-4 top-1/2 -translate-y-1/2 mt-[1px] {{class_icon_size}}">search</span>
    </ng-container>
</div>
