/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Project, ProjectService, Team, TeamService, Ticket, UserData, UserService } from '@penji/shared/data-access';
import { SpinnerComponent } from '@penji/shared/ui/element/spinner';
import { UserRoleComponent } from '@penji/shared/ui/element/user-role';
import { ProjectStatusComponent } from '@penji/team/project/project-ui/project-status';
import { InputComponent } from '@penji/team/shared/shared-ui/input';
import { TicketCateItemComponent } from '@penji/team/shared/shared-ui/ticket-cate-item';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { WorkspaceItemV2Component } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-item-v2';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, of, share, switchMap, tap } from 'rxjs';

@Component({
  selector: 'penji-search',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    SpinnerComponent,
    RouterModule,
    TicketCateItemComponent,
    UserRoleComponent,
    NzEmptyModule,
    NzDropDownModule,
    NzSkeletonModule,
    UserItemCompressComponent,
    ProjectStatusComponent,
    WorkspaceItemV2Component
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() suggestion = false;
  @Input() project = false;
  @Input() user = false;
  @Input() ticket = false;
  @Input() role: number[] = []; // 1=Admin, 2=Teamleader, 4=QC|SL|(PM&is_pm=true)|Designer, 7=Support, 3=Client
  @Input() hotkey = false;
  // For select user
  @Input() select = false;
  @Output() uid_selected = new EventEmitter();
  @Input() auto_focus = true;
  search_control: FormControl = new FormControl('');
  isLoading = false;
  list$: Observable<{
    list_user: UserData[],
    list_project: Project[],
    list_ticket: Ticket[],
    list_workspace: Team[]
  }> | undefined;
  private readonly afs = inject(AngularFirestore);
  private readonly auth = inject(AuthStore);
  private readonly router = inject(Router);
  private readonly userSV = inject(UserService);
  private readonly projectSV = inject(ProjectService);
  private readonly teamSV = inject(TeamService);
  uid?: string;
  suggest_search$!: Observable<any[] | null>;
  add_lastest_search$ = new BehaviorSubject<any>(null);
  index_move = 0;
  check = false;

  @HostListener('keydown', ['$event'])
  onKeydown(e: any) {
    if (e.keyCode === 13 || e.keyCode === 40 || e.keyCode === 38) {
      const matches = document.querySelectorAll('.item-down');
      if (matches.length > 0) {
        if (this.index_move === 0 && !this.check) {
          matches[0].classList.add('focus');
          this.check = true;
        } else {
          matches.forEach((el) => {
            el.classList.remove('focus')
          })
          switch (e.keyCode) {
            case 40:
              if (this.index_move < matches.length - 1) {
                this.index_move++;
                matches[this.index_move].classList.add('focus');
                matches[this.index_move].scrollIntoView({
                  behavior: 'smooth',
                  block: "center"
                });
              } else {
                matches[matches.length - 1].classList.add('focus');
              }
              break;
            case 38:
              if (this.index_move >= 1) {
                this.index_move--;
                matches[this.index_move].classList.add('focus');
                matches[this.index_move].scrollIntoView({
                  behavior: 'smooth',
                  block: "center"
                });
              } else {
                matches[0].classList.add('focus');
              }
              break;
            case 13:
              // @ts-ignore
              matches[this.index_move].click();
              break;
          }
        }
      }

    }
  }

  cancelFocus() {
    this.check = false;
    this.index_move = 0;
    const matches = document.querySelectorAll('.item-down');
    if (matches.length > 0) {
      matches.forEach((el) => {
        el.classList.remove('focus');
      })
    }
    setTimeout(() => {
      document.getElementById('top-dropdown-search')?.scrollIntoView({
        behavior: 'smooth',
        block: "center"
      });
    }, 500);
  }

  ngOnInit(): void {
    if (this.user && this.project) {
      this.list$ = this.onSearch(this.search_control, { type: 'user_project', role: this.role });
    } else if (this.user) {
      this.list$ = this.onSearch(this.search_control, { type: 'user', role: this.role });
    } else if (this.project) {
      this.list$ = this.onSearch(this.search_control, { type: 'project' });
    }
    if (this.ticket) {
      this.list$ = this.onSearch(this.search_control, { type: 'ticket' });
    }
    if (!this.user && !this.project && !this.ticket) {
      this.list$ = this.onSearch(this.search_control);
    }
    this.suggest_search$ = this.auth.authProfile$.pipe(
      tap(rs => this.uid = rs?.id),
      switchMap(rs => {
        return this.getSuggestSearchList(rs?.suggest_search);
      })
    );

  }
  getSuggestSearchList(list: any[] = [], data: any[] = []) {
    return this.add_lastest_search$.pipe(
      map(term => {
        if (term)
          data = [...list, ...[term]];
        else
          data = [...list];
        return data.reverse();
      })
    )
  }
  onSearch(fc: FormControl, search_type?: { type?: string, role?: number[] }, list_recent: any[] = []) {
    return fc.valueChanges.pipe(
      tap(() => {
        this.isLoading = true;
        this.cancelFocus();
      }),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(keyword => {
        if (keyword.trim() != '') {
          if (list_recent.length > 0) {
            const filter_list = list_recent.find(res => res.keyword == keyword.trim());
            if (filter_list) {
              return combineLatest(
                of('recent'),
                of(keyword),
                of(filter_list.list_user as UserData[]),
                of(filter_list.list_project as Project[]),
                of(filter_list.list_ticket as Ticket[]),
                of(filter_list.list_workspace as Team[]));
            }
          }
          if (search_type) {
            switch (search_type.type) {
              case 'user':
                return combineLatest(
                  of('new'),
                  of(keyword),
                  this.userSV.searchUserAlgolia(keyword, this.role),
                  of([]),
                  of([]),
                  of([]));
              case 'project':
                return combineLatest(
                  of('new'),
                  of(keyword),
                  of([]),
                  this.projectSV.searchProjectAlgolia(keyword),
                  of([]),
                  of([]));
              case 'ticket':
                return combineLatest(
                  of('new'),
                  of(keyword),
                  of([]),
                  of([]),
                  this.searchTicket(parseFloat(keyword))),
                  of([]);
              case 'user_project':
                return combineLatest(
                  of('new'),
                  of(keyword),
                  this.userSV.searchUserAlgolia(keyword, this.role),
                  this.projectSV.searchProjectAlgolia(keyword),
                  of([]),
                  of([]));
              default:
                return combineLatest(
                  of('new'),
                  of(keyword),
                  this.userSV.searchUserAlgolia(keyword, this.role),
                  this.projectSV.searchProjectAlgolia(keyword),
                  this.searchTicket(parseFloat(keyword)),
                  this.teamSV.searchTeamAlgolia(keyword));
            }
          } else {
            return combineLatest(
              of('new'),
              of(keyword),
              this.userSV.searchUserAlgolia(keyword, this.role),
              this.projectSV.searchProjectAlgolia(keyword),
              this.searchTicket(parseFloat(keyword)),
              this.teamSV.searchTeamAlgolia(keyword));
          }
        } else {
          return combineLatest(
            of(''),
            of(''),
            of([]),
            of([]),
            of([]),
            of([]));
        }
      }),
      map(([type, keyword, list_user, list_project, list_ticket, list_workspace]) => {
        if (type == 'new') {
          list_recent = [...list_recent, ...[{ keyword: keyword, list_user: list_user, list_project: list_project, list_ticket: list_ticket, list_workspace: list_workspace }]];
        }
        this.isLoading = false;
        return { list_user: list_user, list_project: list_project, list_ticket: list_ticket, list_workspace: list_workspace };
      }),
      share()
    )
  }
  async searchTicket(index_number: number) {
    const result = await this.afs.firestore.collection('ticket_v2').where('index_number', '==', index_number).get();
    if (result.size > 0) {
      const list: Ticket[] = [];
      result.forEach(item => {
        const data = item.data() as Ticket;
        data.id = item.id;
        list.push(data);
      })
      return list;
    } else {
      return [];
    }
  }
  selectUser(uid?: string) {
    this.uid_selected.emit(uid);
  }
  clickSuggest(data: any) {
    const { type, user_id, project_id, ticket_id, client_team_id } = data;
    switch (type) {
      case 'project':
        this.router.navigate([`/project/${project_id}`]);
        break;
      case 'customer':
        this.router.navigate([`/customer/${user_id}`]);
        break;
      case 'member':
        this.router.navigate([`/member/${user_id}`]);
        break;
      case 'ticket':
        this.router.navigate([`/ticket/${ticket_id}`]);
        break;
      case 'workspace':
        this.router.navigate([`/workspace/${client_team_id}`]);
        break;
      default:
        this.router.navigate([`/`]);
        break;
    }
  }
  async onAddKeyword(type: string, title: string, user_id?: string, project_id?: string, ticket_id?: string, client_team_id?: string) {
    try {
      const data = { type: type ?? null, title: title ?? null, user_id: user_id ?? null, project_id: project_id ?? null, client_team_id: client_team_id ?? null };
      if (this.uid) {
        //console.log(this.router.url);
        switch (type) {
          case 'project':
            this.router.navigate([`/project/${project_id}`]);
            break;
          case 'customer':
            this.router.navigate([`/customer/${user_id}`]);
            break;
          case 'member':
            this.router.navigate([`/member/${user_id}`]);
            break;
          case 'ticket':
            this.router.navigate([`/ticket/${ticket_id}`]);
            break;
          case 'workspace':
            this.router.navigate([`/workspace/${client_team_id}`]);
            break;
          default:
            this.router.navigate([`/`]);
            break;
        }
        const result = await this.updateLatestSearchForMember(data, this.uid);
        if (result.flag) {
          this.add_lastest_search$.next(data);
          // console.log(result.message);
        }
        else
          console.log(result.message);
      }

    } catch (error) {
      console.log(error);
    }
  }
  updateLatestSearchForMember(data: any, uid: string) {
    // console.log(data, uid);
    const sfDocRef = this.afs.firestore.collection('user').doc(uid);
    return this.afs.firestore.runTransaction((transaction) => {
      return transaction.get(sfDocRef).then((sfDoc) => {
        if (!sfDoc.exists) {
          throw new Error('Search Bar: Document user does not exist!');
        }
        const user_data = sfDoc.data() as UserData;
        if (user_data.suggest_search) {
          const last_data = user_data.suggest_search[user_data.suggest_search.length - 1];
          if (!(last_data.type == data.type && last_data.title == data.title)) {
            if (user_data.suggest_search.length < 10) {
              user_data.suggest_search.push(data);
              transaction.set(sfDocRef, { suggest_search: user_data.suggest_search }, { merge: true });
            } else {
              user_data.suggest_search.push(data);
              user_data.suggest_search.splice(0, 1);
              transaction.set(sfDocRef, { suggest_search: user_data.suggest_search }, { merge: true });
            }
          }
        }
        else {
          transaction.set(sfDocRef, { suggest_search: [data] }, { merge: true });
        }
      });
    }).then(() => {
      return { flag: true, message: 'Your latest search successfully updated!' };
    }).catch(err => {
      return ({ flag: false, message: err.message });
    });
  }
}
