import { EntityState } from "@ngrx/entity";
import { DocumentData, DocumentReference, Timestamp, QueryDocumentSnapshot } from "firebase/firestore";

export class WorkSpace {
    id?: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    user_id?: string;
    team_id?: string;
    created_at?: Timestamp;
    role?: 'owner' | 'admin' | 'view';
    title?: string;
    logo?: DocumentReference | null;
}

export interface WorkSpacestate extends EntityState<WorkSpace> {
    loading: boolean,
    error: string
}