import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[penjiClickOutside]',
    standalone: true
})
export class ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter();

    constructor(private _eref: ElementRef) { }

    @HostListener('document:click', ['$event', '$event.target'])
    onDocumentClicked(event: MouseEvent, targetElement: HTMLElement) {
        if (targetElement && document.body.contains(targetElement) && !this._eref.nativeElement.contains(targetElement)) {
            this.clickOutside.emit(true);
        } else {
            this.clickOutside.emit(false);
        }
    }
}
