import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageSize } from '@penji/shared/data-access';
import { MediaRefComponent } from '@penji/team/media/media-ui/media-ref';


const ImageSizesInPx: { [key in ImageSize]: number } = {
  'xxs': 16,
  'xs': 24, // +8
  'sm': 32, // +8
  'md': 40, // +8
  'lg': 48, // +8
  'xl': 56, // +8
  '2xl': 72, // +16
  '3xl': 88, // +16
  '4xl': 104, // +16
  '5xl': 120, // +16
};

@Component({
  selector: 'penji-user-avatar',
  standalone: true,
  imports: [
    CommonModule,
    MediaRefComponent
  ],
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() avatar_ref: any;
  @Input() avatar_size: "medium" | "thumb" | "avatar" | "original" = "avatar";
  @Input() avatar_shape: "square" | "circle" | "custom" = "circle";
  @Input() image_size: ImageSize = 'xs';


  get setImageWidth(): number {
    return ImageSizesInPx[this.image_size];
  }
}
