import { ChangeDetectionStrategy, Component, Input, TemplateRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Discussion, DiscussionService, DrawerService, Project } from '@penji/shared/data-access';
import { MediaRefComponent } from '@penji/team/media/media-ui/media-ref';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ViewDesignDetailComponent } from '@penji/team/view-design/view-design-ui/view-design-detail';

@Component({
  selector: 'penji-project-last-design',
  standalone: true,
  imports: [
    CommonModule,
    MediaRefComponent
  ],
  templateUrl: './project-last-design.component.html',
  styleUrls: ['./project-last-design.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLastDesignComponent {

  @Input() project!: Project;
  private readonly discussionSV = inject(DiscussionService);
  private readonly drawerSv = inject(DrawerService);
  last_design$!: Promise<Discussion[]>;
  modal = inject(NzModalService);

  ngOnInit(): void {
    if(this.project){
      this.last_design$ = this.discussionSV.listDiscussion(1, [
        {
          field_name: 'project_id',
          field_operator: '==',
          field_value: this.project.id
        },
        {
          field_name: 'remove',
          field_operator: '==',
          field_value: false
        },
        {
          field_name: 'type',
          field_operator: '==',
          field_value: 'design'
        },
        {
          field_name: 'hidden',
          field_operator: '==',
          field_value: false
        }
      ]);
    }
  }

  openDesign(tlp_icon: TemplateRef<any>, project_id: string, discussion_id: string, id_link: string){
    this.modal.create({
      nzContent: ViewDesignDetailComponent,
      nzBodyStyle: {
        padding: '0',
        overflow: 'hidden',
        background: '#fff',
        height: '100vh'
      },
      nzWidth: '100vw',
      nzStyle: { top: '0', padding: '0', maxWidth: 'none'},
      nzFooter: null,
      nzCloseIcon: tlp_icon,
      nzClassName: 'modal-view-design',
      nzComponentParams: {
        open_modal: true,
        project_id: project_id,
        discussion_id: discussion_id,
        id_link: id_link
      },
    });
  }
  openDrawer(id: string) {
    const temp = {
      type: 'project',
      id: id
    }
    this.drawerSv.openDrawer(temp);
  }
}
