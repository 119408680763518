<ng-container *ngIf="{ user:user$ | async, auth_profile:auth_profile$ | async } as vm">
  <ng-container *ngIf="vm.user && vm.auth_profile">
    <div class="w-auto inline-flex items-center space-x-1.5">
      <div class="w-fit relative" *ngIf="show_user_avatar">
        <ng-container *ngIf="clickable; else clickdisable">
          <ng-container *ngIf="vm.user.role === 3; else is_member_avatar">
            <a (click)="openDrawer($event, vm.user.id, 'customer')">
               <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
            </a>

          </ng-container>
          <ng-template #is_member_avatar>
            <a (click)="openDrawer($event, vm.user.id, 'member')">
              <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
            </a>
          </ng-template>
        </ng-container>
        <ng-template #clickdisable>
          <span nz-tooltip [nzTooltipTitle]="vm.user.first_name + ' ' + vm.user.last_name">
            <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
          </span>
        </ng-template>
        <ng-container *ngIf="show_user_available_status && vm.user.role !== 3">
          <penji-member-checkin-status-by-uid [uid]="vm.user.id" [type]="'dot'" [disabled]="vm.user.disabled?vm.user.disabled:false"></penji-member-checkin-status-by-uid>
        </ng-container>
      </div>
      <div class="w-full flex flex-col space-y-0">
        <div class="w-full flex items-center space-x-0.5" *ngIf="show_user_name">
          <ng-container *ngIf="clickable; else clickdisable">
            <ng-container *ngIf="vm.user.role === 3; else is_member_name">
              <a (click)="openDrawer($event, vm.user.id, 'customer')">
                <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name" [classes]="user_classes" [disabled]="vm.user.disabled!"></penji-user-name>
              </a>
            </ng-container>
            <ng-template #is_member_name>
              <a (click)="openDrawer($event, vm.user.id, 'member')">
                <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name" [classes]="user_classes" [disabled]="vm.user.disabled!"></penji-user-name>
              </a>
            </ng-template>
          </ng-container>
          <ng-template #clickdisable>
            <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name" [classes]="user_classes" [disabled]="vm.user.disabled!"></penji-user-name>
          </ng-template>
        </div>
        <div class="w-full" *ngIf="show_user_role">
          <ng-container *ngIf="vm.user.role !== 3">
              <penji-user-role class="[&>p]:!text-base {{vm.user.role === 2 ? 'text-green-500': vm.user.role === 4 ? 'text-orange-500': vm.user.role === 5 ? 'text-blue-500': vm.user.role === 7 ? 'text-pink-500': 'text-yellow-500'}}" [user_role]="vm.user.role" [is_pm]="vm.user.is_pm"
                  *ngIf="show_user_role"></penji-user-role>
          </ng-container>
          <ng-container *ngIf="vm.user.role === 3">
              <span class="text-purple-500">Customer</span>
          </ng-container>
      </div>
      </div>
    </div>
  </ng-container>
</ng-container>
