/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AuthStore } from '@penji/shared/auth/data-access';
import { CustomerService } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MomentModule } from 'ngx-moment';
import { combineLatest, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'penji-w-watched-customer',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    UserItemCompressComponent,
    NzButtonModule,
    MomentModule,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule,
    NzToolTipModule
  ],
  templateUrl: './w-watched-customer.component.html',
  styleUrls: ['./w-watched-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WWatchedCustomerComponent {

  authStore = inject(AuthStore);
  customerSV = inject(CustomerService);
  auth_id$ = this.authStore.auth_id$;
  authProfile$ = this.authStore.authProfile$;
  isLoading: boolean = true;
  container_height = 0;
  show_view_port: any = {};

  list_customer$ = this.authProfile$.pipe(
    switchMap((authProfile) => {

      if (authProfile && authProfile.client_team_following && authProfile.client_team_following.length > 0) {
        // console.log(authProfile.client_team_following);
        return combineLatest(this.customerSV.getListCustomerByListIds(authProfile?.client_team_following), this.customerSV.getListCustomerPaymentByListIds(authProfile?.client_team_following));
      } else {
        return combineLatest(of([]), of([]));
      }
    }),
    map(([users, payments])=>{
      if(users)
        return users.map(user=>{
          return { ...user, payment: payments.find(p=> p.id === user.id)};
        })
      return [];
    }),
    tap(
      ()=>this.isLoading = false
    )
  );

  getHeight(height: any) {
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if (event.visible) {
      this.show_view_port[id] = true;
    }
  }
  trackBy(index: any, item: any) {
    return item.id;
  }
}
