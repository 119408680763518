<ng-container [ngSwitch]="role">
    <ng-container *ngSwitchCase="1">
        <p class="font-semibold text-yellow-500">Admin</p>
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <p class="font-semibold text-red-500">Team Leader</p>
    </ng-container>
    <ng-container *ngSwitchCase="3">
        <p class="font-semibold text-purple-500">Customer</p>
    </ng-container>
    <ng-container *ngSwitchCase="4">
        <ng-container *ngIf="is_pm; else noPM">
            <p class="font-semibold text-blue-500">Art Director</p>
        </ng-container>
        <ng-template #noPM>
            <p class="font-semibold text-orange-500">Designer</p>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="5">
        <p class="font-semibold text-blue-500">Social Media Manager</p>
    </ng-container>
    <ng-container *ngSwitchCase="7">
        <p class="font-semibold text-secondary-500">Supporter</p>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <p class="font-semibold text-gray-500 dark:text-gray-300">Penji Staff</p>
    </ng-container>
</ng-container>
