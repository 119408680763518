import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Comment } from '@penji/shared/data-access';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { CommentStoreService } from '@penji/team/view-design/data-access';
import { RevisionByIdlinkComponent } from '@penji/team/view-design/view-design-ui/revision-by-idlink';
import { ViewDesignCommentItemComponent } from '@penji/team/view-design/view-design-ui/view-design-comment-item';
import { ViewDesignInputReplyComponent } from '@penji/team/view-design/view-design-ui/view-design-input-reply';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-view-design-comment-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzEmptyModule,
    ViewDesignInputReplyComponent,
    ViewDesignCommentItemComponent,
    NzSkeletonModule,
    RevisionByIdlinkComponent
  ],
  templateUrl: './view-design-comment-list.component.html',
  styleUrls: ['./view-design-comment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ViewDesignCommentListComponent {

  @Input() id_link?: string;
  @Output() new_annotation = new EventEmitter();

  private readonly projectStoreSV = inject(ProjectStoreService);
  private readonly commentStoreSv = inject(CommentStoreService);
  private readonly authStore = inject(AuthStore);

  project_data$ = this.projectStoreSV.current_project_data$;
  loading$ = this.commentStoreSv.loading$;
  auth_id$ = this.authStore.auth_id$;
  list_comment$: Observable<any> | undefined;


  ngOnChanges(): void {
    if(this.id_link)
      this.list_comment$ = this.commentStoreSv.data_comment_by_idlink$(this.id_link);
  }

  onClickComment(comment: Comment) {
    const id = comment?.marker?.id ? comment?.marker?.id : 'annotation-'+comment.id;
    const svg_note = document.querySelectorAll('.a9s-annotationlayer, .comment-content, .a9s-annotation');
    svg_note.forEach(el => {
      el.classList.remove('focus');
    });
    const comment_element = document.getElementById(id);
    if (comment_element) {
      comment_element.classList.add('focus');
    }
    const anno_element = document.querySelectorAll(`[data-id="${id}"]`)[0];
    if (anno_element) {
      anno_element.classList.add('focus');
      setTimeout(() => {
        anno_element.scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
    }
    if (!comment.marker && comment.version == 2) {
      svg_note[0].classList.add('focus');
    }
    document.getElementById('input_reply_' + id)?.focus();
    this.new_annotation.emit(null)
  }

  trackBy(index: number, item: any) {
    return item.id;
  }
aaa(){}
cancel(){}
}
