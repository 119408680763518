/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { Project, ProjectService } from '@penji/shared/data-access';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { Observable, interval, map } from 'rxjs';

@Component({
  selector: 'penji-project-deadline-item',
  standalone: true,
  imports: [CommonModule, NzStatisticModule],
  templateUrl: './project-deadline-item.component.html',
  styleUrls: ['./project-deadline-item.component.scss'],
})
export class ProjectDeadlineItemComponent {
  @Input() project_data!: Project;


  projectSV = inject(ProjectService);
  cdr = inject(ChangeDetectorRef);

  show_time = true;
  overdue = false;
  overdue_time = 3600 * 24;// one day
  now: any = new Date().getTime() / 1000;
  deadline: any;

  temp$!: Observable<any>;

  ngOnInit() {
    this.temp$ = interval(1000).pipe(map(() => {
      this.cdr.detectChanges();
    }));
  }
  ngOnChanges() {
    if (this.project_data) {
      if (this.project_data.in_queue == true ||
        !this.project_data.time_change ||
        this.project_data.pause == true ||
        [4, 5, 6, 7, 8].includes(this.project_data.status!) ||
        this.project_data.status_addition == 3)
      {
        this.show_time = false;
      }

      if (this.project_data.time_change) {
        if (typeof this.project_data.time_change == 'number') {
          this.deadline = Date.now() + 1000 * ((this.project_data.time_change/1000 + 3600 * 24) - this.now);
          if ((this.project_data?.time_change/1000 + this.overdue_time) < this.now && this.project_data.status != 4 && this.project_data.status != 5 && this.project_data.status != 6 && this.project_data.in_queue == false)
            this.overdue = true;
        } else {
          this.deadline = Date.now() + 1000 * ((this.project_data.time_change.seconds + 3600 * 24) - this.now);
          if ((this.project_data?.time_change?.seconds + this.overdue_time) < this.now && this.project_data.status != 4 && this.project_data.status != 5 && this.project_data.status != 6 && this.project_data.in_queue == false)
            this.overdue = true;
        }
      }
    }
  }
}

