/* eslint-disable */
import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Comment, MessageNoteService } from '@penji/shared/data-access';
import { catchError, concatMap, EMPTY, mergeMap, Observable, of, switchMap, tap } from 'rxjs';

export interface CommentState extends EntityState<Comment> {
  loading: boolean;
  success: boolean;
}

const adapter = createEntityAdapter<Comment>();
const initialState: CommentState = adapter.getInitialState({
  loading: false,
  success: false,
});
const { selectAll } = adapter.getSelectors();

@Injectable()
export class CommentStoreService extends ComponentStore<CommentState> {

  messageNoteSv = inject(MessageNoteService);

  data$ = this.select(selectAll);
  data_comment_root$ = this.select((s) => {
    const list_all_comment = Object.values(s.entities);
    const list_commment_root = list_all_comment.filter(rs => rs?.parent_id === rs?.id_link && !rs?.removed);
    return list_commment_root.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
  });
  data_comment_by_idlink$ = (id_link: string) => this.select((s) => {
    const list_all_comment = Object.values(s.entities);
    const list_commment_root = list_all_comment.filter(rs => rs?.parent_id === id_link && !rs?.removed);
    return list_commment_root.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
  });
  data_comment_by_commentid$ = (comment_id: string) => this.select((s) => {
    const list_all_comment = Object.values(s.entities);
    const list_commment_by_id = list_all_comment.filter(rs => rs?.parent_id === comment_id && !rs?.removed);
    return list_commment_by_id.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1)
  });

  data_comment_by_idlink_notes$ = (id_link: string, image_url: string, percent: any) => this.select((s) => {
    const list_all_comment = Object.values(s.entities);
    let list_commment_root = list_all_comment.filter(rs => rs?.parent_id === id_link);
    return list_commment_root.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
  }).pipe(
    switchMap(list => {
      //for v1
      let marker: any = {
        "id": "annotation-init",
        "type": "Annotation",
        "@context": "http://www.w3.org/ns/anno.jsonld",
        "body": [],
        "target": {
          "source": image_url,
          "selector": {
            "conformsTo": "http://www.w3.org/TR/media-frags/",
            "value": "xywh=percent:1,1,1,1",
            "type": "FragmentSelector"
          }
        }
      };
      list = list.map(item => {
        // console.log(item);

        if (item && item.version !==2 && !item?.marker) {
            const marker_merge = structuredClone({ ...marker, id: `annotation-${item.id}` });
            marker_merge.target.selector.value = `xywh=percent:${item.x! * 100 - 1},${item.y! * 100 - 0.5},2,1`;
            // console.log(marker_merge);
            return { ...item, marker: marker_merge };
        }
        return item;
      })
      // end for v1

      return of(list);
    })
  );
  loading$ = this.select((s) => s.loading);
  success$ = this.select((s) => s.success);

  getAllComment$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((array_link: any) => {

        return this.messageNoteSv.getListComment(array_link).then(
          (list_comment: any) => {
            if (list_comment) {
              this.setState((state) => adapter.setAll(list_comment, state));
            }
            this.patchState({ loading: false });
            return EMPTY;
          },
          catchError(() => {
            this.patchState({ loading: false });
            return EMPTY;
          })
        );
      })
    );
  });

  // Create
  addComment$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) =>
        this.messageNoteSv.addMessageNote(data.comment).then(
          (rs) => {
            if (rs.flag && rs.data) {
              this.setState((state) => adapter.addOne(rs.data, state));
              this.patchState({ success: true });
            }
            this.patchState({ success: false });
            return EMPTY;
          },
          catchError(() => {
            this.patchState({ success: false });
            return EMPTY;
          })
        )
      )
    );
  });

  // Update
  updateComment$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) =>
        this.messageNoteSv.updateMessageNote(data.comment).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) =>
                adapter.updateOne(
                  { id: data.comment.id, changes: data.comment },
                  state
                )
              );
              this.patchState({ success: true });
            }
            this.patchState({ success: false });
            return EMPTY;
          },
          catchError(() => {
            this.patchState({ success: false });
            return EMPTY;
          })
        )
      )
    );
  });

  // Delete
  deleteComment$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      mergeMap((data) =>
        this.messageNoteSv.deleteMessageNote(data.comment).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) =>
                adapter.removeOne(data.comment.id, state)
              );
              this.patchState({ success: true });
            }
            this.patchState({ success: false });
            return EMPTY;
          },
          catchError(() => {
            this.patchState({ success: false });
            return EMPTY;
          })
        )
      )
    );
  });

  constructor() {
    super(initialState);
  }
}
