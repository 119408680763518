<ng-container *ngIf="project_data?.final_assigned === 1; else NotFinalAssigned">
    <span class="inline-flex" penjiPermission="_project-review" style_type="button">
        <a title="Final assigned" nz-popover [nzPopoverTitle]="titleFinal" [(nzPopoverVisible)]="visible_popover"
        nzPopoverTrigger="click" [nzPopoverContent]="popUnFinal"
          class="rounded-full p-0.5 hover:bg-gray-200 dark:hover:bg-gray-500 !w-[26px] !h-[26px]">
          <span class="material-icons-outlined text-[18px] bg-green-500 text-white rounded-full p-0.5">how_to_reg</span>
        </a>
    </span>
</ng-container>
<ng-template #NotFinalAssigned>
    <span class="inline-flex" penjiPermission="_project-review" style_type="button">
        <a title="Not final assigned" nz-popover [nzPopoverTitle]="titleFinal" [(nzPopoverVisible)]="visible_popover"
        nzPopoverTrigger="click" [nzPopoverContent]="popFinal"
          class="rounded-full p-0.5 hover:bg-gray-200 dark:hover:bg-gray-500 !w-[26px] !h-[26px]">
          <span class="material-icons-outlined text-[22px] text-gray-500">how_to_reg</span>
        </a>
    </span>
</ng-template>

<ng-template #titleFinal>
    <p class="text-center font-semibold text-lg">Update Final Assigned</p>
  </ng-template>
  <ng-template #popFinal>
    <p class="mb-3">
      Mark this project is final assigned
    </p>
    <span penjiPermission="_project-review" style_type="button">
      <button (click)="final()" nz-button [nzLoading]="isLoading" nzType="primary" nzBlock=""
        class="flex items-center justify-center space-x-2 btn-primary-green">
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.49992 1.33331C4.82392 1.33331 1.83325 4.32398 1.83325 7.99998C1.83325 11.676 4.82392 14.6666 8.49992 14.6666C12.1759 14.6666 15.1666 11.676 15.1666 7.99998C15.1666 4.32398 12.1759 1.33331 8.49992 1.33331ZM7.16725 10.942L4.69192 8.47198L5.63325 7.52798L7.16592 9.05798L10.6953 5.52865L11.6379 6.47131L7.16725 10.942Z"
            fill="#F6FFED" />
        </svg>
        <span>Mark as Assigned</span>
      </button>
    </span>
  
  </ng-template>
  
  <!-- temp review circle type 2 -->
  <ng-template #popUnFinal>
    <p class="mb-3">
      Mark this project is not final assigned
    </p>
    <span penjiPermission="_project-review" style_type="button">
      <button (click)="undo_final()" nz-button [nzLoading]="isLoading" nzType="default" nzDanger="" nzBlock
        class="flex items-center justify-center">
        <span>Undo final assigned</span>
      </button>
    </span>
  </ng-template>