import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { DesignerTeam } from '../models/designer-team.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { map } from 'rxjs/operators';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DesignerTeamService extends LogService<DesignerTeam>{
  async listDesignerTeam(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      let query: Query = this.afs.firestore.collection('team_designer');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      query = query.where('disabled','==', false);
      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        const doc = await this.afs.firestore.doc(`team_designer/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`team_designer/${end_before}`).get();
        query = query.endBefore(doc)
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<DesignerTeam> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as DesignerTeam;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  getDesignerTeamDetail(team_designer_id: string) {
    return this.afs.doc<DesignerTeam>(`team_designer/${team_designer_id}`).valueChanges().pipe(
      map(rs=> ({
        id: team_designer_id,
        ...rs
      } as DesignerTeam))
    );
  }

  addDesignerTeam(team_designer: DesignerTeam) {
    return this.afs.collection(`team_designer`).add({...team_designer}).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new DesignerTeam, ...team_designer };
      this.createLog();
      return ({ flag: true, message: 'Designer team successfully added!', data: { ...team_designer, id: rs.id } });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message, data: {} as DesignerTeam });
    });
  }

  updateDesignerTeam(team_designer: DesignerTeam) {
    delete team_designer.doc;
    return this.afs.doc(`team_designer/${team_designer.id}`).update({...team_designer}).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new DesignerTeam, ...team_designer };
      this.createLog();
      return ({ flag: true, message: 'Designer team successfully updated!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }

  deleteDesignerTeam(team_designer_id: string) {
    return this.afs.doc(`team_designer/${team_designer_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new DesignerTeam, team_designer_id: team_designer_id } as DesignerTeam;
      this.createLog();
      return ({ flag: true, message: 'Designer team successfully deleted!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }
}
