<div *ngIf="workspace_data" class="flex items-center w-full gap-3 py-4 sticky top-0 bg-white dark:bg-gray-600 z-10
">
    <div class="flex-none">
        <span nz-tooltip [nzTooltipTitle]="workspace_data.title">
            <penji-workspace-avatar [client_team_id]="workspace_data.id" [avatar_ref]="workspace_data.logo" [image_size]="'xl'" avatar_shape="square"></penji-workspace-avatar>
        </span>
    </div>
    <div class="flex-auto">
        <div class="flex items-center mb-0.5">
            <penji-workspace-name [client_team_id]="workspace_data.id" classes="!text-lg !max-w-fit" class="{{(workspace_data.label_info?.account_level===0 || !workspace_data.label_info?.account_level)?'dark:[&_p]:!text-gray-200':''}}"></penji-workspace-name>
            <div class="ml-2 dark:[&_.ant-typography-copy]:text-white">
                <penji-copy-item [id]="workspace_data.id" copy_type="workspace" [link_title]="workspace_data.title"></penji-copy-item>
            </div>
        </div>
        <div class="flex items-center pt-0.5">
          <penji-workspace-level-v2 [client_team_id]="workspace_data.id" classes="[&_.class-label]:!w-5 [&_.class-label]:!h-5"></penji-workspace-level-v2>
          <nz-divider nzType="vertical" class="border-gray-300 h-[28px] !mx-3 !top-0"></nz-divider>
          <penji-workspace-status [workspace]="workspace_data"></penji-workspace-status>
      </div>
    </div>
</div>
<div class="
    grid grid-cols-1 gap-4 pb-4 border-b border-gray-200 dark:border-gray-500
    [&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:text-gray-800 dark:[&>div]:text-white [&>div]:text-base [&>div]:leading-[18px]
    [&>div>span:first-child]:text-gray-500 dark:[&>div>span:first-child]:text-gray-200
">
    <div class="pt-2">
        <span class="!text-gray-600 dark:!text-gray-200">Designer Team:</span>
        <penji-designer-team-name [designer_team_id]="workspace_data?.team_designer_id!" classes="text-gray-500"></penji-designer-team-name>
    </div>
    <div>
        <span>Discovery Phase:</span>
        <span class="flex items-center"><penji-workspace-discovery-phase [phase]="1"></penji-workspace-discovery-phase> <penji-workspace-discovery-phase-tooltip [icon_size]="12"></penji-workspace-discovery-phase-tooltip></span>
    </div>
    <div>
        <span>Plans:</span>
        <penji-workspace-membership-dropdown [client_team_id]="workspace_data?.id!"></penji-workspace-membership-dropdown>
    </div>
    <div>
        <span>Industry:</span>
        <span>{{workspace_data?.onboarding_data?.industries_sector ?? 'N/A' }}</span>
    </div>
    <div>
        <span>Owner:</span>
        <penji-user-item-compress [user_id]="workspace_data?.owner_id" [show_user_avatar]="true" [show_user_name]="true" [clickable]="true" [user_classes]="'text-gray-500'" user_image_size="sm"></penji-user-item-compress>
    </div>
</div>
<nz-collapse class="border-0 [&_.ant-collapse-item]:!border-b-gray-200 [&_.ant-collapse-header]:!bg-white [&_.ant-collapse-header]:!px-1 [&_.ant-collapse-header]:!py-3.5 [&_.ant-collapse-header]:!text-lg [&_.ant-collapse-header]:!font-semibold dark:[&_.ant-collapse-header]:!bg-gray-600 [&_.ant-collapse-content]:!border-t-gray-200 dark:[&_.ant-collapse-content]:!border-t-gray-500 [&_.ant-collapse-arrow]:!text-lg [&_.ant-collapse-arrow]:!align-[2px] [&_.ant-collapse-content-box]:!px-2 [&_.ant-collapse-content-box]:!py-4 [&_.ant-collapse-item-active_.arrow]:rotate-90 [&_.arrow]:transition-all [&_.arrow]:ease-in-out [&_.arrow]:duration-200 [&_.ant-collapse-item-active_.ant-collapse-header_span]:text-blue-500 dark:[&_.ant-collapse-item]:!border-b-gray-500 dark:[&_.ant-collapse-header]:text-white ">
    <nz-collapse-panel class="!border-0 !border-none" [nzHeader]="title_card_label" [nzExpandedIcon]="icon" [nzActive]="true">
        <penji-workspace-label [workspace_data]="workspace_data!"></penji-workspace-label>
    </nz-collapse-panel>
</nz-collapse>


<ng-template #title_card_label>
    <div class="flex items-center space-x-1.5 text-gray-600 dark:!text-white pl-1.5">
      <span class="font-semibold">Executive Labels</span>
    </div>
  </ng-template>

  <ng-template #icon>
    <span class="arrow material-icons-outlined text-[26px]">
      chevron_right
    </span>
  </ng-template>
