import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';

@Component({
  selector: 'penji-media-cover',
  standalone: true,
  imports: [CommonModule, NzImageModule],
  providers:[],

  templateUrl: './media-cover.component.html',
  styleUrls: ['./media-cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCoverComponent {

  @Input() image_url?: any;
  @Input() clickable = false;
  @Input() image_auto_fit = false;
  @Input() image_type: 'image' | 'design' = 'image';

  nzImageService = inject(NzImageService);

  placeholder = "./assets/images/no-image.png";

  ngOnInit(): void {
    if(this.image_type==='design'){
      this.placeholder = './assets/images/no-design.jpg'
    }
  }

  openImage(){
    const images = [
      {
        src: this.image_url,
        alt: 'Penji image'
      }
    ]
    this.nzImageService.preview(images);
  }
}
