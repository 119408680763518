/* eslint-disable @typescript-eslint/no-empty-interface */
import { EntityState } from "@ngrx/entity";

export class CategoryClass {
  id?: string;
  title?: string;
  order?: number;
  categories?: Category[];
  log_type?: 'category_class';
}

export class Category {
  id?: string;
  eta_hours?: number;
  hide?: boolean;
  images?: string;
  point?: number;
  point_bonus?: number;
  title?: string;
  type?: string;
  class_id?: string;
  price?: number;
  style_preference_tag?: Array<string>;
  deadline_hours?: number;
  multiple_deadline_hours?: Array<any>;
  oneoff?: boolean;
  show_delay_notice?: boolean;
  delay_notice?: string;
  show_style_preference?: boolean;
  pages?: any;
  length?: any;
  dimensions?: any;
  dimensions_mobile?: any;
  dimension_multiple!: boolean;
  extra_field?: string[];
  description?: any;
  log_type? = 'category';
}

export interface CategoryClassState extends EntityState<any>{}
