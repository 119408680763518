import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SquadGroupStoreService } from '@penji/team/squad-group/data-access';
import { DrawerService, SquadGroup } from '@penji/shared/data-access';
import { Observable } from 'rxjs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'penji-squad-group-name',
  standalone: true,
  imports: [CommonModule, NzToolTipModule],
  templateUrl: './squad-group-name.component.html',
  styleUrls: ['./squad-group-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquadGroupNameComponent implements OnChanges{

  @Input() squad_group_id: string | undefined = '';
  @Input() clickable = true;
  @Input() classes? = '';

  squadGroupStoreSv = inject(SquadGroupStoreService);
  drawerSv = inject(DrawerService);
  squad_group$: Observable<SquadGroup> | undefined;

  ngOnChanges(): void {
    if(this.squad_group_id)
      this.squad_group$ = this.squadGroupStoreSv.getOneSquadGroup(this.squad_group_id);
  }
  openDrawer(id: string) {
    const temp = { type: 'squad-group', id: id };
    this.drawerSv.openDrawer(temp);
  }
}
