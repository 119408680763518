<div class="flex space-x-3" *ngIf="sum$ | async as sum">
  <div class="inline-flex items-center space-x-1.5">
    <p class="text-green-500">Checkin: <b>{{ sum.sum_checkin.length }}</b></p>
  </div>
  <div class="inline-flex items-center space-x-1.5">
    <p class="text-gray-500 dark:text-gray-200">Checkout: <b>{{ sum.sum_checkout.length }}</b></p>
  </div>
  <div class="inline-flex items-center space-x-1.5">
    <p class="text-orange-500">Off: <b>{{ sum.sum_off.length }}</b></p>
  </div>
</div>
