import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { PermissionDirective } from '@penji/shared/directives';

@Component({
  selector: 'penji-user-email',
  standalone: true,
  imports: [
    CommonModule,
    NzTypographyModule,
    PermissionDirective
  ],
  templateUrl: './user-email.component.html',
  styleUrls: ['./user-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEmailComponent {
  @Input() user_email?: string;
  @Input() classes = '';
}
