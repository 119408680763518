<div class="min-h-[60px]">
  <ng-container *ngIf="{workspace: workspace_by_customer$ | async, loading: loading$ | async} as vm">
    <ng-container *ngIf="vm.loading; else loaded">
      <div class="flex items-center p-2 rounded bg-gray-50 dark:bg-gray-600">
        <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1, width: '100%'}"></nz-skeleton>
      </div>
    </ng-container>
    <ng-template #loaded>
        <ng-container *ngIf="vm.workspace && vm.workspace.length > 0; else not_found">
          <ng-container *ngFor="let item of vm.workspace; trackBy:trackBy">
            <ng-container *ngIf="workspace_list_type==='item'; else is_tag">
              <div [id]="item.id" class="w-full flex flex-col space-y-0 justify-start relative mb-4 overflow-x-hidden">
                <penji-workspace-item-v2 
                  [client_team_id]="item.team_id" 
                  [show_workspace_avatar]="true" 
                  [workspace_image_size]="'lg'"
                  [show_workspace_name]="true" 
                  [show_workspace_level]="true" 
                  [show_workspace_level_on_left]="true" 
                  [show_border_box_workspace]="true" 
                  [padding_border]="'p-1.5'" 
                  [clickable]="true"
                  [client_id]="uid"
                  [hide_workspace_popover]="true"
                  workspace_classes="!max-w-fit !text-lg"
                  [show_workspace_status]="true"
                  class="[&_.wsp-status]:text-[12px]"
                ></penji-workspace-item-v2>
              </div>
            </ng-container>
            <ng-template #is_tag>
              <nz-tag class="!text-base py-1.5 px-3">
                {{item.title ? item.title : 'N/A'}} {{item.role ? '('+item.role+')' : ''}}
              </nz-tag>
            </ng-template>
            <ng-template #role>
              <ng-container *ngIf="item.role==='owner'; else member">
                <div nz-tooltip nzTooltipTitle="Owner">
                  <img src="./assets/images/owner.png" alt="" class="w-6">
                </div>
              </ng-container>
              <ng-template #member>
                <div nz-tooltip nzTooltipTitle="Member">
                  <span class="material-icons text-[28px] text-gray-400">
                    person
                  </span>
                </div>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #not_found>
          <nz-empty [nzNotFoundContent]="'No Workspace'" class="[&>.ant-empty-image]:!h-8 pt-5 text-gray-500" nzNotFoundImage="simple"></nz-empty>
        </ng-template>
    </ng-template>
</ng-container>
</div>
