<div *ngIf="uid_array && uid_array.length > 0" class="flex items-center justify-start -space-x-3">
  <ng-container *ngFor="let uid of uid_array.slice(0, 3)">
    <penji-user-info-item [user_id]="uid" [show_user_avatar]="true"
        [show_user_available_status]="true" [clickable]="true" [user_image_size]="user_image_size"></penji-user-info-item>
  </ng-container>
  <div
    class="relative group h-8 w-8 min-w-[32px] min-h-[32px] rounded-full box-content border border-gray-500 text-gray-500 bg-white flex items-center justify-center hover:bg-transparent hover:border-transparent"
    *ngIf="uid_array.length > 3">
    <span class="material-icons-outlined text-base -mr-0.5">add</span>
    <span class="text-[13px]">{{ uid_array.length - 3 }}</span>
    <div class="absolute hidden group-hover:flex -space-x-3 left-0 z-50">
      <p *ngFor="let uid of uid_array.slice(3, uid_array.length)">
        <penji-user-info-item [user_id]="uid" [show_user_avatar]="true"
        [show_user_available_status]="true" [clickable]="true" [user_image_size]="user_image_size"></penji-user-info-item>
      </p>
    </div>
  </div>
</div>
