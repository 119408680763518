<div [ngSwitch]="pto">
    <p *ngSwitchCase="0" class="space-x-1.5 inline-flex items-center">
        <span *ngIf="style==='design'" class="bg-gray-400 dark:bg-gray-300 rounded-full w-3 h-3"></span>
        <span lass="text-gray-400 dark:text-gray-300">UNSET</span>
    </p>
    <p *ngSwitchCase="1" class="space-x-1.5 inline-flex items-center">
        <span *ngIf="style==='design'" class="bg-blue-500 rounded-full w-3 h-3"></span>
        <span class="text-blue-500">PTO</span>
    </p>
    <p *ngSwitchCase="2" class="space-x-1.5 inline-flex items-center">
        <span *ngIf="style==='design'" class="bg-orange-500 rounded-full w-3 h-3"></span>
        <span class="text-orange-500">UPTO</span>
    </p>
    <p *ngSwitchDefault class="space-x-1.5 inline-flex items-center">
        <span *ngIf="style==='design'" class="bg-gray-400 dark:bg-gray-300 rounded-full w-3 h-3"></span>
        <span class="text-gray-400 dark:text-gray-300">N/A</span>
    </p>
</div>
