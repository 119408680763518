/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DesignerTeam, DrawerService } from '@penji/shared/data-access';
import { DesignerTeamStoreService } from '@penji/team/designer-team/data-access';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-designer-team-name',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzToolTipModule
  ],
  templateUrl: './designer-team-name.component.html',
  styleUrls: ['./designer-team-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignerTeamNameComponent implements OnChanges{

  @Input() designer_team_id!: string;
  @Input() clickable = true;
  @Input() font_size = 'base';
  @Input() classes? = 'text-gray-600';
  @Input() font_weight: 'semibold' | 'regular' = 'semibold';

  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  designer_team$!: Observable<DesignerTeam>;
  drawerSv = inject(DrawerService);

  ngOnChanges() {
    if (this.designer_team_id) {
      this.designer_team$ = this.DesignerTeamStoreService.getOneDesignerTeam(this.designer_team_id);
    }
  }
  openDrawer(id: string) {
    const temp = { type: 'designer-team', id: id }
    this.drawerSv.openDrawer(temp);
  }
}
