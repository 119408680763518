/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { Discussion, DiscussionService, UserData } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, mergeMap, of } from 'rxjs';
import { UploadFileStoreService } from './upload-file-store.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

export interface ProjectUpload {
  id: string,
  message_to_client: string,
  mask_id: string;
  error: string,
  status: 'pending' | 'uploading' | 'completed' | 'failed'
}

export interface ProjectUploadState extends EntityState<ProjectUpload> {
  loading: boolean;
}
export const adapter = createEntityAdapter<ProjectUpload>();
export const initialState: ProjectUploadState = adapter.getInitialState({
  loading: false
})
const { selectAll } = adapter.getSelectors();

@Injectable()
export class ProjectUploadStoreService extends ComponentStore<ProjectUploadState> {

  private readonly discussionSV = inject(DiscussionService);
  private readonly uploadfileStore = inject(UploadFileStoreService);

  readonly data$ = this.select(selectAll);
  readonly loading$ = this.select(s => s.loading);
  constructor() {
    super(initialState);
  }

  readonly addProjectUpload = this.updater((state: ProjectUploadState, item: ProjectUpload) =>
    adapter.upsertOne(item, state)
  );
  readonly updateProjectUpload = this.updater((state: ProjectUploadState, update: Update<ProjectUpload>) =>
    adapter.updateOne(update, state)
  );
  readonly updateStatusProjectUpload = this.updater((state: ProjectUploadState, update: Update<ProjectUpload>) => {
    const list = Object.values(state.entities) as ProjectUpload[];
    if (list.length > 0) {
      const item_exist = list.find(f => f.id === update.id);
      if (item_exist && item_exist.status !== update.changes.status) {
        return adapter.updateOne(update, state);
      }
    }
    return state;
  });


  async removeProjectUpload(id: string) {
    this.setState((state) => adapter.removeOne(id, state));
    await Promise.all([
      this.uploadfileStore.removeFileState(id + '_img'),
      this.uploadfileStore.removeFileState(id + '_zip'),
      this.uploadfileStore.removeFileState(id + '_font')
    ]);
  }
  getOneProjectUpload(project_upload_id: string) {
    return this.select(s => s.entities[project_upload_id])
  }

  submitDesign$ = this.effect((params$: Observable<{ discussion: Discussion, user_data: UserData, modal_ref?: NzModalRef }>) => {
    return params$.pipe(
      mergeMap(params => {
        return this.discussionSV.uploadDesignV2(params.discussion.project_id!, params.discussion, params.user_data).then(rs => {
          if (rs.flag) {
            this.removeProjectUpload(params.discussion.project_id!);
            if (params['modal_ref'])
              params['modal_ref'].close();
          } else {
            // console.log(rs.message);
            this.setState((state) => adapter.updateOne({ id: params.discussion.project_id!, changes: { error: rs.message } }, state));
          }
        }).catch(err => {
          console.log(err);

          this.setState((state) => adapter.updateOne({ id: params.discussion.project_id!, changes: { error: err as string } }, state));
        });
      })
    )
  })
}
