/* eslint-disable */
import { Directive, HostListener, Input, Output, inject, EventEmitter } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { SelectCategoriesLayoutComponent } from '@penji/client-v3/categories/ui/select-categories-layout';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { WorkspaceStoreService } from '@penji/client-v3/workspace/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import * as firebase from 'firebase/firestore';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { take } from 'rxjs';
import { UnlimitedPaymentFailedComponent } from '@penji/client-v3/projects/ui/project-unlimited/unlimited-payment-failed';
@Directive({
  selector: '[penjiCheckSubscriptionPermission]',
  standalone: true,
  providers: [NzModalService]
})
export class CheckSubscriptionPermissionDirective {
  @Input() project_type: 'sub_design' | 'one_design' | 'sub_content' | '' = '';
  @Input() output?: boolean = false;
  @Output() output_event = new EventEmitter();
  
  private readonly membershipStoreSV = inject(MembershipStoreService);
  private readonly workspaceStoreSV = inject(WorkspaceStoreService);
  private readonly customerTeamStoreSV = inject(CustomerTeamStoreService);
  // private readonly afa = inject(AngularFireAuth);
  private readonly modal = inject(NzModalService);
  private readonly router = inject(Router);
  private readonly authStoreSV = inject(AuthStore);
  

  @HostListener('click') onClick() {
    // console.log('click');
    const membershipData$ = this.membershipStoreSV.data$.pipe(take(1)).toPromise();
    const workspaceData$ = this.workspaceStoreSV.data$.pipe(take(1)).toPromise();
    const client_team_id$ = this.customerTeamStoreSV.team_id$.pipe(take(1)).toPromise();
    // const auth$ = this.afa.authState.pipe(take(1)).toPromise();
    const auth_id$ = this.authStoreSV.auth_id$.pipe(take(1)).toPromise();

    Promise.all([membershipData$, workspaceData$, client_team_id$, auth_id$]).then(([memberships, workspace, client_team_id, auth_id]) => {
      if (memberships) {
        const indexUserExist = workspace?.findIndex(f => f.user_id === auth_id && f.team_id === client_team_id);
        // console.log(indexUserExist);
        switch (this.project_type) {
          case 'sub_design': {
            const membership = memberships.find(f => f.team_id === client_team_id);
            if (membership?.metadata && membership.metadata.type === 'sub_design') {
              const numberActive = membership.active;
              if (numberActive === 1 && indexUserExist !== -1) {
                if (this.output) {
                  this.output_event.emit(true);
                } else {
                  this.createProjectUnlimited(client_team_id!);
                }
              } else {
                this.openCheckoutUnlimitedComponent(client_team_id!);
              }
            } else {
              this.openCheckoutUnlimitedComponent(client_team_id!);
            }
            break;
          }
          case 'sub_content': {
            const membership = memberships.find(f => f.team_id === client_team_id);
            // console.log(membership);
            if (membership?.metadata && membership.metadata.type === 'sub_content') {
              const numberActive = membership.active;
              if (numberActive === 1 && indexUserExist !== -1) {
                this.createProjectContent();
              } else {
                this.openCheckoutContentComponent();
              }
            }
            break;
          }
          case 'one_design':
            // this.openCheckoutMarketPlaceComponent();
            this.router.navigate([`/t/${client_team_id}/projects/marketplace/landing/cate/all`]);
            break;
          default:
            break;
        }
      }
      // console.log(memberships, workspace, client_team_id);
    }).catch(err => {
      console.error(err);
    })
  }
  async createProjectUnlimited(client_team_id: string) {
    // alert('create project unlimited!')
    const ref: NzModalRef = this.modal.create({
      nzCentered: true,
      nzContent: SelectCategoriesLayoutComponent,
      nzBodyStyle: { 
        padding: '24px', 
        overflow: 'auto', 
        background: '#fff',
        height: '85vh'
      },
      nzTitle: 'Create a project',
      nzComponentParams: {
        oneoff: false
      },
      nzWidth: '800px',
      nzStyle: { top: '1rem' },
      nzFooter: null,
      nzWrapClassName: 'modal-create-project [&_.ant-modal-title]:text-center [&_.ant-modal-body]:!pt-0'
    });
    const rs = await ref.afterClose.pipe(take(1)).toPromise();
    if (rs) {
      this.router.navigate([`/t/${client_team_id}/projects/unlimited/create/${rs}`]);
    }
  }
  createProjectContent() {
    alert('create project content!')
  }
  openCheckoutMarketPlaceComponent() {
    alert('checkout MarketPlace popup!')
  }
  openCheckoutUnlimitedComponent(client_team_id: string) {
    const ref: NzModalRef = this.modal.create({
      nzCentered: true,
      nzContent: UnlimitedPaymentFailedComponent,
      nzBodyStyle: { 
        padding: '24px', 
        overflow: 'auto', 
        background: '#fff',
      },
      nzComponentParams: {
        client_team_id: client_team_id
      },
      nzWidth: '640px',
      nzStyle: { top: '1rem' },
      nzFooter: null,
    });
  }
  openCheckoutContentComponent() {
    alert('checkout content popup!')
  }
}
