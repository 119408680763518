import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Category, CategoryClass, CategoryClassState } from "@penji/shared/data-access";

export const selectCategoryClassState = createFeatureSelector<CategoryClassState>('category_redux');

export const getAllClass = createSelector(selectCategoryClassState, categoryClassState => {
  const cate_class = Object.values(categoryClassState.entities);
  const all_class = cate_class.filter(a_class=>a_class.order) as CategoryClass[];
  if(all_class.length>0){
    all_class.forEach((a_class, index)=> all_class[index]={
      ...a_class,
      categories: cate_class.filter(cate=>cate.class_id===a_class.id)
    });
  }
  return all_class;
});

export const getAllCategory = createSelector(selectCategoryClassState, categoryClassState => {
  const cate_class = Object.values(categoryClassState.entities);
  const all_category = cate_class.filter(a_class=> !a_class.order).sort((a: any, b:any)=>( a.created_at < b.created_at)?1:-1) as Category[];
  return all_category;
});

export const getCategory = (category_id: string) => createSelector(selectCategoryClassState, categoryClassState => {
  return categoryClassState.entities[category_id] as Category;
});

export const getClass = (class_id: string) => createSelector(selectCategoryClassState, categoryClassState => {
  return categoryClassState.entities[class_id] as CategoryClass;
});
