import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Discussion } from '@penji/shared/data-access';
import { DataFromRefPipe } from '@penji/shared/pipes';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzProgressStatusType } from 'ng-zorro-antd/progress';

@Component({
  selector: 'penji-download-design',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzDropDownModule,
    SvgIconComponent,
    DataFromRefPipe
  ],
  templateUrl: './download-design.component.html',
  styleUrls: ['./download-design.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzNotificationService]
})
export class DownloadDesignComponent {

  @Input() discussion?: Discussion;
  @Input() download_type: 'button' | 'icon' | 'link' = 'button';
  @Input() download_size: 'small' | 'default' | 'large' = 'default';

  httpClient = inject(HttpClient);
  cdr = inject(ChangeDetectorRef);
  now_load = new Date().getTime() / 1000;
  status?: NzProgressStatusType = 'active';
  nzMessageService = inject(NzMessageService);
  notificationSv = inject(NzNotificationService);


  async getFile(url: string) {
    const res = await this.httpClient.get(url, {
      responseType: 'blob'
    }).toPromise().catch((err: HttpErrorResponse) => {
      const error = err.error;
      return error;
    });
    if (res.type === 'error') {
      this.nzMessageService.error('Please try it later!');
    } else {
      return res;
    }
  }
  downloadCallback(meta_data: any) {
    // this.count_percent = meta_data.percent.toFixed(0);
    this.cdr.detectChanges();
  }
  async createZip(files: any[], zip_name: string) {
    this.status = 'active';
    const zip = new JSZip();
    const name = zip_name + '.zip';
    for (let counter = 0; counter < files.length; counter++) {
      const element = files[counter] + '?' + this.now_load;
      const fileData: any = await this.getFile(element);
      const b: any = new Blob([fileData], { type: '' + fileData.type + '' });
      zip.file(files[counter].substring(files[counter].lastIndexOf('/') + 1), b);
    }
    zip.generateAsync({ type: "blob" }, (meta_data:any) => this.downloadCallback(meta_data)).then((content:any) => {
      if (content) {
        FileSaver.saveAs(content, name);
        this.status = 'success';
        setTimeout(() => {
          this.notificationSv.remove();
          this.status = 'active';
          this.cdr.detectChanges();
        }, 1000);
        this.cdr.detectChanges();
      }
    }).catch((error: any)=>{
      this.status = 'exception';
      console.log(error);
      this.cdr.detectChanges();
    })
  }
  async downloadFile(type: string, template: any) {
    const temp_file = [];
    const list = [];
    this.notificationSv.template(template,
      {
        nzPlacement: 'bottomRight',
        nzDuration: 0,
      }
    );
    if(this.discussion){
      if (type === 'multi') {
        if(this.discussion.array_link){
          for (const item of this.discussion.array_link) {
            list.push(item.link);
          }
        }
      } else if (type === 'single') {
        list.push(this.discussion.link);
      } else if (type === 'font') {
        if(this.discussion.list_fonts){
          for (const item of this.discussion.list_fonts) {
            if(item.data){
              temp_file.push(item.data.original);
            }else if(item.media_ref){
              list.push(item.media_ref);
            }
          }
        }
      }
    }
    for (const item of list) {
      const rs = await new DataFromRefPipe().transform(item);
      if(rs&&rs.original) {
        let temp_url_original = rs.original;
        if(rs.version) {
          const match = temp_url_original.match(/(.*)\/(.*)\/(.*)/);
          temp_url_original = `https://s3-storage-penji-cloudfront.s3.amazonaws.com/${match[2]}/${match[3]}`;
        }
        temp_file.push(temp_url_original);
      }
    }
    await this.createZip(temp_file, 'penji-design-' + (this.discussion && this.discussion.number_index) +'-'+ type+ '-' + Math.floor(this.now_load));
  }
}
