import { Injectable, inject } from '@angular/core';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { WorkspaceStoreService } from '@penji/client-v3/workspace/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { combineLatest, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CheckMembershipService {

    private readonly membershipStoreSV = inject(MembershipStoreService);
    private readonly workspaceStoreSV = inject(WorkspaceStoreService);
    private readonly customerTeamStoreSV = inject(CustomerTeamStoreService);
    private readonly authStoreSV = inject(AuthStore);
    
    checkMembership(project_type: 'sub_design' | 'one_design' | 'sub_content' | '' = '') {
        const membershipData$ = this.membershipStoreSV.data$;
        const workspaceData$ = this.workspaceStoreSV.data$;
        const client_team_id$ = this.customerTeamStoreSV.team_id$;
        const auth_id$ = this.authStoreSV.auth_id$;

        return combineLatest(membershipData$, workspaceData$, client_team_id$, auth_id$).pipe(
            switchMap(([memberships, workspace, client_team_id, auth_id]) => {
                // console.log(memberships, workspace, client_team_id, auth_id);
                if (memberships && workspace && client_team_id && auth_id) {
                    const indexUserExist = workspace?.findIndex(f => f.user_id === auth_id && f.team_id === client_team_id);
                    // console.log(indexUserExist);
                    switch (project_type) {
                        case 'sub_design': {
                            const membership = memberships.find(f => f.team_id === client_team_id);
                            if (membership?.metadata && membership.metadata.type === 'sub_design') {
                                const numberActive = membership.active;
                                if (numberActive === 1 && indexUserExist !== -1) {
                                    return of(1);
                                }else if (numberActive === 2 && indexUserExist !== -1) {
                                    return of(2);
                                } else {
                                    return of(0);
                                }
                            } else {
                                return of(0);
                            }
                        }
                        case 'sub_content': {
                            const membership = memberships.find(f => f.team_id === client_team_id);
                            if (membership?.metadata && membership.metadata.type === 'sub_content') {
                                const numberActive = membership.active;
                                if (numberActive === 1 && indexUserExist !== -1) {
                                    return of(1);
                                }else if (numberActive === 2 && indexUserExist !== -1) {
                                    return of(2);
                                } else {
                                    return of(0);
                                }
                            } else {
                                return of(0);
                            }
                        }
                        default:
                            return of(1);
                    }
                } else { 
                    return of(0);
                }
            })
        )
    }
}
