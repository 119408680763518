import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Discussion, DiscussionService } from '@penji/shared/data-access';
import { catchError, EMPTY, map, Observable, switchMap, tap } from 'rxjs';

export interface ViewDesignState extends EntityState<Discussion> {
  loading: boolean;
  success: boolean;
  error: string;
}
export const adapter = createEntityAdapter<Discussion>();
export const initialState: ViewDesignState = adapter.getInitialState({
  loading: false,
  success: false,
  error: ''
});
const { selectAll } = adapter.getSelectors();

@Injectable()
export class ViewDesignStoreService extends ComponentStore<ViewDesignState> {
  private discussionSv = inject(DiscussionService);

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  success$ = this.select((s) => s.success);

  getAllDesign$ = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap((params: any) => {
        let project_id = '';
        if (params) {
          for (const k in params) {
            if (k == 'project_id') {
              project_id = params[k];
            }
          }
        }
        return this.discussionSv.getListDesign(project_id).then(
          (list_discussion: any) => {
            if (list_discussion) {
              this.setState((state) => adapter.setAll(list_discussion, state));
            }
            this.patchState({ loading: false });
            return EMPTY;
          },
          catchError(() => {
            this.patchState({ loading: false });
            return EMPTY;
          })
        );
      })
    );
  });
  updateDesign$ = this.effect((params$: Observable<Discussion>) => {
    return params$.pipe(
      map((params) => {
        if (params) {
          this.setState((state) => adapter.upsertOne(params, state));
        } else {
          this.patchState({ loading: false, error: 'error: update design store failed!' });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
  getOneDesign(params: Params) {
    let discussion_id = '';
    if (params) {
      for (const k in params) {
        if (k == 'discussion_id') {
          discussion_id = params[k];
        }
      }
    }

    return this.select((s) => s.entities[discussion_id])
  }

  constructor() {
    super(initialState);
  }
}
