/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CategoryService, CategoryV2, CategoryV2state } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';

const adapter: EntityAdapter<CategoryV2> = createEntityAdapter<CategoryV2>();
export const initialState: CategoryV2state = adapter.getInitialState({
  loading: false,
  error: '',
});

@Injectable({
  providedIn: 'root'
})
export class CategoryStoreService extends ComponentStore<CategoryV2state> {

  private readonly categorySV = inject(CategoryService);
  constructor() { super(initialState); }
  readonly data$ = this.select(s => {
    const list = Object.values(s.entities).sort((a: any, b: any) => (a.title > b.title) ? 1 : -1) as CategoryV2[];
    return list;
  });
  readonly list_cate_by_parent$ = (parent_id: string = 'root') => this.select(s => {
    const list = Object.values(s.entities).sort((a: any, b: any) => (a.title > b.title) ? 1 : -1) as CategoryV2[];
    return list.filter(f=>f.parent_id === parent_id);
  });
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);
  readonly cate_detail = (cate_id: string)=> this.select(s=>s.entities[cate_id]);

  loadCategories$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => {
        this.patchState({ loading: true })
      }),
      switchMap(() => {
        return this.categorySV.getAllCategoryV2();
      }),
      map(list => {
        if (list && list.length > 0) {
          this.setState((state) => adapter.setAll(list, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false });
        return EMPTY;
      })
    )
  })
}
