<ng-container *ngIf="{list:last_design$ | async} as vm">
  <ng-container *ngIf="vm.list&&vm.list.length > 0; else no_design">
    <ng-container *ngIf="vm.list[0] as last_design">
      <a class="flex group/last rounded overflow-hidden relative bg-gray-100 border border-gray-100 w-[125px] h-[90px]">
        <penji-media-ref [media_ref]="last_design.link" media_size="thumb" [image_auto_fit]="true" image_type="design"></penji-media-ref>
        <span class="hidden bg-black/30 group-hover/last:flex justify-center items-center absolute inset-0 z-10">
          <a (click)="openDesign(tlp_icon, last_design.project_id!, last_design.id, last_design.array_link&&last_design.array_link[0].id_link ?last_design.array_link[0].id_link : last_design.design_id)" nz-button nzSize="small" nzType="default" class="inline-flex !py-0 space-x-2 !border-white items-center !font-normal border-2 !text-[13px] hover:bg-transparent !text-white">
            <span class="material-icons-outlined">
            fullscreen
            </span>
          </a>
        </span>
      </a>
    </ng-container>
  </ng-container>
  <ng-template #no_design>
    <a class="flex rounded overflow-hidden relative bg-gray-100 border border-gray-100 w-[125px] h-[90px]" (click)="openDrawer(project.id!)">
      <penji-media-ref [media_ref]="null" [image_auto_fit]="true" media_size="thumb" image_type="design"></penji-media-ref>
    </a>
  </ng-template>
</ng-container>

<ng-template #tlp_icon>
  <span class="material-icons-outlined">close</span>
</ng-template>
