import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'penji-user-role',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
})
export class UserRoleComponent {

  @Input() role: number | undefined;
  @Input() is_pm?: boolean;
}
