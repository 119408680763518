
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Notification, Notificationstate, NotitficationService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, map, of, switchMap, tap } from 'rxjs';

const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>();
export const initialState: Notificationstate = adapter.getInitialState({
  loading: false,
  error: '',
});

@Injectable()
export class NotitficationStoreService extends ComponentStore<Notificationstate> {

  private readonly notitficationSV = inject(NotitficationService);
  private readonly authStore = inject(AuthStore);
  constructor() { super(initialState); }
  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as Notification[];
    return list;
  });
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);

  loadNotification$ = this.effect((params$:Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true })
      }),
      switchMap(params => {
        return this.authStore.auth_id$.pipe(
            switchMap(auth_id=>{
                if(auth_id)
                    return this.notitficationSV.getListNotification(auth_id,params['type'], params['start_after']);
                return of([] as Notification[]);
            })
        )
      }),
      map(list => {
        if (list && list.length > 0) {
          this.setState((state) => adapter.addMany(list, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false });
        return EMPTY;
      })
    )
  })
}
