import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Ticket } from '@penji/shared/data-access';
import { UserInfoItemComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-info-item';
import { TicketStoreService } from '@penji/team/ticket/data-access';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'penji-ticket-claim-state',
  standalone: true,
  imports: [CommonModule, NzButtonModule, UserInfoItemComponent],
  templateUrl: './ticket-claim-state.component.html',
  styleUrls: ['./ticket-claim-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketClaimStateComponent {
  @Input() size: any = 'small'; //small, default, large
  @Input() ticket!: Ticket;

  ticketStore = inject(TicketStoreService);
  authStore = inject(AuthStore);

  authProfile$ = this.authStore.authProfile$;
  loading = false;

  updateTicket(status: number, user_claimed_id: string | any) {
    if (user_claimed_id != "") {
      this.loading = true;
      const ticket_change = new Ticket();
      ticket_change.id = this.ticket.id;
      ticket_change.status = status;
      ticket_change.user_claimed_id = (status > 1 ? user_claimed_id : '');
      this.ticketStore.updateTicket$(ticket_change);
      this.loading = false;
    }
  }
}

