/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ProjectService } from '@penji/shared/data-access';


@Component({
  selector: 'penji-member-last-seven-days-feedback',
  standalone: true,
  imports: [CommonModule, ],
  templateUrl: './member-last-seven-days-feedback.component.html',
  styleUrls: ['./member-last-seven-days-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberLastSevenDaysFeedbackComponent implements OnInit {
  @Input() uid!: string;
  projectSV = inject(ProjectService);
  result$: Promise<any> | undefined;
  ngOnInit(): void {
    if (this.uid) {
      this.result$ = Promise.all([
        this.projectSV.getTotalProjectCompleteForMember(this.uid, 2),
        this.projectSV.getTotalProjectCompleteForMember(this.uid, 7),
        this.projectSV.getTotalProjectCompleteForMember(this.uid, 4)
      ]);
    }
  }
}
