import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { WorkSpace, WorkspaceService } from '@penji/shared/data-access';
import { tap } from 'rxjs';

export interface WorkSpaceState extends EntityState<WorkSpace> {
    loading: boolean,
    error: string
}
const adapter = createEntityAdapter<WorkSpace>();
const initialState: WorkSpaceState = adapter.getInitialState({
    loading: false,
    error: ''
})
const { selectAll } = adapter.getSelectors();
@Injectable({
    providedIn: 'root'
})
export class WorkSpaceStoreService extends ComponentStore<WorkSpaceState>{

    workspaceSV = inject(WorkspaceService);
    constructor() { super(initialState); }

    data$ = this.select(selectAll);
    loading$ = this.select((s) => s.loading);
    error$ = this.select((s) => s.error);

    getWorkSpaceByCustomer(uid: string) {
        this.patchState({ error: '', loading: true });
        return this.select(this.data$, (list) => list.filter(item => item.user_id === uid)).pipe(
            tap(rs => {
                // console.log(rs);
                if (!rs || rs.length === 0) {
                    this.workspaceSV.getListWorkSpaceFromClient(uid).then(data => {
                        data ? this.patchState((state) => adapter.upsertMany(data, state)) : this.patchState({ error: 'error' });
                    }).catch(err => this.patchState({ loading: false, error: err.message }));
                } else {
                    this.patchState({ loading: false });
                }
            })
        );
    }
}
