<ng-container *ngIf="authProfile$ | async as authProfile">
    <div class="w-full flex justify-between items-center mb-2">
        <h6 class="text-gray-600 capitalize dark:text-white">Calendar Request Off</h6>
    </div>
    <ng-container *ngIf="{request_off_calendar:request_off_calendar$ | async} as vm">
        <ng-container *ngIf="vm.request_off_calendar; else loading">
            <nz-calendar (ngModel)="now" (nzMode)="'month'" (nzSelectChange)="dateChange($event)"
                (nzPanelChange)="panelChange($event)">
                <ul *nzDateCell="let d" class="flex items-center flex-wrap gap-0.5">
                    <li *ngFor="let item of vm.request_off_calendar[d.getDate() + '-' + d.getMonth()]">
                        <penji-user-item-compress [user_id]="item.uid" [show_user_avatar]="true" [clickable]="true"
                            [user_image_size]="'xxs'"></penji-user-item-compress>
                    </li>
                </ul>
                <ng-container *nzMonthCell="let m">
                    <div class="flex items-center">
                        <ng-container
                            *ngFor="let item of vm.request_off_calendar[m.getMonth() + '-' + m.getFullYear()]">
                            <penji-user-item-compress [user_id]="item.uid" [show_user_avatar]="true"
                                [clickable]="true"></penji-user-item-compress>
                        </ng-container>
                    </div>
                </ng-container>
            </nz-calendar>
        </ng-container>
        <ng-template #loading>
            <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
        </ng-template>


    </ng-container>
</ng-container>