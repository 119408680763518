<ng-container [ngSwitch]="membership_status">
  <p *ngSwitchCase="'starter'" nz-dropdown [nzDropdownMenu]="menu" class="bg-green-100
    min-w-[120px] h-6 inline-flex rounded-3xl text-center items-center justify-center
    [&_.dot]:w-1.5 [&>.dot]:h-1.5 [&>.dot]:rounded-full [&>.dot]:mr-1.5
    [&_.text]:text-base [&_.text]:leading-[14px] px-1.5 cursor-pointer
  ">
    <span class="dot bg-green-900"></span>
    <span class="text [&_*]:text-green-900 text-green-900">Starter #<penji-workspace-project-active [client_team_id]="client_team_id"></penji-workspace-project-active></span>
  </p>  
  <p *ngSwitchCase="'marketer'" nz-dropdown [nzDropdownMenu]="menu" class="bg-orange-100
    min-w-[120px] h-6 inline-flex rounded-3xl text-center items-center justify-center
    [&_.dot]:w-1.5 [&>.dot]:h-1.5 [&>.dot]:rounded-full [&>.dot]:mr-1.5
    [&_.text]:text-base [&_.text]:leading-[14px] px-1.5 cursor-pointer
  ">
    <span class="dot bg-orange-900"></span>
    <span class="text [&_*]:text-orange-900 text-orange-900">Marketer #<penji-workspace-project-active [client_team_id]="client_team_id"></penji-workspace-project-active></span>
  </p>
  <p *ngSwitchCase="'agency'" nz-dropdown [nzDropdownMenu]="menu" class="bg-blue-200
    min-w-[120px] h-6 inline-flex rounded-3xl text-center items-center justify-center
    [&_.dot]:w-1.5 [&>.dot]:h-1.5 [&>.dot]:rounded-full [&>.dot]:mr-1.5
    [&_.text]:text-base [&_.text]:leading-[14px] px-1.5 cursor-pointer
  ">
    <span class="dot bg-blue-900"></span>
    <span class="text [&_*]:text-blue-900 text-blue-900">Agency #<penji-workspace-project-active [client_team_id]="client_team_id"></penji-workspace-project-active></span>
  </p>
  <p *ngSwitchCase="'invited'" nz-dropdown [nzDropdownMenu]="menu" class="bg-gray-200
    min-w-[120px] h-6 inline-flex rounded-3xl text-center items-center justify-center
    [&_.dot]:w-1.5 [&>.dot]:h-1.5 [&>.dot]:rounded-full [&>.dot]:mr-1.5
    [&_.text]:text-base [&_.text]:leading-[14px] px-1.5 cursor-pointer
  ">
    <span class="dot bg-gray-400"></span>
    <span class="text [&_*]:text-gray-700 ext-gray-700">Invited #<penji-workspace-project-active [client_team_id]="client_team_id"></penji-workspace-project-active></span>
  </p>
  <p  *ngSwitchCase="'one-off'" nz-dropdown [nzDropdownMenu]="menu" class="bg-purple-100
    min-w-[120px] h-6 inline-flex rounded-3xl text-center items-center justify-center
    [&_.dot]:w-1.5 [&>.dot]:h-1.5 [&>.dot]:rounded-full [&>.dot]:mr-1.5
    [&_.text]:text-base [&_.text]:leading-[14px] px-1.5 cursor-pointer
  ">
    <span class="dot bg-purple-500"></span>
    <span class="text [&_*]:text-purple-700 text-purple-700">One Off #<penji-workspace-project-active [client_team_id]="client_team_id"></penji-workspace-project-active></span>
  </p>
  <!-- <p *ngSwitchCase="'starter'"
    class="bg-green-100 py-1 rounded-3xl space-x-1.5 inline-flex items-center px-3.5">
    <span class="bg-green-500 rounded-full w-3 h-3"></span>
    <span class="text-green-700">Starter</span>
  </p>
  <p *ngSwitchCase="'marketer'"
    class="bg-orange-100 py-1 rounded-3xl space-x-1.5 inline-flex items-center px-3.5">
    <span class="bg-orange-500 rounded-full w-3 h-3"></span>
    <span class="text-orange-700">Marketer</span>
  </p>
  <p *ngSwitchCase="'agency'"
    class="bg-blue-100 py-1 rounded-3xl space-x-1.5 inline-flex items-center px-3.5">
    <span class="bg-blue-500 rounded-full w-3 h-3"></span>
    <span class="text-blue-700">Agency</span>
  </p>
  <p *ngSwitchCase="'invited'"
    class="bg-gray-200 py-1 rounded-3xl space-x-1.5 inline-flex items-center px-3.5">
    <span class="bg-gray-400 rounded-full w-3 h-3"></span>
    <span class="text-gray-700">Invited</span>
  </p>
  <p *ngSwitchCase="'one-off'"
    class="bg-purple-100 py-1 rounded-3xl space-x-1.5 inline-flex items-center px-3.5">
    <span class="bg-purple-500 rounded-full w-3 h-3"></span>
    <span class="text-purple-700">One Off</span>
  </p> -->
</ng-container>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSelectable class="[&_li]:py-2">
    <li nz-menu-item class="cursor-default">Project active: <penji-workspace-project-active class="[&_*]:!text-blue-500 ml-1" [client_team_id]="client_team_id"></penji-workspace-project-active></li>
  </ul>
</nz-dropdown-menu>