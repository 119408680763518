import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'penji-project-review-item',
  standalone: true,
  imports: [CommonModule, ],
  templateUrl: './project-review-item.component.html',
  styleUrls: ['./project-review-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectReviewItemComponent {

  @Input() overdue?: boolean = false;
  @Input() circle_type?: number = 1;

}
