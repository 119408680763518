/* eslint-disable */
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DrawerService, Project } from '@penji/shared/data-access';
import { InputComponent } from '@penji/shared/ui/element/input';
import { CategoryTitleComponent } from '@penji/team/categories/category-ui/category-title';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import { MediaRefComponent } from '@penji/team/media/media-ui/media-ref';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectAssignedComponent } from '@penji/team/project/project-ui/project-assigned';
import { ProjectDeadlineItemComponent } from '@penji/team/project/project-ui/project-deadline-item';
import { ProjectFinalAssignedComponent } from '@penji/team/project/project-ui/project-final-assigned';
import { ProjectLastDesignComponent } from '@penji/team/project/project-ui/project-last-design';
import { ProjectNameComponent } from '@penji/team/project/project-ui/project-name';
import { ProjectReviewComponent } from '@penji/team/project/project-ui/project-review';
import { ProjectStatusComponent } from '@penji/team/project/project-ui/project-status';
import { CommonAlgoliaPaginatorComponent } from '@penji/team/shared/shared-ui/common-algolia-paginator';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { WorkspaceItemV2Component } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-item-v2';
import { WorkspaceLevelV2Component } from '@penji/team/workspace/workspace-ui/workspace-level-v2';
import * as firebase from 'firebase/firestore';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

type DataItem = any;
@Component({
  selector: 'penji-project-w-table',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    ProjectStatusComponent,
    ProjectAssignedComponent,
    DesignerTeamNameComponent,
    CategoryTitleComponent,
    DatePipe,
    NzPopconfirmModule,
    NzAlertModule,
    ProjectReviewComponent,
    CopyItemComponent,
    ProjectDeadlineItemComponent,
    NzButtonModule,
    NzToolTipModule,
    NzDropDownModule,
    UserItemCompressComponent,
    CommonAlgoliaPaginatorComponent,
    ProjectWTableComponent,
    InViewportDirective,
    NzSkeletonModule,
    MediaRefComponent,
    NzTypographyModule,
    ProjectNameComponent,
    ProjectLastDesignComponent,
    WorkspaceItemV2Component,
    WorkspaceLevelV2Component,
    ProjectFinalAssignedComponent
  ],
  templateUrl: './project-w-table.component.html',
  styleUrls: ['./project-w-table.component.scss'],
  providers: [NzModalService, ProjectStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectWTableComponent {
  @Input() list_project: Project[] = [];
  @Input() loading = true;
  @Input() class_table = 'theme-reviewed';
  @Input() container_height = 0;

  drawerSv = inject(DrawerService);
  modal = inject(NzModalService);
  store = inject(ProjectStoreService);
  show_view_port: any = {};

  ngOnChanges() {
    if (this.list_project && (this.class_table=='theme-reviewed' || this.class_table=='theme-unreviewed')) {
      const list_overdue = this.list_project.filter((p: any) => p.time_change <= firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1))));
      const list_others = this.list_project.filter((p: any) => p.time_change > firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1))));
      list_overdue.forEach((rs, index)=> list_overdue[index]={
        ...rs,
        temp_overdue: true
      });
      this.list_project = [...list_overdue, ...list_others];
    }
  }
  openDrawer(id: string) {
    const temp = {
      type: 'project',
      id: id
    }
    this.drawerSv.openDrawer(temp);
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
  trackBy(index:any, item: any) {
    return item.id;
  }

}
