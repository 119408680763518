<div class="inset-0 bg-white dark:bg-gray-700 z-[100] overflow-hidden {{open_modal?'absolute':'fixed'}}"
  *ngIf="{project_data:current_project_data$ | async, current_design:current_design$ | async, list_design:list_design$ | async, auth_id:auth_id$ | async} as vm">
  <ng-container *ngIf="vm.current_design && vm.project_data && vm.auth_id">
    <div class="flex items-center space-x-3 border-b py-2.5 pl-4 {{open_modal?'pr-14':'pr-3'}} sticky top-0 {{vm.current_design.hidden?'bg-yellow-100 border-b-yellow-300 text-gray-600 dark:text-white':'bg-white border-b-gray-200 dark:border-b-gray-500 dark:bg-gray-600'}}">
      <div class="flex-auto">
        <div class="flex items-center space-x-5">
          <a (click)="actionDesign(vm.list_design, vm.current_design.design_id, 'previous')" nz-button nzType="link"
            class="btn-link flex items-center text-gray-600 hover:text-blue-600 dark:text-white"
            [disabled]="checkIndexDesign(vm.list_design, vm.current_design.design_id)===0?true:false">
            <span class="material-icons-outlined">arrow_back</span>
          </a>
          <a (click)="actionDesign(vm.list_design, vm.current_design.design_id, 'next')" nz-button nzType="link"
            class="btn-link flex items-center text-gray-600 hover:text-blue-600 dark:text-white"
            [disabled]="checkIndexDesign(vm.list_design, vm.current_design.design_id)===(vm.list_design&&vm.list_design.length-1)?true:false">
            <span class="material-icons-outlined">arrow_forward</span>
          </a>
          <div class="w-[80%]">
            <p class="inline-flex text-lg font-semibold capitalize truncate" nz-tooltip
              [nzTooltipTitle]="vm.project_data.title">{{vm.project_data.title}}</p>
          </div>
        </div>
      </div>
      <div class="flex-initial w-[50%]">
        <div class="flex items-center space-x-4 justify-end">
          <p class="font-semibold text-gray-600 text-lg mr-3 dark:text-white">Design {{vm.current_design.number_index}}</p>
          <penji-download-design [discussion]="vm.current_design" download_size="small"></penji-download-design>
          <ng-container *ngIf="vm.project_data.payment_v2; else elseTemplate">
            <penji-project-design-status-v2 [client_team_id]="vm.project_data.team_id"
              [project_id]="query_params['project_id']" [discussion_id]="vm.current_design.id"
              [discussion]="vm.current_design"></penji-project-design-status-v2>
          </ng-container>
          <ng-template #elseTemplate>
            <penji-project-design-status [client_team_id]="vm.project_data.team_id"
              [project_id]="query_params['project_id']" [discussion_id]="vm.current_design.id"
              [discussion]="vm.current_design"></penji-project-design-status>
          </ng-template>
          <penji-copy-item [id]="vm.current_design.id" copy_type="design"
            [query_params]="query_params"></penji-copy-item>
          <a *ngIf="!open_modal" (click)="closeModal()"
            class="close flex items-center justify-center text-gray-600 hover:text-blue-500 dark:text-white">
            <span class="material-icons-outlined text-[24px]">close</span>
          </a>
        </div>
      </div>
    </div>
    <div class="flex h-[calc(100%-58px)] w-full overflow-hidden dark:bg-gray-600">
      <div class="left h-full overflow-auto flex-initial border-r lg:w-[10%] w-[10%] {{vm.current_design.hidden?'bg-yellow-100 border-r-yellow-300':'bg-purple-50 border-r-gray-200 dark:bg-gray-600 dark:border-r-gray-500'}} p-3 space-y-2">
        <ng-container *ngIf="vm.current_design.array_link && vm.current_design.array_link.length > 0">
          <ng-container *ngFor="let item of vm.current_design.array_link; trackBy:trackBy">
            <a (click)="changeIdLink(item.id_link)" class="inline-flex relative">
              <penji-media-ref
                class="[&_img]:border-[3px] [&_img]:border-solid [&_img]:rounded-md {{item.id_link === query_params['id_link']?'[&_img]:!border-blue-500':'[&_img]:!border-transparent'}}"
                [media_ref]="item.link" [image_auto_fit]="true"></penji-media-ref>
              <penji-revision-by-idlink class="absolute right-0.5 top-0.5 z-10" [id_link]="item.id_link"
                type="primary"></penji-revision-by-idlink>
            </a>
          </ng-container>
        </ng-container>
      </div>
      <div class="middle h-full flex-auto lg:w-[70%] w-[60%] relative group">
        <div *ngIf="vm.project_data.in_queue === false && vm.project_data.status && vm.project_data.status < 6"
          class="absolute -top-16 left-1/2 -translate-x-1/2 pt-1 z-20 lg:w-auto w-[70%] opacity-50 group-hover:!top-2.5 group-hover:opacity-100 transition-all ease-in-out duration-200">
          <p *ngIf="note_text==='' && new_annotation === null"
            class="flex items-center space-x-1 bg-white/80 py-1.5 px-4 rounded-3xl">
            <span class="block w-6 h-6">
              <svg width="25" height="25" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <g id="XMLID_34_" transform="matrix(0.09064, 0, 0, 0.09064, -60.785084, -46.816319)">
                  <path id="XMLID_104_" d="M880.5,803.4c-0.1,0-0.3,0-0.4,0c-2.5-0.2-4.7-1.8-5.6-4.2l-49.2-136.3c-0.8-2.3-0.3-4.9,1.5-6.7
                    c1.8-1.8,4.4-2.3,6.7-1.5l136.3,49.2c2.4,0.9,4,3.1,4.2,5.6c0.2,2.5-1.2,4.9-3.4,6.1L936.8,733l34.8,34.8c2.5,2.5,2.5,6.5,0,9
                    L947.5,801c-1.2,1.2-2.8,1.9-4.5,1.9c-1.7,0-3.3-0.7-4.5-1.9l-34.8-34.8L886.2,800C885.1,802.1,882.9,803.4,880.5,803.4z
                     M902,749.1c1.7,0,3.3,0.7,4.5,1.9l36.5,36.5l15.1-15.1l-36.5-36.5c-1.4-1.4-2.1-3.5-1.8-5.5c0.3-2,1.6-3.8,3.4-4.7l28.6-14.8
                    L842,671.3l39.6,109.8l14.8-28.6c0.9-1.8,2.7-3.1,4.7-3.4C901.4,749.1,901.7,749.1,902,749.1z"></path>
                </g>
                <path id="XMLID_101_"
                  d="M 14.572 13.649 L 3.081 13.649 C 2.763 13.649 2.5 13.386 2.5 13.069 L 2.5 4.577 C 2.5 4.259 2.763 3.996 3.081 3.996 L 14.572 3.996 C 14.89 3.996 15.153 4.259 15.153 4.577 L 15.153 13.069 C 15.153 13.386 14.89 13.649 14.572 13.649 Z M 3.651 12.489 L 13.993 12.489 L 13.993 5.156 L 3.651 5.156 L 3.651 12.489 Z">
                </path>
              </svg>
            </span>
            <span class="font-semibold text-sm dark:text-gray-500">Click or drag to add a revision</span>
          </p>
          <p *ngIf="note_text!=='' || new_annotation" (click)="cancelAnno()"
            class="flex items-center space-x-2 cursor-pointer bg-white/80 text-blue-500 border border-blue-300 py-1.5 px-4 rounded-3xl">
            <span>Now, add your comment in the comments pane</span> <b class="underline text-red-600">Cancel
              selection</b>
          </p>
        </div>
        <div
          class="absolute flex flex-col space-y-2 top-[50px] z-10 left-3 transition-all ease-in-out invisible opacity-0 group-hover:opacity-100 group-hover:visible">
          <button nz-button nzType="default" nzSize="small" class="w-12" nz-tooltip nzTooltipTitle="Zoom In"
            [disabled]="style_width === 200 ? true : false" (click)="zoomIn()">
            <span class="material-icons-outlined text-[20px]">
              add
            </span>
          </button>
          <button nz-button nzType="default" nzSize="small" class="w-12" nz-tooltip nzTooltipTitle="Zoom Out"
            [disabled]="style_width < 101 ? true : false" (click)="zoomOut()">
            <span class="material-icons-outlined text-[20px]">
              remove
            </span>
          </button>
          <button nz-button nzType="default" nzSize="small" class="w-12" nz-tooltip nzTooltipTitle="Set width default"
            (click)="setDefault()" [disabled]="style_width > 100?false:true">
            <span class="material-icons-outlined text-[20px]">
              restart_alt
            </span>
          </button>
          <p
            class="bg-white h-8 w-12 rounded inline-flex items-center justify-center text-[13px] border border-gray-400">
            {{style_width}}%</p>
        </div>
        <div class="h-full overflow-auto bg-gray-100 shadow-inner dark:bg-gray-600">
          <div [style.width]="style_width+'%'" class="transition-all ease-in-out duration-300 p-2">
            <ng-container *ngIf="vm.current_design.array_link && vm.current_design.array_link.length > 0">
              <ng-container *ngFor="let item of vm.current_design.array_link">
                <ng-container *ngIf="item.id_link === query_params['id_link']">
                  <ng-container *ngIf="(item.link | dataFromRef | async) as img_url; else loading">
                    <penji-view-design-item-image (new_annotation)="getNewAnnotation($event)"
                      [project]="vm.project_data" [reset_annotaton]="reset_annotaton" [image_url]="img_url.original"
                      [id_link]="query_params['id_link']"
                      class="flex [&>div>div]:!block [&>div]:!block [&>div]:w-full [&>div]:h-full"
                      (click)="outFocus()"></penji-view-design-item-image>
                  </ng-container>
                  <ng-template #loading>
                    <div class="absolute inset-0 p-4 flex justify-center">
                      <div class="flex flex-col justify-center">
                        <nz-spin nzTip="Loading Design..." nzSize="large" class="absolute inset-0"></nz-spin>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="right h-full overflow-hidden flex-initial lg:w-[27%] w-[30%] border-l {{vm.current_design.hidden?'bg-yellow-100 border-l-yellow-200':'bg-white border-l-gray-200 dark:bg-gray-600 dark:border-l-gray-500'}}  relative">
        <div
          class="h-full overflow-auto {{vm.project_data.in_queue === false && vm.project_data.status && vm.project_data.status < 6?'pb-[190px]':'pb-4'}} slide-right bg-white dark:bg-gray-600">
          <div class="py-3 px-4 border-b border-gray-200 mb-3">
            <p class="font-semibold mb-1.5 text-gray-700 dark:text-white">Message:</p>
            <div class="pb-2">
              <penji-user-info-item [user_id]="vm.current_design.user_id" [show_user_avatar]="true" [show_user_name]="true" [show_user_available_status]="true" [clickable]="true"></penji-user-info-item>
            </div>
            <div *ngIf="vm.current_design.message_to_client" class="overflow-auto max-h-[128px]">
              <penji-quill-view-html [description]="vm.current_design.message_to_client"></penji-quill-view-html>
            </div>
          </div>
          <penji-view-design-comment-list [id_link]="query_params['id_link']"
            (new_annotation)="getNewAnnotation($event)"></penji-view-design-comment-list>
          <div *ngIf="vm.project_data.in_queue === false && vm.project_data.status && vm.project_data.status < 6"
            class="absolute bottom-0 right-0 left-0 z-[60] border-[3px] border-gray-100 border-t-gray-100 bg-white [&.focus]:border-blue-600 rounded-sm item-add-comment shadow-lg {{check_focus?'focus':''}} dark:bg-gray-600 dark:border-gray-500">
            <div class="relative">
              <quill-editor [preserveWhitespace]="true" [placeholder]="'Add revision...'" customToolbarPosition="bottom"
                class="w-full [&>.ql-container]:border-none [&>.ql-container>.ql-editor]:!text-[15px] [&>.ql-container>.ql-editor]:min-h-[46px] [&_.ql-editing]:!left-0 [&_.ql-editing]:!top-1/2 [&_.ql-editing]:!-translate-y-1/2 [&_.ql-editing_input]:!outline-none [&_.ql-picker-options]:!z-[60] dark:[&_.ql-container]:border-gray-400 dark:text-white dark:[&_.ql-blank::before]:text-white dark:bg-gray-700"
                [(ngModel)]="note_text" [styles]="{height: '78px'}" [modules]="modules" (onFocus)="focusAddComment($event, vm.auth_id)" (onBlur)="blurAddComment($event)">
                <div quill-editor-toolbar class="!pl-2 !pr-0 !py-1.5 bg-gray-100 !border-none dark:bg-gray-600">
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-link"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align" [title]="'Aligment'">
                      <option selected></option>
                      <option value="center"></option>
                      <option value="right"></option>
                      <option value="justify"></option>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color" [title]="'Color'">
                    </select>
                    <select class="ql-background" [title]="'BackgroundColor'">
                    </select>
                  </span>
                </div>
              </quill-editor>
              <div class="flex justify-end space-x-4 py-2.5 px-4">
                <a nz-button nzType="link" nzSize="small" (click)="cancelAnno()"
                  class="btn-link flex items-center underline text-gray-500 dark:text-gray-200 text-[14px]"
                  [disabled]="(note_text===''|| note_text===null) && (!new_annotation || new_annotation === null)">
                  <span>Cancel</span>
                </a>
                <button (click)="addComment(vm.auth_id)" [disabled]="note_text==='' || note_text===null || is_disable"
                  nz-button nzType="primary" nzSize="small" class="btn-primary-blue px-5">Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
