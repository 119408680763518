import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CategoryStoreService } from '@penji/client-v3/categories/data-access';
import { CategoryNameByIdComponent } from '@penji/client-v3/categories/ui/category-name-by-id';
import { ListCategoriesComponent } from '@penji/client-v3/categories/ui/list-categories';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { CategoryV2 } from '@penji/shared/data-access';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject, map, switchMap } from 'rxjs';

@Component({
  selector: 'penji-select-categories-layout',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzListModule,
    NzInputModule,
    ListCategoriesComponent,
    CategoryNameByIdComponent
  ],
  templateUrl: './select-categories-layout.component.html',
  styleUrls: ['./select-categories-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCategoriesLayoutComponent {

  @Input() oneoff: boolean | string = 'all';

  private readonly cateStore = inject(CategoryStoreService);
  private readonly ref = inject(NzModalRef);
  private readonly authStore = inject(AuthStore);
  private readonly membershipStore = inject(MembershipStoreService);

  membership$ = this.membershipStore.list_sub_design$
  auth_profile$ = this.authStore.authProfile$;
  keyword$ = new BehaviorSubject<string>('');
  data$ = this.cateStore.data$.pipe(
    switchMap(list => {
      if (this.oneoff != 'all') {
        list = list.filter(l => l.oneoff == this.oneoff);
      }
      list = list.filter(l => l.hide == false);
      return this.keyword$.pipe(
        map(keyword => {
          if (keyword.trim() !== '') {
            const list_filter = list.filter(f=> f.parent_id !== 'root' && f.title?.toLowerCase().includes(keyword.toLowerCase().trim()));
            const keys_root = Object.keys(this.groupBy(list_filter));
            keys_root.forEach(key=>{
              const data = list.find(f=>f.id === key);
              if(data)
                list_filter.push(data);
            })
            return this.groupBy(list_filter);
          } else {
            return this.groupBy(list);
          }
        })
      )
    })
  );
  isSwitch = false;
  groupBy(xs: CategoryV2[]) {
    return xs.reduce((rv: any, x: any) => {
      (rv[x.parent_id] = rv[x.parent_id] || []).push(x);
      return rv;
    }, {});
  };
  inputValue(value: string) {
    this.keyword$.next(value);
  }

  selectCategory(category_id: string) {
    this.ref.destroy(category_id);
  }
}
