/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ApiService, Project, ProjectService, Team } from '@penji/shared/data-access';
import { BehaviorSubject, EMPTY, Observable, catchError, combineLatest, debounce, debounceTime, distinctUntilChanged, map, mergeMap, of, switchMap, take, tap } from 'rxjs';

interface ProjectState extends EntityState<Project> {
  loading: boolean,
  error: string,
  current_project_data: Project,
  nbHits: number,
  page: number,
  hitsPerPage: number
}
const adapter = createEntityAdapter<Project>();
const initialState: ProjectState = adapter.getInitialState({
  loading: false,
  error: '',
  current_project_data: {} as Project,
  nbHits: 0,
  page: 0,
  hitsPerPage: 0
})
@Injectable()
export class ProjectStoreService extends ComponentStore<ProjectState> {

  projectSV = inject(ProjectService);
  apiSV = inject(ApiService);
  constructor() { super(initialState); }

  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as Project[];
    return list.sort((a: any, b: any) => (a.time_change > b.time_change) ? -1 : 1);
  });
  readonly data_widget$ = this.select(s => {
    const list = Object.values(s.entities) as Project[];
    return list.sort((a: any, b: any) => (a.time_change > b.time_change) ? 1 : -1);
  });
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);
  private readonly nbHits$ = this.select((s) => s.nbHits);
  private readonly hitsPerPage$ = this.select((s) => s.hitsPerPage);
  private readonly page$ = this.select((s) => s.page);
  readonly current_project_data$ = this.select((s) => s.current_project_data);
  readonly vm$ = this.select(
    this.data$,
    this.nbHits$,
    this.hitsPerPage$,
    this.page$,
    (data, nbHits, hitsPerPage, page) => ({
      data,
      nbHits,
      hitsPerPage,
      page
    })
  );
  disable_previous$ = new BehaviorSubject<boolean>(false); // pagination for firestore
  disable_next$ = new BehaviorSubject<boolean>(false); // pagination for firestore
  loadProjects$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
      }),
      switchMap((params: any) => {
        return this.projectSV.getListProjectAlgolia(params).pipe(
          map((result: any) => {
            if (result.success) {
              const data = result.data.hits as Project[];
              // console.log(data);
              this.setState(s => adapter.removeAll(s));
              this.setState(s => adapter.setAll(data, s))
              this.patchState({ nbHits: result.data.nbHits, hitsPerPage: result.data.hitsPerPage, page: result.data.page, loading: false, error: '' });
            } else {
              this.patchState({ loading: false, error: 'error get projects filter api' });
            }
          })
        )
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        throw new Error('cannot get api!');
        return EMPTY;
      })
    )
  });

  loadProjectsForWidget$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
      }),
      debounceTime(300),
      switchMap((params: any) => {
        if (params['order_by'] && params['order_by'] === 'complete_at') {
          if (!params.start_after)
            this.setState(s => adapter.removeAll(s));
          return this.projectSV.listProject(params.limit ?? -1, params.where_query, params.start_after ?? undefined, undefined, params.order_by, params.order_desc, params.not_order_by);
        } else {
          this.setState(s => adapter.removeAll(s));
          return this.projectSV.getListProjectRealTime(params.limit ?? -1, params.where_query, params.start_after ?? undefined, undefined, params.order_by, params.order_desc, params.not_order_by).pipe(
            switchMap(rs => {
              return of(rs);
            }),
            map(rs => {
              return rs;
            })
          );
        }
      }),
      map(list => {
        // console.log("list", list);
        this.patchState({ loading: false, error: '' });
        list.forEach(item => {
          if (item.action === 'added' || item.action === 'modified') {
            this.setState(state => adapter.upsertOne(item, state));
          } else {
            this.setState(state => adapter.removeOne(item.id!, state));
          }
        })
        return list;
      })
    );
  })
  createProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return this.projectSV.addProject(project);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({ loading: false, current_project_data: { ...rs.data } });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((project: Project) => {
        // console.log('update project: ', project);
        return combineLatest(this.projectSV.updateProject(project, project.status_addition ? 'change_status' : undefined), of(project));
      }),
      map(([rs, project]) => {
        if (rs.flag) {
          this.setState((state) => adapter.upsertOne(project, state));
          this.patchState((state) => {
            const temp = { ...state.current_project_data, ...project } as Project;
            return { loading: false, current_project_data: temp }
          });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return combineLatest(this.projectSV.deleteProject(project), of(project));
      }),
      map(([rs, project]) => {
        if (rs.flag) {
          project.status = 8;
          this.setState((state) => adapter.updateOne({ id: project.id!, changes: project }, state));
          this.patchState({ loading: false });
        } else {
          console.log(rs);
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  getProjectDetail$ = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      distinctUntilChanged((prev, curr) => prev['project_id'] === curr['project_id']),
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((params: Params) => {
        // console.log(params);
        return this.projectSV.getProjectDetail(params['project_id']).pipe(
          switchMap(rs => {
            // console.log(rs);
            if (rs) {
              this.patchState({ current_project_data: rs as Project, loading: false, error: '' });
              return EMPTY;
            } else {
              this.patchState({ loading: true, error: 'cannot update the project!' });
              return EMPTY;
            }
          })
        )
      })
    )
  });
  changeStatusProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((project: Project) => {
        return this.projectSV.changeStatus(project.team_id, project.id!, project.status!);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState(state => {
            return { ...state, current_project_data: { ...state.current_project_data, ...rs.data } as Project, loading: false }
          })
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  loadProjectsForRealTime$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
        this.setState(s => adapter.removeAll(s));
      }),
      switchMap((params: any) => {
        // console.log(params);

        return this.projectSV.getListProjectRealTime(-1, params.where_query, undefined, undefined, params.order_by, params.order_desc, params.not_order_by);
      }),
      map(list => {
        this.setState(state => adapter.setAll(list, state));
        this.patchState({ loading: false, error: '' });
        return list;
      })
    );
  })

  moveProject(action: 'move_to_active' | 'move_to_inqueue', team_data: Team, project_data: Project, project_id_out?: string) {
    if (team_data.moved_to_v2) {
      return this.projectSV.moveProjectV2(project_data.id!, project_id_out);
    } else {
      if (action == 'move_to_active') {
        return this.projectSV.moveProjectV1ToActive(team_data.id!, project_data.id!, project_data.status!, project_id_out);
      } else {
        return this.projectSV.moveProjectV1ToInQueue(team_data.id!, project_data.id!);
      }
    }
  }

  removeProjectFromList$ = this.effect((project_id$: Observable<string>) => {
    return project_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project_id: string) => {
        this.setState((state) => adapter.removeOne(project_id, state));
        this.patchState({ loading: false });
        return EMPTY;
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  })
}
