<ng-container *ngIf="{user: user$ | async, list_project: list_project$ | async} as vm">

        <ng-container *ngIf="(loading$ | async); else loaded">
            <p class="text-xs dark:text-gray-200">Loading...</p>
        </ng-container>
        <ng-template #loaded>
            <ng-container *ngIf="{list_new: list_new$ | async, list_revision: list_revision$ | async, list_delivered: list_delivered$ | async} as ls">
              <div class="flex items-center flex-nowrap space-x-1 mb-2">
                <p class="font-medium text-[13px] text-gray-500 dark:text-white">Current Queue:</p>
                <div class="flex items-center space-x-1">
                  <span
                    *ngIf="((ls.list_delivered ?? 0) + (ls.list_revision ?? 0) + (ls.list_new ?? 0)) >= (vm.user?.out_put_design ?? 0)"
                    class="material-icons text-red-500 text-[18px]"
                  >
                    local_fire_department
                  </span>
                  <span class="font-semibold text-sm dark:text-white">{{ (ls.list_delivered ?? 0) + (ls.list_revision ?? 0) + (ls.list_new ?? 0) }}/{{ vm.user?.out_put_design ?? 0 }}</span>
                </div>
              </div>


                <div class="flex flex-wrap gap-0.5 items-center justify-start max-w-[200px]">
                  <span *ngFor="let item of [].constructor(ls.list_new)" nz-tooltip nzTooltipTitle="Project new">
                    <svg width="18" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="33.226" height="8" fill="#4C9FF7" />
                      </svg>
                  </span>
                  <span *ngFor="let item of [].constructor(ls.list_revision)" nz-tooltip nzTooltipTitle="Project revision">
                      <svg width="18" viewBox="0 0 34 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.0668945" width="33.226" height="8" fill="#F97316" />
                      </svg>
                  </span>
                  <span *ngFor="let item of [].constructor(ls.list_delivered)" nz-tooltip nzTooltipTitle="Project delivered">
                      <svg width="18" viewBox="0 0 35 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.779297" width="33.226" height="8" fill="#8B5CF6" />
                      </svg>
                  </span>
                  <ng-container *ngIf="vm.user && vm.user.out_put_design > 0">
                      <ng-container
                          *ngIf="vm.user.out_put_design - ((ls.list_new??0) + (ls.list_revision??0) + (ls.list_delivered??0)) > 0">
                          <span
                              *ngFor="let item of [].constructor(vm.user.out_put_design - ((ls.list_new??0) + (ls.list_revision??0) + (ls.list_delivered??0)))">
                              <svg width="18" viewBox="0 0 35 8" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.779297" width="33.226" height="8" fill="#94A3B8"></rect>
                              </svg>
                          </span>
                      </ng-container>
                  </ng-container>
                </div>

            </ng-container>
        </ng-template>
</ng-container>
