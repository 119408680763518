/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Params } from '@angular/router';
import { environment } from '@penji/shared/environments';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, Observable, catchError, of, retry, switchMap, take, throttleTime, throwError } from 'rxjs';
import { UserData } from '../models/user.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Query } from '@angular/fire/compat/firestore';
import { Team } from '../models/team.model';
import { AuthStore } from '@penji/shared/auth/data-access';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends LogService<UserData> {
  private readonly api_url = environment.api_algolia;
  private readonly http = inject(HttpClient);
  private readonly afAuth = inject(AngularFireAuth);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly authStoreSV = inject(AuthStore);
  getListMemberAlgolia(filter_obj: Params) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          const params = { ...filter_obj };
          for (const key in params) {
            if (key === 'team_designer_id') {
              params[key] = params[key].split(',');
            } else if (key === 'role') {
              if (parseFloat(params[key]) === 4) {
                params[key] = 4;
                params['is_pm'] = false;
              } else if (parseFloat(params[key]) === 4.5) {
                params[key] = 4;
                params['is_pm'] = true;
              } else {
                params[key] = parseInt(params[key]);
              }
            }
          }
          // console.log(params);
          return this.http.post(`${this.api_url}/filter-member`, params, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      }))
  }
  async getListMember(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
    try {
      let query: Query = this.afs.firestore.collection('user');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (limit != -1)
        query = query.limit(limit);

      return query.get().then(querySnapshot => {
        const list: Array<UserData> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as UserData;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }
  getListMemberRealTime(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
    try {
      let query: Query = this.afs.firestore.collection('user');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      } else {
        query = query.limit(10);
      }
      query = query.orderBy('created_at', 'desc');
      if (limit != -1) query = query.limit(limit);

      return new Observable<UserData[]>(observ => {
        return query.onSnapshot(querySnapshot => {
          const list: Array<UserData> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as UserData;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  updateMember(data: UserData, emit_message: 'yes' | 'no' = 'yes') {
    // console.log(data, UserData.parseObject(data));
    return this.afs.firestore.collection('user').doc(data.id).update(UserData.parseObject(data)).then(() => {
      if (emit_message === 'yes') {
        this.log_model.action = 'update';
        this.log_model.data = { ...new UserData, ...data };
        this.createLog();
        this.nzMessageService.success('updated successfully!');
      }
      return { flag: true, message: 'updated successfully!' };
    }).catch(err => {
      console.log(err);
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }
  async disableMember(uid?: string, role?: number, is_pm?: boolean) {
    const batch = this.afs.firestore.batch();
    if (role == 4) {
      batch.update(this.afs.firestore.doc(`designer_project/${uid}`), { disabled: true });
      batch.update(this.afs.firestore.doc(`user/${uid}`), { disabled: true, deleted: true });
      return await batch.commit().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ...new UserData, id: uid, role: role };
        this.createLog();
        this.nzMessageService.success((is_pm ? 'PM' : 'Designer') + ' successfully disabled!');
        return ({ flag: true, message: (is_pm ? 'PM' : 'Designer') + ' successfully disabled!' });
      }).catch(err => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } else if (role == 7) {
      batch.update(this.afs.firestore.doc(`supporter_ticket/${uid}`), { disabled: true });
      batch.update(this.afs.firestore.doc(`user/${uid}`), { disabled: true, deleted: true });
      return await batch.commit().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ...new UserData, id: uid, role: role };
        this.createLog();
        this.nzMessageService.success('Supporter successfully disabled!');
        return ({ flag: true, message: 'Supporter successfully disabled!' });
      }).catch(err => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } else {
      this.nzMessageService.error('You can NOT disable this role');
      return ({ flag: false, message: 'You can NOT disable this role' });
    }
  }
  async createMember(user_data: UserData) {
    try {
      const data = {
        token: '',
        role: user_data.role,
        team_designer_id: user_data?.team_id,
        first_name: user_data.user_info.first_name,
        last_name: user_data.user_info.last_name,
        email: user_data.user_info.email,
        auto_assign: user_data.auto_assign ? user_data.auto_assign : false,
        // assign_revision: user_data.assign_revision ? user_data.assign_revision : false,
        auto_verify: user_data.auto_verify ? user_data.auto_verify : false,
        enable_pto: user_data.enable_pto ? user_data.enable_pto : false,
        out_put_design: user_data.out_put_design,
        //weight: user_data.weight,
        // permission: this.permission,
        // permission_all_team: this.permissionAllTeam,
        // permission_multiple_team: this.permissionAllTeam ? [] : this.listChecked
      }
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        data.token = rs
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.post(environment.api_link + '/api/user/create-new', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            }),
          )
          .toPromise()
          .then((rs: any) => {
            if (rs['success']) {
              this.log_model.action = 'create';
              this.log_model.data = { ... new UserData, ...user_data };
              this.createLog();
              this.nzMessageService.success('User created successfully!');
              return { flag: true, message: 'User created successfully!' };
            } else {
              this.nzMessageService.error(rs['err']);
              return { flag: false, message: rs['err'] };
            }
          })
          .catch(error => {
            this.nzMessageService.error(error.message);
            return { flag: false, message: error.message };
          });
      } else {
        this.nzMessageService.error('Can NOT get token');
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      this.nzMessageService.error('Can NOT get token');
      return { flag: false, message: 'Can NOT get token' };
    }
  }
  async changeTeamForDesigner(current_designer_team_id: string, new_designer_team_id: string, designer_id: string) {
    const user = await this.afs.firestore.doc(`user/${designer_id}`).get().then(res => res.data() as UserData);
    let filteredArr = [];
    filteredArr = user?.permission_multiple_team ? user?.permission_multiple_team.filter(item => item !== current_designer_team_id) : [];
    filteredArr.push(new_designer_team_id);

    if (user?.is_pm) {
      const result = await this.afs.firestore.collection('team').where('payment', '==', true).where('pm_uid', '==', designer_id).orderBy('created_at', 'desc').limit(1).get();
      if (result.size > 0) {
        return ({ flag: false, message: 'Please reassign all client to another PM!' });
      } else {
        await this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_pm/`).doc(designer_id).set({
          type: 'change_team'
        }, { merge: true });

        const batch = this.afs.firestore.batch();
        let tempRef = this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_pm/`).doc(designer_id);
        batch.delete(tempRef);
        tempRef = this.afs.firestore.collection(`team_designer/${new_designer_team_id}/team_pm/`).doc(designer_id);
        batch.set(tempRef, {
          created_at: firebase.Timestamp.now(),
          designer_id: designer_id,
          designer_ref: this.afs.firestore.doc(`user/${designer_id}`),
        }, { merge: true });

        tempRef = this.afs.firestore.collection(`user`).doc(designer_id);
        batch.update(tempRef, {
          // team_designer_active: new_designer_team_id,
          team_id: new_designer_team_id,
          team_ref: this.afs.firestore.doc(`team_designer/${new_designer_team_id}`),
          squad_id: new_designer_team_id,
          permission_multiple_team: filteredArr,
        });

        return await batch.commit()
          .then(() => ({ flag: true, message: 'Success!' }))
          .catch(err => {
            console.log(err);
            return ({ flag: false, message: err.message });
          });
      }
    } else {
      await this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_member/`).doc(designer_id).set({
        type: 'change_team'
      }, { merge: true });

      const batch = this.afs.firestore.batch();
      let tempRef = this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_member/`).doc(designer_id);
      batch.delete(tempRef);
      tempRef = this.afs.firestore.collection(`team_designer/${new_designer_team_id}/team_member/`).doc(designer_id);
      batch.set(tempRef, {
        created_at: firebase.Timestamp.now(),
        designer_id: designer_id,
        designer_ref: this.afs.firestore.doc(`user/${designer_id}`),
      }, { merge: true });

      tempRef = this.afs.firestore.collection(`user`).doc(designer_id);
      batch.update(tempRef, {
        // team_designer_active: new_designer_team_id,
        team_id: new_designer_team_id,
        team_ref: this.afs.firestore.doc(`team_designer/${new_designer_team_id}`),
        squad_id: new_designer_team_id,
        permission_multiple_team: filteredArr,
      });

      return await batch.commit()
        .then(() => {
          this.nzMessageService.success('change successfully to new team!')
          return { flag: true, message: 'Success!' };
        })
        .catch(err => {
          console.log(err);
          this.nzMessageService.error(err.message);
          return ({ flag: false, message: err.message });
        });
    }
  }
  async changeSquadGroupForDesigner(new_squad_id: string, designer_id: string) {
    return this.afs.firestore.doc(`user/${designer_id}`).update({ squad_id: new_squad_id }).then(() => {
      this.nzMessageService.success('change successfully to new squad group!')
      return { flag: true, message: 'success!' };
    }).catch(err => {
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message });
    })
  }

  // update
  listActiveClientInit(pm_uid: string) {
    try {
      const collection = this.afs.firestore.collection('team')
        .where('payment', '==', true)
        .where('pm_uid', '==', pm_uid)
        .orderBy('created_at', 'desc')
        .limit(20);
      return collection.get().then(querySnapshot => {
        const list = Array<Team>();
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Team;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async switchDesignerToPm(designer_team_id: string, designer_id: string) {
    try {
      const kqUpdate = await this.afs.doc(`team_designer/${designer_team_id}/team_member/${designer_id}`).update({ type: 'change_team' }).then(() => { return true }).catch(() => { return false });
      if (kqUpdate) {
        const batch = this.afs.firestore.batch();
        const sfRef = this.afs.firestore.doc(`team_designer/${designer_team_id}/team_member/${designer_id}`);
        batch.delete(sfRef);

        const sfRef2 = this.afs.firestore.doc(`team_designer/${designer_team_id}/team_pm/${designer_id}`);
        batch.set(sfRef2, {
          created_at: firebase.Timestamp.now(),
          designer_ref: this.afs.firestore.doc(`user/${designer_id}`),
          designer_id: designer_id
        });

        const userRef = this.afs.firestore.doc(`user/${designer_id}`);
        batch.update(userRef, {
          'permission.mark_as_review': true,
          'permission.change_project_status': true,
          'permission.manual_assign_designers': true,
          'permission.verify_design': true,
          'permission.mask_feature': true,
          'permission.edit_anyone_comments': true,
        });

        return await batch.commit().then(() => {
          return ({ flag: true, message: 'Designer has been switched to PM!' });
        }).catch(error => {
          return ({ flag: false, message: error.message });
        });
      } else {
        return ({ flag: false, message: 'Could NOT update "change_team"' });
      }
    } catch (error: any) {
      return ({ flag: false, message: error.message });
    }
  }

  async switchPmToDesigner(designer_team_id: string, pm_uid: string) {
    try {
      const listClientTeam = await this.listActiveClientInit(pm_uid);
      // console.log(listClientTeam);
      if (listClientTeam && listClientTeam.length > 0) {
        return ({ flag: false, message: 'Please reassign all client to another PM!' });
      } else {
        const kqUpdate = await this.afs.doc(`team_designer/${designer_team_id}/team_pm/${pm_uid}`).update({ type: 'change_team' }).then(() => { return true }).catch(() => { return false });
        if (kqUpdate) {
          const batch = this.afs.firestore.batch();
          const sfRef = this.afs.firestore.doc(`team_designer/${designer_team_id}/team_pm/${pm_uid}`);
          batch.delete(sfRef);

          const sfRef2 = this.afs.firestore.doc(`team_designer/${designer_team_id}/team_member/${pm_uid}`);
          batch.set(sfRef2, {
            created_at: firebase.Timestamp.now(),
            designer_ref: this.afs.firestore.doc(`user/${pm_uid}`),
            designer_id: pm_uid
          });

          const userRef = this.afs.firestore.doc(`user/${pm_uid}`);
          batch.update(userRef, {
            'permission.mark_as_review': false,
            'permission.change_project_status': false,
            'permission.manual_assign_designers': false,
            'permission.verify_design': false,
            'permission.mask_feature': false,
            'permission.edit_anyone_comments': false,
          });

          return await batch.commit().then(() => {
            return ({ flag: true, message: 'PM has been switched to Designer!' });
          }).catch(error => {
            return ({ flag: false, message: error.message });
          });
        } else {
          return ({ flag: false, message: 'Could NOT update "change_team"' });
        }
      }
    } catch (error: any) {
      return ({ flag: false, message: error.message });
    }
  }
  saveLastFilterRouting(type: 'project' | 'customer' | 'workspace' | 'member' | 'squad-group'
    | 'designer-team' | 'ticket' | 'checkin' | 'request-off' | 'overtime' | 'hr', url: string) {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          let last_url_routing = '';
          if (url.includes('page')) {
            last_url_routing = url.substring(0, url.lastIndexOf('page'));
          } else if (url.includes('start_after')) {
            last_url_routing = url.substring(0, url.lastIndexOf('start_after'));
          } else if (url.includes('end_before')) {
            last_url_routing = url.substring(0, url.lastIndexOf('end_before'));
          } else {
            last_url_routing = url;
          }
          const deleleSymbolQuestion = last_url_routing.substring(last_url_routing.length - 1, last_url_routing.length);
          if (deleleSymbolQuestion === '?' || deleleSymbolQuestion === '&') {
            last_url_routing = last_url_routing.substring(0, last_url_routing.length - 1);
          }
          this.authStoreSV.updateAuthProfile(of({ [`last_${type}_routing`]: last_url_routing } as unknown as UserData));
          return this.updateMember({ id: user.uid, [`last_${type}_routing`]: last_url_routing } as unknown as UserData, 'no');
        }
        return of(user);
      })
    );
  }
}
