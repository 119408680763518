import { EntityState } from "@ngrx/entity";

export class CategoryV2 {
  id?: string;
  parent_id?: string;
  order?: number;
  eta_hours?: number;
  hide?: boolean;
  images?: string;
  point?: number;
  point_bonus?: number;
  title?: string;
  type?: string;
  price?: number;
  style_preference_tag?: string[];
  deadline_hours?: number;
  multiple_deadline_hours?: any[];
  oneoff?: boolean;
  show_delay_notice?: boolean;
  delay_notice?: string;
  show_style_preference?: boolean;
  pages?: any;
  length?: any;
  dimensions?: any;
  dimensions_mobile?: any;
  dimension_multiple!: boolean;
  extra_field?: string[];
  description?: any;
  locked?: boolean;
  log_type?= 'category-v2';
}

export interface CategoryV2state extends EntityState<CategoryV2> {
  loading: boolean,
  error: string
}