/* eslint-disable */
import { Directive, ElementRef, Input, OnInit, Renderer2, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthStore } from '@penji/shared/auth/data-access';
import { PermissionService, initPermission, permission_type } from '@penji/shared/data-access';
import { take } from 'rxjs';

@Directive({
  selector: '[penjiPermission]',
  standalone: true
})
export class PermissionDirective implements OnInit {
  @Input('penjiPermission') extra_action: permission_type = '_ticket-update';
  @Input() style_type: 'wrapper' | 'img' | 'button' | 'circle' | 'link' | 'dropdown' | 'avatar' | '' = '';
  @Input() user_id: string = '';
  private readonly permissionSV = inject(PermissionService);
  private readonly el = inject(ElementRef);
  private readonly render = inject(Renderer2);
  private readonly authSV = inject(AngularFireAuth);
  ngOnInit(): void {
    this.permissionSV.data$.pipe(take(1)).toPromise().then(permission => {
      if (permission && permission['extra-action']) {
        let isCheck = 0;
        for (const key in permission['extra-action']) {
          const temp = { ...permission['extra-action'] } as any;
          if (key === this.extra_action) {
            if (temp[key]) {
              isCheck = 1;
              break;
            }
          }
        }
        if (isCheck === 0) {
          this.reStyleForPermision();
        }
      } else {
        this.reStyleForPermision();
      }
    });
  }
  htmlPermission() {
    setTimeout(() => {
      if (this.style_type === 'img') { // style for image
        const addHtml = `<div class="permision-img">${this.el.nativeElement.innerHTML}</div>`;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else if (this.style_type === 'wrapper') {
        const addHtml = `<div class="permission-wrapper">${this.el.nativeElement.innerHTML}</div>`;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else if (this.style_type === 'circle') {
        this.el.nativeElement.setAttribute('title', `You do not have permission!`);
        this.el.nativeElement.setAttribute('class', 'relative permission-content group');
        const addHtml = `
          <div class="absolute group-hover:bg-gray-900/20 rounded-full inset-0 hidden group-hover:flex items-center justify-center z-10 text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </div>` + this.el.nativeElement.innerHTML;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else if (this.style_type === 'link') { // style for avatar
        this.el.nativeElement.setAttribute('title', `You do not have permission!`);
        this.el.nativeElement.setAttribute('class', 'relative permission-content group');
        const addHtml = `
          <div class="absolute group-hover:bg-gray-900/20 rounded -inset-y-1 -inset-x-1.5 hidden group-hover:flex items-center justify-center z-10 text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </div>` + this.el.nativeElement.innerHTML;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else if (this.style_type === 'button') { // style for avatar
        this.el.nativeElement.setAttribute('title', `You do not have permission!`);
        this.el.nativeElement.setAttribute('class', 'relative permission-content group flex');
        const addHtml = `
          <div class="absolute group-hover:bg-gray-900/20 rounded inset-0 hidden group-hover:flex items-center justify-center z-10 text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </div>` + this.el.nativeElement.innerHTML;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else if (this.style_type === 'dropdown') { // style for dropdown
        this.el.nativeElement.setAttribute('title', `You do not have permission!`);
        this.el.nativeElement.setAttribute('class', 'relative permission-content group');
        const addHtml = `
          <div class="absolute group-hover:bg-gray-900/20 rounded inset-0 hidden group-hover:flex items-center justify-center z-10 text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </div>` + this.el.nativeElement.innerHTML;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else if (this.style_type === 'avatar') { // style for avatar
        this.el.nativeElement.setAttribute('title', `You do not have permission!`);
        this.el.nativeElement.setAttribute('class', 'relative permission-content group');
        const addHtml = `
          <div class="absolute group-hover:bg-gray-900/20 rounded inset-0 hidden group-hover:flex items-center justify-center z-10 text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
            </svg>
          </div>` + this.el.nativeElement.innerHTML;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      } else { // style for default
        this.el.nativeElement.setAttribute('title', `You do not have permission!`);
        this.el.nativeElement.setAttribute('class', 'relative flex w-fit permission-content group');
        const addHtml = `
      <div class="absolute group-hover:bg-gray-900/20 rounded inset-0 hidden group-hover:flex items-center justify-center z-10 text-red-500">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
        </svg>
      </div>` + this.el.nativeElement.innerHTML;
        this.render.setProperty(this.el.nativeElement, 'innerHTML', addHtml);
      }
    }, 300);
  }
  reStyleForPermision() {
    if (this.extra_action === '_customer-view-email') {
      this.render.setProperty(this.el.nativeElement, 'innerHTML', '*********');
    } else if (this.extra_action === '_member-update'
      || this.extra_action === '_request-off-create' || this.extra_action === '_request-off-delete' || this.extra_action === '_request-off-update'
      || this.extra_action === '_overtime-create' || this.extra_action === '_overtime-delete' || this.extra_action === '_overtime-update') {
      if (this.user_id !== '') {
        this.authSV.authState.pipe(take(1)).toPromise().then(auth => {
          // console.log(auth?.uid, this.user_id);
          if (auth?.uid !== this.user_id) {
            this.htmlPermission();
          }
        })
      }
    }
    else {
      this.htmlPermission();
    }
  }
}
