import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Overtime } from '@penji/shared/data-access';
import { PermissionDirective } from '@penji/shared/directives';
import { OvertimeStoreService } from '@penji/team/overtime/data-access';
import { OvertimeCreateUpdateComponent } from '@penji/team/overtime/overtime-ui/overtime-create-update';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import * as firebase from 'firebase/firestore';

@Component({
  selector: 'penji-overtime-button-update',
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzPopoverModule, PermissionDirective],
  templateUrl: './overtime-button-update.component.html',
  styleUrls: ['./overtime-button-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeButtonUpdateComponent {

  @Input() action: string = 'approve'; // approve, reject, update
  @Input() overtime!: Overtime;
  modal = inject(NzModalService);
  overtimeStore = inject(OvertimeStoreService);
  visible = false;
  
  start_of_month = firebase.Timestamp.fromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0));

  approvedOvertime() {
    if (this.overtime) {
      this.overtime.approved = 1;
      this.overtimeStore.updateOvertime$(this.overtime);
    }
    this.visible = false;
  }
  rejectOvertime() {
    if (this.overtime) {
      this.overtime.approved = 2;
      this.overtimeStore.updateOvertime$(this.overtime);
    }
    this.visible = false;
  }
  updateOvertime() {
    const ref: NzModalRef = this.modal.create({
      nzContent: OvertimeCreateUpdateComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        form_type: 'edit',
        overtime: this.overtime
      },
      nzWidth: '450px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
  }
  deleteOvertime() {
    if (this.overtime && this.overtime.id)
      this.overtimeStore.deleteOvertime$(this.overtime.id);
  }
}
