/* eslint-disable */
import { inject, Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { map, take } from 'rxjs';
import { Log } from '../models/log.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { startAfter, where } from 'firebase/firestore';

@Injectable({ providedIn: 'root' })
export class LogService<T>{
    afs = inject(AngularFirestore);
    // authStoreSV = inject(AuthStore);
    // readonly auth_id$ = this.authStoreSV.auth_id$;
    authsv = inject(AngularFireAuth);
    readonly auth_id$ = this.authsv.authState.pipe(map(rs => rs?.uid));
    log_model = new Log<T>;
    async createLog() {
        const uid = await this.auth_id$.pipe(take(1)).toPromise();
        this.log_model.by_user_id = uid;
        // console.log(this.log_model);
        this.afs.firestore.collection('log').add({
            ...this.log_model,
            created_at: firebase.serverTimestamp(),
            data: { ...this.log_model.data }
        }).then(() => {
            // console.log({ flag: true, message: 'Log created successfully!' });
        }).catch(error => {
            console.log({ flag: false, message: error.message });
        });
    }

    async getListLogs(
        limit: number = 20,
        where_query?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string) {
        try {
            let query: Query = this.afs.firestore.collection('log');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', 'desc');
            if (start_after) {
                const doc = await this.afs.firestore.doc(`log/${start_after}`).get();
                query = query.startAfter(doc)
                if (limit != -1) query = query.limit(limit);
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`log/${end_before}`).get();
                query = query.endBefore(doc)
                if (limit != -1) query = query.limitToLast(limit);
            } else {
                if (limit != -1) query = query.limit(limit);
            }

            return query.get().then(querySnapshot => {
                const list = Array<Log<T>>();
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as Log<T>;
                    data.id = doc.id;
                    data.expand = false;
                    list.push(data);
                });
                return list;
            }).catch(err => {
                console.log(err);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }
    async getListLogsAdvance(
        limit: number = 20,
        where_query_and?: Array<WhereQueryInterface>,
        where_query_or?: Array<WhereQueryInterface>,
        start_after?: string,
        end_before?: string) {
        try {
            const constraints_and: firebase.QueryFieldFilterConstraint[] = [];
            const constraints_or: firebase.QueryFieldFilterConstraint[] = [];
            if (where_query_and && where_query_and.length > 0) {
                where_query_and.forEach(q => {
                    constraints_and.push(where(q.field_name, q.field_operator, q.field_value));
                })
            }
            if (where_query_or && where_query_or.length > 0) {
                where_query_or.forEach(q => {
                    constraints_or.push(where(q.field_name, q.field_operator, q.field_value));
                })
            }
            let query = firebase.query(firebase.collection(this.afs.firestore,'log'),
                firebase.and(
                    ...constraints_and,
                    firebase.or(...constraints_or)
                ),
                firebase.orderBy('created_at', 'desc')
            );
            if (start_after) {
                const doc = await this.afs.firestore.doc(`log/${start_after}`).get();
                query = firebase.query(query, startAfter(doc));
                if (limit != -1) query = firebase.query(query, firebase.limit(limit));
            } else if (end_before) {
                const doc = await this.afs.firestore.doc(`log/${end_before}`).get();
                query = firebase.query(query, firebase.endBefore(doc));
                if (limit != -1) query = firebase.query(query, firebase.limitToLast(limit));
            } else {
                if (limit != -1) query = firebase.query(query, firebase.limit(limit));
            }
            // console.log(query);
            return firebase.getDocs(query).then(querySnapshot => {
                const list = Array<Log<T>>();
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as Log<T>;
                    data.id = doc.id;
                    data.expand = false;
                    list.push(data);
                });
                // console.log(list);
                return list;
            }).catch(err=>{
                console.log(err);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }
}
