import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { RequestOff, RequestOffService, WhereQueryInterface } from '@penji/shared/data-access';
import * as firebase from 'firebase/firestore';
import { EMPTY, Observable, catchError, combineLatest, concatMap, debounceTime, map, mergeMap, of, switchMap, tap } from 'rxjs';

interface RequestOffState extends EntityState<RequestOff> {
  loading: boolean,
  success: boolean,
  disable_previous: boolean,
  disable_next: boolean,
  error: string
}
const adapter = createEntityAdapter<RequestOff>();
const initialState: RequestOffState = adapter.getInitialState({
  loading: false,
  success: false,
  disable_previous: false,
  disable_next: false,
  error: ''
})
const { selectAll } = adapter.getSelectors();
@Injectable({
  providedIn: 'root'
})
export class RequestOffStoreService extends ComponentStore<RequestOffState>{

  limit = 20;

  requestoffSV = inject(RequestOffService);
  constructor() { super(initialState); }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  disable_previous$ = this.select((s) => s.disable_previous);
  disable_next$ = this.select((s) => s.disable_next);
  error$ = this.select((s) => s.error);

  loadRequestOffV2$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
      }),
      debounceTime(300),
      switchMap((params: any) => {
        // console.log(params);
        if (!params.start_after)
          this.setState(s => adapter.removeAll(s));
        return this.requestoffSV.listRequestOff(params.limit ?? -1, params.where_query, params.start_after ?? undefined, undefined)
      }),
      map(list => {
        // console.log("list", list);
        this.setState(state => adapter.addMany(list, state));
        this.patchState({ loading: false, error: '' });
      })
    );
  });

  loadRequestOff$ = this.effect((params$: Observable<{}>) => {
    return params$.pipe(
      tap(() => {
        this.setState((state) => adapter.removeAll(state));
        this.patchState({ loading: true, disable_previous: false, disable_next: false, error: '' })
      }),
      switchMap((params: any) => {
        // console.log(params);
        const where_query: Array<WhereQueryInterface> = [];
        let start_after;
        let end_before;
        if (params) {
          for (const k in params) {
            if (k == 'limit') {
              this.limit = params[k];
            } else if (k == 'start_after') {
              start_after = params[k];
            } else if (k == 'end_before') {
              end_before = params[k];
            } else if (k == 'start_at') {
              where_query.push({
                field_name: 'off_at',
                field_operator: '>=',
                field_value: firebase.Timestamp.fromDate(new Date(parseInt(params[k])))
              });
            } else if (k == 'end_at') {
              where_query.push({
                field_name: 'off_at',
                field_operator: '<=',
                field_value: firebase.Timestamp.fromDate(new Date(parseInt(params[k])))
              });
            } else if (k == 'pto' || k == 'approved') {
              where_query.push({
                field_name: k, field_operator: '==', field_value: parseInt(params[k])
              });
            } else {
              where_query.push({
                field_name: k, field_operator: '==',
                field_value: params[k] == 'true' ? true : params[k] == 'false' ? false : params[k]
              });
            }
          }
        }
        return this.requestoffSV.listRequestOff(this.limit, where_query, start_after, end_before).then(rs => {
          if (rs && rs.length > 0) {
            const promise = [
              this.requestoffSV.listRequestOff(1, where_query, undefined, rs[0].id),
              this.requestoffSV.listRequestOff(1, where_query, rs[rs.length - 1].id, undefined)
            ]
            return combineLatest(of(rs), Promise.all(promise)).pipe(
              map(([rs, before_after]) => {
                if (before_after[0] && before_after[0].length > 0) {
                  this.patchState({ disable_previous: false });
                } else {
                  this.patchState({ disable_previous: true });
                }
                if (before_after[1] && before_after[1].length > 0) {
                  this.patchState({ disable_next: false });
                } else {
                  this.patchState({ disable_next: true });
                }
                return rs;
              })
            )
          } else {
            return of([] as RequestOff[]);
          }
        }).catch(err => {
          console.log(err);
          return of([] as RequestOff[]);
        })
      }),
      switchMap(rs => rs),
      map(final_list => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }
        if (final_list.length == 0 || final_list.length < this.limit) {
          this.patchState({ loading: false });
          if (final_list.length == 0) {
            this.patchState({ disable_previous: true, disable_next: true });
          }
        }
      }),
      catchError(err => {
        this.patchState({ loading: false });
        return EMPTY;
      })
    )
  })

  updateRequestOff$ = this.effect((RequestOff$: Observable<RequestOff>) => {
    return RequestOff$.pipe(
      tap(() => this.patchState({
        loading: true,
        error: ''
      })),
      concatMap((requestoff) =>
        this.requestoffSV.updateRequestOff(requestoff).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) => {
                // console.log(requestoff);
                return adapter.updateOne({
                  id: requestoff.id,
                  changes: requestoff
                }, state)
              })
              this.patchState({ loading: false, success: true });
            } else {
              this.patchState({ loading: false, error: rs.message });
            }
            return EMPTY;
          }, catchError(err => {
            this.patchState({ loading: false, error: err as string });
            return EMPTY;
          })
        ))
    )
  });

  deleteRequestOff$ = this.effect((requestoff_id$: Observable<string>) => {
    return requestoff_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((requestoff_id) => this.requestoffSV.deleteRequestOff(requestoff_id).then(
        (rs) => {
          if (rs.flag) {
            this.setState((state) => adapter.removeOne(requestoff_id, state));
            this.patchState({ loading: false, success: true });
          } else {
            this.patchState({ loading: false, error: rs.message });
          }
          return EMPTY;
        }, catchError(err => {
          this.patchState({ loading: false, error: err as string });
          return EMPTY;
        })
      ))
    )
  });

  addRequestOff$ = this.effect((RequestOff$: Observable<RequestOff>) => {
    return RequestOff$.pipe(
      concatMap((requestoff) => this.requestoffSV.addRequestOff(requestoff).then(
        (rs) => {
          if (rs.flag && rs.data) {
            this.setState((state) => adapter.addOne(rs.data, state))
            this.patchState({ loading: false, success: true });
          } else {
            this.patchState({ loading: false, error: rs.message });
          }
          return EMPTY;
        }, catchError(err => {
          this.patchState({ loading: false, error: err as string });
          return EMPTY;
        })
      ))
    )
  });
}
