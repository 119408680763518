import { Injectable, inject } from '@angular/core';
import { AngularFirestore, DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Notification, NotificationNew, RootNotification } from '../models/notitfication.model';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as firebase from 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EMPTY, catchError, map, of, switchMap, take } from 'rxjs';
import { Discussion } from '../models/discussion.model';
import { Project } from '../models/project.model';

@Injectable({
    providedIn: 'root'
})
export class NotitficationService {
    private readonly afs = inject(AngularFirestore);
    private readonly afa = inject(AngularFireAuth);
    private readonly datePipe = inject(DatePipe);
    private readonly nzMessage = inject(NzMessageService);

    getTotalNotification() {
        return this.afa.authState.pipe(
            switchMap(auth => {
                if (auth)
                    return this.afs.collection('notification').doc<RootNotification>(auth?.uid).valueChanges().pipe(
                        catchError(() => EMPTY)
                    );
                return of(undefined);
            })
        )
    }
    getListNotification(user_id: string, type: 'all' | 'upload' | 'message' = 'all', start_after?: QueryDocumentSnapshot<DocumentData>) {
        try {
            let collection = this.afs.firestore.collection(`notification/${user_id}/list_notification`).orderBy('created_at', 'desc');
            if (type === 'upload') {
                collection = collection.where('title', 'in', ['upload new design', 'upload new image', 'upload new file']);
            } else if (type === 'message') {
                collection = collection.where('title', 'in', ['left a message', 'commented on project']);
            }
            if (start_after) {
                collection = collection.startAfter(start_after);
            }
            collection = collection.limit(10);
            return collection.get().then(querySnapshot => {
                const notis = Array<Notification>();
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as Notification;
                    data.id = doc.id;
                    data.doc = doc;
                    data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
                    notis.push(data);
                });
                return notis;
            });
        } catch (error) {
            console.log(error);
            return Promise.resolve([] as Notification[]);
        }
    }
    addNotificationToClient(discussion: Discussion) {
        const notification = new Notification();
        notification.created_at = firebase.Timestamp.now();
        notification.project_id = discussion.project_id;
        notification.project_ref = this.afs.firestore.doc(`project_v2/${discussion.project_id}`);
        notification.read = false;
        notification.sender_id = discussion.user_id;
        notification.sender_ref = discussion.user_ref;
        notification.team_id = discussion.client_team_id ?? '';
        notification.content = discussion.content ?? '';
        if (discussion.type === 'text') {
            notification.title = 'left a message';
        } else if (discussion.type === 'image') {
            notification.title = 'upload new image';
        } else if (discussion.type === 'file') {
            notification.title = 'upload new file';
        } else if (discussion.type === 'design') {
            notification.title = 'upload new design';
        }
        this.afs.firestore.doc(`project_v2/${discussion.project_id}`).get().then(rs => {
            if (rs.exists) {
                const project = rs.data() as Project;
                if (project.owner_id !== notification.sender_id) {
                    notification.recipient_id = project.owner_id;
                    notification.recipient_ref = this.afs.firestore.doc(`user/${project.owner_id}`);;
                    this.afs.firestore.collection(`notification/${project.owner_id}/list_notification`).add({ ...notification }).then(() => {
                        console.log('add a notification to client successfully!');
                    });
                    this.afs.collection('notification').doc(project.owner_id).set({
                        total_unread: firebase.increment(1)
                    }, {merge: true});
                }
            }
        })
    }
    async markAllAsRead(list: Notification[]) {
        const batch = this.afs.firestore.batch();
        list.forEach(notiDocRef => {
            if (notiDocRef.doc) {
                batch.update(notiDocRef.doc.ref, { read: true });
            }
        })
        return await batch.commit()
            .then(() => {
                this.nzMessage.success('mark All As Read successfully!')
                return ({ flag: true, message: 'mark All As Read successfully!' })
            })
            .catch(err => {
                console.log(err);
                this.nzMessage.error(err.message);
                return ({ flag: false, message: err.message });
            });
    }
    async updateTotal() {
        const result = await this.afa.authState.pipe(
            switchMap(auth => {
                if (auth) {
                    return this.afs.firestore.collection('notification').doc(auth.uid).set({
                        total_unread: 0
                    }, { merge: true }).then(() => ({ flag: true, message: 'success!' }));
                }
                return of({ flag: false, message: 'success!' });
            })
        ).pipe(take(1)).toPromise();
        if (result) {
            if (result.flag)
                this.nzMessage.success(result.message);
            else {
                this.nzMessage.error(result.message);
            }
        }
    }

    /***************** old team app notification system ******************/
    //type: team(1), project(2), client(3), ticket(4)
    async listNotificationInit(user_id: string, noti_type: number = 0, limit = 10, start_after?: QueryDocumentSnapshot<DocumentData>) {
        try {
            let query = this.afs.firestore.collection('user').doc(user_id)
                .collection('notification').orderBy('created_at', 'desc');
            if (noti_type > 0) {
                query = query.where('noti_type', '==', noti_type);
            }
            if (start_after) {
                query = query.startAfter(start_after);
            }
            query = query.limit(limit);
            return query.get().then(querySnapshot => {
                const notis = Array<NotificationNew>();
                querySnapshot.forEach((doc) => {
                    const data = doc.data() as NotificationNew;
                    data.id = doc.id;
                    data.doc = doc;
                    data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y') ?? '';
                    notis.push(data);
                });
                // console.log(notis);
                return notis;
            });
        } catch (error) {
            console.log(error);
            return Promise.resolve([]);
        }
    }
    updateNotification(user_id: string, notification_id: string, notification: NotificationNew) {
        try {
            return this.afs.collection('user').doc(user_id)
                .collection('notification').doc(notification_id).update(notification)
                .then(() => ({ flag: true, message: 'Success!' }))
                .catch(err => {
                    console.log(err);
                    return ({ flag: false, message: err.message });
                });
        } catch (err: any) {
            return ({ flag: false, message: err.message as string });
        }
    }
}
