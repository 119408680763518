<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
    <div class="flex justify-between items-center pb-2 pr-20">
        <h6 class="text-gray-600 capitalize dark:text-white">List Unclaimed Tickets</h6>
        <a [routerLink]="['/ticket']" [queryParams]="{status: '1,2'}"
            class="view-all !text-blue-500 hover:!text-blue-600 hidden">View All</a>
    </div>

    <ng-container *ngIf="{ticket : data$ | async, auth_id: auth_id$ | async} as vm">
        <ng-container *ngIf="vm.ticket; else loading">
            <nz-table #table_list [nzData]="vm.ticket" [nzBordered]="false" [nzFrontPagination]="false"
                [nzShowPagination]="false" [nzSize]="'middle'" class="theme theme-default"
                [nzScroll]="{ x: '36.146vw', y: container_height - 120+ 'px'}">
                <thead>
                    <tr>
                        <th nzLeft nzWidth="200px">Title</th>
                        <th nzWidth="180px">Client</th>
                        <th nzWidth="80px">Claimed</th>
                        <th nzWidth="100px">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
                        <tr
                            [ngClass]="vm.auth_id && item.viewer && item.viewer.includes(vm.auth_id)?'[&>td]:!bg-purple-100':''">
                            <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">

                                <td nzLeft nzWidth="200px">
                                    <penji-ticket-cate-item [ticket]="item" [clickable]="true"></penji-ticket-cate-item>
                                </td>
                                <td nzWidth="180px">
                                    <penji-user-item-compress [user_id]="item.client_id"
                                        [show_user_name]="true"></penji-user-item-compress>
                                </td>
                                <td nzWidth="75px">
                                    <penji-ticket-claim-state [ticket]="item"></penji-ticket-claim-state>
                                </td>
                                <td nzWidth="100px" class="dark:text-gray-200">
                                    <a class="line-clamp-1" nz-tooltip
                                        [nzTooltipTitle]="(item.created_at.toDate() | date:'medium')">
                                        {{ item.created_at.toDate() | date:'mediumDate' }}
                                    </a>
                                </td>
                            </ng-container>
                        </tr>
                        <ng-template #loading_tr>
                            <td [colSpan]="4" inViewport (inViewportAction)="viewPort($event, item.id!)">
                                <nz-skeleton [nzActive]="true" [nzTitle]="false"
                                    [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
                            </td>
                        </ng-template>
                    </ng-container>
                </tbody>
            </nz-table>
        </ng-container>
        <ng-template #loading>
            <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
        </ng-template>
    </ng-container>
</div>