import { Injectable, inject } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { Checkin, CheckinOld } from '../models/checkin.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { MemberService } from './member.service';
import { EMPTY, Observable, catchError, map, of, shareReplay, switchMap, take } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class CheckinService extends LogService<Checkin>{

  memberSV = inject(MemberService);
  datePipe = inject(DatePipe);
  private readonly nzMessageService = inject(NzMessageService);

  async listCheckin(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      // console.log(where_query);
      let query: Query = this.afs.firestore.collection('checkin_v2');
      if (where_query && where_query.length > 0) {
        where_query.forEach(async q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        const doc = await this.afs.firestore.doc(`checkin_v2/${start_after}`).get();
        query = query.startAfter(doc).limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`checkin_v2/${end_before}`).get();
        query = query.endBefore(doc).limitToLast(limit);
      } else {
        query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Checkin> = [];
        querySnapshot.forEach((doc: any) => {
          const data = doc.data() as Checkin;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  getListCheckinRealTime(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
    try {
      let query: Query = this.afs.firestore.collection('checkin_v2');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      } else {
        query = query.limit(10);
      }
      query = query.orderBy('created_at', 'desc');
      if (limit != -1) query = query.limit(limit);

      return new Observable<Checkin[]>(observ => {
        return query.onSnapshot(querySnapshot => {
          const list: Array<Checkin> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as Checkin;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        },
          error => {
            observ.error(error);
          })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  getCheckinDetail(checkin_id: string) {
    return this.afs.doc<Checkin>(`checkin_v2/${checkin_id}`).valueChanges({ idField: 'id' }).pipe(catchError(() => EMPTY));
  }

  getCheckinDetailByUID(uid: string) {
    const __checkin_id = uid + '_' + this.datePipe.transform(firebase.Timestamp.now().toDate().getTime(), 'yyyy-MM-dd') as string;
    return this.afs.doc<Checkin>(`checkin_v2/${__checkin_id}`).valueChanges().pipe(map(item => {
      if (item) {
        item.id = __checkin_id;
        return item;
      } else {
        return item;
      }
    },
      catchError(() => EMPTY)
    ), shareReplay());
  }

  // trigger
  // update lateness on checkin_v2 & update team_designer_id
  addCheckin(checkin: Checkin) {
    const __checkin_id = checkin.uid + '_' + this.datePipe.transform(checkin?.checkin_at?.toDate().getTime(), 'yyyy-MM-dd') as string;
    return this.afs.firestore.doc(`checkin_v2/${__checkin_id}`).set({ ...checkin }).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new Checkin, ...checkin };
      this.createLog();
      this.nzMessageService.success('Checkin successfully added!');
      return ({ flag: true, message: 'Checkin successfully added!', data: { ...checkin, id: __checkin_id } });
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message, data: {} as Checkin });
    });
  }

  // trigger
  // update lateness on checkin_v2
  updateCheckin(checkin: Checkin) {
    delete checkin.doc;
    return this.afs.firestore.doc(`checkin_v2/${checkin.id}`).update({ ...checkin }).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new Checkin, ...checkin };
      this.createLog();
      this.nzMessageService.success('Checkin successfully updated!');
      return ({ flag: true, message: 'Checkin successfully updated!', data: { ...checkin } });
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message, data: null });
    });
  }

  deleteCheckin(checkin_id: string) {
    return this.afs.firestore.doc(`checkin_v2/${checkin_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new Checkin, ticket_id: checkin_id } as Checkin;
      this.createLog();
      this.nzMessageService.success('Checkin successfully deleted!');
      return ({ flag: true, message: 'Checkin successfully deleted!' });
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }

  findCheckinToday(uid: string) {
    try {
      const collection = this.afs.collection(`checkin_v2`, ref => {
        return ref.where('uid', '==', uid)
          .where('created_at', '>=', firebase.Timestamp.fromDate(new Date(new Date().setHours(0, 0, 0))))
          .where('created_at', '<=', firebase.Timestamp.fromDate(new Date(new Date().setHours(23, 59, 59))))
          .limit(1);
      });

      return collection.snapshotChanges().pipe(
        switchMap(result => {
          if (result && result.length > 0) {
            const data = result[0].payload.doc.data() as Checkin;
            data.id = result[0].payload.doc.id;
            return of(data);
          }
          return of(null);
        }),
        catchError(() => EMPTY)
      );
    } catch (error) {
      console.log(error);
      return of(null);
    }
  }

  // add, edit, delete checkin old ver =========================== start
  async addCheckinVer5(checkin: Checkin) {
    if(checkin && checkin.checkin_at){
      const __checkin_id = this.datePipe.transform(checkin.checkin_at.toDate().getTime(), 'yyyy-MM-dd') as string;
      const find = await this.afs.collection(`user/${checkin.uid}/checkin`).doc(__checkin_id).valueChanges().pipe(take(1)).toPromise();
      if (!find) {
        const data = new CheckinOld();
        data.uid = checkin.uid;
        data.clock_in = checkin.checkin_at ? checkin.checkin_at : null;
        data.clock_out = checkin.checkout_at ? checkin.checkout_at : null;
        data.created_at = checkin.created_at;
        data.new_project = 0;
        data.off = checkin.pto == 0 ? false : true;
        data.pto = checkin.pto == 1 ? true : false;
        data.revision_project = 0;
        data.time_work = 0;
        data.total_point = 0;
        data.over_time = false;
        data.note = checkin.note ? checkin.note : '';
        data.log_type = checkin.log_type ? checkin.log_type : '';
        // data.lateness = checkin.lateness ? checkin.lateness : false;
        return this.afs.firestore.doc(`user/${checkin.uid}/checkin/${__checkin_id}`).set({ ...data }).then(rs => {
          // console.log(rs);
          this.log_model.action = 'create';
          this.log_model.data = { ... new Checkin, ...checkin };
          this.createLog();
          this.nzMessageService.success('Checkin successfully added!');
          return ({ flag: true, message: 'Checkin successfully added!', data: { ...checkin, id: checkin.uid + '_' + __checkin_id } });
        }).catch((err: any) => {
          this.nzMessageService.error(err.message);
          return ({ flag: false, message: err.message, data: {} as Checkin });
        });
      } else {
        return ({ flag: false, message: 'Checkin already exists!', data: {} as Checkin });
      }
    } else {
      return ({ flag: false, message: 'Checkin is not available!', data: {} as Checkin });
    }
  }

  async updateCheckinVer5(checkin: Checkin) {
    delete checkin.doc;
    const _key_arr = checkin.id.trim().split('_');
    if (_key_arr.length > 1) {
      const __uid = _key_arr[0];
      const __checkin_id = _key_arr[1];
      // convert data checkin to checkinold
      const data = new CheckinOld();
      data.uid = __uid;
      data.clock_in = checkin.checkin_at ? checkin.checkin_at : null;
      data.clock_out = checkin.checkout_at ? checkin.checkout_at : null;
      data.created_at = checkin.created_at;
      data.new_project = 0;
      data.off = checkin.pto == 0 ? false : true;
      data.pto = checkin.pto == 1 ? true : false;
      data.revision_project = 0;
      if (checkin.checkout_at && checkin.checkin_at){
        data.time_work = ((checkin.checkout_at.seconds - checkin.checkin_at.seconds) / 3600) | 0;
      }
      data.total_point = 0;
      data.over_time = false;
      data.note = checkin.note ? checkin.note : '';
      data.log_type = checkin.log_type ? checkin.log_type : '';
      // data.lateness = checkin.lateness ? checkin.lateness : false;
      return this.afs.firestore.doc(`user/${__uid}/checkin/${__checkin_id}`).update({ ...data }).then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ... new Checkin, ...checkin };
        this.createLog();
        this.nzMessageService.success('Checkin successfully updated!');
        return ({ flag: true, message: 'Checkin successfully updated!', data: { ...checkin } });
      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message, data: null });
      });
    } else {
      return this.updateCheckin(checkin);
    }
  }

  async deleteCheckinVer5(checkin_id: string) {
    const _key_arr = checkin_id.trim().split('_');
    if (_key_arr.length > 1) {
      const __uid = _key_arr[0];
      const __checkin_id = _key_arr[1];
      return this.afs.firestore.doc(`user/${__uid}/checkin/${__checkin_id}`).delete().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new Checkin, id: checkin_id } as Checkin;
        this.createLog();
        this.nzMessageService.success('Checkin successfully deleted!');
        return ({ flag: true, message: 'Checkin successfully deleted!' });
      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } else {
      return this.deleteCheckin(checkin_id);
    }
  }
  // add, edit, delete checkin old ver =========================== end
}
