<ng-container *ngIf="{user:user$ | async, auth_profile: auth_profile$ | async} as vm">
  <ng-container *ngIf="vm.user; else noUser">
    <div class="flex items-center space-x-3 user-item">
      <div class="user-avatar" *ngIf="user_avatar" [style.width.px]="setImageSize" nz-tooltip [nzTooltipTitle]="clickable?vm.user.first_name+' '+vm.user.last_name:null">
        <div class="overflow-hidden rounded-full" [style.width.px]="setImageSize" [style.height.px]="setImageSize">
          <ng-container *ngIf="clickable && vm.user.id !== '8i7FhXui3qU9Q2DWgIOY3t3oV3l1'; else no_click">
            <a [title]="vm.user.first_name+' '+vm.user.last_name" class="cursor-default">
              <ng-container *ngTemplateOutlet="avatar"></ng-container>
            </a>
          </ng-container>
          <ng-template #no_click>
            <ng-container *ngTemplateOutlet="avatar"></ng-container>
          </ng-template>
        </div>
      </div>
      <div class="w-auto flex-col items-center user-contents {{setImageSize===48?'space-y-1':setImageSize===56?'space-y-1.5':''}}" *ngIf="user_name || user_email || user_role">
        <ng-container *ngTemplateOutlet="name"></ng-container>
        <ng-container *ngTemplateOutlet="email"></ng-container>
        <ng-container *ngTemplateOutlet="role"></ng-container>
      </div>
    </div>

    <ng-template #avatar>
      <ng-container *ngIf="user_avatar">
        <penji-media-ref [media_ref]="vm.user.avatar" [media_size]="media_size"></penji-media-ref>
      </ng-container>
    </ng-template>
    <ng-template #name>
      <ng-container *ngIf="clickable && vm.user.id !== '8i7FhXui3qU9Q2DWgIOY3t3oV3l1'; else no_click">
        <a [title]="vm.user.first_name+' '+vm.user.last_name" class="cursor-default hover:text-primary-500 dark:text-white">
          <p *ngIf="vm.user.first_name && vm.user.last_name && user_name" [style.font-size.px]="font_size" class="flex items-center {{width_name}}">
            <span nz-tooltip [nzTooltipTitle]="vm.user.first_name+' '+vm.user.last_name" class="font-{{font_weight}} capitalize truncate user-name">{{ vm.user.first_name }} {{ vm.user.last_name }}</span>
          </p>
        </a>
      </ng-container>
      <ng-template #no_click>
        <p *ngIf="vm.user.first_name && vm.user.last_name && user_name" [style.font-size.px]="font_size" class="flex items-center {{width_name}} dark:text-white">
          <span nz-tooltip [nzTooltipTitle]="vm.user.first_name+' '+vm.user.last_name" class="font-{{font_weight}} capitalize truncate user-name">{{ vm.user.first_name }} {{ vm.user.last_name }}</span>
        </p>
      </ng-template>
    </ng-template>
    <ng-template #email>
      <p class="flex items-center dark:text-gray-100" *ngIf="vm.user.email && user_email" [style.font-size.px]="font_size" nz-typography nzCopyable [nzCopyText]="vm.user.email">
        {{ vm.user.email }}
      </p>
    </ng-template>
    <ng-template #role>
      <p *ngIf="user_role && vm.user.role" [style.font-size.px]="font_size">
        <ng-container *ngIf="vm.user.id === vm.auth_profile?.id; else Role">
          <p>You</p>
        </ng-container>
        <ng-template #Role>
          <penji-user-role  [role]="vm.user.role"></penji-user-role>
        </ng-template>
      </p>
    </ng-template>
    <ng-template #email2>
      <p class="flex items-center" nz-typography nzCopyable [nzCopyText]="vm.user.email">
        <a class="text-blue-500 hover:text-blue-600" href="mailto:{{vm.user.email}}">{{vm.user.email}}</a>
      </p>
    </ng-template>
  </ng-container>
  <ng-template #noUser>
    <nz-skeleton-element
        nzType="avatar"
        [nzActive]="true"
      ></nz-skeleton-element>
  </ng-template>
</ng-container>
