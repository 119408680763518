<ng-container *ngIf="discussion">
  <ng-container [ngSwitch]="download_type">
    <ng-container *ngSwitchCase="'icon'">
        <span class="material-icons-outlined text-[24px] cursor-pointer text-gray-500 dark:text-white hover:text-blue-600" nzTrigger="click" [nzPlacement]="'bottomRight'" nz-dropdown [nzDropdownMenu]="menuDownLoad" [nzOverlayClassName]="'!mt-4'">
          file_download
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="'link'">
      <a class="flex space-x-1.5 items-center justify-center text-gray-700 dark:text-white hover:text-blue-600 hover:border-blue-600 [&>span]:leading-[1]" nz-button nzType="link" [nzSize]="download_size" nzTrigger="click" [nzPlacement]="'bottomRight'" nz-dropdown [nzDropdownMenu]="menuDownLoad" [nzOverlayClassName]="'!mt-4'">
        <span class="material-icons-outlined text-[21px]">
          file_download
        </span>
        <span>Download All</span>
      </a>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <button class="flex items-center justify-center space-x-1.5 text-gray-700 dark:text-white hover:text-blue-600 hover:border-blue-600 [&>span]:leading-[1]" nzType="default" [nzSize]="download_size" nzTrigger="click" [nzPlacement]="'bottomRight'" nz-button
        nz-dropdown [nzDropdownMenu]="menuDownLoad" [nzOverlayClassName]="'!mt-4'">
        <span class="material-icons-outlined text-[20px]">
          file_download
        </span>
        Download
      </button>
    </ng-container>
  </ng-container>

  <nz-dropdown-menu #menuDownLoad="nzDropdownMenu">
    <ul nz-menu class="min-w-[180px] [&>li]:py-2.5 [&>li]:px-4">
      <ng-container *ngIf="discussion.multiple_link; else single">
        <li nz-menu-item (click)="downloadFile('multi', template_download)" class="flex items-center space-x-1.5  [&_.material-icons-outlined]:text-gray-400 dark:[&_.material-icons-outlined]:text-gray-200 [&_.material-icons-outlined]:text-[26px] [&_.material-icons-outlined]:hover:text-blue-600 hover:text-blue-600 dark:text-white [&>span]:leading-[1]">
          <span class="material-icons-outlined">
            image
          </span>
          <span>Download preview <b>JPG</b></span>
        </li>
      </ng-container>
      <ng-template #single>
        <li nz-menu-item (click)="downloadFile('single', template_download)" class="flex items-center space-x-1.5  [&_.material-icons-outlined]:text-gray-400 dark:[&_.material-icons-outlined]:text-gray-200 [&_.material-icons-outlined]:text-[26px] [&_.material-icons-outlined]:hover:text-blue-600 hover:text-blue-600 dark:text-white [&>span]:leading-[1]">
          <span class="material-icons-outlined">
            image
          </span>
          <span>Download preview <b>JPG</b></span>
        </li>
      </ng-template>
      <ng-container *ngIf="discussion.list_fonts && discussion.list_fonts.length > 0">
        <li nz-menu-item (click)="downloadFile('font', template_download)" class="flex items-center space-x-1.5  [&_.material-icons-outlined]:text-gray-400 dark:[&_.material-icons-outlined]:text-gray-200 [&_.material-icons-outlined]:text-[26px] [&_.material-icons-outlined]:hover:text-blue-600 hover:text-blue-600 dark:text-white [&>span]:leading-[1]">
          <span class="material-icons-outlined">
            text_fields
          </span>
          <span>Download fonts <b>TTF</b></span>
        </li>
      </ng-container>
      <ng-container *ngIf="discussion.source && (discussion.source | dataFromRef | async) as media">
        <li nz-menu-item>
          <a href="{{media.original}}" download target="_blank" class="flex items-center space-x-1.5 w-full [&_.material-icons-outlined]:text-gray-400 dark:[&_.material-icons-outlined]:text-gray-200 [&_.material-icons-outlined]:text-[26px] dark:text-white [&_.material-icons-outlined]:hover:text-blue-600 hover:text-blue-600 [&>span]:leading-[1]">
            <span class="material-icons-outlined">
              folder_zip
            </span>
            <span>Download source <b>ZIP</b></span>
          </a>
        </li>
      </ng-container>
    </ul>
  </nz-dropdown-menu>

  <ng-template #template_download>
    <div class="ant-notification-notice-content">
      <div class="ant-notification-notice-with-icon">
        <span class="ant-notification-notice-icon">
          <svg class="mt-1" width="18" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.125 4.78216H9.625V0H4.375V4.78216H0.875L7 11.1584L13.125 4.78216ZM0 12.7524H14V14.3465H0V12.7524Z"
              fill="#4b9ff7" />
          </svg>
        </span>
        <div class="ant-notification-notice-message font-semibold mb-2">Preparing download</div>
        <div class="ant-notification-notice-description flex items-center">
          <span class="mr-2 mt-1">Preparing your files...</span>
          <ng-container [ngSwitch]="status">
            <ng-container *ngSwitchCase="'success'">
              <span class="material-icons-outlined text-green-500 text-[18px]">
                done_outline
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'exception'">
              <span class="material-icons-outlined text-red-500 text-[18px]">
                error
              </span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <p class="w-5 h-5 flex rounded-full border-l-2 border-r-2 border-blue-500 animate-spin"></p>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>
  </ng-template>

</ng-container>
