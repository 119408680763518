import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { WorkSpaceDetailStoreService } from '@penji/team/workspace/data-access';
import { Observable } from 'rxjs';
import { Team } from '@penji/shared/data-access';

@Component({
  selector: 'penji-workspace-name',
  standalone: true,
  imports: [CommonModule, NzToolTipModule],
  templateUrl: './workspace-name.component.html',
  styleUrls: ['./workspace-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceNameComponent {

  @Input() client_team_id?: string;
  @Input() type?: 'icon' | 'text' = 'icon';
  @Input() classes = '';

  private readonly wspDetailStore = inject(WorkSpaceDetailStoreService);

  workspace_data$?: Observable<Team>;

  ngOnChanges(): void {
    if (this.client_team_id) {
      this.workspace_data$ = this.wspDetailStore.data$(this.client_team_id);
    }
  }
}
