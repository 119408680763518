import { CategoryClassState } from '@penji/shared/data-access';
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from '@ngrx/store';
import * as categoryClassActions from './category-class.action';

const adapter: EntityAdapter<any> = createEntityAdapter<any>();
export const inititialCategoryState: CategoryClassState = adapter.getInitialState();

export const CategoryClassReducer = createReducer(inititialCategoryState,
  on(categoryClassActions.createCategorySuccess, (state, {category})=>{
    return adapter.addOne(category, state);
  }),
  on(categoryClassActions.createCateAndClass, (state, {category_class}) => {
    return adapter.addMany(category_class, state);
  }),
  on(categoryClassActions.updateCategory, (state, {update})=>{
    return adapter.updateOne(update, state);
  })
)
