import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, QueryList, ViewChildren } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterModule } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Comment, Project } from '@penji/shared/data-access';
import { DataFromRefPipe } from '@penji/shared/pipes';
import { DownloadDesignComponent } from '@penji/shared/ui/element/download-design';
import { QuillViewHtmlComponent } from '@penji/shared/ui/element/quill-view-html';
import { MediaRefComponent } from '@penji/team/media/media-ui/media-ref';
import { ProjectMessageStoreService, ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectDesignStatusComponent } from '@penji/team/project/project-ui/project-design-status';
import { ProjectDesignStatusV2Component } from '@penji/team/project/project-ui/project-design-status-v2';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { UserInfoItemComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-info-item';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { CommentStoreService, ViewDesignStoreService } from '@penji/team/view-design/data-access';
import { RevisionByIdlinkComponent } from '@penji/team/view-design/view-design-ui/revision-by-idlink';
import { ViewDesignCommentListComponent } from '@penji/team/view-design/view-design-ui/view-design-comment-list';
import { ViewDesignItemImageComponent } from '@penji/team/view-design/view-design-ui/view-design-item-image';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { Observable, of, switchMap, tap } from 'rxjs';

const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static PROTOCOL_WHITELIST: any;
  static sanitize(url:any) {
    const value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if(value.startsWith(this.PROTOCOL_WHITELIST[i]))
          return value;

        return `http://${value}`
    }
    return value;
  }
}
Quill.register(CustomLink);


@Component({
  selector: 'penji-view-design-detail',
  standalone: true,
  imports: [
    CommonModule,
    MediaRefComponent,
    DataFromRefPipe,
    ViewDesignItemImageComponent,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzEmptyModule,
    NzPopconfirmModule,
    RouterModule,
    RouterLink,
    ViewDesignCommentListComponent,
    NzSpinModule,
    DownloadDesignComponent,
    ProjectDesignStatusComponent,
    CopyItemComponent,
    NzToolTipModule,
    ProjectDesignStatusV2Component,
    QuillViewHtmlComponent,
    QuillModule,
    RevisionByIdlinkComponent,
    UserInfoItemComponent,
    NzPopconfirmModule
  ],
  templateUrl: './view-design-detail.component.html',
  styleUrls: ['./view-design-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ViewDesignStoreService,
    CommentStoreService,
    ProjectStoreService,
    ProjectMessageStoreService
  ],
})
export class ViewDesignDetailComponent {

  @Input() open_modal = false;
  @Input() project_id: string | undefined;
  @Input() discussion_id: string | undefined;
  @Input() id_link: string | undefined;

  @ViewChildren(QuillEditorComponent) editorComponents?: QueryList<QuillEditorComponent>;

  private readonly viewDesignStoreSv = inject(ViewDesignStoreService);
  private readonly projectStoreSV = inject(ProjectStoreService);
  private readonly commentStoreSv = inject(CommentStoreService);
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly authStore = inject(AuthStore);
  private readonly afs = inject(AngularFirestore);
  private readonly router = inject(Router);

  current_design$: Observable<any> | undefined;
  current_project_data$ = this.projectStoreSV.current_project_data$;
  list_design$ = this.viewDesignStoreSv.data$;
  loading$ = this.viewDesignStoreSv.loading$;
  auth_id$ = this.authStore.auth_id$;

  query_params: { [key: string]: string } = {};
  reset_annotaton = false;
  new_annotation: any;
  hide = false;
  note_text = '';
  is_disable = false;
  style_width = 100;
  check_focus = false;

  auth_id = '';
  project_data!: Project;

  modules = {
    keyboard: {
      bindings: {
        custom: {
          key: 'enter',
          shiftKey: false,
          handler: (range: any, context: any) => {
            if (this.note_text&&this.note_text!=='') {
              this.addComment(this.auth_id);
            }
          }
        }
      }
    },
  }


  ngOnInit(): void {
    if (this.open_modal) {
      if(this.project_id && this.discussion_id && this.id_link){
        this.cancelAnno();
        this.query_params = {
          'project_id': this.project_id,
          'discussion_id': this.discussion_id,
          'id_link': this.id_link
        }
        this.projectStoreSV.getProjectDetail$(this.query_params);
        this.viewDesignStoreSv.getAllDesign$(this.query_params);
        this.current_design$ = this.currentDesignModal(this.query_params);

      }
    } else {
      this.current_design$ = this.activeRoute.queryParams.pipe(
        switchMap((params) => {
          if (params) {
            this.query_params = { ...params };

            this.cancelAnno();
            return this.viewDesignStoreSv.getOneDesign(this.query_params);
          }
          return of();
        }),
        tap((rs) => {
          if (rs && rs.array_link) {
            this.commentStoreSv.getAllComment$(of(rs.array_link));
          }
        })
      );
      this.projectStoreSV.getProjectDetail$(this.activeRoute.queryParams);
      this.viewDesignStoreSv.getAllDesign$(this.activeRoute.queryParams);
    }

  }

  cancel(): void {

  }

  confirm(): void {

  }

  currentDesignModal(params: any): Observable<any>{
    return this.viewDesignStoreSv.getOneDesign(params).pipe(
      tap(rs=>{
        if (rs && rs.array_link) {
          this.commentStoreSv.getAllComment$(of(rs.array_link));
        }
      })
    );
  }

  getNewAnnotation(event: any) {
    this.new_annotation = event;
    if(event){
      this.focusAddComment(event, this.auth_id);
    }else{
      this.blurAddComment(event);
      if (this.reset_annotaton) {
        this.reset_annotaton = false;
      } else {
        this.reset_annotaton = true;
      }
    }
  }

  cancelAnno() {
    if (!this.hide) {
      this.note_text = '';
      this.new_annotation = null;
      if (this.reset_annotaton) {
        this.reset_annotaton = false;
      } else {
        this.reset_annotaton = true;
      }
    }
  }

  addComment(auth_id: string) {
    this.is_disable = true;
    if (this.note_text.trim() == '') {
      return;
    }
    if (this.new_annotation) {
      this.new_annotation.type = 'Annotation';
    }

    const comment = new Comment();
    comment.content = this.note_text;
    comment.created_at = firebase.Timestamp.now();
    comment.updated_at = firebase.Timestamp.now();
    comment.owner_id = auth_id;
    comment.owner_ref = this.afs.firestore.collection('user').doc(auth_id);
    comment.id_link = this.query_params['id_link'];
    comment.marker = this.new_annotation ?? null;
    comment.removed = false;
    comment.version = 2;
    comment.parent_id = this.query_params['id_link'];
    comment.project_id = this.query_params['project_id'];
    comment.discussion_design_id = this.query_params['discussion_id'];
    // console.log(comment);

    this.commentStoreSv.addComment$(of({ comment: comment }));

    this.cancelAnno();
    this.is_disable = false;
  }

  trackBy(index: number, item: any) {
    return item.id;
  }
  outFocus(){
    const image = document.querySelector('.a9s-annotationlayer');
    if(image){
      image.addEventListener('click', (event:any) => {
        if(event.target.classList[0]==='a9s-annotationlayer'){
          if(!this.new_annotation && this.new_annotation == null && this.check_focus){
            this.check_focus = false;
            setTimeout(() => {
              if(this.editorComponents){
                this.editorComponents.forEach(cp=>{
                  cp.quillEditor.blur();
                });
              }
            }, 150);
          }
          const svg_note = document.querySelectorAll('.a9s-annotationlayer, .comment-content, .a9s-annotation');
          svg_note.forEach(el=>{
            el.classList.remove('focus');
          });
        }
      });
    }
  }
  focusAddComment(event: any, auth_id: string) {
    this.auth_id = auth_id;
    const svg_note = document.querySelectorAll(
      '.a9s-annotationlayer, .comment-content, .a9s-annotation'
    );
    svg_note.forEach((el) => {
      el.classList.remove('focus');
    });
    this.check_focus = true;
    setTimeout(() => {
      if(this.editorComponents) {
        this.editorComponents.forEach(cp=>{
          cp.quillEditor.focus();
        });
      }
    }, 150);
  }

  blurAddComment(event: any) {
    this.check_focus = false;
    setTimeout(() => {
      if(this.editorComponents) {
        this.editorComponents.forEach(cp=>{
          cp.quillEditor.blur();
        });
      }
    }, 150);
  }

  closeModal(){
    if(!this.open_modal){
      this.router.navigate(['/project/', this.query_params['project_id']]);
    }
  }
  changeIdLink(id_link: string){
    this.style_width = 100;
    this.cancelAnno();
    if(this.open_modal){
      this.query_params =  {...this.query_params, ...{'id_link': id_link}}

    }else{
      this.router.navigate(['/view-design'], {
        queryParams: {'id_link': id_link},
        queryParamsHandling: 'merge' });
    }
  }
  actionDesign(list_design: any, current_id: string, action: string){
    let temp_design;
    if(action==='next'){
      temp_design = list_design[list_design.indexOf(list_design.find((rs:any) => rs.design_id === current_id))+1];
    }else{
      temp_design = list_design[list_design.indexOf(list_design.find((rs:any) => rs.design_id === current_id))-1]
    }
    this.style_width = 100;
    this.cancelAnno();
    if(this.open_modal){
      const obj = {
        'discussion_id': temp_design.id,
        'id_link': temp_design.array_link[0].id_link
      }
      this.query_params = {...this.query_params, ...obj}
      this.current_design$ = this.currentDesignModal(this.query_params);
    }else{
      this.router.navigate(['/view-design'], {
          queryParams: {'discussion_id': temp_design.id, 'id_link': temp_design.array_link[0].id_link},
          queryParamsHandling: 'merge'
        }
      );
    }
  }
  checkIndexDesign(list_design: any, current_id: string){
    return list_design.indexOf(list_design.find((rs:any) => rs.design_id === current_id));
  }
  zoomIn(){
    this.style_width += 20;
  }
  zoomOut(){
    this.style_width -= 20;
  }
  setDefault(){
    this.style_width = 100;
  }

}
