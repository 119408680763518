import { Injectable } from '@angular/core';
import {
  AngularFirestore
} from '@angular/fire/compat/firestore';
import { TicketCategory, TicketSubCategory } from '../models/ticket.model';

@Injectable({
  providedIn: 'root',
})
export class TicketCateService {
  constructor(private afs: AngularFirestore) {}
  async getTicketCategory() {
    return await this.afs.firestore
      .collection('ticket_category_v2')
      .get()
      .then(async (querySnapshot) => {
        const listTicketCategory = Array<TicketCategory>();
        querySnapshot.forEach((doc) => {
          const data = doc.data() as TicketCategory;
          data.id = doc.id;
          listTicketCategory.push(data);
        });
        return listTicketCategory;
      });
  }
  async getTicketSubCategory(ticket_category_id: string, color: string) {
    return await this.afs.firestore
      .collection(
        `ticket_category_v2/${ticket_category_id}/ticket_sub_category`
      )
      .get()
      .then(async (querySnapshot) => {
        const listTicketSubCategory = Array<TicketSubCategory>();
        querySnapshot.forEach((doc) => {
          const data = doc.data() as TicketSubCategory;
          data.id = doc.id;
          data.ticket_category_id = ticket_category_id;
          data.color = color;
          data.main = false;
          listTicketSubCategory.push(data);
        });
        return listTicketSubCategory;
      });
  }

  async getAllCategoryClass() {
    const all_category_class: any[] = [];
    const all_class = await this.afs.firestore
      .collection('ticket_category_v2')
      .get();
    const promises = [];
    for (let index = 0; index < all_class.docs.length; index++) {
      const snapshot = all_class.docs[index];
      const class_category = snapshot.data() as TicketCategory;
      class_category.id = snapshot.id;
      class_category.main = true;
      all_category_class.push(class_category);
      promises.push(
        this.afs.firestore
          .collection('ticket_category_v2')
          .doc(snapshot.id)
          .collection('ticket_sub_category')
          .get()
      );
    }

    const all_category_promises = await Promise.all(promises);
    all_category_promises.forEach((ref, index) => {
      ref.docs.forEach((item) => {
        const category = item.data() as TicketSubCategory;
        category.id = item.id;
        category.ticket_category_id = all_category_class[index].id;
        category.color = all_category_class[index].color;
        all_category_class.push(category);
      });
    });
    return all_category_class;
  }
}
