/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable, ViewContainerRef, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { NzModalService } from 'ng-zorro-antd/modal';
import { catchError, map, of, retry, share, shareReplay, switchMap, throwError } from 'rxjs';
import { Dashboard } from '../models/dashboard.model';
import { Widget } from '../models/widget.model';
import { WProjectByTeamComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-project-by-team';
import { WProjectBySquadComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-project-by-squad';
import { WFormComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-form';
import { WRequestOffComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-request-off';
import { WOvertimeComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-overtime';
import { WProjectByDesignerComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-project-by-designer';
import { WMemberByTeamComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-member-by-team';
import { WWatchedCustomerComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-watched-customer';
import { WMemberBySquadComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-member-by-squad';
import { WUpcomingOffComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-upcoming-off';
import { WRequestOffCalendarComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-request-off-calendar';
import { WCustomerNewThirtyDaysComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-customer-new-thirty-days';
import { WUnclaimTicketComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-unclaim-ticket';
import { WSquadByTeamComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-squad-by-team';
import { WTrackingDataComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-tracking-data';
import { HttpClient } from '@angular/common/http';
import { environment } from '@penji/shared/environments';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private wp_url = environment.penjico;
  http = inject(HttpClient);
  list_widgets = [
    // {
    //   name: 'Project Todo',
    //   componentName: 'penji-w-project-todo',
    //   componentType: WProjectTodoComponent,
    //   cols: 20,
    //   rows: 10,
    //   settings: {
    //     user_id: null,
    //     designer_team_id: null
    //   },
    //   selected: false
    // },
    {
      name: 'List Project in Team',
      componentName: 'penji-w-project-by-team',
      componentType: WProjectByTeamComponent,
      cols: 20,
      rows: 11,
      settings: {
        designer_team_id: null
      },
      selected: false,
      type: 'Team Leader'
    },
    {
      name: 'List Project in Squad Group',
      componentName: 'penji-w-project-by-squad',
      componentType: WProjectBySquadComponent,
      cols: 20,
      rows: 11,
      settings: {
        squad_id: null
      },
      selected: false,
      type: 'Squad Leader'
    },
    {
      name: 'List my Project',
      componentName: 'penji-w-project-by-designer',
      componentType: WProjectByDesignerComponent,
      cols: 20,
      rows: 11,
      selected: false,
      type: 'Designer'
    },
    {
      name: 'List Request Off',
      componentName: 'penji-w-request-off',
      componentType: WRequestOffComponent,
      cols: 10,
      rows: 8,
      selected: false,
      type: 'HR'
    },
    {
      name: 'List Overtime',
      componentName: 'penji-w-overtime',
      componentType: WOvertimeComponent,
      cols: 10,
      rows: 8,
      selected: false,
      type: 'HR'
    },

    {
      name: 'List Watched Customer',
      componentName: 'penji-w-watched-customer',
      componentType: WWatchedCustomerComponent,
      cols: 10,
      rows: 8,
      selected: false,
      type: 'Supporter'
    },
    {
      name: 'List member in Team',
      componentName: 'penji-w-member-by-team',
      componentType: WMemberByTeamComponent,
      cols: 10,
      rows: 8,
      settings: {
        designer_team_id: null
      },
      selected: false,
      type: 'Team Leader'
    },
    {
      name: 'List member in Squad',
      componentName: 'penji-w-member-by-squad',
      componentType: WMemberBySquadComponent,
      cols: 10,
      rows: 8,
      settings: {
        squad_id: null
      },
      selected: false,
      type: 'Squad Leader'
    },
    {
      name: 'List Squad in Team',
      componentName: 'penji-w-squad-by-team',
      componentType: WSquadByTeamComponent,
      cols: 20,
      rows: 11,
      settings: {
        designer_team_id: null
      },
      selected: false,
      type: 'Team Leader'
    },
    {
      name: 'List Upcoming Off',
      componentName: 'penji-w-upcoming-off',
      componentType: WUpcomingOffComponent,
      cols: 10,
      rows: 8,
      selected: false,
      type: 'HR'
    },
    {
      name: 'Calendar Request Off',
      componentName: 'penji-w-request-off-calendar',
      componentType: WRequestOffCalendarComponent,
      cols: 20,
      rows: 11,
      selected: false,
      type: 'HR'
    },
    {
      name: 'List New Customer',
      componentName: 'penji-w-customer-new-thirty-days',
      componentType: WCustomerNewThirtyDaysComponent,
      cols: 10,
      rows: 8,
      selected: false,
      type: 'Supporter'
    },
    {
      name: 'List Unclaimed Tickets',
      componentName: 'penji-w-unclaimed-ticket',
      componentType: WUnclaimTicketComponent,
      cols: 10,
      rows: 8,
      selected: false,
      type: 'Supporter'
    },
    {
      name: 'Tracking Data',
      componentName: 'penji-w-tracking-data',
      componentType: WTrackingDataComponent,
      cols: 10,
      rows: 9,
      selected: false,
      type: 'Supporter'
    },
  ];
  constructor(
    private afs: AngularFirestore, private afAuth: AngularFireAuth
  ) { }
  getDashboard() {
    return this.afAuth.authState.pipe(
      switchMap(res => {
        if (res) {
          return this.afs.firestore.collection('dashboard').doc(res.uid).get().then((doc) => {
            const data = { id: doc.id, ...doc.data() } as Dashboard;
            if (data.widgets) {
              data.widgets = data.widgets.map(widget => {
                const t_widget = this.list_widgets.find(res => res.componentName === widget.componentName);
                return { ...widget, ...t_widget, settings: widget.settings };
              })
            }
            return data;
          })
        } else {
          return of(null);
        }
      })
    )
  }

  updateDashboard(widgets: Widget[] = []) {
    return this.afAuth.authState.pipe(
      switchMap(res => {
        if (res) {
          const dashboard = new Dashboard;
          dashboard.owner_id = res.uid;
          dashboard.created_at = firebase.Timestamp.now();
          if (widgets.length > 0) {
            widgets.forEach(item => {
              const data = structuredClone({ ...item, componentType: '', componentForm: '' });
              dashboard.widgets.push(data);
            })
          }
          return this.afs.firestore.collection('dashboard').doc(res.uid).set({ ...dashboard }, { merge: true }).then(() => {
            return {
              flag: true,
              message: 'update the dashboard successfully!',
              data: widgets
            };
          }).catch(err => {
            return {
              flag: false,
              message: err,
              data: null
            }
          })
        } else {
          return of(null);
        }
      }),
      shareReplay()
    )
  }
  selectWidget(modal: NzModalService, viewContainerRef: ViewContainerRef, widget?: Widget) {
    return modal.create({
      nzTitle: widget?.name + ' Setting',
      nzContent: WFormComponent,
      nzStyle: { top: '20px' },
      nzWidth: '30%',
      nzClassName: '[&_.ant-modal-title>div]:text-xl [&_.ant-modal-title>div]:capitalize [&_.ant-modal-title>div]:text-gray-600',
      nzViewContainerRef: viewContainerRef,
      nzFooter: null,
      nzComponentParams: {
        widget_data: widget
      }
    })
  }

  // WORDPRESS (PENJI.CO)
  /* type: users, media, pages, posts, categories, project, designer, design_category */
  getSingleById(type: string, id: string) {
    const url = `${this.wp_url}wp-json/wp/v2/${type}/${id}`;
    const a = this.http.get(url).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(err => {
        console.log(err);
        return throwError(() => new Error(err.message));
      }),
      share()
    )
    return a;
  }

  /* type: users, media, pages, posts, categories, project, designer, design_category */
  getSingleBySlug(type: string, slug: string) {
    const url = `${this.wp_url}wp-json/wp/v2/${type}?slug=${slug}`;
    return this.http.get(url).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(err => {
        console.log(err);
        return throwError(() => new Error(err.message));
      }),
      map((rs: any) => rs[0]));
  }

  /*
  type: users, media, pages, posts, categories, project, designer, design_category
  taxonomy_type: categories, design_category, desinger_id
  */
  listAll(type: string, page?: number, per_page?: number, query_object?: Array<{ taxonomy_type: string, taxonomy_value: any[] }>) {
    let url = `${this.wp_url}wp-json/wp/v2/${type}?`
    if (page) url += `&page=${page}`;
    if (per_page) url += `&per_page=${per_page}`;
    if (query_object && query_object.length > 0) {
      query_object.forEach(rs => {
        const temp = rs.taxonomy_value.join(",");
        url += `&${rs.taxonomy_type}=${temp}`;
      });
    }
    // console.log(url);
    return this.http.get(url).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(err => {
        console.log(err);
        return throwError(() => new Error(err.message));
      }),
      map((rs: any) => [...rs])
    );
  }
  // END WORDPRESS (PENJI.CO)
}
