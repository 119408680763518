import { DocumentReference } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';

export class Brand {
  id?: string;
  team_id?: string;
  brand_color?: string;
  color?: Array<string>;
  created_at?: Timestamp;
  created_by?: string;
  description?: string;
  media?: Array<DocumentReference>;
  title?: string;
  type?: string;
  updated_at?: Timestamp;
  industry?: string;
  website?: string;
  guideline?: Array<DocumentReference>;
  list_project?: string[];
  target_audience?: string;
  logo?: Array<DocumentReference>;
  font?: Array<DocumentReference>;
  important_link?:string[];
  log_type = 'brand';
}
