import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { list_all_plan } from '@penji/shared/environments';
import { Plan } from '@penji/shared/data-access';

@Component({
  selector: 'penji-plan-description',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './plan-description.component.html',
  styleUrls: ['./plan-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDescriptionComponent {
  @Input() grid = 1;
  @Input() title = false;
  @Input() creative_talent = false;
  @Input() creative_services = false;
  @Input() features = false;

  @Input() plan_package? = 'starter';
  @Input() plan_duration? = 'monthly';
  list_all_plan: { [key: string]: Plan } = list_all_plan;
}
