<ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="'update'">
        <span penjiPermission="_request-off-update" style_type="button" [user_id]="request_off.uid!">
            <ng-container *ngIf="request_off.off_at>=start_of_month">
                <span class="material-icons-outlined text-blue-500 text-[20px] cursor-pointer" (click)="updateRequestOff()"
                    title="Edit">edit</span>
            </ng-container>
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="'delete'">
        <span penjiPermission="_request-off-delete" style_type="button" [user_id]="request_off.uid!">
            <ng-container *ngIf="request_off.off_at>=start_of_month">
                <span class="material-icons-outlined text-red-500 text-xl cursor-pointer" nz-popover
                    [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="delete_template"
                    title="Delete">delete</span>
            </ng-container>
        </span>
        <ng-template #delete_template>
            <p class="dark:text-white">Are you sure you want to <b>Delete</b> this request off?</p>
            <div class="flex space-x-3 pt-5">
                <button (click)="visible=!visible" nz-button nzType="default" nzSize="small"
                    class="!font-normal flex-1">No</button>
                <button (click)="deleteRequestOff()" nz-button nzType="primary" nzSize="small"
                    class="flex-1">Yes</button>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <span class="text-gray-500 text-sm dark:text-white" title="Loading">Loading...</span>
    </ng-container>
</ng-container>