<ng-container *ngIf="{workspace_data:workspace_data$ | async} as vm">
    <ng-container *ngIf="vm.workspace_data; else no_wsp">
        <div class="
            inline-block
            [&_.class-label]:inline-flex [&_.class-label]:items-center [&_.class-label]:justify-center [&_.class-label]:w-6 [&_.class-label]:h-6 {{classes}} [&_.class-label]:rounded-full  [&_.class-label]:font-semibold
            [&_.class-label]:text-[14px] [&_.class-label]:leading-6 [&_.class-label]:text-center m-1
        ">
            <ng-container [ngSwitch]="vm.workspace_data.label_info&&vm.workspace_data.label_info?.account_level">
                <ng-container *ngSwitchCase="3">
                    <span nz-tooltip nzTooltipTitle="S Class Level" class="class-label outline-double outline-4 outline-offset-0 border-green-500 text-green-500 bg-green-50">S</span>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <span nz-tooltip nzTooltipTitle="A Class Level" class="class-label outline-double outline-4 outline-offset-0 border-blue-500 text-blue-500 bg-blue-50">A</span>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <span nz-tooltip nzTooltipTitle="D Class Level" class="class-label outline-double outline-4 outline-offset-0 border-red-500 text-red-500 bg-red-50">D</span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <span nz-tooltip nzTooltipTitle="Unidentified Class Level" class="class-label outline-double outline-4 outline-offset-0 border-gray-500 text-gray-500 bg-gray-50">U</span>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #no_wsp>
        <nz-skeleton-element
            nzType="avatar"
            [nzActive]="true"
            nzShape="circle"
            nzSize="small"
            ></nz-skeleton-element>
    </ng-template>
</ng-container>
