import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Project } from '@penji/shared/data-access';
import { PermissionDirective } from '@penji/shared/directives';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectReviewItemComponent } from '@penji/team/project/project-ui/project-review-item';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'penji-project-review',
  standalone: true,
  imports: [
    CommonModule,
    NzPopoverModule,
    NzButtonModule,
    ProjectReviewItemComponent,
    PermissionDirective
  ],
  templateUrl: './project-review.component.html',
  styleUrls: ['./project-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectReviewComponent {

  @Input() project_data!: Project;

  visible_popover = false;

  overdue = false;
  overdue_time = 3600 * 24;// one day
  now: any = new Date().getTime() / 1000;

  isLoading = false;

  projectStoreSv = inject(ProjectStoreService);

  ngOnChanges() {
    if (this.project_data) {
      if (this.project_data?.deadline_at) {
        if (this.project_data?.deadline_at < firebase.Timestamp.now() && this.project_data.status != 4 && this.project_data.status != 5 && this.project_data.status != 6 && this.project_data.in_queue == false)
          this.overdue = true;
      } else {
        if (this.project_data?.time_change)
          if ((this.project_data?.time_change?.seconds + this.overdue_time) < this.now && this.project_data.status != 4 && this.project_data.status != 5 && this.project_data.status != 6 && this.project_data.in_queue == false)
            this.overdue = true;
      }
    }
  }

  async reviewed() {
    this.visible_popover = false;
    this.isLoading = true;
    const project_change = new Project();
    project_change.id = this.project_data.id;
    project_change.team_id = this.project_data.team_id;
    project_change.circle_type = 2;
    project_change.updated_at = firebase.Timestamp.now();
    this.projectStoreSv.updateProject$(project_change);
    this.isLoading = false;
  }

  async unreviewed() {
    this.visible_popover = false;
    this.isLoading = true;
    const project_change = new Project();
    project_change.id = this.project_data.id;
    project_change.team_id = this.project_data.team_id;
    project_change.circle_type = 1;
    project_change.updated_at = firebase.Timestamp.now();
    this.projectStoreSv.updateProject$(project_change);
    this.isLoading = false;
  }
}
