import { DocumentData, DocumentReference, QueryDocumentSnapshot } from "@angular/fire/compat/firestore";
import { EntityState } from "@ngrx/entity";
import { Timestamp } from "firebase/firestore";

export class Notification {
    id?: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    content?: string;
    created_at!: Timestamp;
    date!: string | null;
    project_id?: string;
    project_ref?: DocumentReference;
    read?: boolean;
    recipient_id?: string;
    recipient_ref?: DocumentReference;
    sender_id?: string;
    sender_ref?: DocumentReference;
    team_id?: string;
    title?: string;
}
export class RootNotification {
    total?: number;
    total_unread?: number;
}

export interface Notificationstate extends EntityState<Notification> {
    loading: boolean,
    error: string
}

/*****************  Old team app notification system ******************/

export class NotificationNew {
    id?: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    created_at!: Timestamp;
    title?:string;
    content?:string;
    date!: string;
    noti_type?: number;
    noti_template_id?: string;
    sender_id?: string;
    read?: boolean;
    route_link?:string;
    noti_team: any;
    noti_project: any;
    noti_client: any;
    noti_ticket: any;
  }