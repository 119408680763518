import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as userActions from '@penji/shared/data-access';
import { UserSimple, WhereQueryInterface } from '@penji/shared/data-access';
import { ProjectStoreService } from '@penji/team/project/data-access';
import * as firebase from 'firebase/firestore';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BehaviorSubject, Observable, Subject, map, of, switchMap, takeUntil, tap } from 'rxjs';

const now = firebase.Timestamp.now().toDate().getTime();
const temp = now - 24 * 60 * 60 * 1000;
const final = firebase.Timestamp.fromDate(new Date(temp));

@Component({
  selector: 'penji-member-current-queue',
  standalone: true,
  imports: [
    CommonModule,
    NzToolTipModule
  ],
  templateUrl: './member-current-queue.component.html',
  styleUrls: ['./member-current-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectStoreService],
})
export class MemberCurrentQueueComponent implements OnChanges {
  @Input() uid?: string;

  private readonly projectStoreSV = inject(ProjectStoreService);
  private readonly store = inject(Store);

  user$?: Observable<UserSimple> | undefined;
  take$ = new Subject<void>();

  list_new$ = new BehaviorSubject<number>(0);
  list_revision$ = new BehaviorSubject<number>(0);
  list_delivered$ = new BehaviorSubject<number>(0);

  loading$ = this.projectStoreSV.loading$;
  list_project$ = this.projectStoreSV.data_widget$.pipe(
    tap(list => {
      if (list.length > 0) {
        this.list_new$.next(list.filter((project: any) => project.status == 1).length ?? 0);
        this.list_revision$.next(list.filter((project: any) => project.status == 2).length ?? 0);
        this.list_delivered$.next(list.filter((project: any) => project.status == 4 && project.time_change >= final).length ?? 0);
      }
    })
  );

  ngOnChanges() {
    if (this.uid) {
      this.store.dispatch(userActions.loadUserDB({ uid: this.uid ?? '' }));
      this.user$ = this.store.pipe(
        select(userActions.getUser(this.uid)),
        takeUntil(this.take$),
        map(user => {
          if (user) this.take$.complete();
          return user;
        })
      );
      this.projectStoreSV.loadProjectsForWidget$(of(this.uid).pipe(
        switchMap((uid) => {
          let where_query: WhereQueryInterface[] = [];
          let order_by;
          let order_desc;
          where_query = [
            { field_name: 'status', field_operator: 'in', field_value: [1, 2, 4] },
            { field_name: 'in_queue', field_operator: '==', field_value: false },
            { field_name: 'pause', field_operator: '==', field_value: false },
          ]
          order_by = 'time_change';
          order_desc = true;
          where_query.push({
            field_name: 'assign_by',
            field_operator: 'array-contains',
            field_value: uid
          })
          return of({ where_query, order_by, order_desc });
        })
      ));
    }
  }
}
