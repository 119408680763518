
  <ng-container *ngIf="clickable; else no_click;">
    <a (click)="openDrawer($event, project_data.id!)" [href]="'/project/'+project_data.id" class="text-base flex w-full items-center space-x-3 font-semibold text-gray-900 hover:text-blue-600 cursor-pointer dark:text-white dark:hover:text-blue-600">
      <span class="truncate max-w-[calc(100%-48px)] project-title {{classes}}" nz-tooltip [nzTooltipTitle]="project_data.title">{{ project_data.title }}</span>
      <span *ngIf="project_data.payment_v2" class="bg-purple-500 text-white font-semibold rounded w-[36px] h-[28px] flex items-center justify-center text-sm">V2</span>
      <ng-container *ngIf="project_data.id">
        <penji-copy-item [id]="project_data.id" copy_type="project" class="absolute top-1/2 -translate-y-1/2 right-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible" [link_title]="project_data.title"></penji-copy-item>
      </ng-container>
    </a>
  </ng-container>
  <ng-template #no_click>
    <p class="text-base flex items-center space-x-3 font-semibold text-gray-900 dark:text-white">
      <span class="truncate max-w-[calc(100%-48px)] project-title {{classes}}" nz-tooltip [nzTooltipTitle]="project_data.title">{{ project_data.title }}</span>
      <span *ngIf="project_data.payment_v2" class="bg-purple-500 text-white font-semibold rounded w-[36px] h-[28px] flex items-center justify-center text-sm">V2</span>
    </p>
  </ng-template>



