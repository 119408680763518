import { inject, Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LogService } from './log.service';
import { Comment } from '../models/message-note.model';
import { Discussion } from '../models/discussion.model';
import * as firebase from 'firebase/firestore';
import { DiscussionService } from './discussion.service';

@Injectable({
  providedIn: 'root',
})
export class MessageNoteService extends LogService<Comment> {
  nzMessageService = inject(NzMessageService);
  private readonly discussionSV = inject(DiscussionService);

  async getListComment(array_link: any[] = []) {
    const promise: any[] = [];
    array_link.forEach((item: any) => {
      if (item.id_link) {
        promise.push(
          this.afs.firestore
            .collection('comment_v2')
            .where('id_link', '==', item.id_link)
            .get()
        );
      }
    });
    const result = await Promise.all(promise);
    const list: Array<Comment> = [];
    result.forEach((snapshot) => {
      snapshot.docs.forEach((doc: any) => {
        const data = doc.data() as Comment;
        data.id = doc.id;
        list.push(data);
      });
    });
    return list;
  }

  addMessageNote(data: Comment) {
    // console.log(data);
    
    return this.afs.firestore
      .collection('comment_v2')
      .add({ ...data })
      .then((rs) => {
        this.log_model.action = 'add';
        this.log_model.data = { ...new Comment(), ...data };
        this.createLog();
        const comment_temp = { ...data } as Comment;
        comment_temp.id = rs.id;
        this.nzMessageService.success('Added successfully!');

        // add Discussion
        if (data.project_id) {
          const discussion = new Discussion();
          discussion.created_at = firebase.Timestamp.now();
          discussion.content = `left a feedback for design: ${data.content}`;
          discussion.type = 'text';
          discussion.user_id = data.owner_id;
          discussion.user_ref = data.owner_ref;
          discussion.star = 0;
          discussion.hidden = true;
          discussion.remove = false;
          discussion.id_link = data.id_link;
          discussion.project_id = data.project_id;
          discussion.discussion_design_id = data.discussion_design_id;
          this.discussionSV.addDiscussionV2(discussion, false);
        }

        return {
          flag: true,
          message: 'Comment successfully added!',
          data: comment_temp,
        };
      })
      .catch((error) => ({
        flag: false,
        message: error.message,
        data: {} as Comment,
      }));
  }

  updateMessageNote(data: Comment) {
    return this.afs.firestore
      .collection('comment_v2')
      .doc(data.id)
      .update({ ...data })
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ...new Comment(), ...data };
        this.createLog();
        this.nzMessageService.success('Comment updated successfully!');
        return { flag: true, message: 'Comment updated successfully!' };
      })
      .catch((error) => ({ flag: false, message: error.message }));
  }

  deleteMessageNote(data: Comment) {
    return this.afs.firestore
      .collection('comment_v2')
      .doc(data.id)
      .update({ ...data })
      .then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ...new Comment(), ...data };
        this.createLog();
        this.nzMessageService.success('Comment deleted successfully!');
        return { flag: true, message: 'Comment deleted successfully!' };
      })
      .catch((error) => ({ flag: false, message: error.message }));
  }
}
