<nz-table #table_list [nzData]="list_project" [nzBordered]="false" [nzFrontPagination]="false"
  [nzShowPagination]="false" [nzSize]="'middle'" [nzScroll]="{ x: '78.250vw', y: container_height + 'px' }"
  class="theme theme-default" [nzLoading]="loading">
  <thead>
    <tr>
      <th nzLeft nzWidth="40px"></th>
      <th nzLeft nzWidth="360px">
        Title
      </th>
      <th nzWidth="140px">
        Status
      </th>
      <th nzWidth="150px">
        Assigned
      </th>
      <th nzWidth="200px">
        Customer Workspace
      </th>
      <th nzWidth="100px">
        Deadline
      </th>
      <!-- <th nzWidth="50px">Action</th> -->
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
      <tr class="{{item.temp_overdue ? 'row-overdue' : ''}}">
        <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
          <td nzLeft>
            <penji-project-review [project_data]="item"></penji-project-review>
            <penji-project-final-assigned [project_data]="item"></penji-project-final-assigned>
          </td>
          <td nzLeft class="relative">
            <div class="flex space-x-3">
              <ng-container *ngIf="item.payment_v2; else payment_v1">
                <div>
                  <penji-project-last-design [project]="item"></penji-project-last-design>
                </div>
              </ng-container>
              <ng-template #payment_v1>
                <div class="w-[125px] h-[90px] group">
                  <div class="w-full h-full rounded overflow-hidden relative bg-100 border border-gray-200 dark:border-gray-500">
                    <a (click)="openDrawer(item.id!)"><penji-media-ref
                        class="[&>penji-media-cover]:h-full [&>penji-media-cover>img]:h-full [&>penji-media-cover>img]:w-full [&>penji-media-cover>img]:object-cover"
                        [media_ref]="item.last_design" [image_auto_fit]="true" media_size="thumb"
                        image_type="design"></penji-media-ref>
                    </a>
                  </div>
                </div>
              </ng-template>
              <div class="flex flex-col w-[calc(100%-125px)]">
                <penji-project-name [project_data]="item"></penji-project-name>
                <p class="mt-1 mb-1.5"><penji-category-title
                    [category_id]="item.project_cat_id"></penji-category-title></p>
                <div class="items-center space-x-3 hidden show-on-hover">
                  <ng-container *ngTemplateOutlet="open_item; context: {param: {type:'project', id:item.id}}"></ng-container>
                  <penji-copy-item [id]="item.id" copy_type="project" [link_title]="item.title"></penji-copy-item>
                </div>
              </div>
            </div>
          </td>
          <td>
            <penji-project-status [project_data]="item"></penji-project-status>
          </td>
          <td>
            <penji-project-assigned [project_data]="item" [reassign_on]="true"></penji-project-assigned>
          </td>
          <td>
            <!-- <penji-user-item-compress [user_id]="item.owner_id" [show_user_name]="true" [clickable]="true"
            [user_image_size]="'md'"></penji-user-item-compress> -->
            <div class="flex items-center gap-2">
              <div class="w-[220px]">
                <penji-workspace-item-v2 [client_team_id]="item.team_id" [show_workspace_avatar]="true" [show_workspace_name]="true" [show_workspace_level_on_left]="true" [show_border_box_workspace]="true" [padding_border]="'p-1.5'" [clickable]="true" workspace_classes="!max-w-[130px]"></penji-workspace-item-v2>
              </div>
              <div class="w-[80px]">
                <penji-workspace-level-v2 [client_team_id]="item.team_id"></penji-workspace-level-v2>
              </div>
            </div>
          </td>
          <td>
            <penji-project-deadline-item [project_data]="item"></penji-project-deadline-item>
          </td>
        </ng-container>
      </tr>
      <ng-template #loading_tr>
        <td [colSpan]="6" inViewport (inViewportAction)="viewPort($event, item.id!)">
          <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
        </td>
      </ng-template>
    </ng-container>
  </tbody>
</nz-table>

<ng-template #open_item let-open_temp="param">
  <a class="inline-flex py-1.5 px-2 rounded text-gray-500 hover:text-purple-600"
    [routerLink]="['/'+ open_temp.type +'/' + open_temp.id]" nz-tooltip nzTooltipTitle="Full screen">
    <span class="material-icons-outlined text-[18px]">open_in_full</span>
  </a>
</ng-template>
