/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';

import { Project } from '@penji/shared/data-access';
import { CommentStoreService } from '@penji/team/view-design/data-access';
// @ts-ignore
import { Annotorious } from '@recogito/annotorious';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, EMPTY, Observable, of, switchMap, tap } from 'rxjs';

// @ts-ignore
@Component({
  selector: 'penji-view-design-item-image',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzToolTipModule
  ],
  templateUrl: './view-design-item-image.component.html',
  styleUrls: ['./view-design-item-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDesignItemImageComponent implements OnInit {

  @Input() image_url?: string;
  @Output() new_annotation = new EventEmitter();
  @Input() reset_annotaton = false;
  @Input() id_link?: string;
  @Input() project!: Project;

  private readonly commentStoreSv = inject(CommentStoreService);

  tempAnnotation: any;
  anno: any;
  visible = false;
  list_comment$: Observable<any> | undefined;
  percent$ = new BehaviorSubject<any>({});

  ngOnInit() {

    this.anno = new Annotorious(
      {
        image: "sourceImage",
        disableEditor: true,
        headless: true,
        disableSelect: true,
        readOnly: (this.project && this.project.status && this.project.in_queue === false && this.project.status < 6) ? false : true,
        crosshair: (this.project && this.project.status && this.project.in_queue === false && this.project.status < 6) ? false : true
      }
    );
    if (this.id_link && this.image_url) {
      // this.loadImageAsync(this.image_url);
      this.getMeta(this.image_url, (err: any, img: any) => {
        let percent_width = 0;
        let percent_height = 0;
        if (img.naturalHeight > img.naturalWidth) {
          percent_width = 1.5;
          percent_height = (img.naturalWidth * percent_width) / img.naturalHeight;
        } else {
          percent_height = 2;
          percent_width = (img.naturalHeight * percent_height) / img.naturalWidth;
        }
        // console.log({ percent_width: percent_width, percent_height: percent_height })
        this.percent$.next({ percent_width: percent_width, percent_height: percent_height });
      });
      this.list_comment$ = combineLatest(of(this.id_link), of(this.image_url), this.percent$).pipe(
        // debounceTime(500),
        switchMap(([id_link, image_url, percent]) => {
          if (percent && percent.percent_width && percent.percent_height) {
            return this.commentStoreSv.data_comment_by_idlink_notes$(id_link, image_url!, percent).pipe(
              distinctUntilChanged((prev: any, curr: any) => prev.filter((ft: any) => ft.marker !== null).length === curr.filter((ft: any) => ft.marker !== null).length),
              tap(rs => {
                const annotations: Array<any> = [];
                rs.forEach((item: any) => {
                  if (item.marker && !item.removed) {
                    annotations.push(item.marker);
                  }
                })
                if (this.anno) {
                  this.anno.setAnnotations(annotations);
                }
              })
            );
          }
          return EMPTY;
        })
      )

    }
    this.onAction();
  }

  getMeta = (url: any, cb: any) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reset_annotaton'] && changes['reset_annotaton'].previousValue != undefined && changes['reset_annotaton'].previousValue != changes['reset_annotaton'].currentValue) {
      this.tempAnnotation = null;
      if (this.anno) {
        this.anno.cancelSelected();
      }
    }
  }
  onAction() {
    this.anno.on('changeSelectionTarget', (target: any) => {
      if (this.tempAnnotation && this.tempAnnotation.target.source == target.source) {
        this.tempAnnotation.target = target;
        this.new_annotation.emit(this.tempAnnotation);
      }
    });
    this.anno.on('clickAnnotation', (annotation: any, element: any) => {
      if (annotation.id) {
        this.new_annotation.emit(null);
        this.anno.cancelSelected();
        const svg_note = document.querySelectorAll('.a9s-annotationlayer, .comment-content, .a9s-annotation');
        svg_note.forEach(el => {
          el.classList.remove('focus');
        });
        element.classList.add('focus');
        const comment_element = document.getElementById(annotation.id);
        if (comment_element) {
          comment_element.classList.add('focus');
          comment_element.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    });
    this.anno.on('createSelection', (annotation: any) => {
      if (annotation.type == 'Selection') {
        const obj = { 'id': 'annotation-' + new Date().getTime(), '@context': 'http://www.w3.org/ns/anno.jsonld', ...annotation };
        this.tempAnnotation = obj;
        this.new_annotation.emit(this.tempAnnotation);
      }
      const all_comment = document.querySelectorAll('.comment-content');
      all_comment.forEach(el => {
        el.classList.remove('focus');
      });
      const all_element = document.querySelectorAll('.a9s-annotation');
      all_element.forEach(el => {
        el.classList.remove('focus');
      });
      const item_add_comment = document.querySelectorAll('.item-add-comment');
      item_add_comment.forEach(el=>{
        el.classList.add('active');
        setTimeout(() => {
          el.classList.remove('active');
        }, 1100);
      });

    });
    this.anno.on('cancelSelected', () => {
      this.new_annotation.emit(null);
    });

  }
  hideNote() {
    this.visible = !this.visible;
    this.new_annotation.emit(null);
    if (!this.visible) {
      this.anno.setVisible(true);
    } else {
      this.anno.setVisible(false);
    }
  }
}
