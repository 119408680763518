<ng-container *ngIf="user && auth">

  <penji-user-item-decompress [user_id]="user.id" [show_user_avatar]="true" [show_user_name]="true" [show_user_email]="true" [show_user_available_status]="true" user_image_size="lg" user_classes="text-lg !max-w-[180px]" [show_user_copy]="true" class="[&_.user-name]:!text-gray-700 dark:[&_.user-name]:!text-white">
  </penji-user-item-decompress>

  <!-- just for customner -->
  <div *ngIf="user.role === 3" class="flex items-center justify-between space-x-1 mt-4">
    <p class="font-semibold text-gray-500 dark:text-gray-200">Role:</p>
    <penji-user-role [role]="user.role" [is_pm]="user.is_pm"></penji-user-role>
  </div>

<!-- just for designer -->
  <div *ngIf="user.role !== 3" class="flex items-center space-x-1 mt-4">
    <penji-user-role [role]="user.role" [is_pm]="user.is_pm"></penji-user-role>
    <span class="dark:text-gray-400">on</span>
    <ng-container *ngIf="user.role === 4 && !user.is_pm">
      <penji-squad-group-name class="[&_*]:whitespace-pre" [squad_group_id]="user.squad_id" ></penji-squad-group-name>
      <span *ngIf="user.squad_id">/</span>
    </ng-container>
    <penji-designer-team-name class="[&_*]:whitespace-pre" [designer_team_id]="user.team_designer_active"></penji-designer-team-name>
  </div>

  <!-- just for designer -->
  <ng-container *ngIf="user.role && user.role === 4 && !user.is_pm">
    <div class="w-full max-h-[200px] flex flex-col space-y-2 py-3 mt-3 border-t border-gray-200 dark:border-gray-500 overflow-y-auto ">
      <div class="w-full" *ngIf="user.the_best && user.the_best.length > 0">
        <p class="mb-2 font-semibold text-gray-500 dark:text-gray-200">Design Expertise:</p>
        <penji-member-top-expertise [the_best]="user.the_best"></penji-member-top-expertise>
      </div>
      <div class="w-full pt-3">
        <penji-member-current-queue [uid]="user.id"></penji-member-current-queue>
      </div>
    </div>
  </ng-container>

  <!-- just for customer -->
  <ng-container *ngIf="user.role && user.role === 3">
    <div class="w-full max-h-[400px] flex flex-col space-y-2 py-3 mt-3 border-t border-gray-200 dark:border-gray-500 overflow-y-auto ">
      <div class="w-full" *ngIf="user.id">
        <p class="mb-2 font-semibold text-gray-500 dark:text-gray-200">Workspace:</p>
        <penji-workspace-list-by-uid [uid]="user.id" workspace_list_type="item"></penji-workspace-list-by-uid>
      </div>
    </div>
  </ng-container>
</ng-container>
