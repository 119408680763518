<ng-container *ngIf="result$ | async as result">
    <div class="flex space-x-3 items-center">
      <p class="inline-flex items-center space-x-1 text-green-500">
        <span class="material-icons-outlined text-[26px]">
        emoji_emotions
        </span>
        <span>{{ result[0] }}</span>
      </p>
      <p class="inline-flex items-center space-x-1 text-yellow-500">
        <span class="material-icons-outlined text-[26px]">
        sentiment_neutral
        </span>
        <span>{{ result[1] }}</span>
      </p>
      <p class="inline-flex items-center space-x-1 text-red-500">
        <span class="material-icons-outlined text-[26px]">
        sentiment_dissatisfied
        </span>
        <span>{{ result[2] }}</span>
      </p>
    </div>
</ng-container>
