/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { DesignerTeam } from '@penji/shared/data-access';
import { DesignerTeamStoreService } from '@penji/team/designer-team/data-access';
import { UserInfoItemComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-info-item';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-designer-team-leader',
  standalone: true,
  imports: [
    CommonModule, 
    UserInfoItemComponent
  ],
  templateUrl: './designer-team-leader.component.html',
  styleUrls: ['./designer-team-leader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignerTeamLeaderComponent implements OnChanges{
  @Input() designer_team_id!: string;

  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  designer_team$!: Observable<DesignerTeam>;

  ngOnChanges() {
    if (this.designer_team_id) {
      this.designer_team$ = this.DesignerTeamStoreService.getOneDesignerTeam(this.designer_team_id);
    }
  }
}
