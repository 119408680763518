<ng-container *ngIf="project_data?.circle_type === 1">
  <span class="inline-flex" penjiPermission="_project-review" style_type="button">
    <a title="Review project" nz-popover [nzPopoverTitle]="titleReview" [(nzPopoverVisible)]="visible_popover"
      nzPopoverTrigger="click" [nzPopoverContent]="popReview"
      class="rounded-full p-0.5 hover:bg-gray-200 dark:hover:bg-gray-500">
      <penji-project-review-item [overdue]="overdue" [circle_type]="1"></penji-project-review-item>
    </a>
  </span>
</ng-container>
<ng-container *ngIf="project_data?.circle_type === 2">
  <span class="inline-flex" penjiPermission="_project-review" style_type="button">
    <a nz-popover [nzPopoverTitle]="titleReview" title="Review project" [(nzPopoverVisible)]="visible_popover"
      nzPopoverTrigger="click" [nzPopoverContent]="popUnReview"
      class="rounded-full p-0.5 hover:bg-gray-200 dark:hover:bg-gray-500">
      <penji-project-review-item [overdue]="overdue" [circle_type]="2"></penji-project-review-item>
    </a>
  </span>
</ng-container>
<!-- temp review circle type 1,3 -->
<ng-template #titleReview>
  <p class="text-center font-semibold text-lg">Update Review Status</p>
</ng-template>
<ng-template #popReview>
  <p>
    By marking this project as reviewed, you are certain that you have checked the following details:
  </p>
  <ul class="[&>li]:flex [&>li]:items-center [&>li]:space-x-2 space-y-3 my-3">
    <li>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M4.85779 7.87155C5.8649 6.86444 7.62135 6.86444 8.62846 7.87155L9.2569 8.49999L10.5138 7.24311L9.88535 6.61466C9.04713 5.77555 7.93068 5.31244 6.74313 5.31244C5.55557 5.31244 4.43913 5.77555 3.6009 6.61466L1.71468 8.49999C-0.0168733 10.2324 -0.0168733 13.0529 1.71468 14.7853C2.58135 15.652 3.71913 16.0849 4.8569 16.0849C5.99557 16.0849 7.13424 15.652 8.00002 14.7853L8.62846 14.1569L7.37157 12.9L6.74313 13.5284C5.70313 14.5658 4.01157 14.5676 2.97157 13.5284C1.93246 12.4884 1.93246 10.7969 2.97157 9.75688L4.85779 7.87155Z"
            fill="#9059FE" />
          <path
            d="M8.00011 2.21464L7.37166 2.84309L8.62855 4.09998L9.25699 3.47153C10.2961 2.4342 11.9877 2.43242 13.0286 3.47153C14.0677 4.51153 14.0677 6.20309 13.0286 7.24309L11.1423 9.12842C10.1352 10.1355 8.37877 10.1355 7.37166 9.12842L6.74322 8.49998L5.48633 9.75687L6.11477 10.3853C6.95299 11.2244 8.06944 11.6875 9.25699 11.6875C10.4446 11.6875 11.561 11.2244 12.3992 10.3853L14.2854 8.49998C16.017 6.76753 16.017 3.94709 14.2854 2.21464C12.5521 0.481311 9.73166 0.4822 8.00011 2.21464Z"
            fill="#9059FE" />
        </g>
      </svg>
      <span>Links are all working</span>
    </li>
    <li>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M0 2.27778V14.7222C0 15.7027 0.797333 16.5 1.77778 16.5H14.2222C15.2027 16.5 16 15.7027 16 14.7222V2.27778C16 1.29733 15.2027 0.5 14.2222 0.5H1.77778C0.797333 0.5 0 1.29733 0 2.27778ZM14.224 14.7222H1.77778V2.27778H14.2222L14.224 14.7222Z"
            fill="#9059FE" />
          <path
            d="M10.6668 8.49999H12.4446V4.05554H8.00011V5.83332H10.6668V8.49999ZM8.00011 11.1667H5.33344V8.49999H3.55566V12.9444H8.00011V11.1667Z"
            fill="#9059FE" />
        </g>
      </svg>
      <span>Dimensions are specified</span>
    </li>
    <li>
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M14.4 0.299988H1.6C0.7176 0.299988 0 1.01759 0 1.89999V13.1C0 13.9824 0.7176 14.7 1.6 14.7H14.4C15.2824 14.7 16 13.9824 16 13.1V1.89999C16 1.01759 15.2824 0.299988 14.4 0.299988ZM1.6 13.1V1.89999H14.4L14.4016 13.1H1.6Z"
            fill="#9059FE" />
          <path
            d="M3.19995 3.5H12.8V5.1H3.19995V3.5ZM3.19995 6.7H12.8V8.3H3.19995V6.7ZM3.19995 9.9H7.99995V11.5H3.19995V9.9Z"
            fill="#9059FE" />
        </g>
      </svg>
      <span>Expected deliverables are clear</span>
    </li>
    <li>
      <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M12.0032 0.900024C12.0016 0.900024 12.0008 0.900024 12 0.900024H11.9992H5.6C4.1296 0.900024 2.7312 1.48962 1.6608 2.56082C0.5896 3.63202 0 5.03042 0 6.50002C0 7.97042 0.5896 9.36882 1.6608 10.4392C2.7312 11.5104 4.1296 12.1 5.6 12.1H12V10.5H5.6C4.5576 10.5 3.56 10.0768 2.792 9.30802C2.0232 8.54002 1.6 7.54242 1.6 6.50002C1.6 5.45842 2.0232 4.46082 2.792 3.69202C3.56 2.92322 4.5576 2.50002 5.6 2.50002H12V1.70002L12.0008 2.50002C12.0016 2.50002 12.0024 2.50002 12.0032 2.50002C12.6352 2.50002 13.2344 2.75122 13.6904 3.20882C14.1472 3.66562 14.3992 4.26642 14.4 4.90162C14.4008 5.53282 14.1496 6.13282 13.6928 6.58962C13.2344 7.04722 12.6328 7.29922 12 7.30002H5.6C5.3952 7.30002 5.196 7.21442 5.0416 7.06002C4.8856 6.90402 4.8 6.70482 4.8 6.50002C4.8 6.29842 4.888 6.09442 5.0408 5.94162C5.196 5.78562 5.3952 5.70002 5.6 5.70002H12V4.10002H5.6C4.968 4.10002 4.3672 4.35202 3.9088 4.81122C3.4512 5.26882 3.2 5.86882 3.2 6.50002C3.2 7.13122 3.4512 7.73202 3.9104 8.19122C4.368 8.64882 4.9688 8.90002 5.6 8.90002H12.0008C13.06 8.89922 14.0624 8.48162 14.8232 7.72162C15.584 6.96162 16.0016 5.95842 16 4.89922C15.9992 3.83842 15.5808 2.83602 14.8224 2.07762C14.064 1.31762 13.0624 0.900024 12.0032 0.900024Z"
            fill="#9059FE" />
        </g>
      </svg>
      <span>Attachments are complete</span>
    </li>
  </ul>
  <span penjiPermission="_project-review" style_type="button">
    <button (click)="reviewed()" nz-button [nzLoading]="isLoading" nzType="primary" nzBlock=""
      class="flex items-center justify-center space-x-2 btn-primary-green">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.49992 1.33331C4.82392 1.33331 1.83325 4.32398 1.83325 7.99998C1.83325 11.676 4.82392 14.6666 8.49992 14.6666C12.1759 14.6666 15.1666 11.676 15.1666 7.99998C15.1666 4.32398 12.1759 1.33331 8.49992 1.33331ZM7.16725 10.942L4.69192 8.47198L5.63325 7.52798L7.16592 9.05798L10.6953 5.52865L11.6379 6.47131L7.16725 10.942Z"
          fill="#F6FFED" />
      </svg>
      <span>Mark as Reviewed</span>
    </button>
  </span>

</ng-template>

<!-- temp review circle type 2 -->
<ng-template #popUnReview>
  <span penjiPermission="_project-review" style_type="button">
    <button (click)="unreviewed()" nz-button [nzLoading]="isLoading" nzType="default" nzDanger="" nzBlock
      class="flex items-center justify-center">
      <span>Mark as Unreviewed</span>
    </button>
  </span>
</ng-template>