import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProfileChangePlanComponent } from '@penji/client-v3/profiles/ui/profile-change-plan';
import { MembershipNameComponent } from '@penji/client-v3/membership/membership-name';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'penji-membership-summary',
  standalone: true,
  imports: [
    CommonModule,
    MembershipNameComponent,
    NzButtonModule
  ],
  templateUrl: './membership-summary.component.html',
  styleUrls: ['./membership-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipSummaryComponent {

  private readonly membershipStore = inject(MembershipStoreService);
  private readonly modal = inject(NzModalService);

  membership$ = this.membershipStore.list_sub_design$
  
  openChangePlan() {
    this.modal.create({
      nzCentered: true,
      nzContent: ProfileChangePlanComponent,
      nzBodyStyle: { padding: '0px', overflow: 'auto', background: '#fff'},
      nzWidth: '1080px',
      nzStyle: { top: '1rem' },
      nzTitle: 'Change your plan',
      nzFooter: null,
      nzWrapClassName: 'modal-profile-change-plan [&_.ant-modal-title]:text-center'
    });
  }

}
