/* eslint-disable */
import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Team, TeamService } from '@penji/shared/data-access';
import { catchError, distinctUntilChanged, EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';

interface WorkspaceState extends EntityState<Team> {
  loading: boolean,
  error: string,
  current_team: Team | null,
  nbHits: number,
  page: number,
  hitsPerPage: number
}
const adapter = createEntityAdapter<Team>();
const initialState: WorkspaceState = adapter.getInitialState({
  loading: false,
  error: '',
  current_team: null,
  nbHits: 0,
  page: 0,
  hitsPerPage: 0
})
@Injectable()
export class WorkspaceAlgoliaStoreService extends ComponentStore<WorkspaceState> {

  private teamSV = inject(TeamService);
  constructor() { super(initialState); }
  readonly data$ = this.select(s => Object.values(s.entities) as Team[]);
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);
  private readonly nbHits$ = this.select((s) => s.nbHits);
  private readonly hitsPerPage$ = this.select((s) => s.hitsPerPage);
  private readonly page$ = this.select((s) => s.page);
  readonly current_team$ = this.select((s) => s.current_team);
  readonly vm$ = this.select(
    this.data$,
    this.nbHits$,
    this.hitsPerPage$,
    this.page$,
    (data, nbHits, hitsPerPage, page) => ({
      data,
      nbHits,
      hitsPerPage,
      page
    })
  );

  loadTeams$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
      }),
      switchMap((params: any) => {
        // console.log(params);
        return this.teamSV.getListTeamAlgolia(params).pipe(
          map((result: any) => {
            if (result.success) {
              let data = result.data.hits as Team[];
              data = data.map(item => {
                item.id = item.team_id;
                return item;
              })
              data = data.filter(f=> f.team_id);
              this.setState(s => adapter.setAll(data, s))
              this.patchState({ nbHits: result.data.nbHits, hitsPerPage: result.data.hitsPerPage, page: result.data.page, loading: false, error: '' });
            } else {
              this.patchState({ loading: false, error: 'error get customers filter api' });
            }
          })
        )
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        throw new Error('cannot get api http!');
        return EMPTY;
      })
    )
  });
  getTeamDetail2$ = this.effect((customer_team_id$: Observable<string>) => {
    return customer_team_id$.pipe(
      distinctUntilChanged(),
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((customer_team_id: string) => {
        // console.log(customer_team_id);
        return this.teamSV.getTeamDetail(customer_team_id).pipe(
          switchMap(rs => {
            // console.log(rs);
            if (rs) {
              this.patchState({ current_team: rs as Team, loading: false, error: '' });
              return EMPTY;
            } else {
              this.patchState({ loading: true, error: 'cannot update the team!' });
              return EMPTY;
            }
          })
        )
      })
    )
  });

  async exportDataWorkSpace(filter_obj: any){
    const result = await this.teamSV.exportDataWorkspace(filter_obj).pipe(take(1)).toPromise();
    console.log(result);
  }
}
