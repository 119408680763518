/* eslint-disable */
import { inject, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ComponentStore } from "@ngrx/component-store";
import { UserData, UserService } from "@penji/shared/data-access";
import { catchError, debounceTime, distinctUntilChanged, EMPTY, Observable, of, shareReplay, switchMap } from "rxjs";
import { AuthService } from "../auth.service";
import { AuthState, inititialState } from "./auth.state";
@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
    userSV = inject(UserService);
    private readonly afa = inject(AngularFireAuth);
    constructor(
        private authSV: AuthService,
    ) {
        super(inititialState);
    }
    readonly auth_id$ = this.select(state => state.auth_profile?.id);
    readonly authProfile$ = this.select(state => state.auth_profile);
    readonly refresh_token$ = this.select(state => state.refresh_token);

  readonly updateAuthProfile = this.updater((state: AuthState, user: UserData) => {
    console.log({ ...state.auth_profile, ...user });
    return {
      ...state,
      auth_profile: { ...state.auth_profile, ...user }
    }
  });
  readonly init2 = this.effect((auth$: Observable<any>) => {
    return auth$.pipe(
      switchMap((auth) => {
        if (auth) {
          // console.log(auth)
          return this.authSV.getUserDetail(auth.uid).then(userInfo => {
            this.setState((state) => {
              return {
                ...state,
                auth_profile: userInfo,
                refresh_token: auth.refreshToken
              }
            });
          })
        }
        this.setState((state) => {
          return {
            ...state,
            auth_profile: null,
            refresh_token: null
          }
        });
        return EMPTY
      }), catchError(() => {
        this.setState((state) => {
          return {
            ...state,
            auth_profile: null,
            refresh_token: null
          }
        });
        return EMPTY
      })
    )
  })
  updateAuthProfile$ = this.effect((user$: Observable<UserData>) => {
    return user$.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      switchMap(user => {
        return this.afa.authState.pipe(
          switchMap(auth => {
            if (auth && auth.uid === user.id) {
              return this.userSV.updateUserByAuth(user, user.user_info ? 'yes' : 'no').then(result => {
                if (result.flag) {
                  this.updateAuthProfile(user);
                }
                return result;
              });
            } else {
              return of({ flag: false });
            }
          }));
      })
    )
  })
}
