/* eslint-disable */
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, Query } from "@angular/fire/compat/firestore";
import * as firebase from 'firebase/firestore';
import { BehaviorSubject, Observable, catchError, map, of, retry, switchMap, take, throttleTime, throwError } from "rxjs";
import { Media } from "../models/media.model";
import { LogService } from "./log.service";
import { WhereQueryInterface } from "../interfaces/where-query-interface";
import * as mime from 'mime';

@Injectable({
  providedIn: 'root'
})
export class MediaService extends LogService<Media> {

  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  override afs = inject(AngularFirestore);
  getMediaRefDetail(user_id: string, media_id: string) {
    return this.afs.firestore.collection('media').doc(user_id).collection('files').doc(media_id).get().then((result: any) => {
      const media = result.data() as Media;
      media.id = result.id;
      return media;
    }).catch(error => {
      console.log(error);
      return Promise.resolve({} as Media);
    });
  }
  //upload multi part start upload
  startUploadForMultiPart(file_name: string, file_size: number) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'token': token,
            'file_name': file_name,
            'file_size': file_size
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post('https://penjiapi.herokuapp.com/api/storage/start-upload', data, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  getPresignedUrlForMultiPartUpload(file_name: string, part_number: number, upload_id: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'file_name': file_name,
            'part_number': part_number,
            'upload_id': upload_id,
            'token': token
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post('https://penjiapi.herokuapp.com/api/storage/get-upload-part-url', data, httpOptions)
              .pipe(
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  getCompleteMultiPartUpload(file_name: string, parts: any, upload_id: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'file_name': file_name,
            'parts': parts,
            'upload_id': upload_id,
            'token': token
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json'
              })
            }
            return this.http.post('https://penjiapi.herokuapp.com/api/storage/complete-upload', data, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  // end upload
  async addOneMedia(user_id: string, file: any, type: string, public_show: boolean = false, size: number = 0) {
    const data = new Media();
    data.size = size;
    if (type === 'image') {
      const ext = file.original.name.substr(file.original.name.lastIndexOf('.') + 1);
      const name = file.original.name.substr(0, file.original.name.lastIndexOf('_'));
      const newName = name + '.' + ext;
      data.created_at = firebase.Timestamp.now();
      data.type = 'image';
      data.fullname = file.original.name;
      data.name = newName;
      data.original = file.original.url;
      data.medium = file.medium.url;
      data.thumb = file.thumb.url;
      data.avatar = file.avatar.url;
      data.public = public_show;
    } else {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const name = file.name.substr(0, file.name.lastIndexOf('_'));
      const newName = name + '.' + ext;
      data.created_at = firebase.Timestamp.now();
      data.type = type;
      data.fullname = file.name;
      data.name = newName
      data.original = file.url;
      data.extension = ext;
      data.public = public_show;
    }

    const kq = await this.afs.firestore.collection(`media/${user_id}/files/`).add({ ...data }).then(rs => rs).catch(err => err);
    if (kq.message) {
      return null;
    } else {
      this.log_model.action = 'upload';
      this.log_model.data = { ...new Media, ...data };
      this.createLog();
      return kq;
    }
  }
  async addOneMediaV2(user_id: string, file: any, type: string, public_show: boolean = false, size: number = 0) {
    const data = new Media();
    data.size = size;
    if (type === 'image') {
      const ext = file.original.name.substr(file.original.name.lastIndexOf('.') + 1);
      const name = file.original.name.substr(0, file.original.name.lastIndexOf('_'));
      const newName = name + '.' + ext;
      data.created_at = firebase.Timestamp.now();
      data.type = 'image';
      data.fullname = file.original.name;
      data.name = newName;
      data.original = file.original.url;
      data.medium = file.original.url + '?format=webp&size=600x460';
      data.thumb = file.original.url + '?format=webp&size=175x125';
      data.avatar = file.original.url + '?format=webp&size=150x150';
      data.public = public_show;
      data.version = 2;
    } else {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const name = file.name.substr(0, file.name.lastIndexOf('_'));
      const newName = name + '.' + ext;
      data.created_at = firebase.Timestamp.now();
      data.type = type;
      data.fullname = file.name;
      data.name = newName
      data.original = file.url;
      data.extension = ext;
      data.public = public_show;
      data.version = 2;
    }
    // console.log(`media/${user_id}/files/`);
    const kq = await this.afs.firestore.collection(`media/${user_id}/files/`).add({ ...data }).then(rs => rs).catch(err => err);
    if (kq.message) {
      return null;
    } else {
      this.log_model.action = 'upload';
      this.log_model.data = { ...new Media, ...data };
      this.createLog();
      return kq;
    }
  }
  async deleteMediaRef(user_id: string, media_id: string) {
    return await this.afs.firestore.collection('media').doc(user_id).collection('files').doc(media_id).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ...new Media, id: media_id } as Media;
      this.createLog();
      return { flag: true, massage: 'Media successfully deleted!' }
    }).catch(error => {
      return { flag: false, message: error }
    });
  }
  async getListMedias(
    user_id: string,
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc: boolean = true) {
    try {
      let query: Query = this.afs.firestore.collection('media').doc(user_id).collection('files');
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`media/${user_id}/files/${start_after}`).get();
        query = query.startAfter(doc)
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`media/${user_id}/files/${end_before}`).get();
        query = query.endBefore(doc)
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list = Array<Media>();
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Media;
          data.id = doc.id;
          data.doc = doc.ref;
          data.owner_id = user_id;
          list.push(data);
        });
        return list;
      }).catch(err => {
        console.log(err);
        return [];
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  updateMedia(media: Media) {
    return this.afs.doc(`media/${media.owner_id}/files/${media.id}`).update({ ...media }).then(() => {
      return ({ flag: true, message: 'Media succesfully updated!' });
    }).catch(err => {
      return ({ flag: false, message: err.message });
    })
  }
  //upload 1 link to server
  getLinkUpload(file_name: string, file_size: number) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          //console.log(token);
          const data = {
            'file_name': file_name,
            'file_size': file_size
          }
          // console.log(data);
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              }),
            };
            return this.http.post('https://api.penji.co/api-upload-file-cloudfront/get-link-upload ', data, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }


  async uploadOneFileToServerV2(user_id: string, file: any) {
    const file_size = file.size;
    const file_name: any = file.name;
    let url = '';
    try {

      const res = await this.getLinkUpload(file.name, file_size);
      // console.log(res);
      if (res.success) {
        const xhr = new XMLHttpRequest();
        const success: any = await new Promise((resolve) => {
          xhr.upload.addEventListener('progress', (event) => {
            // console.log(parseInt(((event.loaded / event.total) * 100).toFixed(2)));
            const process = Math.round((100 * event.loaded) / (event.total));
            if (event.lengthComputable) {
              // processing...
            }
          });
          xhr.addEventListener('loadend', async () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              resolve(true);
            }
          });
          xhr.addEventListener('error', (event) => {
            console.log(event);
            resolve(false);
          });
          xhr.open('PUT', res.data.upload_link, true);
          xhr.send(file);
        });
        if (success) {
          // console.log(res);
          const get_type_fname = mime.getType(file_name.split('.').pop().toLowerCase()) || '';
          if (get_type_fname.match('image.*') && !get_type_fname.match('image/vnd.adobe.photoshop')) {
            await this.addOneMediaV2(user_id, res.data.path_file, 'image', false, file_size);
            url = res.data.path_file.original.url;
          } else if (get_type_fname.match('application.*')) {
            await this.addOneMediaV2(user_id, res.data.path_file, 'file', false, file_size);
            url = res.data.path_file.url;
          } else if (get_type_fname.match('font.*')) {
            await this.addOneMediaV2(user_id, res.data.path_file, 'font', false, file_size);
            url = res.data.path_file.url;
          } else {
            await this.addOneMediaV2(user_id, res.data.path_file, 'file', false, file_size);
            url = res.data.path_file.url;
          }
        } else {
          console.log('error');
        }
        return url;
      } else {
        console.log(res);
        throw new Error(res.err);
      }
    } catch (err: any) {
      throw new Error(err);
    }

  }
}
