import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { RouterUtils } from '@penji/shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerTeamCanActiveGuard implements CanActivate {
  authStore = inject(AuthStore);
  authProfile$ = this.authStore.authProfile$;
  router = inject(Router);
  canActivate( route: ActivatedRouteSnapshot) {
       this.authProfile$.subscribe(
        rs => {
          if(rs && rs.team_active){
            if(!route.children[0])
              this.router.navigate(['/t',rs.team_active])
          }       
        } 
       )
    return true;
  }
  
}
