import { EntityAdapter } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as userActions from './user.action';
import { UserSimple, UserState } from '../../models/user.model';

const adapter: EntityAdapter<UserSimple> =  createEntityAdapter<UserSimple>();
export const inititialUserState: UserState = adapter.getInitialState();

export const UserReducer = createReducer(inititialUserState,
  on(userActions.createUser, (state, {user}) => {
    return adapter.setOne(user, state)
  }),
  on(userActions.updateUser, (state, {update})=>{
    return adapter.updateOne(update, state);
  })
);
