import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentStoreService } from '@penji/team/view-design/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-revision-by-idlink',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './revision-by-idlink.component.html',
  styleUrls: ['./revision-by-idlink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevisionByIdlinkComponent {

  @Input() id_link?: string;
  @Input() type: 'default' | 'primary' = 'default';

  private readonly commentStoreSv = inject(CommentStoreService);

  list_comment$: Observable<any> | undefined;


  ngOnChanges(): void {
    if(this.id_link)
      this.list_comment$ = this.commentStoreSv.data_comment_by_idlink$(this.id_link);
  }
}
