
<p *ngIf="design_status === 'verified'" class="text-green-500 bg-green-100 border border-green-300 py-1 px-2.5 rounded space-x-1 inline-flex items-center">
  <span class="material-icons-outlined text-base">
  done
  </span>
  <span class="text-[13px]">Verified</span>
</p>
<p *ngIf="design_status === 'to-verify'" class="text-orange-500 bg-orange-100 border border-orange-300 py-1 px-2.5 rounded space-x-1 inline-flex items-center">
  <span class="material-icons-outlined text-base">
  schedule
  </span>
  <span class="text-[13px]">To Verify</span>
</p>
<p *ngIf="design_status === 'reject'" class="text-red-500 bg-red-100 border border-red-300 py-1 px-2.5 rounded space-x-1 inline-flex items-center">
  <span class="material-icons-outlined text-base">
  error
  </span>
  <span class="text-[13px]">Send Back</span>
</p>

<p *ngIf="design_status === 'be-scheduled-now'" class="text-blue-500 bg-blue-100 border border-blue-300 py-1 px-2.5 rounded space-x-1 inline-flex items-center">
  <span class="material-icons-outlined text-base">
  error
  </span>
  <span class="text-[13px]">Scheduled Now</span>
</p>
<p *ngIf="design_status === 'be-scheduled-6pm'" class="text-yellow-500 bg-yellow-100 border border-yellow-300 py-1 px-2.5 rounded space-x-1 inline-flex items-center">
  <span class="material-icons-outlined text-base">
  error
  </span>
  <span class="text-[13px]">Scheduled at 6PM</span>
</p>
