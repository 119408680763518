<div class="w-full flex space-x-1 py-2">
  <ng-container *ngIf="project_data.assign_team_designer">
    <penji-designer-team-leader [designer_team_id]="project_data.assign_team_designer"></penji-designer-team-leader>
  </ng-container>
  <penji-user-array-item [uid_array]="list_user_id" [user_image_size]="user_image_size"></penji-user-array-item>
  <ng-container *ngIf="reassign_on">
    <penji-project-reassign class="[&_.permission-content]:block [&_.permission-content>div]:rounded-full"
      [project_data]="project_data" (list_user_id_output)="getListUserId($event)"></penji-project-reassign>
  </ng-container>
</div>
