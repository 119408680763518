<!--
  // 0: Unavailable
  // 1: PTO
  // 2: UPTO
  // 3: Checkin
  // 4: Late
  // 5: Checkout
-->

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'dot'">
    <div
      class="[&_span]:w-2 [&_span]:h-2 [&_span]:rounded-full [&_span]:ring-2 [&_span]:ring-white [&_span]:inline-flex {{checkin_status===1?'[&_span]:bg-blue-500':checkin_status===2?'[&_span]:bg-orange-500':checkin_status===3?'[&_span]:bg-[#22C55E]':checkin_status===4?'[&_span]:bg-orange-500':checkin_status===5?'[&_span]:bg-gray-400':checkin_status===6?'[&_span]:bg-red-500':'[&_span]:bg-gray-400'}}">
      <span nz-tooltip
        [nzTooltipTitle]="checkin_status===1?'PTO':checkin_status===2?'UPTO':checkin_status===3?'Check in':checkin_status===4?'Late':checkin_status===5?'Check out':checkin_status===6?'Disabled':'Unavailable'"
        nzTooltipPlacement="bottom" [nzTooltipOverlayClassName]="'text-xs'"></span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'text'">
    <ng-container [ngSwitch]="checkin_status">
      <p *ngSwitchCase="1" class="text-blue-500 text-sm">on PTO</p>
      <p *ngSwitchCase="2" class="text-orange-500 text-sm">on UPTO</p>
      <p *ngSwitchCase="3" class="text-[#22C55E] text-sm">
        Checked in at {{checkin_at ? (checkin_at.toDate() | date : 'shortTime') : 'N/A'}}
      </p>
      <p *ngSwitchCase="4" class="text-orange-500 text-sm">
        Checked in at {{checkin_at ? (checkin_at.toDate() | date : 'shortTime') : 'N/A'}}
      </p>
      <p *ngSwitchCase="5" class="text-gray-400 text-sm">
        Checked out at {{checkout_at ? (checkout_at.toDate() | date : 'shortTime') : 'N/A'}}
      </p>
      <p *ngSwitchCase="6" class="text-red-500 text-sm">Account disabled</p>
      <p *ngSwitchDefault class="text-gray-400 text-sm">Unavailable</p>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'full'">
    <ng-container [ngSwitch]="checkin_status">
      <p *ngSwitchCase="1" class="text-blue-500 space-x-1.5 inline-flex items-center">
        <span class="material-icons text-[16px]">circle</span>
        <span>on PTO</span>
      </p>
      <p *ngSwitchCase="2" class="text-orange-500 space-x-1.5 inline-flex items-center">
        <span class="material-icons text-[16px]">circle</span>
        <span>on UPTO</span>
      </p>
      <p *ngSwitchCase="3" class="text-[#22C55E] space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkin_at ? (checkin_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Checked in:</span>
        <span class="text-gray-500 dark:text-white">
          {{checkin_at ? (checkin_at.toDate() | date : 'shortTime') : 'N/A'}}
        </span>
      </p>
      <p *ngSwitchCase="4" class="text-orange-500 space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkin_at ? (checkin_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Checked in:</span>
        <span class="text-gray-500 dark:text-white">
          {{checkin_at ? (checkin_at.toDate() | date : 'shortTime') : 'N/A'}}
        </span>
      </p>
      <p *ngSwitchCase="5" class="text-gray-400 space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkout_at ? (checkout_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Checked out:</span>
        <span class="text-gray-500 dark:text-white">
          {{checkout_at ? (checkout_at.toDate() | date : 'shortTime') : 'N/A'}}
        </span>
      </p>
      <p *ngSwitchCase="6" class="text-red-500 space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkout_at ? (checkout_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Account disabled</span>
      </p>
      <p *ngSwitchDefault class="text-gray-400 space-x-1.5 inline-flex items-center">
        <span class="material-icons text-[16px]">circle</span>
        <span>Unavailable</span>
      </p>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container [ngSwitch]="checkin_status">
      <p *ngSwitchCase="1" class="text-blue-500 space-x-1.5 inline-flex items-center">
        <span class="material-icons text-[16px]">circle</span>
        <span>PTO</span>
      </p>
      <p *ngSwitchCase="2" class="text-orange-500 space-x-1.5 inline-flex items-center">
        <span class="material-icons text-[16px]">circle</span>
        <span>UPTO</span>
      </p>
      <p *ngSwitchCase="3" class="text-[#22C55E] space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkin_at ? (checkin_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Check in</span>
      </p>
      <p *ngSwitchCase="4" class="text-orange-500 space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkin_at ? (checkin_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Late</span>
      </p>
      <p *ngSwitchCase="5" class="text-gray-400 space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkout_at ? (checkout_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Check out</span>
      </p>
      <p *ngSwitchCase="6" class="text-red-500 space-x-1.5 inline-flex items-center" nz-tooltip
        [nzTooltipTitle]="checkout_at ? (checkout_at.toDate() | date : 'medium') : 'N/A'">
        <span class="material-icons text-[16px]">circle</span>
        <span>Disabled</span>
      </p>
      <p *ngSwitchDefault class="text-gray-400 space-x-1.5 inline-flex items-center">
        <span class="material-icons text-[16px]">circle</span>
        <span>Unavailable</span>
      </p>
    </ng-container>
  </ng-container>
</ng-container>