import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClientShellRoutingModule } from './client-shell.routing';


@NgModule({
  imports: [
    CommonModule,
    ClientShellRoutingModule
  ]
})
export class ClientShellModule { }
