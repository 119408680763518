/* eslint-disable */
import { inject, Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { OrderInterface } from '../interfaces/order-interface';

@Injectable({
  providedIn: 'root',
})
export class TrackingDataService {
  private readonly afs = inject(AngularFirestore);

  listTrackingData(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: Array<OrderInterface>,
    start_at?: QueryDocumentSnapshot<DocumentData>,
    end_at?: QueryDocumentSnapshot<DocumentData>
  ) {
    try {
      let query: Query = this.afs.firestore.collection(`tracking_data_v2`);

      if (where_query && where_query.length > 0) {
        where_query.forEach((q) => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        });
      }

      if (order_by && order_by.length > 0) {
        order_by.forEach(q => {
          query = query.orderBy(q.field_name, q.field_direction);
        });
      } else {
        query = query.orderBy('created_at', 'desc');
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else if (start_at) {
        query = query.startAt(start_at);
        if (limit != -1) query = query.limit(limit);
      } else if (end_at) {
        query = query.endAt(end_at);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query
        .get()
        .then((querySnapshot) => {
          const list: Array<any> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as any;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return list;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    } catch (error) {
      console.log(error);
      return Promise.resolve([]);
    }
  }

  listTrackingDataV3(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: Array<OrderInterface>,
    start_at?: QueryDocumentSnapshot<DocumentData>,
    end_at?: QueryDocumentSnapshot<DocumentData>
  ) {
    try {
      let query: Query = this.afs.firestore.collection(`/tracking_data_v3/tracking_project_log/log`);

      if (where_query && where_query.length > 0) {
        where_query.forEach((q) => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        });
      }

      if (order_by && order_by.length > 0) {
        order_by.forEach(q => {
          query = query.orderBy(q.field_name, q.field_direction);
        });
      } else {
        query = query.orderBy('log_created_at', 'desc');
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else if (start_at) {
        query = query.startAt(start_at);
        if (limit != -1) query = query.limit(limit);
      } else if (end_at) {
        query = query.endAt(end_at);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query
        .get()
        .then((querySnapshot) => {
          const list: Array<any> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as any;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return list;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    } catch (error) {
      console.log(error);
      return Promise.resolve([]);
    }
  }
}
