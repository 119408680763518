
<ng-container *ngIf="workspace_data$ | async as workspace_data">
  <ng-container *ngIf="workspace_data.moved_to_v2; else isnot_v2">
    <ng-container *ngIf="workspace_data.membership && workspace_data.membership.length > 0; else no_data">
      <span class="text-gray-500 font-semibold">{{workspace_data.membership[0].metadata.total_active_project}}</span>
    </ng-container>
  </ng-container>
  <ng-template #isnot_v2>
    <span class="text-gray-500 font-semibold">{{workspace_data.number_of_active_project}}</span>
  </ng-template>
</ng-container>
<ng-template #no_data>
  <p class="text-gray-500">N/A</p>
</ng-template>
