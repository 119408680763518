import { inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { DocumentReference } from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';

import { WorkSpace } from '../models/workspace.model';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  private readonly afs = inject(AngularFirestore);
  private readonly afa = inject(AngularFireAuth);
  private readonly nzMessageService = inject(NzMessageService);
  listForPermission$ = this.afa.authState.pipe(
    switchMap(user => {
      if (user) {
        return new Observable<WorkSpace[]>(observe => {
          return this.afs.firestore.collection('workspace').where('user_id', '==', user.uid).onSnapshot(
            snapshot => {
              const list: Array<WorkSpace> = [];
              snapshot.forEach(doc => {
                const data = doc.data() as WorkSpace;
                data.id = data.team_id;
                list.push(data);
              });
              observe.next(list)
            },
            error => {
              observe.error(error);
            })
        })
      } else {
        return of([] as WorkSpace[]);
      }
    })
  )
  constructor() { this.listForPermission$.subscribe() }
  getListWorkSpace() {
    return this.afa.authState.pipe(
      switchMap(user => {
        if (user) {
          return new Observable<WorkSpace[]>(observe => {
            return this.afs.firestore.collection('workspace').where('user_id', '==', user.uid).onSnapshot(
              snapshot => {
                const list: Array<WorkSpace> = [];
                snapshot.forEach(doc => {
                  const data = doc.data() as WorkSpace;
                  data.id = data.team_id;
                  list.push(data);
                });
                observe.next(list)
              },
              error => {
                observe.error(error);
              })
          })
        } else {
          return of([] as WorkSpace[]);
        }
      }),
      switchMap(list => {
        // console.log(list);
        const promises: any = [];
        list.forEach(item => {
          promises.push(this.afs.firestore.doc(`team/${item.team_id}`).get().then(rs => ({ ...rs.data(), id: item.team_id })));
        })
        return combineLatest(of(list), Promise.all(promises));
      }),
      map(([list, list_team]) => {
        list.map(item => {
          const team = list_team.find(f => f.id === item.team_id);
          if (team) {
            item.title = team.title ?? '';
            item.logo = team.logo ?? null;
          }
          return item;
        })
        // console.log(list)
        return list;
      }),
      shareReplay()
    )
  }
  getListMemberFromWorkSpace(client_team_id: string) {
    try {
      return this.afs.firestore.collection('workspace').where('team_id', '==', client_team_id).get().then(snapshot => {
        const list: Array<WorkSpace> = [];
        snapshot.forEach(doc => {
          const workspace = doc.data() as WorkSpace;
          workspace.id = doc.id;
          list.push(workspace);
        })
        return list;
      })
    } catch (error) {
      console.log(error);
      return Promise.resolve([]);
    }

  }
  async getListWorkSpaceFromClient(user_id: string) {
    // console.log(user_id);
    const list: Array<WorkSpace> = [];
    const result = await this.afs.firestore.collection('workspace').where('user_id', '==', user_id).get();

    // console.log(result.size);
    if (result.size > 0) {
      result.forEach(doc => {
        const data = doc.data() as WorkSpace;
        data.id = data.team_id +'_'+ user_id;
        list.push(data);
      });
      const promises: any = [];
      list.forEach(item => {
        promises.push(this.afs.firestore.doc(`team/${item.team_id}`).get().then(rs => ({ ...rs.data(), id: item.team_id })));
      })
      const list_team = await Promise.all(promises);
      list.map(item => {
        const team = list_team.find(f => f.id === item.id);
        if (team) {
          item.title = team.title ?? '';
          item.logo = team.logo ?? null;
        }
        return item;
      })
    }
    return list;
  }
  async addWorkSpace(user_id: string, title: string, logo: DocumentReference) {
    try {
      const result = await this.afs.firestore.collection('team').add({
        title,
        logo,
        created_at: firebase.Timestamp.now(),
        owner_id: user_id
      });
      const workspace = new WorkSpace();
      workspace.team_id = result.id;
      workspace.role = 'owner';
      workspace.user_id = user_id;
      await this.afs.firestore.collection('workspace').add({ ...workspace });
      this.nzMessageService.success('add workspace successfully!');
      return { flag: true, message: 'add workspace successfully!' }
    } catch (error: any) {
      console.log(error);
      return { flag: true, message: error.message };
    }
  }
  async updateWorkSpace(team_id: string, title: string, logo: DocumentReference) {
    try {
      await this.afs.firestore.doc(`team/${team_id}`).set({ title, logo }, { merge: true });
      this.nzMessageService.success('update workspace successfully!');
      return { flag: true, message: 'update workspace successfully!' }
    } catch (error: any) {
      console.log(error);
      return { flag: true, message: error.message };
    }
  }
  deleteMemberInWorkSpace(workspace_id: string) {
    try {
      return this.afs.doc(`workspace/${workspace_id}`).delete().then(() => {
        this.nzMessageService.success('The member successfully deleted!');
        return { flag: true, message: 'The member successfully deleted!' };
      }).catch(error => {
        console.log(error);
        this.nzMessageService.error(error.message);
        return Promise.resolve({ flag: false, message: error.message as string });
      });
    } catch (error: any) {
      console.log(error);
      return Promise.resolve({ flag: false, message: error.message as string })
    }

  }

  updateMemberWorkSpace(workspace: WorkSpace) {
    return this.afs.doc(`workspace/${workspace.id}`).update({ ...workspace }).then(() => {
      this.nzMessageService.success('Ticket successfully updated!');
      return ({ flag: true, message: 'Membership ownership successfully updated!' });
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }
  getWorkSpaceDetailOnce(workspace_id: string) {
    try {
      return this.afs.firestore.collection('workspace').doc(workspace_id).get()
        .then(rs => {
          if (rs.data() as WorkSpace) {
            const data = { ...rs.data(), id: rs.id } as WorkSpace;
            return { flag: true, data: data }
          }
          return { flag: false, data: null };
        }).catch((error) => {
          this.nzMessageService.error(error.message);
          return Promise.resolve({ flag: false, message: error.message as string });
        });
    } catch (error: any) {
      console.log(error);
      return Promise.resolve({ flag: false, message: error.message as string });
    }
  }
  async assignSMMtoTeam(client_team_id: string, user_id: string) {
    try {
      return await this.afs.firestore.doc(`team/${client_team_id}`).set({
        'teammates': firebase.arrayUnion(user_id)
      }, { merge: true }).then(() => {
        return ({ flag: true, message: 'success!' });
      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } catch (err: any) {
      return ({ flag: false, message: err.message });
    }
  }
  async removeAssignSMMtoTeam(client_team_id: string, user_id: string) {
    try {
      return await this.afs.firestore.doc(`team/${client_team_id}`).set({
        'teammates': firebase.arrayRemove(user_id)
      }, { merge: true }).then(() => {
        return ({ flag: true, message: 'success!' });
      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } catch (err: any) {
      return ({ flag: false, message: err.message });
    }
  }
}
