/* eslint-disable @typescript-eslint/no-empty-interface */
import { DocumentData, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';

// tslint:disable:variable-name
export class Ticket {
  id!: string;
  doc?: any;
  action?: 'added' | 'modified' | 'removed';
  at_fault!: string[];
  client_id!: string;
  closed_at!: Timestamp;
  created_at!: Timestamp;
  description!: string;
  index_number!: string;
  owner_id!: string;
  last_message!: string;
  last_role!: number;
  last_user!: string;
  project!: Array<any>;
  project_array!: Array<string>;
  resolution!: string[];
  status!: number; // 1|2|3|4 (1: unclaimed, 2: claimed, 3 solved, 4 duplicated)
  tags!: string[];
  team_client_id?: string;
  team_designer_id!: string;
  ticket_sub_category_id!: string;
  ticket_main_category_id!: string;
  ticket_source!: number; // 1: automatic(trigger), 2: report issue, 3: client app help
  ticket_summary!: string;
  user_claimed_id!: string;
  user_solved_id!: string;
  user_array!: string[];
  files?: any[];
  updated_at!: Timestamp;
  viewer!: any[];
  reply_viewer!: boolean;
  reviewed!: boolean;
  log_type = 'ticket';
}
export class TicketAssigned {
  id!: string;
  created_at!: Timestamp;
  type!: number;
  user_id!: string;
  user_ref!: DocumentReference;
}
export class TicketDiscussion {
  id!: string;
  ticket_id!: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  created_at!: Timestamp;
  updated_at?: Timestamp;
  date!: string | null;
  type!: string;
  link!: DocumentReference;
  source!: DocumentReference;
  user_id!: string;
  user_ref!: DocumentReference;
  owner_id!: string;
  owner_ref!: DocumentReference;
  group!: string;
  content!: string;
  remove!: boolean;
  realtime?: boolean;
  late?: boolean;
  late_time_seconds?: number;
  payload_type?: string; // added, modified, removed
  action_type?: string; // text, email
  to?: string;// to email
  from?: string;// from email
  hidden?: boolean;
  list_mention?: string[];
}

export class TicketCategory {
  id!: string;
  title!: string;
  color!: string;
  main!: boolean;
  count?: number;
}
export class TicketSubCategory {
  id!: string;
  title!: string;
  hide!: boolean;
  source!: number;
  color?: string;
  ticket_category_id?: string;
  main!: boolean;
  count?: number;
}

export interface TicketState extends EntityState<Ticket> { }
export interface TicketDiscussionState extends EntityState<TicketDiscussion> {
  loading: boolean;
  error: string;
  anchor_id: string
}
