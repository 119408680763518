import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { DesignerTeam, DesignerTeamService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';

interface DesignerTeamState extends EntityState<any> {
  loading: boolean,
  error: string
}
const adapter = createEntityAdapter<any>();
const initialState: DesignerTeamState = adapter.getInitialState({
  loading: false,
  error: ''
})
const { selectAll } = adapter.getSelectors();

@Injectable({
  providedIn: 'root'
})
export class DesignerTeamStoreService extends ComponentStore<DesignerTeamState> {

  DesignerTeamSV = inject(DesignerTeamService);

  constructor() { super(initialState); }

  data$ = this.select(selectAll);
  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);

  getOneDesignerTeam(designer_team_id: string) {
    return this.select((s) => s.entities[designer_team_id] as DesignerTeam);
  }

  getFilterDesignerTeam(params: Params) {
    return this.select(this.data$,
      (rs) => {
        let temp = [...rs] as DesignerTeam[];
        for (let k in params) {
          temp = temp.filter((t: any) => t[k] == params[k]);
        }
        return temp.sort((a: any, b:any)=>( a.created_at < b.created_at)?1:-1);
      }
    );
  }

  loadOneDesignerTeam$ = this.effect((team_id$: Observable<string>) => {
    return team_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((team_id) => {
        return this.DesignerTeamSV.getDesignerTeamDetail(team_id);
      }),
      map((designer_team: DesignerTeam) => {
        if (designer_team) {
          this.setState((state) => adapter.setOne(designer_team, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  loadDesignerTeam$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap(() => {
        return this.DesignerTeamSV.listDesignerTeam(-1);
      }),
      map((final_list: DesignerTeam[]) => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  createDesignerTeam$ = this.effect((designer_team$: Observable<DesignerTeam>) => {
    return designer_team$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((designer_team: DesignerTeam) => {
        return this.DesignerTeamSV.addDesignerTeam(designer_team);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateDesignerTeam$ = this.effect((designer_team$: Observable<DesignerTeam>) => {
    return designer_team$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((designer_team: DesignerTeam) => {
        return combineLatest(this.DesignerTeamSV.updateDesignerTeam(designer_team), of(designer_team));
      }),
      map(([rs, designer_team]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({id: designer_team.id, changes: designer_team}, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteDesignerTeam$ = this.effect((designer_team_id$: Observable<string>) => {
    return designer_team_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((designer_team_id: string) => {
        return combineLatest(this.DesignerTeamSV.deleteDesignerTeam(designer_team_id), of(designer_team_id));
      }),
      map(([rs, designer_team_id]) => {
        if (rs.flag) {
          this.setState((state) => adapter.removeOne(designer_team_id, state));
          this.patchState({loading: false});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
}
