import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { DocumentReference, Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';

export class SquadGroup {
  id!: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  name!: string;
  team_designer_id!: string;
  description?: string;
  squad_leader_id!: string;
  created_at!: Timestamp;
  created_by!: string;
  updated_at!: Timestamp;
  avatar?: DocumentReference;
  log_type = 'squad-group';
}

export interface SquadGroupState extends EntityState<SquadGroup> {
  loading: boolean;
  error: string;
}
