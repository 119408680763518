/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Team, TeamService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';

interface TeamState {
  team_data: Team ,
  loading: boolean,
  error: string
}
const initialState: TeamState = {
  team_data: {} as Team,
  loading: false,
  error: ''
}

@Injectable({ providedIn: 'root' })
export class CustomerTeamStoreService extends ComponentStore<TeamState> {

  private readonly teamSV = inject(TeamService);
  readonly team_data$ = this.select((s) => s.team_data);
  readonly team_id$ = this.select((s) => s.team_data.id);
  readonly loading$ = this.select((s) => s.loading);
  constructor() { super(initialState); }
  getTeamDetail$ = this.effect((client_team_id$: Observable<string>) => {
    return client_team_id$.pipe(
      distinctUntilChanged(),
      tap(() => this.patchState({ team_data: {} as Team, loading: true, error: '' })),
      switchMap((client_team_id: string) => {
        if(client_team_id)
        {
          return of(this.teamSV.getTeamDetail2(client_team_id)).pipe(
            map(rs => {
              rs?.then((team: Team) => {
                if(team)
                  this.setState({ team_data: team, loading: false, error: '' });
                else
                  this.patchState({ loading: true, error: 'Team is unvailable!' });
              })

              }
            ))
        }
        else {
          this.patchState({ team_data: {} as Team, loading: true, error: 'Team is unvailable!' });
          return EMPTY;
        }
      })
    )
  });
  updateTeam$ = this.effect((team$: Observable<Team>) => {
    return team$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((team: Team) => {
        return combineLatest(this.teamSV.updateTeam(team.id!, team.owner_id!, Team.parseObject(team), false), of(team));
      }),
      map(([rs, team]) => {
        if (rs.flag) {
          this.setState(state=>{
            return { ...state, team_data: {...state.team_data,...team} as Team, loading: false}
          })
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
}
