import { Timestamp } from '@firebase/firestore-types';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

export class RequestOff {
    id!: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    created_at!: Timestamp;
    off_at!: Timestamp;
    pto!: number; //0,1,2
    reason?: string;
    approved!: number; //0,1,2
    uid!: string;
    team_designer_id?: string;
    why_unapproved?: string;
    log_type = 'request-off';
}

export class RequestOffOld {
    id!: string;
    uid!: string;
    team_designer_id?: string;
    view!: boolean;
    approved!: boolean;
    created_at!: Timestamp;
    date_off!: string;
    pto!: boolean;
    reason?: string;
    why_unapproved?: string;
    log_type = 'request-off';
}

// convert request_off to request_off_v2 ===================================
// export class RequestOff {
//     id: string; -> (move)
//     uid: string; -> (move)
//     view: boolean; -> (delete)
//     approved: boolean; -> approved (view==false -> default: 0, approved==true: 1, approved==false: 2)
//     created_at: Timestamp; -> (move)
//     date_off: string; -> off_at (Timestamp)
//     pto: boolean; -> pto (true: 1, false: 2, default: 0)
//     reason?: string; -> (move)
//     why_unapproved?: string; -> (move)
//     log_type = 'request-off'; -> (add)
// }
// end convert request_off to request_off_v2 ===================================
