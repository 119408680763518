<div class="payment-wrap space-y-6">
  <div class="text-center mb-4 space-y-2">
    <span class="material-icons-outlined text-[#DA1E28] text-[64px]"> error </span>
    <h4 class="text-[#1B1F24] text-[28px] font-[700]">Payment failed</h4>
    <p class="text-[#334155] text-[16px]">
      We're having trouble processing your payment. <br />
      Please update your payment information to continue your subscription.
    </p>
  </div>
  <button
    (click)="updatePayment()"
    type="button"
    nz-button
    nzType="primary"
    nzBlock
    nzSize="large"
    class="btn-primary-blue"
  >
    Update payment information
  </button>
</div>
