import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MediaCoverComponent } from '@penji/client-v3/media/ui/media-cover';
import { MediaCoverV2Component } from '@penji/client-v3/media/ui/media-cover-v2';
import { Media } from '@penji/shared/data-access';
import * as mediaActions from '@penji/team/media/data-access';
import * as mediaSelectors from '@penji/team/media/data-access';
import { EMPTY, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'penji-media-ref',
  standalone: true,
  imports: [
    CommonModule, 
    MediaCoverComponent,
    MediaCoverV2Component
  ],
  templateUrl: './media-ref.component.html',
  styleUrls: ['./media-ref.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
}) 
export class MediaRefComponent {

  @Input() media_ref: any;
  @Input() media_size: "medium" | "thumb" | "avatar" | "original"= "medium";
  @Input() clickable = false;
  @Input() image_fit = false;
  @Input() image_type: 'image' | 'design' = 'image';

  store = inject(Store);
  media$: Observable<Media> | undefined;
  take$ = new Subject<void>()
  ngOnChanges(): void {
    if(this.media_ref) {
      console.log(this.media_ref);
      if(this.media_ref?.no_ref && this.media_ref?.id && this.media_ref?.parent) {
          this.media$ = this.store.select(mediaSelectors.getMedia(this.media_ref.id)).pipe(
            takeUntil(this.take$),
            map(
              (media)=> {
                // console.log(this.media_ref.id)
                if(!media)
                  this.store.dispatch(mediaActions.loadMediaRefDB({user_id: this.media_ref.parent, media_id: this.media_ref.id}));
                else
                  this.take$.complete();
                return media
              }
            ),
          );
      }
      else {
          if(this.media_ref?.parent && this.media_ref?.parent?.parent){
            this.media$ = this.store.select(mediaSelectors.getMedia(this.media_ref.id)).pipe(
              takeUntil(this.take$),
              map(
                (media)=> {
                  if(!media)
                    this.store.dispatch(mediaActions.loadMediaRefDB({user_id: this.media_ref.parent.parent.id, media_id: this.media_ref.id}));
                  else
                    this.take$.complete();
                  return media
                }
              ),
            );
          }
      }
    }else {
      this.media$ = EMPTY;
    }
  }
  ngOnInit(): void {
    if(this.media_ref) {
      // console.log(this.media_ref)
      if(this.media_ref?.no_ref && this.media_ref?.id && this.media_ref?.parent) {
          this.media$ = this.store.select(mediaSelectors.getMedia(this.media_ref.id)).pipe(
            // takeUntil(this.take$),
            map(
              (media)=> {
                // console.log(this.media_ref.id)
                if(!media)
                  this.store.dispatch(mediaActions.loadMediaRefDB({user_id: this.media_ref.parent, media_id: this.media_ref.id}));
                else
                  this.take$.complete();
                return media
              }
            ),
          );
      }
      else {
          if(this.media_ref?.parent && this.media_ref?.parent?.parent){
            this.media$ = this.store.select(mediaSelectors.getMedia(this.media_ref.id)).pipe(
              // takeUntil(this.take$),
              map(
                (media)=> {
                  if(!media)
                    this.store.dispatch(mediaActions.loadMediaRefDB({user_id: this.media_ref.parent.parent.id, media_id: this.media_ref.id}));
                  else
                    this.take$.complete();
                  return media
                }
              ),
            );
          }
      }
    }
  }
}
