

import { createAction, props } from "@ngrx/store";
import { UserSimple } from "../../models/user.model";
import { Update } from "@ngrx/entity";

//Store
export const createUser = createAction('[User] Create User', props<{user: UserSimple}>());
export const updateUser = createAction('[User] Update User', props<{update: Update<UserSimple>}>())
//Database
export const loadUserDB = createAction('[User] Load User', props<{uid: string}>());
export const noThing = createAction('No thing');

