import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'penji-unlimited-payment-failed',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule
  ],
  templateUrl: './unlimited-payment-failed.component.html',
  styleUrls: ['./unlimited-payment-failed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnlimitedPaymentFailedComponent {

  @Input() client_team_id!: string;
  @Input() closable = true;
  
  private readonly ref = inject(NzModalRef);
  private readonly router = inject(Router);

  updatePayment() {
    this.router.navigate([`/t/${this.client_team_id}/settings/billing`]);
    this.ref.destroy();
  }

  close() {
    this.ref.destroy();
  }
}
