/* eslint-disable */
import { DatePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, Observable, of } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Discussion } from '../models/discussion.model';
import { Project } from '../models/project.model';
import { UserData } from '../models/user.model';
import { LogService } from './log.service';
import { NotitficationService } from './notitfication.service';
import { Team } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class DiscussionService extends LogService<Discussion> {
  private readonly datePipe = inject(DatePipe);
  private readonly nzMessage = inject(NzMessageService);
  private readonly notificationSV = inject(NotitficationService);

  listDiscussion(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    start_at?: QueryDocumentSnapshot<DocumentData>,
    end_at?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let query: Query = this.afs.firestore.collection(`discussion_v2`);

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else if (start_at) {
        query = query.startAt(start_at);
        if (limit != -1) query = query.limit(limit);
      } else if (end_at) {
        query = query.endAt(end_at);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Discussion> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Discussion;
          data.id = doc.id;
          data.doc = doc;
          data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMMM d, y');
          data['realtime'] = false;
          data['payload_type'] = 'added';
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      }

      );
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  listDiscussionRealtime(
    where_query?: Array<WhereQueryInterface>,
    end_before?: QueryDocumentSnapshot<DocumentData> | null,
    order_by: 'asc' | 'desc' | '' = 'desc'
  ) {
    try {
      let query: Query = this.afs.firestore.collection(`discussion_v2`);
      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      if (order_by != '')
        query = query.orderBy('created_at', order_by);

      if (end_before) {
        query = query.endBefore(end_before);
      }
      return new Observable<Discussion[]>(observ => {
        return query.onSnapshot(querySnapshot => {
          const list: Array<Discussion> = [];
          querySnapshot.docChanges().forEach((change) => {
            const data = change.doc.data() as Discussion;
            data.id = change.doc.id;
            data.doc = change.doc;
            data['realtime'] = true;
            data['payload_type'] = change.type;
            data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
            list.push(data);
          });
          return observ.next(list);
        });
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  async addDiscussion(client_team_id: string, project_id: string, discussion: Discussion, is_create_log = true) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    discussion.project_id = project_id;// for activity
    if (project.payment_v2) {
      return this.afs.collection(`discussion_v2`).add({ ...discussion }).then(rs => {
        // console.log(rs);
        if (is_create_log) {
          this.log_model.action = 'create';
          this.log_model.data = { ...new Discussion, ...discussion };
          this.createLog();
        }
        if (discussion.hidden === false) {
          this.notificationSV.addNotificationToClient(discussion);
        }
        return ({ flag: true, message: 'Discussion successfully added!', data: { ...discussion, id: rs.id, doc: rs } });
      }).catch((err: any) => {
        console.log(err);

        return ({ flag: false, message: err.message, data: {} as Discussion });
      });
    } else {
      return this.afs.collection(`team/${client_team_id}/project/${project_id}/discussion`).add({ ...discussion }).then(rs => {
        // console.log(rs);
        if (is_create_log) {
          this.log_model.action = 'create';
          this.log_model.data = { ...new Discussion, ...discussion };
          this.createLog();
        }
        if (discussion.hidden === false) {
          this.notificationSV.addNotificationToClient(discussion);
        }
        return ({ flag: true, message: 'Discussion successfully added!', data: { ...discussion, id: rs.id, doc: rs } });
      }).catch((err: any) => {
        console.log(err);

        return ({ flag: false, message: err.message, data: {} as Discussion });
      });
    }

  }

  addDiscussionV2(discussion: Discussion, is_create_log = true) {
    return this.afs.collection(`discussion_v2`).add({ ...discussion }).then(rs => {
      if (is_create_log) {
        this.log_model.action = 'create';
        this.log_model.data = { ...new Discussion, ...discussion };
        this.createLog();
      }
      if (discussion.hidden === false) {
        this.notificationSV.addNotificationToClient(discussion);
      }
      return ({ flag: true, message: 'Discussion successfully added!', data: { ...discussion, id: rs.id, doc: rs } });
    }).catch((err: any) => {
      console.log(err);
      return ({ flag: false, message: err.message, data: {} as Discussion });
    });
  }

  async updateDiscussion(client_team_id: string, project_id: string, discussion: Discussion) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    discussion.project_id = project_id;// for activity
    if (project.payment_v2) {
      return this.afs.doc(`discussion_v2/${discussion.id}`).update({ ...discussion }).then(() => {
        return ({ flag: true, message: 'Discussion successfully updated!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    } else {
      return this.afs.doc(`team/${client_team_id}/project/${project_id}/discussion/${discussion.id}`).update({ ...discussion }).then(() => {
        return ({ flag: true, message: 'Discussion successfully updated!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    }
  }

  async deleteDiscussion(client_team_id: string, project_id: string, discussion_id: string) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    if (project.payment_v2) {
      return this.afs.doc(`discussion_v2/${discussion_id}`).delete().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new Discussion, discussion_id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Discussion successfully deleted!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    } else {
      return this.afs.doc(`team/${client_team_id}/project/${project_id}/discussion/${discussion_id}`).delete().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ... new Discussion, discussion_id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Discussion successfully deleted!' });
      }).catch((err: any) => {
        return ({ flag: false, message: err.message });
      });
    }

  }
  async uploadDesign(client_team_id: string, project_id: string, discussion: any, user_data: UserData) {
    const team = await this.afs.firestore.doc(`team/${client_team_id}`).get().then(rs => rs.data() as Team); 
    if (team && team.auto_verify) {
      discussion.verify_progress = true;
      discussion.verify_type = 1;
    }

    const batch = this.afs.firestore.batch();
    const result = await this.addDiscussion(client_team_id, project_id, discussion, false);
    discussion.project_id = project_id; // for activity
    discussion.team_id = client_team_id;
    if (result.flag) {
      const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
      if (project.payment_v2) {
        if (user_data.role! <= 2 || user_data.auto_verify == true || (team && team.auto_verify)) {
          const data = {
            'created_at': firebase.Timestamp.now(),
            'project_id': project_id,
            'discussion_id': result.data.id,
            'link': result.data.link,
            'upload_design_by_uid': result.data.user_id,
            'verify_by_uid': user_data.id,
            'verify_now': true,
          };

          //add verify_design_v2 doc
          let sfRef = this.afs.firestore.collection(`verify_design_v2`).doc(data.discussion_id);
          batch.set(sfRef, data, { merge: true });

          //update project_v2 doc
          sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
          batch.set(sfRef, { pending_review: true, verify_progress: true, verify_type: discussion.verify_type, status_addition: 0 }, { merge: true });
        }
        //neu auto verify = false
        else {
          const sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
          batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3) }, { merge: true });
        }
      } else {
        if (user_data.role! <= 2 || user_data.auto_verify == true || (team && team.auto_verify)) {
          const data = {
            'created_at': firebase.Timestamp.now(),
            'teamId': client_team_id,
            'projectId': project_id,
            'discussionId': result.data.id,
            'discussion_ref': result.data.doc,
            'link': result.data.link,
            'upload_design_by_uid': result.data.user_id,
            'verify_by_uid': user_data.id,
            'verify_type': discussion.verify_type,
          };

          let sfRef = this.afs.firestore.collection(`verify_design`).doc(data.discussionId);
          batch.set(sfRef, data, { merge: true });
          sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}`);
          batch.set(sfRef, { pending_review: true, verify_progress: true, verify_type: data.verify_type, status_addition: 0 }, { merge: true });
        } else {
          const sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}`);
          batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3) }, { merge: true });
        }
      }

    }
    return await batch.commit().then(async () => {
      this.log_model.action = 'upload_design';
      this.log_model.data = { ...new Discussion, ...discussion };
      await this.createLog();
      if (discussion.verify_type == 1) {
        this.notificationSV.addNotificationToClient(discussion);
        this.log_model.action = 'verify_design';
        this.log_model.data = { ...new Discussion, ...discussion };
        await this.createLog();
      }
      return ({ flag: true, message: 'upload design successfully!' });
    }).catch(err => {
      console.log(err);
      return ({ flag: false, message: err.message });
    });
  }

  async uploadDesignV2(project_id: string, discussion: any, user_data: UserData) {

    const batch = this.afs.firestore.batch();
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);

    const team = await this.afs.firestore.doc(`team/${project.team_id}`).get().then(rs => rs.data() as Team); 
    if (team && team.auto_verify) {
      discussion.verify_progress = true;
      discussion.verify_type = 1;
    }

    const result = await this.addDiscussionV2({ ...discussion, 'number_index': project.total_design_index ? project.total_design_index + 1 : 1 }, false);
    //add verify_design_v2
    if (result.flag) {
      //neu auto verify = true
      if (user_data.role! <= 2 || user_data.auto_verify == true || (team && team.auto_verify)) {
        const data = {
          'created_at': firebase.Timestamp.now(),
          'project_id': project_id,
          'discussion_id': result.data.id,
          'discussion_ref': result.data.doc,
          'link': result.data.link,
          'upload_design_by_uid': result.data.user_id,
          'verify_by_uid': user_data.id,
          'verify_now': discussion.verify_type == 1 ? true : false,
        };

        //add verify_design_v2 doc
        let sfRef = this.afs.firestore.collection(`verify_design_v2`).doc(data.discussion_id);
        batch.set(sfRef, data, { merge: true });

        //update project_v2 doc
        sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
        batch.set(sfRef, {
          'pending_review': true,
          'verify_progress': true,
          'verify_type': discussion.verify_type,
          'status_addition': 0,
          'total_design_index': project.total_design_index ? project.total_design_index + 1 : 1
        }, { merge: true });
      }
      //neu auto verify = false
      else {
        const sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
        batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3), 'total_design_index': project.total_design_index ? project.total_design_index + 1 : 1 }, { merge: true });
      }
    }
    return await batch.commit().then(async () => {
      this.log_model.action = 'upload_design';
      this.log_model.data = { ...new Discussion, ...discussion, project_id: project_id };
      await this.createLog();
      if (discussion.verify_type == 1) {
        this.notificationSV.addNotificationToClient(discussion);
        this.log_model.action = 'verify_design';
        this.log_model.data = { ...new Discussion, ...discussion, project_id: project_id };
        await this.createLog();
      }
      this.nzMessage.success('upload design successfully!');
      return ({ flag: true, message: 'upload design successfully!' });
    }).catch(err => {
      console.log(err);
      this.nzMessage.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }
  changeStatusWhenUploadDesign(status: number) {
    const data = new Project;
    data.updated_at = firebase.Timestamp.now();
    if (status === 0) { // change to started
      data.pending_review = false;
      data.verify_progress = false;
      data.status_addition = 1;
      data.start_design_at = firebase.Timestamp.now();
    } else if (status === 0.5) { // Undo started design
      data.pending_review = false;
      data.verify_progress = false;
      data.status_addition = 0;
    } else if (status === 1.5) { // change to question
      data.pending_review = false;
      data.verify_progress = false;
      data.status_addition = 3;
      data.circle_type = 1;
    } else if (status === 2) { // change to revision
      data.status = 2;
    } else if (status === 3) { // change to pending review
      data.pending_review = true;
      data.verify_progress = false;
      data.status_addition = 0;
    } else if (status === 3.5) { // change to reject
      data.pending_review = false;
      data.verify_progress = false;
      data.status_addition = 2;
    } else if (status === 4) { // change to delivered
      data.status = 4;
    } else if (status === 5) { // change to on hold
      data.status = 5;
    } else if (status === 6) { // change to completed
      data.type_complete = 2;
      data.status = 6;
      data.complete_at = firebase.Timestamp.now();
    }
    return data;
  }
  async verfyDesign(client_team_id?: string, project_id?: string, data: any = {}) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    if (project.payment_v2) {
      const batch = this.afs.firestore.batch();
      const obj = {
        'created_at': firebase.Timestamp.now(),
        'project_id': project_id,
        'discussion_id': data.discussion_id,
        'link': data.link,
        'upload_design_by_uid': data.upload_design_by_uid,
        'verify_now': true,
      };
      // update verify design V2
      let sfRef = this.afs.firestore.collection(`verify_design_v2`).doc(data.id);
      batch.set(sfRef, data, { merge: true });

      //update discussion V2
      sfRef = this.afs.firestore.doc(`discussion_v2/${data.id}`);
      batch.set(sfRef, { verify_progress: true, verify_type: data.verify_type }, { merge: true });

      //update project V2
      sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
      batch.set(sfRef, { pending_review: true, verify_progress: true, verify_type: data.verify_type }, { merge: true });

      return await batch.commit().then(() => {
        this.log_model.action = 'verify_design';
        this.log_model.data = { ...new Discussion, ...obj, id: data.discussion_id, project_id: project_id, verify_type: data.verify_type } as Discussion;
        this.createLog();
        return { flag: true, message: 'Success!' };
      }
      ).catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
    } else {
      const obj = {
        'id': data.discussion_id,
        'created_at': firebase.Timestamp.now(),
        'teamId': client_team_id,
        'projectId': project_id,
        'discussionId': data.discussion_id,
        'discussion_ref': this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}/discussion/${data.discussion_id}`),
        'link': data.link,
        'upload_design_by_uid': data.upload_design_by_uid,
        'verify_type': data.verify_type
      };

      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`verify_design`).doc(data.discussion_id);
      batch.set(sfRef, obj, { merge: true });
      sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}/discussion/${data.discussion_id}`);
      batch.set(sfRef, { verify_progress: true, verify_type: obj.verify_type }, { merge: true });

      sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}`);
      batch.set(sfRef, { pending_review: true, verify_progress: true, verify_type: obj.verify_type }, { merge: true });

      return await batch.commit().then(() => {
        this.log_model.action = 'verify_design';
        this.log_model.data = { ...new Discussion, ...obj, id: data.discussion_id, project_id: project_id, verify_type: data.verify_type } as Discussion;
        this.createLog();
        return { flag: true, message: 'Success!' };
      }
      ).catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
    }
  }
  async verfyDesignV2(project_id?: string, verify_type = 2, data: any = {}) {
    const batch = this.afs.firestore.batch();
    // update verify design V2
    let sfRef = this.afs.firestore.collection(`verify_design_v2`).doc(data.id);
    batch.set(sfRef, data, { merge: true });

    //update discussion V2
    sfRef = this.afs.firestore.doc(`discussion_v2/${data.id}`);
    batch.set(sfRef, { verify_progress: true, verify_type: verify_type }, { merge: true });

    //update project V2
    sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
    batch.set(sfRef, { pending_review: true, verify_progress: true, verify_type: verify_type }, { merge: true });

    return await batch.commit().then(() => {
      const obj = {
        'created_at': firebase.Timestamp.now(),
        'project_id': project_id,
        'discussion_id': data.discussion_id,
        'link': data.link,
        'upload_design_by_uid': data.upload_design_by_uid,
        'verify_now': true,
      };
      this.log_model.action = 'verify_design';
      this.log_model.data = { ...new Discussion, ...obj, id: data.id, project_id: project_id, verify_type: verify_type } as Discussion;
      this.createLog();
      return { flag: true, message: 'Success!' };
    }
    ).catch(err => {
      console.log(err);
      return ({ flag: false, message: err.message });
    });
  }
  async unverifyDesign(client_team_id?: string, project_id?: string, discussion_id?: string) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    if (project.payment_v2) {
      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`discussion_v2`).doc(discussion_id);
      batch.update(sfRef, { hidden: true });
      sfRef = this.afs.firestore.doc('project_v2/' + project_id);
      batch.update(sfRef, { pending_review: false });
      return await batch.commit().then(() => {
        this.log_model.action = 'un-verify_design';
        this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Success!' });
      })
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    } else {
      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`team/${client_team_id}/project/${project_id}/discussion`).doc(discussion_id);
      batch.update(sfRef, { hidden: true });
      sfRef = this.afs.firestore.doc('team/' + client_team_id + '/project/' + project_id);
      batch.update(sfRef, { pending_review: false });
      return await batch.commit().then(() => {
        this.log_model.action = 'un-verify_design';
        this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Success!' });
      })
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    }

  }
  async rejectDesign(client_team_id?: string, project_id?: string, discussion_id?: string) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    if (project.payment_v2) {
      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`discussion_v2`).doc(discussion_id);
      batch.update(sfRef, { reject: true });
      sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
      batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3.5) }, { merge: true });

      return await batch.commit().then(() => {
        this.log_model.action = 'reject_design';
        this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Success!' });
      })
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    } else {
      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`team/${client_team_id}/project/${project_id}/discussion`).doc(discussion_id);
      batch.update(sfRef, { reject: true });
      sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}`);
      batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3.5) }, { merge: true });

      return await batch.commit().then(() => {
        this.log_model.action = 'reject_design';
        this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Success!' });
      })
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    }

  }
  async rejectDesignV2(project_id?: string, discussion_id?: string) {
    const batch = this.afs.firestore.batch();
    let sfRef = this.afs.firestore.collection(`discussion_v2`).doc(discussion_id);
    batch.update(sfRef, { reject: true });

    sfRef = this.afs.firestore.doc(`project_v2/${project_id}`);
    batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3.5) }, { merge: true });

    return await batch.commit().then(() => {
      this.log_model.action = 'reject_design';
      this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
      this.createLog();
      return ({ flag: true, message: 'Success!' });
    })
      .catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
  }
  async undoVerifyDesign(client_team_id?: string, project_id?: string, discussion_id?: string) {
    const project = await this.afs.firestore.doc(`project_v2/${project_id}`).get().then(rs => rs.data() as Project);
    if (project.payment_v2) {
      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`verify_design_v2`).doc(discussion_id);
      batch.delete(sfRef);
      sfRef = this.afs.firestore.doc(`discussion_v2/${discussion_id}`);
      batch.set(sfRef, { verify_progress: false, }, { merge: true });
      sfRef = this.afs.firestore.doc('project_v2/' + project_id);
      batch.update(sfRef, { pending_review: true });

      return await batch.commit().then(() => {
        this.log_model.action = 'undo_verify_design';
        this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Success!' });
      })
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    } else {
      const batch = this.afs.firestore.batch();
      let sfRef = this.afs.firestore.collection(`verify_design`).doc(discussion_id);
      batch.delete(sfRef);
      sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}/discussion/${discussion_id}`);
      batch.set(sfRef, { verify_progress: false, }, { merge: true });
      sfRef = this.afs.firestore.doc('team/' + client_team_id + '/project/' + project_id);
      batch.update(sfRef, { pending_review: true });

      return await batch.commit().then(() => {
        this.log_model.action = 'undo_verify_design';
        this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
        this.createLog();
        return ({ flag: true, message: 'Success!' });
      })
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    }

  }
  async undoVerifyDesignV2(client_team_id?: string, project_id?: string, discussion_id?: string) {
    var batch = this.afs.firestore.batch();
    var sfRef = this.afs.firestore.collection(`verify_design_v2`).doc(discussion_id);
    batch.delete(sfRef);

    sfRef = this.afs.firestore.collection(`discussion_v2`).doc(discussion_id);
    batch.set(sfRef, { verify_progress: false, }, { merge: true });

    sfRef = this.afs.firestore.collection(`project_v2`).doc(project_id);
    batch.update(sfRef, { pending_review: true });

    return await batch.commit().then(() => {
      this.log_model.action = 'undo_verify_design';
      this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
      this.createLog();
      return ({ flag: true, message: 'Success!' });
    })
      .catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
  }
  async undoRejectDesign(client_team_id?: string, project_id?: string, discussion_id?: string) {
    var batch = this.afs.firestore.batch();

    let sfRef = this.afs.firestore.collection(`team/${client_team_id}/project/${project_id}/discussion`).doc(discussion_id);
    batch.update(sfRef, { reject: false });

    sfRef = this.afs.firestore.doc(`team/${client_team_id}/project/${project_id}`);
    batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3) }, { merge: true });

    return await batch.commit().then(() => {
      this.log_model.action = 'undo_reject_design';
      this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
      this.createLog();
      return ({ flag: true, message: 'Success!' });
    })
      .catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
  }
  async undoRejectDesignV2(project_id?: string, discussion_id?: string) {
    var batch = this.afs.firestore.batch();

    let sfRef = this.afs.firestore.collection(`discussion_v2`).doc(discussion_id);
    batch.update(sfRef, { reject: true });

    sfRef = this.afs.firestore.collection(`project_v2`).doc(project_id);
    batch.set(sfRef, { ... this.changeStatusWhenUploadDesign(3) }, { merge: true });

    return await batch.commit().then(() => {
      this.log_model.action = 'undo_reject_design';
      this.log_model.data = { ...new Discussion, id: discussion_id, project_id: project_id } as Discussion;
      this.createLog();
      return ({ flag: true, message: 'Success!' });
    })
      .catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
  }
  getListDesign(project_id: string) {
    let query: Query = this.afs.firestore.collection(`discussion_v2`);
    query = query.where('type', '==', 'design')
      .where('project_id', '==', project_id)
      .where('remove', '==', false)
      .orderBy('created_at', 'desc');
    return query.get().then(querySnapshot => {
      const list: Array<Discussion> = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as Discussion;
        data.id = doc.id;
        data.doc = doc;
        if (!data.array_link) {
          data.array_link = [{
            'id_link': data.design_id,
            'link': data.link
          }]
        }
        list.push(data);
      });
      return list;
    }).catch(() => of(null))
  }
  listPinnedRealtime(project_id: string){
    return this.afs.collection(`discussion_v2`, ref => {
      return ref
        .where("project_id", "==", project_id)
        .where('pin_by', '!=', '')
        .where('remove', '==', false)
    }).snapshotChanges().pipe(map(action => action.map(res => {
      const data = res.payload.doc.data() as Discussion;
      data.id = res.payload.doc.id;
      return data;
    })));
  }
}
