/* eslint-disable */
import { SquadGroupNameComponent } from '@penji/team/squad-group/squad-group-ui/squad-group-name';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProjectService, UserData, WhereQueryInterface } from '@penji/shared/data-access';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectWTableComponent } from '@penji/team/project/project-ui/project-w-table';
import { NavigationTabComponent } from '@penji/team/shared/shared-ui/navigation-tab';
import * as firebase from 'firebase/firestore';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BehaviorSubject, of, switchMap, take, tap } from 'rxjs';
import { ContentHeightDirective } from '@penji/shared/directives';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { AuthStore } from '@penji/shared/auth/data-access';

@Component({
  selector: 'penji-project-by-squad-widget',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzTabsModule,
    NavigationTabComponent,
    ProjectWTableComponent,
    SquadGroupNameComponent,
    ContentHeightDirective,
    NzSpinModule
  ],
  templateUrl: './w-project-by-squad.component.html',
  styleUrls: ['./w-project-by-squad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectStoreService],
})
export class WProjectBySquadComponent implements OnInit {

  @Input() squad_id!: string;
  projectSV = inject(ProjectService);
  store = inject(ProjectStoreService);
  authStore = inject(AuthStore);
  class_table = 'theme-reviewed';
  index_tabs = 0;
  current_tab$ = new BehaviorSubject<string>('Reviewed');
  container_height = 0;
  list_project$ = this.store.data_widget$;
  loading$ = this.store.loading$;
  user_auth$ = this.authStore.authProfile$.pipe(tap(user=>{
    if(user && user.last_url_routing_tabs && user.last_url_routing_tabs['w-project-by-squad']){
      const tab = user.last_url_routing_tabs['w-project-by-squad'];
      if(this.tabs[tab] && this.tabs[tab].name){
        this.current_tab$.next(this.tabs[tab].name);
        this.index_tabs = tab;
      }
      
    }
  }), take(1));
  
  tabs = [
    // { key: 'overdue', name: 'Overdue' },
    { key: 'reviewed', name: 'Reviewed' },
    { key: 'unreviewed', name: 'Unreviewed' },
    { key: 'verify', name: 'To Verify' },
    { key: 'schedule', name: 'Be Scheduled' }
  ];
  
  
  ngOnInit(): void {
    if (this.squad_id) {
      this.store.loadProjectsForWidget$(this.current_tab$.pipe(
        switchMap(current_tab => {
          let where_query: WhereQueryInterface[] = [];
          let order_by;
          let order_desc;

          if (current_tab == 'To Verify') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2],
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: true
              },
              {
                field_name: 'verify_progress',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false
              }
            ]
            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-verify';
          }

          if (current_tab == 'Be Scheduled') {
            where_query = [
               {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2],
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: true
              },
              {
                field_name: 'verify_progress',
                field_operator: '==',
                field_value: true
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false
              }
            ]
            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-schedule';
          }

          if (current_tab == 'Overdue') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2]
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'time_change',
                field_operator: '<=',
                field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1)))
              },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-overdue';
          }

          if (current_tab == 'Reviewed') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2]
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'circle_type',
                field_operator: '==',
                field_value: 2
              },
              // {
              //   field_name: 'time_change',
              //   field_operator: '>',
              //   field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1)))
              // },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-reviewed';
          }

          if (current_tab == 'Unreviewed') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2]
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false
              },
              {
                field_name: 'circle_type',
                field_operator: '==',
                field_value: 1
              },
              // {
              //   field_name: 'time_change',
              //   field_operator: '>',
              //   field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1)))
              // },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-unreviewed';
          }

          where_query.push({
            field_name: 'squad_id',
            field_operator: 'array-contains',
            field_value: this.squad_id
          })
          return of({ where_query, order_by, order_desc });
        })
      ));
    }
  }
  switchTab(value: string) {
    this.current_tab$.next(value);
  }

  handleTabChange($event: any, user_data: UserData) {
    this.current_tab$.next(this.tabs[$event.index].name);
    this.index_tabs = $event.index;
    this.authStore.updateAuthProfile$(of({ 'id': user_data.id, 'last_url_routing_tabs': { ...user_data.last_url_routing_tabs, 'w-project-by-squad': $event.index } } as UserData));
  }
  getHeight(height: any){
    this.container_height = height;
  }
}
