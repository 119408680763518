<ng-container
  *ngIf="{list_suggest: list_suggest$ | async, clients_like: clients_like$ | async, clients_hate: clients_hate$ | async, uid: uid$| async } as vm">
  <div nz-row class="mb-4">
    <div nz-col [nzSpan]="24">
      <penji-search [user]="true" [role]="role" [select]="true" [auto_focus]="false"
        (uid_selected)="selectUser($event)"></penji-search>
    </div>
  </div>
  <div class="max-h-100 overflow-auto">
    <ng-container *ngIf="multiple_select; else single_select">
      <nz-checkbox-wrapper (nzOnChange)="onClickUser($event)">
        <p class="text-gray-500 mb-1.5 font-semibold dark:text-white" *ngIf="list_user_id.length > 0">Current selected:</p>
        <ng-container *ngFor="let item of list_user_id">
          <label class="flex items-center px-4 py-2 hover:bg-gray-100 w-full !mx-0 [&_.ant-checkbox]:top-0 rounded dark:[&.ant-checkbox-wrapper-checked]:bg-gray-500 dark:hover:!bg-gray-500"
            nz-checkbox [nzValue]="item"
            [ngModel]="(current_selected_user_id && current_selected_user_id.includes(item)) ? true : false">
            <div class="flex items-center">

              <penji-user-item-compress [user_id]="item" [show_user_avatar]="true" [show_user_name]="true"
                [user_image_size]="'sm'" class="flex"></penji-user-item-compress>
              <span *ngIf="client_team_id && vm.clients_like && vm.clients_like.includes(item)"
                class="material-icons text-green-500 text-[20px] ml-3">thumb_up</span>
              <span *ngIf="client_team_id && vm.clients_hate && vm.clients_hate.includes(item)"
                class="material-icons text-red-500 text-[20px] ml-3" style="transform: rotateX(180deg) rotateZ(180deg);">thumb_down</span>
            </div>
          </label>
        </ng-container>
        <ng-container *ngFor="let item of list_search_result">
          <label class="flex items-center px-4 py-2 hover:bg-gray-100 w-full !mx-0 [&_.ant-checkbox]:top-0 rounded dark:[&.ant-checkbox-wrapper-checked]:bg-gray-500 dark:hover:!bg-gray-500"
            nz-checkbox [nzValue]="item"
            [ngModel]="(current_selected_user_id && current_selected_user_id.includes(item)) ? true : false">
            <div class="flex items-center">
              <penji-user-item-compress [user_id]="item" [show_user_avatar]="true" [show_user_name]="true"
                [user_image_size]="'sm'" class="flex"></penji-user-item-compress>
              <span *ngIf="client_team_id && vm.clients_like && vm.clients_like.includes(item)"
                class="material-icons ttext-green-500 text-[20px] ml-3">thumb_up</span>
              <span *ngIf="client_team_id && vm.clients_hate && vm.clients_hate.includes(item)"
                class="material-icons text-red-500 text-[20px] ml-3" style="transform: rotateX(180deg) rotateZ(180deg);">thumb_down</span>
            </div>
          </label>
        </ng-container>
        <ng-container *ngIf="show_list_suggest">
          <p class="text-gray-500 my-1.5 font-semibold dark:text-white">Suggested:</p>
          <div class="max-h-[160px] w-[280px] overflow-auto">
            <ng-container *ngIf="vm.list_suggest && vm.list_suggest.length > 0; else noData">
              <ng-container *ngFor="let user of vm.list_suggest">
                <label *ngIf="!list_user_id.includes(user.id!) && !list_search_result.includes(user.id!)"
                  class="flex items-center px-4 py-2 hover:bg-gray-100 w-full !mx-0 [&_.ant-checkbox]:top-0 rounded dark:[&.ant-checkbox-wrapper-checked]:bg-gray-500 dark:hover:!bg-gray-500"
                  nz-checkbox [nzValue]="user.id"
                  [ngModel]="(current_selected_user_id && current_selected_user_id.includes(user.id!)) ? true : false">
                  <div class="flex items-center">
                    <penji-user-item-compress [user_id]="user.id" [show_user_avatar]="true" [show_user_name]="true"
                    [user_image_size]="'sm'" class="flex"></penji-user-item-compress>
                    <span *ngIf="client_team_id && vm.clients_like && vm.clients_like.includes(user.id!)"
                      class="material-icons text-green-500 text-[20px] ml-3">thumb_up</span>
                    <span *ngIf="client_team_id && vm.clients_hate && vm.clients_hate.includes(user.id!)"
                      class="material-icons text-red-500 text-[20px] ml-3" style="transform: rotateX(180deg) rotateZ(180deg);">thumb_down</span>
                  </div>
                </label>
              </ng-container>
            </ng-container>
            <ng-template #noData>
              <nz-empty nzNotFoundImage="simple" class="[&>.ant-empty-image]:!h-8 text-gray-500"></nz-empty>
            </ng-template>
          </div>
        </ng-container>
      </nz-checkbox-wrapper>
    </ng-container>
    <ng-template #single_select>
      <nz-radio-group (ngModelChange)="radioClick($event)" [ngModel]="current_selected_user_id[0]" class="w-full">
        <p class="text-gray-500 my-1.5 font-semibold dark:text-white">Current selected:</p>
        <ng-container *ngFor="let item of list_user_id">
          <label *ngIf="!list_user_id.includes(vm.uid!)"
            class="flex items-center px-4 py-2 hover:bg-gray-100 w-full !mx-0 [&_.ant-radio]:top-0 rounded dark:[&.ant-radio-wrapper-checked]:bg-gray-500 dark:hover:!bg-gray-500" nz-radio
            [nzValue]="item"
            [ngModel]="(current_selected_user_id && current_selected_user_id.includes(item)) ? true : false">
            <penji-user-item-compress [user_id]="item" [show_user_avatar]="true"
              [show_user_name]="true" class="flex"></penji-user-item-compress>
          </label>
        </ng-container>
        <ng-container *ngIf="show_auth && vm.uid">
          <label class="flex items-center px-4 py-2 hover:bg-gray-100 w-full !mx-0 [&_.ant-radio]:top-0 rounded dark:[&.ant-radio-wrapper-checked]:bg-gray-500 dark:hover:!bg-gray-500"
            nz-radio [nzValue]="vm.uid"
            [ngModel]="(current_selected_user_id && current_selected_user_id.includes(vm.uid)) ? true : false">
            <penji-user-item-compress [user_id]="vm.uid" [show_user_avatar]="true"
              [show_user_name]="true" class="flex"></penji-user-item-compress>
          </label>
        </ng-container>
        <ng-container *ngFor="let item of list_search_result">
          <label class="flex items-center px-4 py-2 hover:bg-gray-100 w-full !mx-0 [&_.ant-radio]:top-0 rounded dark:[&.ant-radio-wrapper-checked]:bg-gray-500 dark:hover:!bg-gray-500"
            nz-radio [nzValue]="item"
            [ngModel]="(current_selected_user_id && current_selected_user_id.includes(item)) ? true : false">
            <penji-user-item-compress [user_id]="item" [show_user_avatar]="true"
              [show_user_name]="true" class="flex"></penji-user-item-compress>
          </label>
        </ng-container>
      </nz-radio-group>
    </ng-template>
  </div>

  <div nz-row class="pt-5" *ngIf="query_params==''">
    <div nz-col [nzSpan]="24">
      <button nz-button type="button" nzType="primary" [nzBlock]="true"
        (click)="onSubmit()">Save Changes</button>
    </div>
  </div>
</ng-container>