import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'penji-quill-view-html',
  standalone: true,
  imports: [CommonModule, QuillModule],
  templateUrl: './quill-view-html.component.html',
  styleUrls: ['./quill-view-html.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuillViewHtmlComponent {

  @Input() description: string | undefined = '';
}
