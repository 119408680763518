import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Observable, combineLatest, map, of, switchMap, take, tap } from 'rxjs';
import { RouterUtils } from '@penji/shared/utils';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { TeamService, ThemeService } from '@penji/shared/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';

@Component({
  selector: 'penji-layout-blank',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './layout-blank.component.html',
  styleUrls: ['./layout-blank.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutBlankComponent {

  active_router = inject(ActivatedRoute);
  customerTeamStore = inject(CustomerTeamStoreService);
  private readonly teamSV = inject(TeamService);
  private readonly authStore = inject(AuthStore);
  themeSV = inject(ThemeService);
  // theme$ = new BehaviorSubject<any>('');
  auth_profile$: Observable<any> | undefined;

  last_login$ = combineLatest(this.customerTeamStore.team_data$, this.authStore.auth_id$).pipe(
    take(1),
    tap(([team_data, auth_id]) => {
      if (team_data.id && auth_id)
        this.teamSV.updateLastLogin(team_data.id, auth_id);
    })
  )

  team_id$ = this.active_router.params.pipe(
    switchMap((params) => of(params)),
    map(rs => rs[RouterUtils.Configuration.client_team_id])
  );
  ngOnInit(): void {
    this.customerTeamStore.getTeamDetail$(this.team_id$);
    this.auth_profile$ =  this.authStore.authProfile$.pipe(
      tap(user => {
        if(user) {
          const temp = this.themeSV.setThemes(user.theme ?? 'light', 'auth');
        }
      })
    )
  }
}
