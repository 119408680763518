import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByDate',
  standalone: true
})
export class GroupByDatePipe implements PipeTransform {

  transform(collection: any[], property: string = 'date') {
    if (!collection) {
      return null;
    }
    const gc = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [];
      }
      previous[current[property]].push(current);
      return previous;
    }, {});
    return Object.keys(gc).map(date => ({ date, data: gc[date] }));
  }

}
