import { ChangeDetectionStrategy, Component, Input, Output, inject, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserData, MemberService, DrawerService, WhereQueryInterface, CheckinService } from '@penji/shared/data-access';
import { BehaviorSubject, EMPTY, Observable, from, map, mergeMap, of, scan, share, shareReplay, switchMap, tap } from 'rxjs';

@Component({
  selector: 'penji-squad-group-attendance',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './squad-group-attendance.component.html',
  styleUrls: ['./squad-group-attendance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquadGroupAttendanceComponent {
  @Input() limit = -1;
  @Input() squad_id: string | undefined;
  @Output() sum_checkin = new EventEmitter<{}>();

  
  private checkinSV = inject(CheckinService);
  private memberSV = inject(MemberService);

  list_attendance:any = {};
  list_member$ = new BehaviorSubject<(UserData[])>([]);

  list_checkin$: Observable<any> = this.list_member$.pipe(switchMap((list_member)=> from(list_member)), mergeMap(
    rs => {
      if(rs && rs.id) {
        return this.checkinSV.getCheckinDetailByUID(rs.id).pipe(
          map(item => {
            var checkin_status = 0;
            if(rs.id) {
              this.list_attendance[rs.id] = checkin_status;
              if (item) {
                if (item.checkout_at) {
                  checkin_status = 5;
                }
                else if (item.checkin_at) {
                  checkin_status = 3;
                }
                
                if (item.pto && item.pto != 0) {
                  checkin_status = item.pto;
                }
                this.list_attendance[rs.id] = checkin_status;
              }
            }
            
            return this.list_attendance;
          }));
      }
      return EMPTY
    }
  ), share());

  sum$: Observable<any> = this.list_checkin$.pipe(
    map((rs)=> {
      var sum_checkout = Object.values(rs).filter((item: any) => item == 5);
      var sum_checkin = Object.values(rs).filter((item: any) => item == 3 || item == 4);
      var sum_off = Object.values(rs).filter((item: any) => item < 3);
      this.sum_checkin.emit(
        {
          sum_checkin: sum_checkin.length,
          sum_checkout: sum_checkout.length,
          sum_off: sum_off.length
        }
      );
      return {sum_checkout: sum_checkout, sum_checkin: sum_checkin, sum_off: sum_off}
    })
  )
  

  async ngOnChanges(): Promise<void> {

    if (this.squad_id) {
      const where_query: WhereQueryInterface[] = [
        {
          field_name: 'squad_id', field_operator: '==', field_value: this.squad_id
        }
      ];
      this.list_member$.next(await this.memberSV.getListMember(this.limit, where_query));
    }
  }
}
