<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">

  <div class="flex justify-between items-center pb-2 pr-20">
    <h6 class="text-gray-600 capitalize dark:text-white [&_*]:!text-blue-500">List Member in <penji-squad-group-name
        [squad_group_id]="squad_id"></penji-squad-group-name></h6>
    <a [routerLink]="['/member']" [queryParams]="{ squad_id: squad_id }" queryParamsHandling="merge"
      class="view-all !text-blue-500 hover:!text-blue-600 hidden">View All</a>
  </div>
  <ng-container *ngIf="{list_member:list_member$ | async} as vm">
    <ng-container *ngIf="vm.list_member; else loading">
      <nz-table #table_list [nzFrontPagination]="false" [nzShowPagination]="false" [nzData]="vm.list_member"
        [nzBordered]="false" [nzSize]="'middle'" class="theme theme-default"
        [nzScroll]="{ x: '36.146vw', y: container_height - 120 + 'px' }">
        <thead>
          <tr>
            <th nzLeft nzWidth="220px">Member name</th>
            <th nzWidth="80px" [nzSortFn]="sortActiveProject">Project</th>
            <th nzWidth="110px">Status</th>
            <th nzWidth="140px" nz-tooltip nzTooltipTitle="Performance 7 days" nzEllipsis>Performance 7 days</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
            <tr>
              <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
                <td nzLeft>
                  <penji-user-item-compress [user_id]="item.id" [show_user_avatar]="true" [show_user_name]="true"
                    [clickable]="true"></penji-user-item-compress>
                </td>
                <td class="dark:text-gray-200">{{item?.total_active_project ?? 0}} / {{item?.out_put_design ?? 0}}
                </td>
                <td><penji-member-checkin-status-by-uid [uid]="item.id!"></penji-member-checkin-status-by-uid></td>
                <td><penji-member-last-seven-days-feedback [uid]="item.id!"></penji-member-last-seven-days-feedback>
                </td>
              </ng-container>
            </tr>
            <ng-template #loading_tr>
              <td [colSpan]="4" inViewport (inViewportAction)="viewPort($event, item.id!)">
                <nz-skeleton [nzActive]="true" [nzTitle]="false"
                  [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
              </td>
            </ng-template>
          </ng-container>
        </tbody>
      </nz-table>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>
</div>
