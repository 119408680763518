/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DrawerService {
  data$ = new BehaviorSubject<{ visible: boolean, type?: number, value: { type: string, id: string } }>({ visible: false, type: 1 ,value: { type: '', id: '' } });
  recentOpenedDrawer: [{ visible: boolean, type?: number, value: { type: string, id: string } }] = [] as any;
  openDrawer(input_data: any) {
    const temp = {
      visible: true,
      type: this.data$.value.type,
      value: {
        type: input_data.type,
        id: input_data.id
      }
    }
    // console.log(temp);
    
    this.data$.next(temp);
    this.recentOpenedDrawer.push(temp);
  }
  constructor() {
    const item = localStorage.getItem('drawer_mode');
    if (item) {
      // console.log(item);
      const { visible, value, type } = this.data$.value;
      this.data$.next({ visible, value, type: Number(item) || 1 });
    }
  }
  close() {
    const { type } = this.data$.value;
    this.data$.next({ visible: false, type, value: { type: '', id: '' } });
    this.recentOpenedDrawer = [] as any;
  }
  backDrawer() {
    // console.log(this.recentOpenedDrawer);
    if (this.recentOpenedDrawer.length > 1) {
      const back_item = this.recentOpenedDrawer[this.recentOpenedDrawer.length - 2];
      this.data$.next(back_item);
      this.recentOpenedDrawer.splice(this.recentOpenedDrawer.length - 1, 1);
    } else {
      this.close();
    }
  }
}
