import { DocumentData, DocumentReference, QueryDocumentSnapshot } from "@angular/fire/compat/firestore";
import { Timestamp } from "firebase/firestore";
import { Membership } from "./membership.model";

export class Team {
  id?: string;
  objectID?: string; // algolia
  team_id?:string; // algolia
  doc?: QueryDocumentSnapshot<DocumentData> | string;
  created_at?: Timestamp;
  title?: string;
  logo?:string;
  mail_first_project?: boolean;
  number_of_active_project?: number;
  number_of_member?: number;
  number_of_new_project?: number | undefined;
  number_of_queue?: number;
  owner_id?: string;
  owner_ref?: DocumentReference | string;
  pause?: boolean;
  payment?: boolean;
  pm_uid?: string | null;
  total_dislike?: number;
  total_like?: number;
  total_project_complete?: number;
  team_designer_id?: string;
  clients_like?: string[];
  clients_hate?: string[];
  log_type = 'team';
  moved_to_v2?: boolean;
  lastLogin?: Timestamp;
  onboarding?: boolean;
  onboarding_data: {
    your_goals?: string[],
    company_name?: string,
    address?: string,
    city?: string,
    state?: string,
    country?: string,
    phone?: string,
    industries_sector?: string,
    website?: string,
    number_of_employees?: string,
    position_company?: string,
    find_us?: string,
    find_us_more?: string,
  } = {};
  label_info: {
    account_level?: number, //3-S level ,2-A level ,1-D level
    experience_level?: number,
    project_volume?: number,
    project_difficulties?: number,
    personality?: number,
  } = {};
  membership?: Membership[];
  teammates?: string[];
  continue_payment?: number;
  auto_verify?: boolean;
  static parseObject(data: any) {
    const transform: any = {};
    for (const key in data) {
      const value = data[key];
      if (Array.isArray(data[key])) {
        transform[key] = value;
      } else if (typeof data[key] === 'object') {
        for (const keyDeep in data[key]) {
          if (key === 'onboarding_data') {
            transform[`onboarding_data.${keyDeep}`] = data[key][keyDeep];
          } else if  (key === 'label_info'){
            transform[`label_info.${keyDeep}`] = data[key][keyDeep];
          } else{
            transform[key] = value;
          }
        }
      } else {
        transform[key] = value;
      }
    }
    return transform;
  }
}
export class TeamMember {
  userId?: string;
  role?: string;
  log_type?:'team_member';
}
