<div class="flex space-x-1">
    <ng-container *ngIf="assign_by && assign_by.length > 0">
        <penji-user-array-item [uid_array]="assign_by ? assign_by:[]"></penji-user-array-item>
    </ng-container>
    <ng-container *ngIf="reassign_on">
        <button nz-button nzType="dashed" nzShape="circle" nzSize="small" nz-popover nzPopoverTitle="Assign To"
            [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="reassign_content"
            nzPopoverOverlayClassName="popover-filter">
            <span class="material-icons-outlined text-[18px]">add</span>
        </button>
        <ng-template #reassign_content>
            <span (click)="visible = false" class="material-icons-outlined text-gray-800 hover:text-gray-500 dark:text-white dark:hover:text-gray-200 cursor-pointer -top-8 dark:-top-[38px] right-4 absolute z-10 text-[25px]">close</span>
            <penji-user-select [list_user_id]="assign_by ? assign_by:[]" [role]="[2,4,7]" [multiple_select]="false"
                (list_user_id_output)="getListUserId($event)"></penji-user-select>
        </ng-template>
    </ng-container>
</div>
