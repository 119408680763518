<form nzLayout="vertical" nz-form [formGroup]="dataForm">
    <nz-form-item nz-col>
        <nz-form-label nzRequired>Workspace name</nz-form-label>
        <nz-form-control nzErrorTip="Please input name!">
            <input type="text" nzSize="large" class="rounded-sm" nz-input
                formControlName="title" />
        </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-col>
        <nz-form-label>Workspace logo</nz-form-label>
        <penji-input-file-form formControlName="logo"
            [file_ext]="['png', 'jpg', 'jpeg', 'gif']"
            [max_file_upload]="1"
            [type_upload]="'image'"
            [size_upload]="'small'"
            [size_limit]="500000"></penji-input-file-form>
    </nz-form-item>
    <nz-form-item *ngIf="workspace">
        <div class="flex items-center">
            <div class="w-16 h-16">
                <penji-media-ref [media_ref]="workspace.logo" [image_fit]="true"></penji-media-ref>
            </div>
        </div>
    </nz-form-item>
    <button [disabled]="(isLoading$ | async) || dataForm.invalid" nz-button nzBlock="" nzSize="large" class="btn-primary-blue"
        (click)="onSubmit()" nzType="primary">
        {{this.workspace ? 'Save changes' : 'Add Workspace'}}
    </button>
</form>
