/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryStoreService } from '@penji/client-v3/categories/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { EMPTY, distinctUntilChanged, switchMap, tap } from "rxjs";
import { AppInit } from './app-init.action';

@Injectable()
export class ApplicationEffects {

  private readonly actions$ = inject(Actions);
  private readonly authStore = inject(AuthStore);
  private readonly cateStore = inject(CategoryStoreService);
  private readonly afAuth = inject(AngularFireAuth);


  initAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppInit),

        switchMap(()=> {
        return this.afAuth.authState.pipe(
          distinctUntilChanged(),
          switchMap((auth) => {
            // console.log(auth)
            if(auth){
              this.authStore.init2(auth);
              this.cateStore.loadCategories$();
            }
            return EMPTY;
          })
        )
      })

      ),
    {
      dispatch: false
    }
  );
}
