<ng-container *ngIf="{membership:membership$ | async} as vm">
  <ng-container *ngIf="vm.membership && vm.membership[0] && vm.membership[0].product_name">
    <!-- Starter -->
    <div class="bg-blue-100 border border-blue-300 p-4 pt-2 rounded-lg dark:bg-blue-400 dark:border-blue-600">
      <penji-membership-name></penji-membership-name>
      <p class="mb-1 mt-2"><b>{{vm.membership[0].metadata.total_active_project}}</b> active project slots</p>
      <p class="mb-3"><b>80+</b> project categories</p>
      <button (click)="openChangePlan()" nz-button nzType="default" nzBlock class="">Upgrade plan</button>
      
    </div>
  </ng-container>
</ng-container>