<ng-container *ngIf="user_auth$ | async as user_auth">
  <div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
    <div class="flex justify-between items-center pb-2 pr-20">
      <h6 class="text-gray-600 capitalize dark:text-white">My Project</h6>
      <ng-container *ngIf="auth_id$ | async as auth_id">
        <a [routerLink]="['/project']" [queryParams]="{ assign_by: auth_id }" queryParamsHandling="merge"
          class="view-all !text-blue-500 hover:!text-blue-600 hidden">View All</a>
      </ng-container>
    </div>

    <nz-tabset class="ant-tabs-custom-3 [&>.ant-tabs-nav]:!m-0 [&>.ant-tabs-nav:before]:!content-none !overflow-auto px-3.5 pb-3.5 pt-2 bg-white rounded-lg border border-gray-300 dark:bg-gray-600 dark:border-gray-500" (nzSelectChange)="handleTabChange($event, user_auth)" [nzSelectedIndex]="index_tabs">
      <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name">
      </nz-tab>
    </nz-tabset>

    <ng-container *ngIf="{list_project:list_project$ | async, loading:loading$ | async} as vm">
      <ng-container *ngIf="vm.list_project && !vm.loading; else loading">
        <ng-container *ngIf="vm.list_project && vm.loading!==null">
          <penji-project-w-table [container_height]="container_height-180" [list_project]="vm.list_project"
            [loading]="vm.loading" [class_table]="class_table"></penji-project-w-table>
        </ng-container>
      </ng-container>
      <ng-template #loading>
        <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
