import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CategoryTitleComponent } from '@penji/team/categories/category-ui/category-title';
import { NzTagModule } from 'ng-zorro-antd/tag';

@Component({
  selector: 'penji-member-top-expertise',
  standalone: true,
  imports: [
    CommonModule, 
    CategoryTitleComponent, 
    NzTagModule
  ],
  templateUrl: './member-top-expertise.component.html',
  styleUrls: ['./member-top-expertise.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberTopExpertiseComponent {
  @Input() the_best?: string [];
  view_more = false;
}
