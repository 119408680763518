import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'penji-project-design-status-item',
  standalone: true,
  imports: [
    CommonModule,

  ],
  templateUrl: './project-design-status-item.component.html',
  styleUrls: ['./project-design-status-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDesignStatusItemComponent {
  @Input() design_status = "to-verify";
}
