<!-- <ng-container *ngIf="{ user:user$ | async, auth_profile:auth_profile$ | async } as vm">
    <ng-container *ngIf="vm.user && vm.auth_profile">
        <div class="w-full flex {{drawer?'flex-row space-x-6':'flex-col justify-center space-y-2'}} items-center">
            <div class="w-fit relative" *ngIf="show_user_avatar">
                <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
            </div>
            <div class="w-full flex flex-col {{drawer?'justify-start':'justify-center items-center'}} space-y-1">
                <div class="w-full flex items-center {{drawer?'justify-start':'justify-center'}} space-x-1"
                    *ngIf="show_user_name">
                    <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name"
                        [classes]="'text-purple-500'" [max_width]="'250px'" [font_size]="'xl'"
                        [font_weight]="'bold'"></penji-user-name>
                </div>
                <penji-user-role [user_role]="vm.user.role" [is_pm]="vm.user.is_pm"
                    *ngIf="show_user_role"></penji-user-role>
                <penji-user-email [user_email]="vm.user.email" [max_width]="'250px'"
                    *ngIf="show_user_email"></penji-user-email>
                <penji-user-country [user_country]="vm.user.country" *ngIf="show_user_country"></penji-user-country>
            </div>
        </div>
    </ng-container>
</ng-container> -->



<ng-container *ngIf="{ user:user$ | async, auth_profile:auth_profile$ | async } as vm">
  <ng-container *ngIf="vm.user && vm.auth_profile">
    <div class="w-full flex items-center space-x-2.5 relative">
      <div class="w-fit relative" *ngIf="show_user_avatar">
        <ng-container *ngIf="clickable; else clickdisable">
          <ng-container *ngIf="vm.user.role === 3; else is_member_avatar">
            <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
          </ng-container>
          <ng-template #is_member_avatar>
            <a (click)="openDrawer($event, vm.user.id, 'member')">
              <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
            </a>
          </ng-template>
        </ng-container>
        <ng-template #clickdisable>
          <span nz-tooltip [nzTooltipTitle]="vm.user.first_name + ' ' + vm.user.last_name">
            <penji-user-avatar [avatar_ref]="vm.user.avatar" [image_size]="user_image_size"></penji-user-avatar>
          </span>
        </ng-template>
        <ng-container *ngIf="show_user_available_status && vm.user.role !== 3">
          <penji-member-checkin-status-by-uid [uid]="vm.user.id" [type]="'dot'" [disabled]="vm.user.disabled?vm.user.disabled:false"></penji-member-checkin-status-by-uid>
        </ng-container>
      </div>
      <div class="w-full flex flex-col space-y-0 justify-start">
        <div class="w-full flex items-center space-x-0.5" *ngIf="show_user_name">
          <ng-container *ngIf="clickable; else clickdisable">
            <ng-container *ngIf="vm.user.role === 3; else is_member_name">
              <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name" [classes]="user_classes" [disabled]="vm.user.disabled!"></penji-user-name>
            </ng-container>
            <ng-template #is_member_name>
              <a (click)="openDrawer($event, vm.user.id, 'member')">
                <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name" [classes]="user_classes" [disabled]="vm.user.disabled!"></penji-user-name>
              </a>
            </ng-template>
          </ng-container>
          <ng-template #clickdisable>
            <penji-user-name [user_name]="vm.user.first_name + ' ' + vm.user.last_name" [classes]="user_classes" [disabled]="vm.user.disabled!"></penji-user-name>
          </ng-template>
        </div>
        <ng-container *ngIf="show_user_email">
          <penji-user-email [user_email]="vm.user.email" [classes]="user_classes"></penji-user-email>
        </ng-container>
      </div>
      <ng-container *ngIf="show_user_copy">
        <div class="absolute right-0 top-1 dark:[&_.ant-typography-copy]:text-white dark:hover:[&_.ant-typography-copy]:text-purple-600">
          <penji-copy-item [id]="vm.user.id" copy_type="customer" [link_title]="vm.user.first_name + ' ' + vm.user.last_name"></penji-copy-item>
      </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
