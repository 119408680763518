/* eslint-disable */

import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, Observable, catchError, map, of, retry, switchMap, throwError } from 'rxjs';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Team } from '../models/team.model';
import { LogService } from './log.service';
import { environment } from '@penji/shared/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class TeamService extends LogService<Team> {
  nzMessageService = inject(NzMessageService);
  private readonly api_url = environment.api_algolia;
  private readonly http = inject(HttpClient);
  private readonly afAuth = inject(AngularFireAuth);
  searchTeamAlgolia(keyword: string) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          return this.http.post(`${this.api_url}/search-workspace`, { keyword }, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      }),
      map((result: any) => {
        // console.log(result);
        if (result)
          return (result.data.hits as Team[]).map(team => ({ ...team, id: team.objectID } as Team));
        else
          return [];
      }))
  }
  getListTeamAlgolia(filter_obj: any) {
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          const params = { ...filter_obj };
          params['membership.type'] = 'sub_design';
          if (params['membership']) {
            if (params['membership'] === 'starter') {
              params['membership.product_name'] = 'Starter Plan 2023';
            } else if (params['membership'] === 'marketer') {
              params['membership.product_name'] = 'Marketer Plan 2023';
            } else if (params['membership'] === 'agency') {
              params['membership.product_name'] = 'Agency Plan 2023';
            }
            delete params['membership'];
          }
          if(params['workspace_class']){
            params['label_info.account_level'] = parseInt(params['workspace_class']);
            delete params['workspace_class'];  
          }
          if(params['coupon']){
            params['membership.coupon'] = params['coupon'];
            delete params['coupon'];  
          }
          if(params['industry']){
            params['onboarding_data.industries_sector'] = params['industry'];
            delete params['industry'];  
          }
          if(params['active']){
            params['membership.active'] = parseInt(params['active']);
            delete params['active'];
          }
          if(params['hibernating']){
            params['hibernating'] = parseInt(params['hibernating']);
            params['membership.active'] = 1;
          }
          if(params['workspace_phase']){
            params['continue_payment'] = parseInt(params['workspace_phase']);
            delete params['workspace_phase'];  
          }
          params['limit'] = 30;
          // console.log(params);
          return this.http.post(`${this.api_url}/filter-workspace`, params, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }
  exportDataWorkspace(filter_obj: any){
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          const params = { ...filter_obj };
          params['membership.type'] = 'sub_design';
          if (params['membership']) {
            if (params['membership'] === 'starter') {
              params['membership.product_name'] = 'Starter Plan 2023';
            } else if (params['membership'] === 'marketer') {
              params['membership.product_name'] = 'Marketer Plan 2023';
            } else if (params['membership'] === 'agency') {
              params['membership.product_name'] = 'Agency Plan 2023';
            }
            delete params['membership'];
          }
          if(params['workspace_class']){
            params['label_info.account_level'] = parseInt(params['workspace_class']);
            delete params['workspace_class'];  
          }
          if(params['coupon']){
            params['membership.coupon'] = params['coupon'];
            delete params['coupon'];  
          }
          if(params['industry']){
            params['onboarding_data.industries_sector'] = params['industry'];
            delete params['industry'];  
          }
          if(params['active']){
            params['membership.active'] = parseInt(params['active']);
            delete params['active'];
          }
          if(params['hibernating']){
            params['hibernating'] = parseInt(params['hibernating']);
            params['membership.active'] = 1;
          }
          // console.log(params);
          return this.http.post(`${this.api_url}/export-data-workspace`, params, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }
  getTeamDetail(team_id: string) {
    if (team_id)
      return this.afs.collection('team').doc<Team>(team_id).valueChanges().pipe(
        map(rs => { return { ...rs, id: team_id } as Team }),
        catchError(() => EMPTY)
      );
    return of({ ...new Team })
  }
  getTeamDetail2(team_id: string) {
    if (team_id)
      return this.afs.firestore.doc(`team/${team_id}`).get().then((rs: any) => {
        if (rs.exists) {
          const data = rs.data() as Team;
          data.id = rs.id;
          return data as Team;
        }
        return {} as Team;
      });
    return Promise.resolve({} as Team);
  }

  getTeamMember(team_id: string) {
    return this.afs.collection('team').doc(team_id).collection('team_member').valueChanges({ idField: 'userId' });
  }

  getTeamInfo(team_id: string) {
    return this.afs.firestore.doc(`team/${team_id}/team_info/info`).get().then((rs: any) => {
      if (rs.exists)
        return rs.data();
      return null;
    });
  }

  async getTotalActiveTeam(designer_team_id: string) {
    try {
      const x = new Date();
      x.setMonth(x.getMonth() - 1);
      // console.log(x)
      const coll = firebase.collection(firebase.getFirestore(), 'team');
      const q = firebase.query(coll,
        firebase.where("team_designer_id", "==", designer_team_id),
        firebase.where("pause", "==", false),
        firebase.where("lastLogin", ">=", x)
      );
      const snapshot = await firebase.getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
  async getTotalHibernateTeam(designer_team_id: string) {
    try {
      const x = new Date();
      x.setMonth(x.getMonth() - 1);
      console.log(x)
      const coll = firebase.collection(firebase.getFirestore(), 'team');
      const q = firebase.query(coll,
        firebase.where("team_designer_id", "==", designer_team_id),
        firebase.where("pause", "==", false),
        firebase.where("lastLogin", "<", x)
      );
      const snapshot = await firebase.getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  updateTeam(team_id: string, customer_id: string, data: any, is_update: boolean) {
    try {
      delete data.doc;

      const batch = this.afs.firestore.batch();
      batch.update(this.afs.firestore.collection('team').doc(team_id), { ...data }, { merge: true });
      // batch.update(this.afs.firestore.collection('payment').doc(customer_id), { 'not_update_plan': is_update });
      return batch.commit().then(() => {
        this.log_model.action = 'update';
        const obj = { ...data };
        obj['not_update_plan'] = is_update;
        obj['team_id'] = team_id;
        this.log_model.data = { ...new Team, ...obj };
        this.createLog();
        this.nzMessageService.success('update succesfully!');
        return ({ flag: true, message: 'Success' })
      }).catch(error => {
        console.log(error);
        return ({ flag: false, message: error.message as string })
      })
    } catch (err) {
      console.log(err);
      return Promise.resolve({ flag: false, message: err as string })
    }
  }

  removePM(team_id: string) {
    return this.afs.firestore.doc(`team/${team_id}`).update({pm_uid: null}).then(() => {
      return ({ flag: true, message: 'PM successfully removed!' });
    }).catch(err => {
      return ({ flag: false, message: err.message as string });
    })
  } 

  changeDesignerTeamForCustomer(client_team_id: string, team_designer_id_new: string) {
    return this.afs.firestore.doc(`team/${client_team_id}`).update({
      team_designer_id: team_designer_id_new,
      clients_like: [],
      clients_hate: []
    }).then(() => {
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err => {
      return {
        flag: false,
        message: err
      }
    })
  }
  updateDesignersLikeForCustomer(client_team_id: string, list: string[]) {
    return this.afs.firestore.doc(`team/${client_team_id}`).update({
      clients_like: list
    }).then(() => {
      this.nzMessageService.success('update succesfully!');
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err => {
      console.log(err.message);
      if (err.message == 'Missing or insufficient permissions.')
        this.nzMessageService.error('You do not have permission to update!');
      return {
        flag: false,
        message: err
      }
    })
  }
  updateDesignersHateForCustomer(client_team_id: string, list: string[]) {
    return this.afs.firestore.doc(`team/${client_team_id}`).update({
      clients_hate: list
    }).then(() => {
      this.nzMessageService.success('update succesfully!');
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err => {
      console.log(err.message);
      if (err.message == 'Missing or insufficient permissions.')
        this.nzMessageService.error('You do not have permission to update!');
      return {
        flag: false,
        message: err
      }
    })
  }
  updateCustomerAccountLabel(client_team_id: string, label_info: object) {
    return this.afs.firestore.doc(`team/${client_team_id}`).update({label_info: label_info}).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ...new Team, ...label_info, team_id: client_team_id };
      this.createLog();
      this.nzMessageService.success('update succesfully!');
      return {
        flag: true,
        message: 'update succesfully!'
      }
    }).catch(err => {
      console.log(err.message);
      if (err.message == 'Missing or insufficient permissions.')
        this.nzMessageService.error('You do not have permission to update!');
      return {
        flag: false,
        message: err
      }
    })
  }

  async listTeam(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let query: Query = this.afs.firestore.collection('team');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Team> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Team;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listTeamRealTime(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let query: Query = this.afs.firestore.collection('team');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return new Observable<Team[]>(observ => {
        return query.onSnapshot(querySnapshot => {
          const list: Array<Team> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as Team;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  deleteMember(client_team_id: string, user_id: string) {
    return this.afs.doc(`team/${client_team_id}/team_member/${user_id}`).delete().then(() => {
      this.nzMessageService.success('The member successfully deleted!');
      return { flag: true, message: 'The member successfully deleted!' };
    }).catch(error => {
      console.log(error);
      this.nzMessageService.error(error.message);
      return { flag: false, message: error };
    });
  }
  async updateCustomerLikeDesigner(client_team_id: string, designer_id: string, type: 'like' | 'unlike' = 'like') {
    try {
      if (type === 'like') {
        await this.afs.firestore.doc(`team/${client_team_id}`).update({
          "clients_like": firebase.arrayUnion(designer_id)
        });
      } else if (type === 'unlike') {
        await this.afs.firestore.doc(`team/${client_team_id}`).update({
          "clients_like": firebase.arrayRemove(designer_id)
        });
      }

      return Promise.resolve({
        flag: true,
        message: 'update succesfully!'
      })
    } catch (err: any) {
      return Promise.reject({ flag: false, message: err.message })
    }
  }

  async reassignPmToClient(client_team_id: string, designer_team_id: string, in_pm_uid: string, out_pm_uid?: string) {
    try {
      if (out_pm_uid) {
        const kqUpdate = await this.afs.doc(`team/${client_team_id}/project_manager/${out_pm_uid}`).update({ type: 'change_team' }).then(() => { return true }).catch(() => { return false });
        if (kqUpdate) {
          const batch = this.afs.firestore.batch();
          const sRef = this.afs.firestore.doc(`team/${client_team_id}/project_manager/${out_pm_uid}`);
          batch.delete(sRef);

          const sRef2 = this.afs.firestore.doc(`team/${client_team_id}/project_manager/${in_pm_uid}`);
          batch.set(sRef2, {
            pm_ref: this.afs.firestore.doc(`user/${in_pm_uid}`),
            pm_uid: in_pm_uid,
            team_designer_id: designer_team_id,
            team_designer_ref: this.afs.firestore.doc(`team_designer/${designer_team_id}`)
          });

          return await batch.commit().then(() => {
            return ({ flag: true, message: 'PM successfully added to client!' });
          }).catch(error => {
            this.nzMessageService.error(error.message);
            return ({ flag: false, message: error.message });
          });
        } else {
          this.nzMessageService.error('Could NOT update "change_team"');
          return ({ flag: false, message: 'Could NOT update "change_team"' });
        }
      } else {
        return this.afs.doc(`team/${client_team_id}/project_manager/${in_pm_uid}`).set({
          pm_ref: this.afs.firestore.doc(`user/${in_pm_uid}`),
          pm_uid: in_pm_uid,
          team_designer_id: designer_team_id,
          team_designer_ref: this.afs.firestore.doc(`team_designer/${designer_team_id}`),
        }).then(() => ({ flag: true, message: 'PM successfully added to client!' }))
          .catch(err => {
            this.nzMessageService.error(err.message);
            return ({ flag: false, message: err.message });
          });
      }
    } catch (err) {
      this.nzMessageService.error(err as string);
      return ({ flag: false, message: err as string });
    }
  }

  async updateLastLogin(client_team_id: string, uid: string) {
    try {
      const batch = this.afs.firestore.batch();
      const tRef = this.afs.firestore.doc(`team/${client_team_id}`);
      batch.update(tRef, {
        lastLogin: firebase.Timestamp.now()
      });
      const uRef = this.afs.firestore.doc(`user/${uid}`);
      batch.update(uRef, {
        lastLogin: firebase.Timestamp.now()
      });
      return await batch.commit().then(() => {
        return ({ flag: true, message: 'Last login updated!' });
      }).catch(error => {
        console.log(error.message);
        return ({ flag: false, message: error.message });
      });
    } catch (err) {
      console.log(err as string);
      return ({ flag: false, message: err as string });
    }
  }
}
