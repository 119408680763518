<ng-container *ngIf="{ user:user$ | async, auth_profile:auth_profile$ | async } as vm">
  <div *ngIf="vm.user && vm.auth_profile">
    <ng-container *ngIf="vm.user.role === 3; else is_member_avatar">
      <a nz-popover nzPopoverPlacement="right" [nzPopoverContent]="popoverInfo" nzPopoverTrigger="hover"
        [nzPopoverMouseEnterDelay]="0.25"
        [nzPopoverMouseLeaveDelay]="0.15"
        [nzPopoverOverlayClassName]="'[&_.ant-popover-inner]:min-w-[350px] [&_.ant-popover-inner]:w-fit '"
        >
        <penji-user-item-compress [user_id]="vm.user.id" [show_user_avatar]="show_user_avatar" [show_user_name]="show_user_name" [clickable]="clickable" [user_image_size]="user_image_size" [user_classes]="user_classes"></penji-user-item-compress>
      </a>
    </ng-container>
    <ng-template #is_member_avatar>
      <a nz-popover nzPopoverPlacement="right" [nzPopoverContent]="popoverInfo" nzPopoverTrigger="hover"
        [nzPopoverMouseEnterDelay]="0.25"
        [nzPopoverMouseLeaveDelay]="0.15"
        [nzPopoverOverlayClassName]="'[&_.ant-popover-inner]:min-w-[350px] [&_.ant-popover-inner]:w-fit'"
        >
        <penji-user-item-compress [user_id]="vm.user.id" [show_user_avatar]="show_user_avatar" [show_user_name]="show_user_name" [clickable]="clickable" [show_user_available_status]="show_user_available_status" [user_image_size]="user_image_size" [user_classes]="user_classes"></penji-user-item-compress>
      </a>
    </ng-template>
    <ng-template #popoverInfo>
      <penji-user-popover-info [user]="vm.user" [auth]="vm.auth_profile"></penji-user-popover-info>
   </ng-template>
  </div>
</ng-container>

