export class RouterUtils {
  static Configuration = {
    user_id: 'user_id',
    project_id: 'project_id',
    client_team_id: 'client_team_id',
    designer_team_id: 'designer_team_id',
    squad_group_id: 'squad_group_id',
    ticket_id: 'ticket_id',
    checkin_id: 'checkin_id',
    requestoff_id: 'requestoff_id',
    style_preference_id: 'style_preference_id',
    template_answer_id: 'template_answer_id',
    affiliate_id: 'affiliate_id',
    category_id: 'category_id',
    brand_id: 'brand_id',
    course_id: 'course_id',
    course_cate_id: 'course_cate_id'
  };
}
