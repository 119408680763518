import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotitficationStoreService } from '@penji/client-v3/notifications/data-access';
import { BehaviorSubject, map, tap } from 'rxjs';
import { RouterModule } from '@angular/router';
import { NotificationItemComponent } from '@penji/client-v3/notifications/ui/notification-item';
import { GroupByDatePipe } from '@penji/shared/pipes';
import { NzListModule } from 'ng-zorro-antd/list';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { SpinnerComponent } from '@penji/shared/ui/element/spinner';

@Component({
  selector: 'penji-notification-uploads',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzListModule,
    GroupByDatePipe,
    MomentModule,
    NotificationItemComponent,
    InfiniteScrollModule,
    SpinnerComponent
  ],
  templateUrl: './notification-uploads.component.html',
  styleUrls: ['./notification-uploads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotitficationStoreService]
})
export class NotificationUploadsComponent implements OnInit{

  @Output() listUnread = new EventEmitter();
  @Input() onpage = false;

  private readonly notificationStoreSV = inject(NotitficationStoreService);
  today = new Date();
  data$ = this.notificationStoreSV.data$.pipe(
    tap(list=>{
      this.listUnread.emit(list.filter(f=>!f.read));
    })
  );
  loading$ = this.notificationStoreSV.loading$;
  next_page$ = new BehaviorSubject<object>({});
  ngOnInit(): void {
    this.notificationStoreSV.loadNotification$(this.next_page$.pipe(
      map(params=>{
        return { ...params, type: 'upload'}
      })
    ));
  }
  onScroll(last_doc:any){
    // console.log("scroll!");
    this.next_page$.next({ start_after: last_doc});
  }
}
