import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Membership, MembershipService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, map, switchMap, tap } from 'rxjs';

interface MembershipState extends EntityState<Membership> {
  loading: boolean,
  error: string,
}
const adapter = createEntityAdapter<any>();
const initialState: MembershipState = adapter.getInitialState({
  loading: false,
  error: '',
})

@Injectable({ providedIn: 'root' })
export class MembershipStoreService extends ComponentStore<MembershipState> {

  private readonly membershipSV = inject(MembershipService);
  readonly data$ = this.select(s => Object.values(s.entities) as Membership[]);
  readonly loading$ = this.select((s) => s.loading);
  readonly list_sub_design$ = this.select((s) => {
    const list = Object.values(s.entities) as Membership[];
    return list.filter(f => f.metadata['type'] === 'sub_design');
  })
  readonly list_one_design$ = this.select((s) => {
    const list = Object.values(s.entities) as Membership[];
    return list.filter(f => f.metadata['type'] === 'one_design');
  })
  readonly daytime_design$ = this.list_sub_design$.pipe(
    map((rs) => {
      // console.log(rs)
      if (rs.length > 0)
        return rs[0].metadata['daytime_design'] ?? false;
      return false
    })
  )

  constructor() { super(initialState); }
  getTeamMembership$ = this.effect((client_team_id$: Observable<string | undefined>) => {
    return client_team_id$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((client_team_id) => {
        // console.log(client_team_id);
        if (client_team_id) {
          const where_query: WhereQueryInterface[] = [
            {
              field_name: 'team_id', field_operator: '==', field_value: client_team_id
            },
            {
              field_name: 'active',
              field_operator: 'in',
              field_value: [0, 1, 2]
            },
          ];
          return this.membershipSV.getListMembershipRealTime(-1, where_query).pipe(
            catchError(()=>EMPTY)
          );
        }
        return EMPTY
      }),
      map((list_membership) => {
        // console.log(list_membership)
        this.setState(s => adapter.setAll(list_membership, s));
        this.patchState({ loading: false, error: '' });
        return list_membership;
      })
    )
  });
}
