
<ng-container *ngIf="overdue; else no_overdue">
  <ng-container *ngIf="circle_type===1">
    <span class="material-icons-outlined text-red-500 text-[22px]">error_outline</span>
  </ng-container>
  <ng-container *ngIf="circle_type===2">
    <span class="material-icons text-red-500 text-[22px]">error</span>
  </ng-container>
</ng-container>
<ng-template #no_overdue>
  <ng-container *ngIf="circle_type===1">
    <span class="material-icons-outlined text-gray-400 text-[22px]">circle</span>
  </ng-container>
  <ng-container *ngIf="circle_type===2">
    <span class="material-icons text-green-500 text-[22px]">
      check_circle
      </span>
  </ng-container>
  <ng-container *ngIf="circle_type===3">
    <span class="material-icons-outlined text-green-500 text-[22px]">check_circle</span>
  </ng-container>
</ng-template>

