<ng-container *ngIf="{list_comment:list_comment$ | async} as vm">
  <ng-container *ngIf="vm.list_comment && vm.list_comment.length > 0">
    <ng-container *ngIf="type==='default'; else primary">
      <span>{{vm.list_comment && vm.list_comment.length}}</span>
    </ng-container>
    <ng-template #primary>
      <span class="rounded-tr-md rounded-bl-md w-6 h-6 text-white flex justify-center items-center bg-blue-500 text-sm">{{vm.list_comment && vm.list_comment.length}}</span>
    </ng-template>
  </ng-container>
</ng-container>

