/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Project } from '@penji/shared/data-access';


type StatusKey = 'in queue' | 'new' | 'question' | 'started' | 'revision' | 'to verify' |
 'scheduled verify' | 'sent back' | 'delivered' | 'on hold' | 'completed' |
  'draft' | 'deleted' | 'pause' | 'positive completed' | 'neutral completed' | 'negative completed';
type StatusTypes = {
  color: string , key: StatusKey
}
@Component({
  selector: 'penji-project-status',
  standalone: true,
  imports: [
    CommonModule,

  ],
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusComponent {
  PROJECT_STATUS: {[key: string]: StatusTypes} = {
    'started': { color: 'bg-[#14B8A6] !text-white', key: 'started' }, // 0, status_addition = 1
    'to verify': { color: 'bg-[#EAB308] !text-white', key: 'to verify' }, // 3
    'scheduled verify': { color: 'bg-[#FDF7E6] !text-[#EAB308] border border-[#EAB308]', key: 'scheduled verify' }, // verify_progress = true
    'sent back': { color: 'bg-[#EF4444] !text-white', key: 'sent back' }, // 3.5, status_addition = 2
    'in queue': { color: 'bg-[#F1F1F1] !text-[#737373]', key: 'in queue' }, // in_queue = true
    'new': { color: 'bg-[#3B82F6] !text-white', key: 'new' }, // 1
    'revision': { color: 'bg-[#F97316] !text-white', key: 'revision' }, // 2
    'delivered': { color: 'bg-[#845EF7] !text-white', key: 'delivered' }, // 4
    'question': { color: 'bg-[#FD5FF4] !text-white', key: 'question' },
    'on hold': { color: 'bg-[#F1F1F1] !text-[#BFBFBF] border border-[#BFBFBF]', key: 'on hold' }, // 5
    'completed': { color: 'bg-green-100 !text-green-600', key: 'completed' }, // 6
    'draft': { color: 'bg-[#EBF3FE] !text-[#3B82F6]', key: 'draft' }, // 7
    'deleted': { color: 'bg-[#FDECEC] !text-[#EF4444] border border-[#EF4444]', key: 'deleted' }, // 8
    'pause': { color: 'bg-[#F1F1F1] !text-[#BFBFBF] border border-[#BFBFBF]', key: 'pause' }, // pause = true
    'positive completed': { color: 'bg-[#E9F9EF] !text-[#22C55E]', key: 'positive completed' },
    'neutral completed': { color: 'bg-[#FDF7E6] !text-[#EAB308]', key: 'neutral completed' },
    'negative completed': { color: 'bg-[#FDECEC] !text-[#EF4444]', key: 'negative completed' },
  };

  @Input() project_data!: Project;

  result_status = '';
  ngOnChanges() {
    if(this.project_data) {
      if (this.project_data.payment_v2) {
        if (this.project_data.in_queue && this.project_data.status == 1) {
          this.result_status = this.PROJECT_STATUS['in queue']['key'];
        } else {
          if (this.project_data.status === 8) {
            this.result_status = this.PROJECT_STATUS['deleted']['key'];
          } else {
            if (this.project_data.pause) {
              this.result_status = this.PROJECT_STATUS['pause']['key'];
            } else {
              if (this.project_data.pending_review) {
                if (this.project_data.verify_progress) {
                  this.result_status = this.PROJECT_STATUS['scheduled verify']['key'];
                } else {
                  this.result_status = this.PROJECT_STATUS['to verify']['key'];
                }
              } else {
                if (!this.project_data.status_addition || this.project_data.status_addition == 0) {
                  if (this.project_data.status === 1) {
                    this.result_status = this.PROJECT_STATUS['new']['key'];
                  } else if (this.project_data.status === 2) {
                    this.result_status = this.PROJECT_STATUS['revision']['key'];
                  } else if (this.project_data.status === 4) {
                    this.result_status = this.PROJECT_STATUS['delivered']['key'];
                  } else if (this.project_data.status === 5) {
                    this.result_status = this.PROJECT_STATUS['on hold']['key'];
                  } else if (this.project_data.status === 6) {
                    this.result_status = this.PROJECT_STATUS['completed']['key'];
                    if (this.project_data.type_complete == 2) {
                      this.result_status = this.PROJECT_STATUS['positive completed']['key'];
                    } else if (this.project_data.type_complete == 4) {
                      this.result_status = this.PROJECT_STATUS['negative completed']['key'];
                    } else if (this.project_data.type_complete == 7) {
                      this.result_status = this.PROJECT_STATUS['neutral completed']['key'];
                    }
                  } else if (this.project_data.status === 7) {
                    this.result_status = this.PROJECT_STATUS['draft']['key'];
                  }
                } else {
                  if (this.project_data.status_addition === 1) {
                    this.result_status = this.PROJECT_STATUS['started']['key'];
                  } else if (this.project_data.status_addition === 2) {
                    this.result_status = this.PROJECT_STATUS['sent back']['key'];
                  } else if (this.project_data.status_addition === 3) {
                    this.result_status = this.PROJECT_STATUS['question']['key'];
                  }
                }
              }
            }
          }
        }
      } else {
        if (this.project_data.status === 8) {
          this.result_status = this.PROJECT_STATUS['deleted']['key'];
        } else {
          if (this.project_data.in_queue && this.project_data.status == 1) {
            this.result_status = this.PROJECT_STATUS['in queue']['key'];
          } else if (this.project_data.pause) {
            this.result_status = this.PROJECT_STATUS['pause']['key'];
          } else {
            if (this.project_data.pending_review) {
              if (this.project_data.verify_progress) {
                this.result_status = this.PROJECT_STATUS['scheduled verify']['key'];
              } else {
                this.result_status = this.PROJECT_STATUS['to verify']['key'];
              }
            } else {
              if (!this.project_data.status_addition || this.project_data.status_addition == 0) {
                if (this.project_data.status === 1) {
                  this.result_status = this.PROJECT_STATUS['new']['key'];
                } else if (this.project_data.status === 2) {
                  this.result_status = this.PROJECT_STATUS['revision']['key'];
                } else if (this.project_data.status === 4) {
                  this.result_status = this.PROJECT_STATUS['delivered']['key'];
                } else if (this.project_data.status === 5) {
                  this.result_status = this.PROJECT_STATUS['on hold']['key'];
                } else if (this.project_data.status === 6) {
                  this.result_status = this.PROJECT_STATUS['completed']['key'];
                  if (this.project_data.type_complete == 2) {
                    this.result_status = this.PROJECT_STATUS['positive completed']['key'];
                  } else if (this.project_data.type_complete == 4) {
                    this.result_status = this.PROJECT_STATUS['negative completed']['key'];
                  } else if (this.project_data.type_complete == 7) {
                    this.result_status = this.PROJECT_STATUS['neutral completed']['key'];
                  }
                } else if (this.project_data.status === 7) {
                  this.result_status = this.PROJECT_STATUS['draft']['key'];
                }
              } else {
                if (this.project_data.status_addition === 1) {
                  this.result_status = this.PROJECT_STATUS['started']['key'];
                } else if (this.project_data.status_addition === 2) {
                  this.result_status = this.PROJECT_STATUS['sent back']['key'];
                } else if (this.project_data.status_addition === 3) {
                  this.result_status = this.PROJECT_STATUS['question']['key'];
                }
              }
            }
          }
        }
      }

    }
  }
}
