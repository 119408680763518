import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Overtime, OvertimeService, WhereQueryInterface } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { ConvertTimezonePipe } from '@penji/shared/pipes';
import { OvertimeButtonUpdateComponent } from '@penji/team/overtime/overtime-ui/overtime-button-update';
import { OvertimeLinkExpandComponent } from '@penji/team/overtime/overtime-ui/overtime-link-expand';
import { InputComponent } from '@penji/team/shared/shared-ui/input';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-w-overtime',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    UserItemCompressComponent,
    OvertimeLinkExpandComponent,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule,
    OvertimeButtonUpdateComponent,
    RouterModule,
    ConvertTimezonePipe
  ],
  templateUrl: './w-overtime.component.html',
  styleUrls: ['./w-overtime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WOvertimeComponent {
  limit = -1;

  overtimeSV = inject(OvertimeService);
  expand = new Set<string>();
  list_overtime$!: Observable<Overtime[]>;
  container_height = 0;
  show_view_port: any = {};

  sortOvertimeAt = (a: Overtime, b: Overtime) => (a.overtime_at < b.overtime_at) ? 1 : -1;
  sortOvertimeLink = (a: Overtime, b: Overtime) => (a.overtime_link.length < b.overtime_link.length) ? 1 : -1;

  constructor() {
    const where_query_list: Array<WhereQueryInterface> = [
      { field_name: 'approved', field_operator: '==', field_value: 0 }
    ];
    this.list_overtime$ = this.overtimeSV.listOvertimeRealTime(this.limit, where_query_list);
  }
  expandOvertime(overtime_id: string) {
    if (this.expand.has(overtime_id)) {
      this.expand.delete(overtime_id);
    } else {
      this.expand.clear();
      this.expand.add(overtime_id);
    }
  }
  getHeight(height: any){
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
  trackBy(index:any, item: any) {
    return item.id;
  }

}
