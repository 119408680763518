<ng-container *ngIf="{media:media$ | async} as vm;">
  <ng-container *ngIf="vm.media; else no_media">
    <ng-container *ngIf="!vm.media.version">
      <ng-container *ngIf="!media_ref?.no_ref">
        <penji-media-cover
          [image_url]="media_ref?.parent?.parent.id==='8i7FhXui3qU9Q2DWgIOY3t3oV3l1'?vm.media['avatar']:vm.media[media_size]"
          [clickable]="clickable" [image_auto_fit]="image_auto_fit" [image_type]="image_type"></penji-media-cover>
      </ng-container>
      <ng-container *ngIf="media_ref?.no_ref">
        <penji-media-cover [image_url]="vm.media[media_size]" [clickable]="clickable" [image_auto_fit]="image_auto_fit"
          [image_type]="image_type"></penji-media-cover>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="vm.media.version">
      <penji-media-cover-v2 [media_url]="vm.media['original']" [clickable]="clickable" [image_auto_fit]="image_auto_fit"
        [image_type]="image_type"></penji-media-cover-v2>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #no_media>
  <penji-media-cover
    [image_url]="media_size === 'avatar'?'./assets/images/no-avatar.png':image_type==='design'?'./assets/images/no-design.jpg':'./assets/images/no-image.png'"
    [image_auto_fit]="image_auto_fit" [image_type]="image_type"></penji-media-cover>
</ng-template>
