/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerService, ImageSize, Team } from '@penji/shared/data-access';
import { WorkSpaceDetailStoreService } from '@penji/team/workspace/data-access';
import { Observable } from 'rxjs';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { WorkspaceNameComponent } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-name';
import { WorkspaceAvatarComponent } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-avatar';
import { WorkspaceLevelV2Component } from '@penji/team/workspace/workspace-ui/workspace-level-v2';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { WorkspaceCustomerClassTooltipComponent } from '@penji/team/workspace/workspace-ui/workspace-customer-class-tooltip';
import { RouterModule } from '@angular/router';
import { WorkspacePopoverComponent } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { WorkspaceStatusComponent } from '@penji/team/workspace/workspace-ui/workspace-status';


@Component({
  selector: 'penji-workspace-item-v2',
  standalone: true,
  imports: [
    CommonModule,
    NzPopoverModule,
    NzToolTipModule,
    WorkspaceNameComponent,
    WorkspaceAvatarComponent,
    WorkspaceLevelV2Component,
    CopyItemComponent,
    WorkspaceCustomerClassTooltipComponent,
    RouterModule,
    WorkspacePopoverComponent,
    NzDividerModule,
    WorkspaceStatusComponent
  ],
  templateUrl: './workspace-item-v2.component.html',
  styleUrls: ['./workspace-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceItemV2Component implements OnChanges {

  @Input() client_team_id?: string;
  @Input() show_workspace_name = false;
  @Input() show_workspace_avatar = false;
  @Input() show_workspace_level = false;
  @Input() show_workspace_level_on_left = false;
  @Input() show_workspace_level_tooltip = false;
  @Input() show_copy_workspace = false;
  @Input() show_border_box_workspace = false;
  @Input() padding_border = 'p-3';
  @Input() workspace_classes = 'text-lg';
  @Input() client_id?: string;
  @Input() workspace_image_size: ImageSize = 'sm';
  @Input() clickable = false;
  @Input() hide_workspace_popover = false;
  @Input() show_workspace_status = false;

  private drawerSv = inject(DrawerService);
  private readonly wspDetailStore = inject(WorkSpaceDetailStoreService);

  workspace_data$?: Observable<Team>;


  ngOnChanges(): void {
    if (this.client_team_id) {
      this.workspace_data$ = this.wspDetailStore.data$(this.client_team_id)
    }
  }

  openDrawer(event: any, id: string) {
    if (!(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      this.drawerSv.openDrawer({ type: 'workspace', id });
    }
  }

}
