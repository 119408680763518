import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, distinct, map, mergeMap, tap } from "rxjs";
import { UserSimple } from "../../models/user.model";
import { CheckinService } from "../../services/checkin.service";
import { UserService } from "../../services/user.service";
import * as userActions from './user.action';

@Injectable()
export class UserEffect {

  actions = inject(Actions);
  userSv = inject(UserService);
  checkinSV = inject(CheckinService);

  loadUser$ = createEffect(() =>  this.actions.pipe(
    ofType(userActions.loadUserDB),
    distinct(e => e.uid),
    mergeMap((action: any) => {
      if(action && action.uid)
        return this.userSv.getUserDetailOnce(action.uid);
      return EMPTY;
    }),
    map((user) => {
      if(user)
      {
        const user_t = new UserSimple;

        user_t.id = user.id ?? '';
        user_t.uid = user.id ?? '';
        user_t.avatar = (user.user_info && user.user_info.avatar) ? {id: user.user_info.avatar.id, parent: user.user_info.avatar.parent.parent?.id, no_ref: true} : undefined;
        user_t.email = user.user_info && user.user_info.email ? user.user_info.email : '';
        user_t.first_name = user.user_info && user.user_info.first_name ? user.user_info.first_name : '';
        user_t.last_name = user.user_info && user.user_info.last_name ? user.user_info.last_name : '';
        user_t.full_name = user.user_info && user.user_info.full_name ? user.user_info.full_name : '';
        user_t.role = user.role ? user.role : -1;
        user_t.client_team_id = user.team_active ?? '';
        user_t.team_designer_active = user.team_designer_active ?? '';
        user_t.squad_id = user.squad_id ?? '';
        user_t.out_put_design = user.out_put_design ? user.out_put_design : -1;
        user_t.is_pm = user.is_pm ? user.is_pm : false;
        user_t.country = user.user_info && user.user_info.country ? user.user_info.country : '';
        user_t.time_zone = user.user_info && user.user_info.time_zone ? user.user_info.time_zone : '';
        user_t.the_best = user.the_best && user.the_best.length > 0 ? user.the_best : [];
        user_t.full_name = user.user_info && user.user_info.full_name ? user.user_info.full_name : '';
        user_t.disabled = user.disabled ? user.disabled : false;
        return userActions.createUser({user:user_t as UserSimple});
      }
      // return { type:''};
      return userActions.noThing();
    })
  ))

}

