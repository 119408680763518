import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertTimezone',
  standalone: true
})
export class ConvertTimezonePipe implements PipeTransform {

  transform(date: Date, timezone: string = "UTC"): unknown {
    return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
  }

}
