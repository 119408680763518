/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerService, Team } from '@penji/shared/data-access';
import * as firebase from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { WorkspaceDiscoveryPhaseTooltipComponent } from '@penji/team/workspace/workspace-ui/workspace-discovery-phase-tooltip';
import { WorkspaceDiscoveryPhaseComponent } from '@penji/team/workspace/workspace-ui/workspace-discovery-phase';
import { WorkspaceStatusComponent } from '@penji/team/workspace/workspace-ui/workspace-status';
import { WorkspaceMembershipDropdownComponent } from '@penji/team/workspace/workspace-ui/workspace-membership-dropdown';
import { UserInfoItemComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-info-item';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { WorkspaceLabelComponent } from '@penji/team/workspace/workspace-ui/workspace-label';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { WorkspaceNameComponent } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-name';
import { WorkspaceAvatarComponent } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-avatar';
import { WorkspaceLevelV2Component } from '@penji/team/workspace/workspace-ui/workspace-level-v2';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { WorkspaceCustomerClassTooltipComponent } from '@penji/team/workspace/workspace-ui/workspace-customer-class-tooltip';
import { RouterModule } from '@angular/router';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';

@Component({
  selector: 'penji-workspace-popover',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    WorkspaceDiscoveryPhaseComponent,
    WorkspaceDiscoveryPhaseTooltipComponent,
    WorkspaceStatusComponent,
    WorkspaceMembershipDropdownComponent,
    UserInfoItemComponent,
    NzCollapseModule,
    WorkspaceLabelComponent,
    NzToolTipModule,
    WorkspaceNameComponent,
    WorkspaceAvatarComponent,
    WorkspaceLevelV2Component,
    CopyItemComponent,
    WorkspaceCustomerClassTooltipComponent,
    UserItemCompressComponent,
    NzDividerModule,
    DesignerTeamNameComponent
  ],
  templateUrl: './workspace-popover.component.html',
  styleUrls: ['./workspace-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspacePopoverComponent {

  @Input() workspace_data?: Team;

  private readonly drawerSv = inject(DrawerService);

  openDrawer(id: string, type: string) {
    const temp = { type: type, id: id }
    this.drawerSv.openDrawer(temp);
  }
}
