import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MediaRefComponent } from '@penji/client-v3/media/ui/media-ref';
import { WorkspaceStoreService } from '@penji/client-v3/workspace/data-access';
import { WorkSpace } from '@penji/shared/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-workspace-item',
  standalone: true,
  imports: [
    CommonModule,
    MediaRefComponent,
    NzToolTipModule
  ],
  templateUrl: './workspace-item.component.html',
  styleUrls: ['./workspace-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceItemComponent {
  @Input() team_id?: string;
  private readonly workspaceStoreSV = inject(WorkspaceStoreService);
  workspace$: Observable<WorkSpace> | undefined;
  ngOnChanges(){
    if(this.team_id)
      this.workspace$ = this.workspaceStoreSV.detail$(this.team_id);
  }
}
