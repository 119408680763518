<div class="flex flex-col h-full">
  <div class="top flex p-[1.05rem_0_1.2rem_2rem]">
    <div class="w-[71.2%]">
      <div class="head-box flex justify-between pt-2 pr-[1.3rem]">
        <h2 class="text-2xl font-semibold dark:text-white"><span>File Manager</span></h2>
        <div class="flex items-center space-x-5">
          <input type="file" #file (input)="onFileChange($event)" class="!hidden" />
          <button (click)="file.click()" nz-button nzType="primary" class="flex items-center justify-center gap-x-2 px-6">
            <span class="material-icons-outlined text-[20px]">upload</span> <span class="text-base">Upload</span>
          </button>
          <nz-select nzPlaceHolder="Select type" (ngModelChange)="inputSort($event)" [ngModel]="action$.getValue().type"
            class="text-base min-w-[120px] text-left [&_.ant-select-selector]:!rounded">
            <nz-option nzValue="all" nzLabel="All"></nz-option>
            <nz-option nzValue="image" nzLabel="Images"></nz-option>
            <nz-option nzValue="file" nzLabel="Files"></nz-option>
            <nz-option nzValue="font" nzLabel="Fonts"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div class="w-[28.8%]"></div>
  </div>

  <div class="content flex {{output_modal?'h-[calc(100%-148px)]':'h-[calc(100%-80px)]'}} pl-8">
    <ng-container
      *ngIf="{highlightItem: highlightItem$ | async, isLoading: isLoading$ | async, list: list$ | async, selectedItem: selectedItem$ | async, data: data$ | async } as vm">
      <div class="w-[71.2%] relative h-full overflow-auto mr-2">

        <!-- <div
          class="filter-box flex items-start justify-between gap-x-4 absolute top-0 left-0 right-0 h-16 bg-white pr-[.9rem]">

          <penji-input container_class_name="w-full"
            input_class_name="shadow text-gray-500 dark:bg-gray-700 dark:text-white dark:!border-gray-500 !border-gray-200 focus:!border-purple-200 hover:bg-white focus:bg-white dark:focus:bg-gray-200 dark:focus:text-black hover:!border-purple-200 hover:shadow-md focus:shadow-md w-[470px] !h-[39px] pl-[40px] pr-2"
            icon="search" icon_size="20px" placeholder="Search" [enable_clear_button]="true" [auto_focus]="true">
          </penji-input>

          <div class="w-full text-right">

          </div>
        </div> -->

        <div *ngIf="vm.list" class="content-innter absolute top-0 left-0 right-0 bottom-0 h-[calc(100%-60px)] overflow-auto pr-[.5rem]"
          infiniteScroll [infiniteScrollDistance]="1" [scrollWindow]="false"
          (scrolled)="scrollDown(vm.list[vm.list.length -1].media_id)" [infiniteScrollThrottle]="1500">
          <div class="grid grid-cols-5 gap-4 pb-4
            {{vm.isLoading?'opacity-40':''}}
            ">

            <!-- current list file uploaded -->
            <ng-container *ngFor="let file of vm.list">
              <ng-container *ngIf="file.status ==='completed' else incompleteFile">
                <div class="relative group rounded border-[2px] border-gray-100 dark:border-gray-500 w-full h-full min-h-[130px] mx-auto flex items-center justify-center
                {{isfileSelected(file) ? (file.id === vm.highlightItem?.id ? '!border-purple-500' : '!border-gray-500') : ''}}"
                (click)="onSelectItem(file)">
                  <ng-container *ngIf="isfileSelected(file)">
                    <span class="absolute top-0 right-0 w-6 h-6 text-white cursor-pointer z-[2] {{isfileSelected(file) ? (file.id === vm.highlightItem?.id ? 'bg-purple-500' : 'bg-gray-500') : ''}} rounded-[0_0_0_4px] [&>.child]:absolute [&>.child]:left-[calc(50%+1px)] [&>.child]:-translate-x-1/2 [&>.child]:top-1/2 [&>.child]:-translate-y-1/2 [&>.child]:text-[18px]">
                      <span class="material-icons-outlined transition-all child group-hover:opacity-0 group-hover:invisible">done</span>
                      <span class="material-icons-outlined transition-all child opacity-0 invisible group-hover:opacity-100 group-hover:visible"
                            (click)="onRemoveItem($event, file)" *ngIf="isfileSelected(file)">remove</span>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="file.status ==='completed'">
                    <img *ngIf="file.type.substring(0,5) === 'image' else otherFile" [src]="file.path_preview" [alt]="file.name" class="w-full h-full object-contain aspect-[1/1]">
                  </ng-container>
                  <ng-template #otherFile>
                    <div class="item relative z-[1] flex flex-col gap-y-4 items-between justify-between w-full h-full p-[1.175rem_0.475rem]">
                      <div class="img-box flex justify-center items-center relative">
                        <img src="assets/images/icons/file-type-icon/file.svg" [alt]="file.name" class="h-[55px]">
                        <span class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base">{{getExtension(file.name)}}</span>
                      </div>
                      <p nz-tooltip [nzTooltipTitle]="file.name" class="text-base text-center whitespace-nowrap">{{getSubName(file.name, 7)}}....{{getExtension(file.name)}}</p>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
              <ng-template #incompleteFile>
                <div class="flex items-center justify-center shadow rounded w-full h-full min-h-[130px] bg-[#D9D9D9] border-[2px]">
                    <nz-progress nz-popconfirm nzPopconfirmTitle="Permanently delete this file. Do you want to delete it?"
                    nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteErrorFile(file)" class="{{file.status==='failed'?'cursor-pointer':''}}" nzStrokeLinecap="round" nzType="circle" [nzPercent]="file.progress" [nzWidth]="50"
                      [nzStatus]="file.status==='failed' ? 'exception' : file.status==='uploading' ? 'active' : 'normal'"></nz-progress>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
        <div *ngIf="vm.isLoading" class="rounded-full bg-purple-100 inline-flex p-1 items-center absolute right-1/2 top-1/2 -translate-y-1/2 -translate-X-1/2 z-50">
          <penji-spinner></penji-spinner>
        </div>
        <div class="bottom-result-messages p-[0.875rem] absolute bottom-0 left-0 right-0 h-16 bg-white border-t border-t-gray-200 dark:bg-gray-700 dark:border-t-gray-500">
          <div class="h-full flex flex-col items-center justify-center [&>p]:m-0 [&>p]:text-base [&>p]:text-center">
            <p class="font-semibold">Showing {{vm.list?.length}} media items</p>
            <p class="text-gray-500 text-sm">Drag and drop files JPEG, PNG, GIF</p>
          </div>
        </div>

      </div>

      <div class="w-[28.8%] bg-gray-50 dark:bg-gray-700 h-full rounded overflow-auto">
        <ng-container *ngIf="vm.highlightItem && vm.highlightItem !== undefined else unselected_item_case">
          <div class="flex flex-col justify-between h-full overflow-auto relative">
            <div class="p-[0.75rem_1.125rem_0.75rem_0.75rem] [&>div]:flex [&>div]:flex-col">
              <div class="heading {{vm.highlightItem.status !=='completed' ? 'h-[162px]' : ''}}">
                <h3 class="text-base mb-2 dark:text-white">{{vm.highlightItem.status === 'uploading' ? 'Uploading' :
                  vm.highlightItem.status === 'failed' ? 'Upload Failed' : 'Attachment Details'}}</h3>
                <div class="content-container w-full shadow rounded">
                  <div
                    class="cover {{vm.highlightItem.status === 'completed' ? '' : 'aspect-[228/104] shadow'}} rounded overflow-hidden w-full">
                    <ng-container *ngIf="vm.highlightItem.status === 'completed'">
                      <div class="w-full h-full item relative z-[1] flex flex-col gap-y-4 items-between justify-between p-[0.875rem]">
                        <img *ngIf="vm.highlightItem.type === 'image' else otherFile" [src]="vm.highlightItem.path_preview"
                          [alt]="vm.highlightItem.name" class="w-full h-[22.2vh] object-contain">
                      </div>

                      <ng-template #otherFile>
                        <div class="w-full h-full">
                          <div class="img-box flex justify-center items-center">
                            <img src="assets/images/icons/file-type-icon/file.svg" [alt]="vm.highlightItem.name" class="h-[55px]">
                            <span class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base">{{getExtension(vm.highlightItem.name)}}</span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>

                    <nz-progress *ngIf="vm.highlightItem.status !== 'completed'" nzStrokeLinecap="round"
                      [nzPercent]="vm.highlightItem.progress"
                      [nzStatus]="vm.highlightItem.status==='failed' ? 'exception' : vm.highlightItem.status==='uploading' ? 'active' : 'normal'"></nz-progress>
                  </div>
                </div>
              </div>
              <div class="w-full pt-4 space-y-2">
                <!-- <p *ngIf="vm.highlightItem.path_preview" class="text-[#303030] block w-[270px] truncate min-h-[22px]">
                  {{vm.highlightItem.path_preview}}
                </p> -->
                <p *ngIf="vm.highlightItem.created_at"><b>Create Day:</b> <span class="text-gray-500 ml-1">{{vm.highlightItem.created_at | date: 'longDate'}}</span></p>
                <p *ngIf="vm.highlightItem.size"><b>Size:</b> <span class="text-gray-500 ml-1">{{vm.highlightItem.size | nzBytes}}</span></p>
                <div *ngIf="vm.highlightItem.name" class="div_text">
                  <p>
                    <b>Name:</b>
                  </p>
                  <div class="flex items-center !mt-1">
                    <p class="text-gray-500 truncate px-3 py-1 bg-gray-200 rounded input-field" nz-tooltip [nzTooltipTitle]="vm.highlightItem.name">{{vm.highlightItem.name}}</p>
                    <p class="[&_button]:inline-flex [&.ant-typography]:text-gray-500 [&.ant-typography]:font-semibold ml-1" nzEllipsis  nz-typography nzCopyable [nzCopyText]="vm.highlightItem.name"></p>
                  </div>
                  <!-- <div class="relative flex items-center justify-between mt-2">
                    <input nz-input class="pr-[8px] w-[calc(100%-33px)] p-2 rounded" [value]="vm.highlightItem.name">
                    <ng-container *ngIf="vm.highlightItem.name">
                      <a class="!h-6 text-center !p-0 [&>nz-text-copy>button]:flex [&>nz-text-copy>button]:mt-[3px] [&>nz-text-copy>button]:text-gray-500 hover:[&>nz-text-copy>button]:text-purple-500 [&>nz-text-copy>button]:items-center flex items-center"
                        nz-typography nzCopyable [nzCopyText]="vm.highlightItem.name"
                        [nzCopyIcons]="[icon_copy, icon_check]">
                      </a>
                      <ng-template #icon_copy>
                        <span class="material-icons-outlined text-[18px]">content_copy</span>
                      </ng-template>
                      <ng-template #icon_check>
                        <span class="material-icons-outlined text-[18px] text-green-500">check</span>
                      </ng-template>
                    </ng-container>
                  </div> -->
                </div>
                <div *ngIf="vm.highlightItem.path_preview" class="div_text">
                  <p><b>Link Url:</b></p>
                  <div class="flex items-center !mt-1">
                    <p class="text-gray-500 truncate pl-3 pr-1 py-1 bg-gray-200 rounded input-field" nz-tooltip [nzTooltipTitle]="vm.highlightItem.path_preview+''">{{vm.highlightItem.path_preview}}</p>
                    <p class="[&_button]:inline-flex [&.ant-typography]:text-gray-500 [&.ant-typography]:font-semibold ml-1" nzEllipsis  nz-typography nzCopyable [nzCopyText]="vm.highlightItem.path_preview+''"></p>
                  </div>

                  <!-- <div class="relative flex items-center justify-between mt-2">
                    <input [value]="vm.highlightItem.path_preview" class="shadow bg-[#D4D4D4] text-gray-500 dark:bg-gray-700 dark:text-white dark:!border-gray-500 !border-gray-200 !h-10 pr-[8px] w-[calc(100%-33px)] p-2 rounded">
                    <ng-container *ngIf="vm.highlightItem.path_preview">
                      <a class="!h-6 text-center !p-0 [&>nz-text-copy>button]:flex [&>nz-text-copy>button]:mt-[3px] [&>nz-text-copy>button]:text-gray-500 hover:[&>nz-text-copy>button]:text-purple-500 [&>nz-text-copy>button]:items-center flex items-center"
                        nz-typography nzCopyable [nzCopyText]="vm.highlightItem.path_preview+''" [nzCopyIcons]="[icon_copy, icon_check]">
                      </a>
                      <ng-template #icon_copy>
                        <span class="material-icons-outlined text-[18px]">content_copy</span>
                      </ng-template>
                      <ng-template #icon_check>
                        <span class="material-icons-outlined text-[18px] text-green-500">check</span>
                      </ng-template>

                    </ng-container>
                  </div> -->
                </div>
                <div class="pt-5">
                  <button nz-button nzType="default" nzSize="small" nzDanger class="flex items-center justify-center space-x-1"
                    nz-popconfirm nzPopconfirmTitle="Permanently delete this file. Do you want to delete it?"
                    nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteImage(vm.highlightItem)"
                  >
                    <span class="material-icons-outlined text-lg">delete</span>
                    <span>Delete Image</span>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="footer">
              <p *ngIf="vm.highlightItem.status_upload!=='error' else error_upload_case"
                class="text-red-500 text-base px-2 {{vm.highlightItem.status_upload ? 'w-[116px]' : 'w-[110px]'}} !inline-flex items-center cursor-pointer border border-transparent rounded hover:border-red-500 hover:bg-red-400 hover:text-white"
                (click)="vm.highlightItem.status_upload ? cancelUpload(vm.highlightItem) : deleteImage(vm.highlightItem)"
              >
                <span class="material-icons-outlined text-base mr-1">{{vm.highlightItem.status_upload ? 'stop_circle' :
                  'delete'}}
                </span>
                <span class="text-base">{{vm.highlightItem.status_upload ? 'Cancel Upload' : 'Delete Image'}}</span>
              </p>
              <ng-template #error_upload_case></ng-template>
            </div> -->
          </div>
        </ng-container>

        <ng-template #unselected_item_case>
          <div class="w-full h-full flex flex-col items-center justify-center [&_.ant-empty-image]:flex [&_.ant-empty-image]:justify-center">
            <nz-empty nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              [nzNotFoundContent]="contentTpl" [nzNotFoundFooter]="footerTpl" class="[&_.ant-empty-footer]:mt-0">
              <ng-template #contentTpl>
                <span>
                  No media selected!
                </span>
              </ng-template>
              <ng-template #footerTpl>
                <p class="text-center text-base text-gray-300">Choose the media on the left side</p>
              </ng-template>
            </nz-empty>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <div class="footer flex pl-8 py-4 border-t border-gray-200" *ngIf="output_modal">
    <div class="w-[71.2%]"></div>
    <div class="w-[28.8%] px-4">
      <button nz-button nzType="primary" nzBlock [disabled]="selectedItem$.getValue().length === 0" (click)="submit()"
        class="flex items-center justify-center btn-primary-blue">

        Select media
      </button>
    </div>
  </div>
</div>
