import { createAction, props } from "@ngrx/store";
import { Media } from "@penji/shared/data-access";

// Store
export const createMedia = createAction('[Media] Create Media', props<{media: Media}>());
export const deleteMedia = createAction('[Media] Delete Media', props<{media_id: string}>());
// Database
export const loadMediaRefDB = createAction('[Media] Load Media DB', props<{user_id: string, media_id: string }>());
export const deleteMediaDB = createAction('[Media] Delete Media DB', props<{user_id: string, media_id: string}>());
// Nothing
export const nothing = createAction('[User] Nothing to do');
