import { Injectable, inject } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Invoice } from '../models/invoice.model';
import { EMPTY, Observable, catchError, of } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService extends LogService<Invoice>{

    nzMessageService = inject(NzMessageService);
    
    getInvoiceDetail(invoice_id: string) {
        return this.afs.doc<Invoice>(`invoice/${invoice_id}`).valueChanges({ idField: 'id' }).pipe(
            catchError(() => EMPTY)
        );
    }
    async getListInvoice(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
        try {
            let query: Query = this.afs.firestore.collection('invoice');
            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            }
            query = query.orderBy('created_at', 'desc');

            if (limit != -1)
                query = query.limit(limit);

            return query.get().then(querySnapshot => {
                const list: Array<Invoice> = [];
                querySnapshot.forEach((doc: any) => {
                    const data = doc.data() as Invoice;
                    data.id = doc.id;
                    data.doc = doc;
                    list.push(data);
                });
                return list;
            }).catch(error => {
                console.log(error);
                return [];
            });
        } catch (error) {
            console.log(error);
            return [];
        }
    }
    getListInvoiceRealTime(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
        try {
            let query: Query = this.afs.firestore.collection('invoice');

            if (where_query && where_query.length > 0) {
                where_query.forEach(q => {
                    query = query.where(q.field_name, q.field_operator, q.field_value);
                })
            } else {
                query = query.limit(10);
            }
            if (limit != -1) query = query.limit(limit);

            return new Observable<Invoice[]>(observ => {
                return query.onSnapshot(
                    querySnapshot => {
                        const list: Array<Invoice> = [];
                        querySnapshot.forEach((doc) => {
                            const data = doc.data() as Invoice;
                            data.id = doc.id;
                            data.doc = doc;
                            list.push(data);
                        });
                        return observ.next(list);
                    },
                    error => {
                        observ.error(error);
                    })
            });
        } catch (error) {
            console.log(error);
            return of([]);
        }
    }
}
