import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamService } from '@penji/shared/data-access';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'penji-designer-team-total-active-customer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './designer-team-total-active-customer.component.html',
  styleUrls: ['./designer-team-total-active-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignerTeamTotalActiveCustomerComponent {
  @Input() designer_team_id!: string;
  teamSV = inject(TeamService);
  total_active_customer$ = new BehaviorSubject<number>(0);

  async ngOnChanges(): Promise<void> {
    const total = await this.teamSV.getTotalActiveTeam(this.designer_team_id);
    // console.log(total)
    this.total_active_customer$.next(total)
  }
}
