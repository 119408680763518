<div class="flex items-center relative min-w-[320px]" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
    <penji-input container_class_name=""
        input_class_name="ant-select-selection-search-input rounded-md !h-[40px] pr-5 pl-12 shadow-sm dark:bg-gray-600 dark:text-white !border-gray-300 dark:!border-gray-500 focus:!border-purple-400 hover:!border-purple-400 rounded focus:!border-purple-400"
        [control]="search_control" icon="search" class_icon_size="text-[22px]" placeholder="Search everything..."
        [enable_clear_button]="true" [auto_focus]="auto_focus" class="w-full">
    </penji-input>

    <div *ngIf="isLoading"
        class="rounded-full bg-purple-100 inline-flex p-1 items-center w-7 h-7 ml-2 absolute right-2 top-1/2 -translate-y-1/2 z-30">
        <penji-spinner [loading_width]="20"></penji-spinner>
    </div>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="min-w-[500px] shadow-lg {{result_all?'border border-gray-200 rounded-lg bg-white dark:bg-gray-700 dark:border-gray-600':''}}">
        <ul nz-menu class="shadow-none menu-search max-h-96 overflow-auto !py-0 dark:bg-gray-700 dark:text-white dark:[&_.item-title]:text-white">
            <li id="top-dropdown-search"></li>
            <ng-container *ngIf="list$ | async as vm">
                <ng-container *ngIf="vm.list_project && vm.list_project.length > 0">
                    <li nz-menu-item
                        class="cursor-default font-semibold text-gray-500 dark:text-white !bg-transparent px-5 py-2">
                        Project</li>
                    <li routerLink="/t/{{client_team_id}}/project-detail/{{project.id}}" nz-menu-item
                        *ngFor="let project of vm.list_project"
                        class="item-title group px-5 py-2.5 space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500">
                        <div class="flex w-full items-center justify-between">
                            <div class="flex space-x-3 w-[calc(100%-120px)]">
                                <svg width="36" height="36" viewBox="0 0 32 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="16" fill="#E9F9EF" />
                                    <mask id="mask0_5542_1218" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6"
                                        y="6" width="20" height="20">
                                        <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
                                    </mask>
                                    <g mask="url(#mask0_5542_1218)">
                                        <path
                                            d="M11.8333 20.1667H17.6667V18.5H11.8333V20.1667ZM11.8333 16.8333H20.1667V15.1667H11.8333V16.8333ZM11.8333 13.5H20.1667V11.8333H11.8333V13.5ZM10.1667 23.5C9.70833 23.5 9.31597 23.3368 8.98958 23.0104C8.66319 22.684 8.5 22.2917 8.5 21.8333V10.1667C8.5 9.70833 8.66319 9.31597 8.98958 8.98958C9.31597 8.66319 9.70833 8.5 10.1667 8.5H21.8333C22.2917 8.5 22.684 8.66319 23.0104 8.98958C23.3368 9.31597 23.5 9.70833 23.5 10.1667V21.8333C23.5 22.2917 23.3368 22.684 23.0104 23.0104C22.684 23.3368 22.2917 23.5 21.8333 23.5H10.1667ZM10.1667 21.8333H21.8333V10.1667H10.1667V21.8333Z"
                                            fill="#22C55E" />
                                    </g>
                                </svg>
                                <div class="flex flex-col w-[calc(100%-45px)]">
                                    <span class="capitalize dark:text-white font-semibold text-gray-800 truncate group-hover:text-blue-600"
                                        [innerHTML]="project.title"></span>
                                    <div class="flex space-x-1">
                                        <p class="text-gray-600 text-sm">
                                            <penji-category-name-by-id
                                                [cate_id]="project.project_cat_id"></penji-category-name-by-id>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <penji-project-status [project_data]="project"></penji-project-status>
                        </div>
                    </li>

                </ng-container>
                <!-- No data -->
                <ng-container *ngIf="!isLoading && search_control.value !== '' && vm.list_project.length === 0">
                    <nz-empty class="height-empty px-4 py-5" nzNotFoundImage="simple"></nz-empty>
                </ng-container>
            </ng-container>
            <!-- loading -->
            <ng-container *ngIf="isLoading">
                <div class="px-5 py-2">
                    <nz-skeleton [nzActive]="true" [nzTitle]="false"
                        [nzParagraph]="{ rows: 6, width: '100%' }"></nz-skeleton>
                </div>
            </ng-container>
        </ul>
        <div *ngIf="result_all" class="flex justify-center px-4 py-3 cursor-pointer font-semibold hover:underline !bg-blue-100 !text-blue-600 dark:!bg-gray-600" routerLink="/t/{{client_team_id}}/projects/search" [queryParams]="{keyword: keyword}">
            <span>View all results</span>
        </div>
        <div *ngIf="result_all" class="px-4 py-3 border-t flex justify-center space-x-14 dark:border-t-gray-500">
            <div class="space-x-2">
                <button disabled class="p-2 bg-gray-100 rounded-lg border border-gray-200"><svg width="12" height="12"
                        viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.705 4.29498L6 6.58498L8.295 4.29498L9 4.99998L6 7.99998L3 4.99998L3.705 4.29498Z"
                            fill="#3F3F3F" />
                    </svg>
                </button>
                <button disabled class="p-2 bg-gray-100 rounded-lg border border-gray-200 rotate-180"><svg width="12"
                        height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.705 4.29498L6 6.58498L8.295 4.29498L9 4.99998L6 7.99998L3 4.99998L3.705 4.29498Z"
                            fill="#3F3F3F" />
                    </svg>
                </button>
                <span class="dark:text-gray-200">To navigate</span>
            </div>
            <div class="space-x-2">
                <button disabled class="p-2 bg-gray-100 rounded-lg border border-gray-200">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 3.5V5.5H2.915L4.705 3.705L4 3L1 6L4 9L4.705 8.295L2.915 6.5H10.5V3.5H9.5Z"
                            fill="#3F3F3F" />
                    </svg>
                </button>
                <span class="dark:text-gray-200">To select</span>
            </div>
            <div>
                
            </div>
        </div>
    </div>
</nz-dropdown-menu>