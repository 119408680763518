<div class="flex items-center relative" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click"
  [nzOverlayClassName]="'shadow-md rounded-lg [&_.ant-dropdown-menu]:shadow-none bg-white dark:bg-gray-700'">
  <penji-input container_class_name=""
    input_class_name="ant-select-selection-search-input rounded-md !h-[42px] pr-5 pl-12 shadow-sm dark:bg-gray-600 dark:text-white !border-gray-300 dark:!border-gray-500 focus:!border-blue-400 hover:!border-blue-240 hover:shadow-md focus:shadow-md rounded focus:!border-blue-400"
    [control]="search_control" icon="search" class_icon_size="text-[22px]" placeholder="Search..."
    [enable_clear_button]="true" [auto_focus]="auto_focus" class="w-full">
  </penji-input>

  <div *ngIf="isLoading"
    class="rounded-full bg-purple-100 inline-flex p-1 items-center w-7 h-7 ml-2 absolute right-2 top-1/2 -translate-y-1/2 z-30">
    <penji-spinner [loading_width]="20"></penji-spinner>
  </div>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="max-h-96 overflow-auto !py-0 dark:bg-gray-700 dark:text-white dark:[&_.item-title]:text-white">
    <li id="top-dropdown-search"></li>
    <ng-container *ngIf="list$ | async as vm">
      <ng-container *ngIf="vm.list_user && vm.list_user.length > 0">
        <li nz-menu-item class="cursor-default font-semibold text-gray-500 dark:text-white !bg-transparent px-5 py-2">
          User
        </li>
        <ng-container *ngFor="let user of vm.list_user">
          <ng-container *ngIf="!select">
            <ng-container [ngSwitch]="user.role">
              <ng-container *ngSwitchCase="'3'">
                <li nz-menu-item class="item-title px-5 py-2.5 capitalize space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
                  (click)="onAddKeyword('customer',user?.user_info?.first_name + ' ' +user?.user_info?.last_name, user?.id)">
                  <penji-user-item-compress [user_id]="user.id" [show_user_avatar]="true" [show_user_name]="true"
                    [show_user_role]="true"></penji-user-item-compress>
                </li>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <li nz-menu-item class="item-title px-5 py-2.5 capitalize space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
                  (click)="onAddKeyword('member',user?.user_info?.first_name + ' ' +user?.user_info?.last_name, user?.id)">
                  <penji-user-item-compress [user_id]="user.id" [show_user_avatar]="true" [show_user_name]="true"
                    [show_user_role]="true"></penji-user-item-compress>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
          <li nz-menu-item *ngIf="select"
            class="item-title px-5 py-2.5 capitalize space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
            (click)="selectUser(user?.id)">
            <penji-user-item-compress [user_id]="user.id" [show_user_avatar]="true" [show_user_name]="true"
              [show_user_role]="true"></penji-user-item-compress>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="vm.list_project && vm.list_project.length > 0">
        <li nz-menu-item class="cursor-default font-semibold text-gray-500 dark:text-white !bg-transparent px-5 py-2">Project</li>
        <li nz-menu-item *ngFor="let project of vm.list_project"
          class="item-title px-5 py-2.5 space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
          (click)="onAddKeyword('project', project.title, '', project?.id)">
          <div class="flex w-full items-center justify-between">
            <div class="flex space-x-3">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#E9F9EF" />
                <mask id="mask0_5542_1218" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20"
                  height="20">
                  <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_5542_1218)">
                  <path
                    d="M11.8333 20.1667H17.6667V18.5H11.8333V20.1667ZM11.8333 16.8333H20.1667V15.1667H11.8333V16.8333ZM11.8333 13.5H20.1667V11.8333H11.8333V13.5ZM10.1667 23.5C9.70833 23.5 9.31597 23.3368 8.98958 23.0104C8.66319 22.684 8.5 22.2917 8.5 21.8333V10.1667C8.5 9.70833 8.66319 9.31597 8.98958 8.98958C9.31597 8.66319 9.70833 8.5 10.1667 8.5H21.8333C22.2917 8.5 22.684 8.66319 23.0104 8.98958C23.3368 9.31597 23.5 9.70833 23.5 10.1667V21.8333C23.5 22.2917 23.3368 22.684 23.0104 23.0104C22.684 23.3368 22.2917 23.5 21.8333 23.5H10.1667ZM10.1667 21.8333H21.8333V10.1667H10.1667V21.8333Z"
                    fill="#22C55E" />
                </g>
              </svg>
              <div class="flex flex-col">
                <span class="capitalize dark:text-white font-semibold max-w-[400px] text-gray-800 truncate"
                  [innerHTML]="project.title"></span>
                <div class="flex space-x-1">
                  <span class="text-gray-500 dark:text-white">Project added by</span>
                  <penji-user-item-compress [user_id]="project.owner_id"
                    [show_user_name]="true"></penji-user-item-compress>
                  <span>on {{$any(project).created_at | date : 'MMMM d, y'}}</span>
                </div>
              </div>
            </div>
            <penji-project-status [project_data]="project"></penji-project-status>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="vm.list_ticket && vm.list_ticket.length > 0">
        <li nz-menu-item class="cursor-default font-semibold text-gray-500 dark:text-white !bg-transparent px-5 py-2">Tickets</li>
        <li nz-menu-item *ngFor="let ticket of vm.list_ticket"
          class="item-title px-5 py-2.5 capitalize space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
          (click)="onAddKeyword('ticket', ticket.index_number, '', '', ticket?.id)">
          <b>
            {{ticket?.index_number?('00000' + ticket?.index_number).slice(-6):'Loading..'}}
          </b>
          <span>-</span>
          <penji-ticket-cate-item [ticket]="ticket" [clickable]="true"></penji-ticket-cate-item>
        </li>
      </ng-container>
      <ng-container *ngIf="vm.list_workspace && vm.list_workspace.length > 0">
        <li nz-menu-item class="cursor-default font-semibold text-purple-500 !bg-transparent px-5 py-2">Workspace</li>
        <li nz-menu-item *ngFor="let workspace of vm.list_workspace"
          class="item-title px-5 py-2.5 capitalize space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
          (click)="onAddKeyword('workspace', workspace.title!, '', '' , '', workspace.team_id)">
          <div class="flex space-x-3 items-center">
            <penji-workspace-item-v2 [client_team_id]="workspace.team_id" [show_workspace_avatar]="true" [show_workspace_name]="true" [show_workspace_level]="true" [show_workspace_level_on_left]="true" [show_border_box_workspace]="true" [padding_border]="'p-1.5'" [clickable]="true" workspace_classes="!max-w-[150px]" [show_workspace_status]="true" class="[&_.wsp-status]:text-[10px]"></penji-workspace-item-v2>
          </div>
        </li>
      </ng-container>
      <!-- No data -->
      <ng-container
        *ngIf="!isLoading && search_control.value !== '' && vm.list_user.length === 0 && vm.list_project.length === 0 && vm.list_ticket.length === 0">
        <nz-empty class="height-empty px-4 py-5" nzNotFoundImage="simple"></nz-empty>
      </ng-container>
    </ng-container>
    <!-- loading -->
    <ng-container *ngIf="isLoading">
      <div class="px-5 py-2">
        <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 6, width: '100%' }"></nz-skeleton>
      </div>
    </ng-container>
    <!-- Suggestion -->
    <ng-container *ngIf="suggestion">
      <ng-container *ngIf="suggest_search$ | async as suggest_search">
        <li nz-menu-item class="cursor-default font-semibold text-gray-500 dark:text-white !bg-transparent px-5 py-2">Recent
          searches:</li>
        <ng-container *ngFor="let item of suggest_search">
          <li nz-menu-item class="item-title px-5 py-2.5 capitalize space-x-1.5 item-down [&.focus]:bg-gray-100 dark:[&.focus]:bg-gray-500"
            (click)="clickSuggest(item)">
            <ng-container *ngIf="item?.type === 'customer'">
              <penji-user-item-compress [user_id]="item.user_id" [show_user_avatar]="true" [show_user_name]="true"
                [show_user_role]="true"></penji-user-item-compress>
            </ng-container>
            <ng-container *ngIf="item?.type === 'member'">
              <penji-user-item-compress [user_id]="item.user_id" [show_user_avatar]="true" [show_user_name]="true"
                [show_user_role]="true"></penji-user-item-compress>
            </ng-container>
            <ng-container *ngIf="item?.type === 'project'">
              <div class="flex space-x-3 items-center">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="16" cy="16" r="16" fill="#E9F9EF" />
                  <mask id="mask0_5542_1218" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="6" y="6" width="20"
                    height="20">
                    <rect x="6" y="6" width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_5542_1218)">
                    <path
                      d="M11.8333 20.1667H17.6667V18.5H11.8333V20.1667ZM11.8333 16.8333H20.1667V15.1667H11.8333V16.8333ZM11.8333 13.5H20.1667V11.8333H11.8333V13.5ZM10.1667 23.5C9.70833 23.5 9.31597 23.3368 8.98958 23.0104C8.66319 22.684 8.5 22.2917 8.5 21.8333V10.1667C8.5 9.70833 8.66319 9.31597 8.98958 8.98958C9.31597 8.66319 9.70833 8.5 10.1667 8.5H21.8333C22.2917 8.5 22.684 8.66319 23.0104 8.98958C23.3368 9.31597 23.5 9.70833 23.5 10.1667V21.8333C23.5 22.2917 23.3368 22.684 23.0104 23.0104C22.684 23.3368 22.2917 23.5 21.8333 23.5H10.1667ZM10.1667 21.8333H21.8333V10.1667H10.1667V21.8333Z"
                      fill="#22C55E" />
                  </g>
                </svg>
                <div class="flex flex-col">
                  <span class="capitalize dark:text-white font-semibold max-w-[400px] text-gray-800 truncate"
                    [innerHTML]="item.title"></span>
                  <span class="text-gray-500 dark:text-white">Project</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item?.type === 'workspace'">
              <penji-workspace-item-v2 [client_team_id]="item.client_team_id" [show_workspace_avatar]="true" [show_workspace_name]="true" [show_workspace_level]="true" [show_workspace_level_on_left]="true" [show_border_box_workspace]="true" [padding_border]="'p-1.5'" [clickable]="true" workspace_classes="!max-w-[150px]" [show_workspace_status]="true" class="[&_.wsp-status]:text-[10px]"></penji-workspace-item-v2>
            </ng-container>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
  <div *ngIf="hotkey" class="p-5 border-t flex space-x-14 dark:border-t-gray-500">
    <div class="space-x-2">
      <button disabled class="p-2 bg-gray-100 rounded-lg border border-gray-200"><svg width="12" height="12"
          viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.705 4.29498L6 6.58498L8.295 4.29498L9 4.99998L6 7.99998L3 4.99998L3.705 4.29498Z"
            fill="#3F3F3F" />
        </svg>
      </button>
      <button disabled class="p-2 bg-gray-100 rounded-lg border border-gray-200 rotate-180"><svg width="12" height="12"
          viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.705 4.29498L6 6.58498L8.295 4.29498L9 4.99998L6 7.99998L3 4.99998L3.705 4.29498Z"
            fill="#3F3F3F" />
        </svg>
      </button>
      <span class="dark:text-gray-200">To navigate</span>
    </div>
    <div class="space-x-2">
      <button disabled class="p-2 bg-gray-100 rounded-lg border border-gray-200">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.5 3.5V5.5H2.915L4.705 3.705L4 3L1 6L4 9L4.705 8.295L2.915 6.5H10.5V3.5H9.5Z" fill="#3F3F3F" />
        </svg>
      </button>
      <span class="dark:text-gray-200">To select</span>
    </div>
  </div>
</nz-dropdown-menu>
