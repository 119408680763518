import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { DrawerService, Project } from '@penji/shared/data-access';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { RouterModule } from '@angular/router';
import { RouterLinkDisabledDirective } from '@penji/shared/directives';

@Component({
  selector: 'penji-project-name',
  standalone: true,
  imports: [
    CommonModule,
    NzToolTipModule,
    CopyItemComponent,
    RouterModule,
    RouterLinkDisabledDirective
  ],
  templateUrl: './project-name.component.html',
  styleUrls: ['./project-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectNameComponent {

  @Input() project_data!: Project;
  @Input() clickable = true;
  @Input() classes = '';

  drawerSv = inject(DrawerService);

  openDrawer(event: any, id: string) {
    if (!(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      this.drawerSv.openDrawer({ type: 'project', id });
    }
  }
}
