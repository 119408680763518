import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService, DesignerTeamService, TrackingDataService, WhereQueryInterface } from '@penji/shared/data-access';
import { differenceInCalendarDays } from 'date-fns';
import * as firebase from 'firebase/firestore';
import { InViewportAction } from 'ng-in-viewport';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { combineLatest, EMPTY, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'penji-w-tracking-data',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    FormsModule,
    NzDatePickerModule,
    NzSpinModule
  ],
  templateUrl: './w-tracking-data.component.html',
  styleUrls: ['./w-tracking-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WTrackingDataComponent implements OnInit {

  private readonly trackingDataSV = inject(TrackingDataService);
  private readonly designerTeamSV = inject(DesignerTeamService);
  private readonly apiSV = inject(ApiService);

  container_height = 0;
  show_view_port: any = {};

  data$!: Promise<any>;
  total_assign$!: Observable<any>;
  total_verify$!: Observable<any>;

  teams$ = combineLatest(
    this.designerTeamSV.getDesignerTeamDetail('3YhGrbyz9Ed4X1wqR1iy'),
    this.designerTeamSV.getDesignerTeamDetail('vYG4gFUZj6BRKyzOY4ML'),
    this.designerTeamSV.getDesignerTeamDetail('AlUsWrjTcwJ63T9EwS5A')
  ).pipe(map(([a, b, west]) => {
    return [a, b, west];
  }));

  date = new Date();
  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) > 0;

  onChangeDate(result: Date): void {
    if (result) {
      this.getTrackingDataV3(result);
    }
  }

  ngOnInit() {
    this.getTrackingDataV3(this.date);
  }

  getTrackingDataV3(date: Date) {
    const where_query: WhereQueryInterface[] = [
      {
        field_name: 'log_created_at',
        field_operator: '>=',
        field_value: firebase.Timestamp.fromDate(new Date(date.setHours(0, 0, 0, 0)))
      },
      {
        field_name: 'log_created_at',
        field_operator: '<=',
        field_value: firebase.Timestamp.fromDate(new Date(date.setHours(23, 59, 59, 999)))
      }
    ];

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    this.total_assign$ = combineLatest(
      // this.apiSV.getTotalAssign('team_designer', '3YhGrbyz9Ed4X1wqR1iy', formattedDate, formattedDate),
      // this.apiSV.getTotalAssign('team_designer', 'vYG4gFUZj6BRKyzOY4ML', formattedDate, formattedDate),
      // this.apiSV.getTotalAssign('team_designer', 'AlUsWrjTcwJ63T9EwS5A', formattedDate, formattedDate)
      this.apiSV.getTrackingData('total-project-active', '3YhGrbyz9Ed4X1wqR1iy', formattedDate, formattedDate, 'team_designer'),
      this.apiSV.getTrackingData('total-project-active', 'vYG4gFUZj6BRKyzOY4ML', formattedDate, formattedDate, 'team_designer'),
      this.apiSV.getTrackingData('total-project-active', 'AlUsWrjTcwJ63T9EwS5A', formattedDate, formattedDate, 'team_designer'),
    ).pipe(switchMap(([rs1, rs2, rs3]) => {
      console.log(rs1, rs2, rs3)
      const obj: any = {};
      if (rs1.flag) {
        obj['assign_project_group_a'] = rs1.data;
      }
      if (rs2.flag) {
        obj['assign_project_group_b'] = rs2.data;
      }
      if (rs3.flag) {
        obj['assign_project_west'] = rs3.data;
      }
      return of(obj);
    }))

    this.total_verify$ = combineLatest(
      // this.apiSV.getTotalVerify('team_designer', '3YhGrbyz9Ed4X1wqR1iy', formattedDate, formattedDate),
      // this.apiSV.getTotalVerify('team_designer', 'vYG4gFUZj6BRKyzOY4ML', formattedDate, formattedDate),
      // this.apiSV.getTotalVerify('team_designer', 'AlUsWrjTcwJ63T9EwS5A', formattedDate, formattedDate)
      this.apiSV.getTrackingData('total-design-verify', '3YhGrbyz9Ed4X1wqR1iy', formattedDate, formattedDate, 'team_designer'),
      this.apiSV.getTrackingData('total-design-verify', 'vYG4gFUZj6BRKyzOY4ML', formattedDate, formattedDate, 'team_designer'),
      this.apiSV.getTrackingData('total-design-verify', 'AlUsWrjTcwJ63T9EwS5A', formattedDate, formattedDate, 'team_designer'),
    ).pipe(switchMap(([rs1, rs2, rs3]) => {
      console.log(rs1, rs2, rs3)
      const obj: any = {};
      if (rs1.flag) {
        obj['verify_group_a'] = rs1.data;
      }
      if (rs2.flag) {
        obj['verify_group_b'] = rs2.data;
      }
      if (rs3.flag) {
        obj['verify_group_west'] = rs3.data;
      }
      return of(obj);
    }))

    this.data$ = this.trackingDataSV.listTrackingDataV3(-1, where_query).then(rs => {
      const obj: any = {};

      obj['completed_group_a'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 6 && r.assign_team_designer == '3YhGrbyz9Ed4X1wqR1iy').length;
      obj['completed_group_b'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 6 && r.assign_team_designer == 'vYG4gFUZj6BRKyzOY4ML').length;
      obj['completed_west'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 6 && r.assign_team_designer == 'AlUsWrjTcwJ63T9EwS5A').length;

      obj['revision_group_a'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 2 && r.assign_team_designer == '3YhGrbyz9Ed4X1wqR1iy').length;
      obj['revision_group_b'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 2 && r.assign_team_designer == 'vYG4gFUZj6BRKyzOY4ML').length;
      obj['revision_west'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 2 && r.assign_team_designer == 'AlUsWrjTcwJ63T9EwS5A').length;

      obj['delivered_group_a'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 4 && r.assign_team_designer == '3YhGrbyz9Ed4X1wqR1iy').length;
      obj['delivered_group_b'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 4 && r.assign_team_designer == 'vYG4gFUZj6BRKyzOY4ML').length;
      obj['delivered_west'] = rs.filter(r => r.fields_updated.includes('status') && r.status == 4 && r.assign_team_designer == 'AlUsWrjTcwJ63T9EwS5A').length;

      return obj;
    }).catch(err => console.log(err));
  }

  getHeight(height: any){
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
}
