/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { WorkspaceAdvice, WorkspaceAdvicesService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, of, switchMap, tap } from 'rxjs';

interface WorkspaceAdviceState extends EntityState<WorkspaceAdvice> {
    loading: boolean,
    error: string
}
const adapter = createEntityAdapter<WorkspaceAdvice>();
const initialState: WorkspaceAdviceState = adapter.getInitialState({
    loading: false,
    error: ''
})
@Injectable()
export class WorkspaceAdvicesStoreService extends ComponentStore<WorkspaceAdviceState> {

    private wspAdviceSV = inject(WorkspaceAdvicesService);
    constructor() { super(initialState); }
    data$ = this.select(s => {
        const list = Object.values(s.entities) as WorkspaceAdvice[];
        return list.sort((a: any, b: any) => {
            if (!a.pinned_at && !b.pinned_at) return 0; // Both objects don't have pinned_at
            if (!a.pinned_at) return 1; // a doesn't have pinned_at, put a after b
            if (!b.pinned_at) return -1; // b doesn't have pinned_at, put b after a
            return a.pinned_at - b.pinned_at; // Compare pinned_at if both exist
        });
        // return list.sort((a: any, b: any) => (a.created_at < b.created_at) ? 1 : -1);
    });
    listNote$ = this.select(s => {
        const list = Object.values(s.entities) as WorkspaceAdvice[];
        return list.filter((item) => !item.for_designer).sort((a: any, b: any) => {
            if (!a.pinned_at && !b.pinned_at) return 0; // Both objects don't have pinned_at
            if (!a.pinned_at) return 1; // a doesn't have pinned_at, put a after b
            if (!b.pinned_at) return -1; // b doesn't have pinned_at, put b after a
            return a.pinned_at - b.pinned_at; // Compare pinned_at if both exist
        });
        // return list.filter((item) => !item.for_designer).sort((a: any, b: any) => (a.created_at < b.created_at) ? 1 : -1);
    })
    listAdvice$ = this.select(s => {
        const list = Object.values(s.entities) as WorkspaceAdvice[];
        return list.filter((item) => item.for_designer).sort((a: any, b: any) => {
            if (!a.pinned_at && !b.pinned_at) return 0; // Both objects don't have pinned_at
            if (!a.pinned_at) return 1; // a doesn't have pinned_at, put a after b
            if (!b.pinned_at) return -1; // b doesn't have pinned_at, put b after a
            return a.pinned_at - b.pinned_at; // Compare pinned_at if both exist
        });
        // return list.filter((item) => item.for_designer).sort((a: any, b: any) => (a.created_at < b.created_at) ? 1 : -1);
    })
    isLoading$ = this.select(s=>s.loading);
    error$ = this.select((s) => s.error);

    loadWorkspaceAdvices$ = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            tap(() => {
                this.patchState({ loading: true, error: ''});
            }),
            switchMap((params: any) => {
                // console.log(params);
                return this.wspAdviceSV.getListWorkspaceAdvices(params['client_team_id'], params['limit']?params['limit']:-1);
            }),
            map((list) => {
                this.setState(state=> adapter.setAll(list, state));
                this.patchState({ loading: false, error: ''});
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    });
    crudWorkspaceAdvice$ = this.effect((params$: Observable<{ action?: string, workspaceAdvice?: WorkspaceAdvice }>) => {
        return params$.pipe(
            tap(()=>{
                this.patchState({ loading: true, error: '' });
            }),
            switchMap((params) => {
            //   console.log(params);
              
                if (params.action && params.workspaceAdvice){
                    if (params.action === 'create')
                        return combineLatest(of(params), this.wspAdviceSV.createWorkspaceAdvice(params.workspaceAdvice));
                    else if (params.action === 'update') {
                        return combineLatest(of(params), this.wspAdviceSV.updateWorkspaceAdvice(params.workspaceAdvice));
                    } else if (params.action === 'unpin') {
                        return combineLatest(of(params), this.wspAdviceSV.unPinWorkspaceAdvice(params.workspaceAdvice));
                    } else if (params.action === 'delete') {
                        return combineLatest(of(params), this.wspAdviceSV.deleteWorkspaceAdvice(params.workspaceAdvice.id!));
                    }
                }
                return of();

            }),
            map(([params, rs]) => {
                if (rs.flag) {
                    if (params && params.workspaceAdvice) {
                        if(params.action === 'create'){
                            this.setState((state)=> adapter.setOne(rs.data, state));
                        } else if (params.action === 'update' || params.action === 'unpin') {
                            this.setState((state) => adapter.updateOne({ id: params.workspaceAdvice!.id!, changes: params.workspaceAdvice! }, state));
                        } else if (params.action === 'delete') {
                            this.setState((state) => adapter.removeOne(params.workspaceAdvice!.id!, state));
                        }
                    }
                    this.patchState({ loading: false, error: '' });
                } else {
                    this.patchState({ loading: false, error: rs.message });
                }
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    });
}
