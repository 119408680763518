import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Team } from '@penji/shared/data-access';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@Component({
  selector: 'penji-workspace-label',
  standalone: true,
  imports: [
    CommonModule,
    NzEmptyModule
  ],
  templateUrl: './workspace-label.component.html',
  styleUrls: ['./workspace-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceLabelComponent {

  @Input() workspace_data?: Team;

}
