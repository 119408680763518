
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";
import { Category } from "@penji/shared/data-access";

//Store
export const createCategorySuccess = createAction('[Category] Create Category Success', props<{category: Category}>());
export const createCateAndClass = createAction('[Category] Create Category And Class', props<{category_class: any[]}>());
export const updateCategory = createAction('[Category] Update Category', props<{update: Update<Category>}>())
//Database
export const createCategoryDB = createAction('[Category] Create Category', props<{class_id: string, category: Category}>());
export const loadCateAndClassDB = createAction('[Category] Load All Category Class');
export const updateCategoryDB = createAction('[Category] Update Category BD', props<{class_id: string, update: Update<Category>}>())
//Nothing
export const nothing = createAction('[Category] Nothing To Do');
