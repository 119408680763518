<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
  <div class="flex justify-between items-center pb-2 pr-20">
    <h6 class="text-gray-600 capitalize dark:text-white">List Request Off</h6>
    <a [routerLink]="['/hr-management']" class="view-all !text-blue-500 hover:!text-blue-600 hidden">View All</a>
  </div>
  <ng-container *ngIf="{list_request_off:list_request_off$ | async} as vm">
    <ng-container *ngIf="vm.list_request_off; else loading">
      <nz-table #table_list [nzData]="vm.list_request_off" [nzBordered]="false" [nzFrontPagination]="false"
        [nzShowPagination]="false" [nzSize]="'middle'" class="theme theme-default"
        [nzScroll]="{ x: '36.146vw', y: container_height - 120+ 'px'}">
        <thead>
          <tr>
            <th nzLeft nzWidth="240px">User Name</th>
            <th nzWidth="125px" [nzSortFn]="sortOfAt">Day Off</th>
            <th nzWidth="75px" [nzFilters]="lisPtoFilter" [nzFilterFn]="filterPto">
              Type
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
            <tr>
              <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
                <td nzLeft>
                  <div class="flex items-center justify-between relative pl-6">
                    <a (click)="expandRequestOff(item.id)"
                      class="flex items-center justify-center absolute left-0 top-1/2 -translate-y-1/2 text-blue-500">
                      <span class="material-icons-outlined">{{expand.has(item.id) ? 'keyboard_double_arrow_down' :
                        'keyboard_double_arrow_right'}}</span>
                    </a>
                    <penji-user-item-compress [user_id]="item.uid" [show_user_avatar]="true" [show_user_name]="true"
                      [clickable]="true"></penji-user-item-compress>
                  </div>
                </td>
                <td>
                  <a class="line-clamp-1 dark:text-gray-200" nz-tooltip
                    [nzTooltipTitle]="item.off_at?(((item.off_at.toDate() | convertTimezone: 'UTC') + '') | date:'mediumDate') : 'N/A'">
                    {{item.off_at?(((item.off_at.toDate() | convertTimezone: 'UTC') + '') | date:'mediumDate') : 'N/A'}}
                  </a>
                </td>
                <td>
                  <penji-member-checkin-status [checkin_status]="item.pto"></penji-member-checkin-status>
                </td>
                <td>
                  <div class="w-full flex space-x-1 justify-center">
                    <ng-container *ngIf="item.approved === 0">
                      <penji-request-off-button-update [action]="'approve'"
                        [request_off]="item"></penji-request-off-button-update>
                      <penji-request-off-button-update [action]="'reject'"
                        [request_off]="item"></penji-request-off-button-update>
                    </ng-container>
                  </div>
                </td>
              </ng-container>
            </tr>
            <tr [nzExpand]="expand.has(item.id)">
              <p><b>Reasons:</b> {{ item.reason }}</p>
            </tr>
            <ng-template #loading_tr>
              <td [colSpan]="4" inViewport (inViewportAction)="viewPort($event, item.id)">
                <nz-skeleton [nzActive]="true" [nzTitle]="false"
                  [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
              </td>
            </ng-template>
          </ng-container>
        </tbody>
      </nz-table>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>
</div>