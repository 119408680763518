import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';

export class Membership {
    id?: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    active?: number; // 0: cancelled, 1: active, 2: pastdue
    end_date!: Timestamp;
    cancel_at_pirod_end!: boolean;
    metadata: any;
    log_type = 'membership';
    price_id!: string;
    product_name!: string;
    sub_id!: string;
    team_id!: string;
    coupon?: string;
    trialing?: boolean;
    product_price?: number;
    static parseObject(data: any) {
        const transform: any = {};
        for (const key in data) {
          const value = data[key];
          if (Array.isArray(data[key])) {
            transform[key] = value;
          } else if (typeof data[key] === 'object') {
            for (const keyDeep in data[key]) {
              if (key === 'metadata') {
                transform[`metadata.${keyDeep}`] = data[key][keyDeep];
              } else{
                transform[key] = value;
              }
            }
          } else {
            transform[key] = value;
          }
        }
        return transform;
    }
}
