/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { WorkSpaceDetailStoreService } from '@penji/team/workspace/data-access';
import { Observable } from 'rxjs';
import { Team } from '@penji/shared/data-access';

@Component({
  selector: 'penji-workspace-level-v2',
  standalone: true,
  imports: [
    CommonModule,
    NzToolTipModule,
    NzSkeletonModule
  ],
  templateUrl: './workspace-level-v2.component.html',
  styleUrls: ['./workspace-level-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceLevelV2Component implements OnChanges {

  @Input() client_team_id?: string;
  @Input() classes: string = '';

  private readonly wspDetailStore = inject(WorkSpaceDetailStoreService);

  workspace_data$?: Observable<Team>;

  ngOnChanges(): void {
    if (this.client_team_id) {
      this.workspace_data$ = this.wspDetailStore.data$(this.client_team_id);
    }
  }
  
}
