import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { CheckMembershipService, Plan } from '@penji/shared/data-access';
import { environment } from '@penji/shared/environments';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { BehaviorSubject, combineLatest, map, throttleTime } from 'rxjs';

interface ProrationPreview {
  price: number;
  real_amount: number;
}
@Component({
  selector: 'penji-profile-confirm-checkout',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzSkeletonModule,
    NzAlertModule,
    NzInputModule,
    FormsModule,
  ],
  templateUrl: './profile-confirm-checkout.component.html',
  styleUrls: ['./profile-confirm-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileConfirmCheckoutComponent {
  @Input() plan!: Plan;

  private readonly http = inject(HttpClient);
  private readonly afAuth = inject(AngularFireAuth);
  private readonly customerTeamStoreSV = inject(CustomerTeamStoreService);
  private readonly checkMembershipSV = inject(CheckMembershipService);
  private readonly membershipStore = inject(MembershipStoreService);
  private readonly modal = inject(NzModalService);
  private readonly router = inject(Router);
  private readonly authStore = inject(AuthStore);
  
  auth_id$ = this.authStore.auth_id$;

  api_checkout = environment.api_checkout;
  payment_source$: Promise<any> | undefined;
  promo_code = '';
  confirm_disabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  proration_preview$: BehaviorSubject<ProrationPreview> =
    new BehaviorSubject<ProrationPreview>({
      price: 0,
      real_amount: 0,
    });

  checkMembership$ = combineLatest(
    this.afAuth.idToken,
    this.customerTeamStoreSV.team_id$,
    this.customerTeamStoreSV.team_data$,
    this.checkMembershipSV.checkMembership('sub_design'),
    this.membershipStore.list_sub_design$
  ).pipe(
    map(([token, team_id, team_data, active, list_sub_design]) => {
      if (token && team_id) {
        this.payment_source$ = this.customerSourceAPI(token, team_id);
        if (list_sub_design[0].sub_id && this.plan.plan_id)
          this.checkPromoCode(
            token,
            team_id,
            list_sub_design[0].sub_id,
            this.plan.plan_id,
            ''
          );
      }
      return {
        token: token,
        team_id: team_id,
        owner_id: team_data.owner_id,
        active: active,
        list_sub_design: list_sub_design,
      };
    })
  );

  customerSourceAPI(token: string, team_id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    const data = { team_id: team_id };
    return this.http
      .post(this.api_checkout + '/customer-source', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((error) => error);
  }

  previewUpcomingInvoiceV2API(
    token: string | null,
    team_id: string,
    sub_id: string,
    basic_price_id: string,
    coupon_id: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    const data = {
      team_id: team_id || '',
      sub_id: sub_id || '',
      basic_price_id: basic_price_id || '',
      coupon_id: coupon_id || '',
    };
    console.log(data);
    return this.http
      .post(
        this.api_checkout + '/preview-invoice-change-price-v2',
        data,
        httpOptions
      )
      .pipe(throttleTime(1500))
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((error) => error);
  }

  checkPromoCode(
    token: string,
    team_id: string,
    sub_id: string,
    basic_price_id: string,
    coupon_id: string
  ) {
    if (token && team_id && sub_id && basic_price_id) {
      this.confirm_disabled$.next(true);
      this.previewUpcomingInvoiceV2API(
        token,
        team_id,
        sub_id,
        basic_price_id,
        coupon_id.toUpperCase()
      ).then((res) => {
        console.log(res);
        if (res.success) {
          this.proration_preview$.next({
            price:
              this.plan.plan_base /
              (this.plan.plan_duration_month
                ? this.plan.plan_duration_month
                : 1),
            real_amount:
              parseInt(res.data) / 100 >= 0 ? parseInt(res.data) / 100 : 0,
          });
        } else {
          this.promo_code = '';
          this.proration_preview$.next({
            price:
              this.plan.plan_base /
              (this.plan.plan_duration_month
                ? this.plan.plan_duration_month
                : 1),
            real_amount:
              this.plan.plan_base /
              (this.plan.plan_duration_month
                ? this.plan.plan_duration_month
                : 1),
          });
        }
        this.confirm_disabled$.next(false);
      });
    }
  }

  updatePriceSubscriptionv2API(
    token: string | null,
    sub_id: string,
    basic_price_id: string,
    coupon_id: string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    const data = {
      sub_id: sub_id || '',
      basic_price_id: basic_price_id || '',
      coupon_id: coupon_id || '',
    };
    return this.http
      .post(
        this.api_checkout + '/update-price-subscription-v2',
        data,
        httpOptions
      )
      .pipe(throttleTime(1500))
      .toPromise()
      .then((res) => {
        return res;
      })
      .catch((error) => error);
  }

  confirmCheckout(
    token: string | null,
    sub_id: string,
    basic_price_id: string,
    coupon_id: string
  ) {
    if (token && sub_id && basic_price_id) {
      this.confirm_disabled$.next(true);
      this.updatePriceSubscriptionv2API(
        token,
        sub_id,
        basic_price_id,
        coupon_id.toUpperCase()
      ).then((res) => {
        if (res.success) {
          this.modal.closeAll();
          console.log(res);
        }
      });
      this.confirm_disabled$.next(false);
    }
  }

  changeCard(team_id?: string) {
    this.modal.closeAll();
    if (team_id) this.router.navigate([`/t/${team_id}/settings/billing`]);
  }
}
