import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, combineLatest, from, map, of, switchMap, tap } from 'rxjs';
import { MemberService, UserData, WhereQueryInterface } from '@penji/shared/data-access';
import { ProjectStoreService } from '@penji/team/project/data-access';
import * as firebase from 'firebase/firestore';
const now = firebase.Timestamp.now().toDate().getTime();
const temp = now - 24 * 60 * 60 * 1000;
const final = firebase.Timestamp.fromDate(new Date(temp));
@Component({
  selector: 'penji-squad-group-workload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './squad-group-workload.component.html',
  styleUrls: ['./squad-group-workload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectStoreService]
})
export class SquadGroupWorkloadComponent {
  @Input() squad_id: string | undefined;
  @Output() sum_workload = new EventEmitter<{}>();

  private memberSV = inject(MemberService);
  private projectStoreSV = inject(ProjectStoreService);;

  list_member$!: Observable<any>;
  sum$!: Observable<any>;

  list_project$ = this.projectStoreSV.data_widget$;


  ngOnInit(): void {
    // if (this.squad_id) {
    //   const where_query: WhereQueryInterface[] = [
    //     {
    //       field_name: 'squad_id', field_operator: '==', field_value: this.squad_id
    //     }
    //   ];

    //   this.list_member$ = this.memberSV.getListMemberRealTime( -1, where_query);
    //   this.sum$ = this.list_member$.pipe(
    //     map((rs) => {
    //       var total_active_project = 0;
    //       var total_output_project = 0;
    //       var total_user = 0;
    //       rs.forEach(
    //         item => {
    //           total_user+=1;
    //           if(item && item.total_active_project)
    //             total_active_project = total_active_project + item.total_active_project
    //           if(item && item.out_put_design)
    //             total_output_project = total_output_project + item.out_put_design
    //         }
    //       )
    //       this.sum_workload.emit({sum_active_project: total_active_project, sum_output_project: total_output_project, total_user: total_user});
    //       return {sum_active_project: total_active_project, sum_output_project: total_output_project};
    //     })
    //   )
    // }

    this.projectStoreSV.loadProjectsForWidget$(of(this.squad_id).pipe(
      switchMap((squad_id) => {
        let where_query: WhereQueryInterface[] = [];
        let order_by;
        let order_desc;

        where_query = [
          {
            field_name: 'status',
            field_operator: 'in',
            field_value: [1, 2, 4]
          },
          {
            field_name: 'in_queue',
            field_operator: '==',
            field_value: false
          },
          {
            field_name: 'pause',
            field_operator: '==',
            field_value: false
          },
        ]
        order_by = 'time_change';
        order_desc = true;

        where_query.push({
          field_name: 'squad_id',
          field_operator: 'array-contains',
          field_value: squad_id
        })

        return of({ where_query, order_by, order_desc });
      })
    ));
  }

  ngOnChanges(): void {
    if(this.squad_id) {
      const where_query: WhereQueryInterface[] = [
        {
          field_name: 'squad_id', field_operator: '==', field_value: this.squad_id
        }
      ];
      this.list_member$ = from(this.memberSV.getListMember( -1, where_query)).pipe(
         map((rs) => {
            var total_output_project = 0;
            var total_user = 0;
            rs.forEach(
              item => {
                total_user+=1;
                if(item && item.out_put_design)
                  total_output_project = total_output_project + item.out_put_design
              }
            )
            // this.sum_workload.emit({sum_active_project: total_active_project, sum_output_project: total_output_project, total_user: total_user});
            return {total_output_project: total_output_project, total_user: total_user};
          })
      )

      this.sum$ = combineLatest(this.list_project$, this.list_member$).pipe(
        map(([list_project, member_output]) => {
          const total_output_project = member_output.total_output_project;
          const total_user = member_output.total_user;
          let total_active_project = 0;
          if (list_project.length > 0) {
            // console.log(this.squad_id, list_project.filter((project: any) => project.status == 1).length);
            // console.log(this.squad_id, list_project.filter((project: any) => project.status == 2).length);
            // console.log(this.squad_id, list_project.filter((project: any) => project.status == 4 && project.time_change >= final).length);


            total_active_project = (list_project.filter((project: any) => project.status == 1).length ?? 0) +  (list_project.filter((project: any) => project.status == 2).length ?? 0) + (list_project.filter((project: any) => project.status == 4 && project.time_change >= final).length ?? 0);
          }
          this.sum_workload.emit({sum_active_project: total_active_project, sum_output_project: total_output_project, total_user: total_user});
          return {sum_active_project: total_active_project, sum_output_project: total_output_project, total_user: total_user};
        }
      ))
    }
  }
}
