import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'penji-user-role',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleComponent {
  @Input() user_role?: number;
  @Input() is_pm?: boolean;
  @Input() classes = '';
}
