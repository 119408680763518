<ng-container *ngIf="media$ | async as media">
    <ng-container *ngIf="media.url">
        <ng-container *ngIf="image_auto_fit; else no_fit">
            <img [src]="media.url" width="150" height="150" alt="penji" class="bg-gray-100 rounded" />
        </ng-container>
        <ng-template #no_fit>
            <img [src]="media.url" width="150" height="150" />
        </ng-template>
        <a *ngIf="clickable" class="absolute inset-0 z-50" (click)="openImage()"></a>
    </ng-container>
</ng-container>
