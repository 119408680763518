<ng-container *ngIf="{team_data: team_data$ |async , data: data$ | async} as vm">
    <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click" [nzClickHide]="false" class="group/wsp wsp-item relative w-full flex items-center justify-between py-2.5 px-6 border-t bg-gray-50 border-gray-100 hover:[&>*]:text-purple-600 transition-all duration-300 overflow-hidden dark:bg-gray-500 dark:border-gray-500 dark:hover:[&>*]:!text-blue-500">
        <ng-container *ngFor="let item of vm.data">
            <penji-workspace-item *ngIf="item?.team_id === vm.team_data?.id" class="w-full group-hover/wsp:[&>*]:!text-purple-600 dark:group-hover/wsp:[&>*]:!text-blue-500"
                [team_id]="item?.team_id"></penji-workspace-item>
        </ng-container>
        <span class="material-icons-outlined text-[20px] absolute top-1/2 -translate-y-1/2 right-1.5 group-hover/wsp:!text-purple-600 dark:group-hover/wsp:!text-blue-500 transition-all duration-200">
            chevron_right
        </span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable class="rounded-lg py-0 [&>li]:p-3 [&>li]:!pl-6 hover:[&>li]:bg-gray-100 w-[248px]
          [&>li:first-of-type]:rounded-[.25rem_.25rem_0_0] [&>li:last-of-type]:rounded-[0_0_.25rem_.25rem] [&>li]:border-gray-100 [&>li]:border-[1px] [&>li:not(:first-of-type)]:border-t-none dark:hover:[&>li]:bg-gray-500 dark:[&>li]:border-gray-500
          ">
            <ng-container *ngFor="let item of vm.data">
                <li nz-menu-item (click)="switchWorkspace(item)" class="{{item?.team_id === vm.team_data?.id?'!cursor-default !bg-gray-100 dark:!bg-gray-500':''}} gap-2 w-full relative overflow-hidden">
                    <div *ngIf="item?.team_id === vm.team_data?.id" class="bg-green-500 absolute w-10 h-5 -top-[3px] -left-[14px] -rotate-45">
                        <span title="Selected" class="material-icons-outlined !text-white text-[18px] rotate-45 absolute left-3 top-0.5">
                            done
                        </span>
                    </div>
                    <penji-workspace-item class="flex w-[calc(100%-22px)] [&_*]:!text-gray-700 dark:[&_*]:!text-white" [team_id]="item?.team_id"></penji-workspace-item>
                    <span *ngIf="item?.team_id === vm.team_data?.id" (click)="editWorkspace(item)" title="Edit" class="material-icons-outlined cursor-pointer text-[22px] text-gray-400 dark:text-gray-200">
                        edit
                    </span>
                </li>
            </ng-container>

            <!-- <li nz-menu-item (click)="addWorkspace()">
                <div class="flex items-center font-medium">
                    <span class="material-icons-outlined text-gray-400 mr-4">add</span> New workspace
                </div>
            </li> -->
        </ul>
    </nz-dropdown-menu>
</ng-container>
