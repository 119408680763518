import { DocumentData, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';

export class Checkin {
  id!: string;
  doc?: QueryDocumentSnapshot<DocumentData>;
  checkin_at!: Timestamp | null;
  checkout_at!: Timestamp | null;
  created_at!: Timestamp;
  lateness!: boolean;
  updated_by?: string;
  updated_at?: Timestamp;
  pto!: number; //0,1,2
  uid!: string;
  team_designer_id?: string;
  note?: string;
  log_type = 'checkin';
}

export class CheckinOld {
    id!: string;
    uid!: string;
    team_designer_id?: string;
    approved?: boolean;
    clock_in!: Timestamp | null;
    clock_out!: Timestamp | null;
    created_at!: Timestamp;
    new_project!: number;
    off!: boolean; 
    pto!: boolean; 
    revision_project!: number;
    time_work!: number;
    total_point!: number;
    lateness?: boolean;
    over_time?: boolean;
    note?: string;
    log_type = 'checkin';
}
// convert checkin to checkin_v2 ===================================
// IF: ((approved==false && off==true) || over_time==true) -> not move
// ELSE:
// export class Checkin {
//   id: string; -> (move)
//   uid: string; -> (move)
//   approved?: boolean; -> (delete)
//   clock_in: Timestamp; -> checkin_at
//   clock_out: Timestamp; -> checkout_at
//   created_at: Timestamp; -> (move)
//   new_project: number; -> (delete)
//   off: boolean; pto: boolean; -> pto ((off==true && pto==true) -> 1) || ((off==true && pto==false) -> 2) || (default: 0))
//   revision_project: number; -> (delete)
//   time_work: number; -> (delete)
//   total_point: number; -> (delete)
//   lateness?: boolean; -> (move)
//   over_time?: boolean; -> (delete)???
//   note?: string; -> (move)
//   log_type = 'checkin'; -> (add)
// }
// end convert checkin to checkin_v2 ===================================
