import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Timestamp } from '@firebase/firestore-types';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';

@Component({
  selector: 'penji-member-checkin-status',
  standalone: true,
  imports: [CommonModule, NzToolTipModule, NzTagModule],
  templateUrl: './member-checkin-status.component.html',
  styleUrls: ['./member-checkin-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberCheckinStatusComponent {
  // 0: Unavailable
  // 1: PTO
  // 2: UPTO
  // 3: Checkin
  // 4: Late
  // 5: Checkout

  @Input() checkin_status?: number = 0;
  @Input() checkin_at?: any;
  @Input() checkout_at?: any;
  @Input() disabled: boolean = false;
  @Input() type: 'dot' | 'text' | 'full' | 'default' = 'default';

}
