/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { MemberService, TeamService, WhereQueryInterface } from '@penji/shared/data-access';
import { SearchComponent } from '@penji/team/shared/shared-ui/search';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { BehaviorSubject, Observable, combineLatest, of, switchMap } from 'rxjs';

@Component({
  selector: 'penji-user-select',
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    NzCheckboxModule,
    FormsModule,
    NzButtonModule,
    NzGridModule,
    NzRadioModule,
    UserItemCompressComponent,
    NzEmptyModule
  ],
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectComponent {

  @Input() list_user_id!: string[];
  @Input() role!: number[];
  @Input() multiple_select?: boolean = true;
  @Input() required?: boolean = true;
  @Input() show_auth?: boolean = false;
  @Input() show_list_suggest = true;
  @Input() client_team_id?: string; // get list client_hate
  @Input() project_cat_id?: string; // get list suggest
  @Input() assign_team_designer?: string; // get list suggest
  @Input() query_params: string = '';
  @Output() list_user_id_output = new EventEmitter<string[]>();

  private readonly authStore = inject(AuthStore);
  private readonly teamSV = inject(TeamService);
  private readonly memberSV = inject(MemberService);
  private readonly router = inject(Router);

  uid$ = this.authStore.auth_id$;
  current_selected_user_id: string[] = [];
  is_loading = false;
  clients_hate$ = new BehaviorSubject<string[]>([]);
  clients_like$ = new BehaviorSubject<string[]>([]);
  list_suggest$: Observable<any[]> = of([]);
  list_search_result: string[] = [];

  ngOnChanges() {
    if (this.list_user_id) {
      this.current_selected_user_id = [...this.list_user_id];
    }
    if (this.client_team_id) {
      this.teamSV.getTeamDetail2(this.client_team_id).then(team => {
        if (team) {
          this.clients_hate$.next(team.clients_hate ?? []);
          this.clients_like$.next(team.clients_like ?? []);
        }
      })
    }
    console.log(this.project_cat_id, this.assign_team_designer)
    if (this.project_cat_id && this.assign_team_designer) {
      let where_query: WhereQueryInterface[] = [];
      where_query.push(
        {
          field_name: 'role',
          field_operator: '==',
          field_value: 4
        },
        {
          field_name: 'the_best',
          field_operator: 'array-contains',
          field_value: this.project_cat_id
        },
        {
          field_name: 'team_designer_active',
          field_operator: '==',
          field_value: this.assign_team_designer
        }
      )
      if (this.show_list_suggest) {
        this.list_suggest$ = combineLatest(this.memberSV.getListMember(-1, where_query), this.clients_like$).pipe(
          switchMap(([list_all, clients_like]) => {
            let temp: any[] = [];
            temp = list_all.filter(l => !this.list_user_id.includes(l.id!))
            temp.forEach((rs, index) => temp[index] = {
              ...rs,
              temp_sort: clients_like.includes(rs.id) ? 1 : 0
            });
            temp = temp.sort((a: any, b: any) => (a.temp_sort < b.temp_sort) ? 1 : -1);
            return of(temp);
          })
        );
      }
    }
  }

  selectUser(user_id: string) {
    if (this.multiple_select == false && this.query_params != '') {
      let obj: any = {};
      obj[this.query_params] = user_id;
      this.router.navigate([], { queryParams: obj });
    } else {
      if (this.list_search_result && !this.current_selected_user_id.includes(user_id)) {
        if (Array.isArray(this.list_search_result)) this.list_search_result.unshift(user_id);
      }
      if (this.multiple_select) {
        if (this.current_selected_user_id && !this.current_selected_user_id.includes(user_id)) {
          if (Array.isArray(this.current_selected_user_id)) this.current_selected_user_id.unshift(user_id);
        }
      } else {
        this.current_selected_user_id = [user_id];
      }
    }
  }

  onClickUser(list_user_id: string[]) {
    this.current_selected_user_id = [...list_user_id];
  }

  radioClick(value: any) {
    if (this.multiple_select == false && this.query_params != '') {
      let obj: any = {};
      obj[this.query_params] = value;
      this.router.navigate([], { queryParams: obj });
    }
    this.current_selected_user_id = [value]
  }

  onSubmit() {
    this.is_loading = true;
    this.list_user_id_output.emit(this.current_selected_user_id);
    this.is_loading = false;
  }
}
