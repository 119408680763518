import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'penji-workspace-discovery-phase',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './workspace-discovery-phase.component.html',
  styleUrls: ['./workspace-discovery-phase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceDiscoveryPhaseComponent {

  @Input() phase!: number;
}
