<ng-container *ngIf="{designer_team:designer_team$ | async} as vm">
  <ng-container *ngIf="vm.designer_team; else no_designer_team">
    <ng-container *ngIf="clickable; else clickdisable">
      <a (click)="openDrawer(vm.designer_team.id)">
        <span class="font-{{font_weight}} capitalize text-{{font_size}} {{classes || ''}} dark:text-white hover:!text-blue-600" nz-tooltip
          [nzTooltipTitle]="vm.designer_team.team_name?vm.designer_team.team_name:'N/A'">
          {{vm.designer_team.team_name}}
        </span>
      </a>
    </ng-container>
    <ng-template #clickdisable>
      <span class="font-{{font_weight}} capitalize text-{{font_size}} {{classes || ''}} dark:text-white" nz-tooltip
        [nzTooltipTitle]="vm.designer_team.team_name?vm.designer_team.team_name:'N/A'">
        {{vm.designer_team.team_name}}
      </span>
    </ng-template>
  </ng-container>
  <ng-template #no_designer_team>
    <span class="capitalize text-{{font_size}} {{classes || ''}} dark:!text-white !text-gray-400">
      No Team
    </span>
  </ng-template>
</ng-container>
