import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  theme$ = new BehaviorSubject<string>('light');

  setThemes(key: string, auth?: 'auth' | 'unauth') {
    const doc = document.documentElement;
    this.theme$.next(key);
    switch (key) {
      case 'light':
        if(auth) localStorage['theme'] = 'light';
        doc.classList.remove('dark');
        break;
      case 'dark':
        if(auth) localStorage['theme'] = 'dark';
        doc.classList.add('dark')
        break;
      case 'system':
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
          doc.classList.remove('dark');
        } else {
          doc.classList.add('dark')
        }
        window.matchMedia("(prefers-color-scheme: dark)").addListener(
          e => {
            if (e.matches)
            doc.classList.add('dark')
            else
              doc.classList.remove('dark');
          }
        );
        if(auth) localStorage.removeItem('theme');
        break;
      default:
        if(auth) localStorage['theme'] = 'light';
        doc.classList.remove('dark');
        break;
    }
    return this.theme$;
  }

}
