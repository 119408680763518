import { EntityAdapter } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';
import { Media, MediaState } from '@penji/shared/data-access';
import { createReducer, on } from '@ngrx/store';
import * as mediaActions from './media.action';

const adapter: EntityAdapter<Media> =  createEntityAdapter<Media>();
export const inititialMediaState: MediaState = adapter.getInitialState();

export const MediaReducer = createReducer(inititialMediaState,
  on(mediaActions.createMedia, (state, {media}) => {
    return adapter.addOne(media, state)
  }),
  on(mediaActions.deleteMedia, (state, {media_id}) => {
    return adapter.removeOne(media_id, state)
  })
);
