import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { WorkspaceDiscoveryPhaseComponent } from '@penji/team/workspace/workspace-ui/workspace-discovery-phase';
@Component({
  selector: 'penji-workspace-discovery-phase-tooltip',
  standalone: true,
  imports: [CommonModule, NzPopoverModule, WorkspaceDiscoveryPhaseComponent],
  templateUrl: './workspace-discovery-phase-tooltip.component.html',
  styleUrls: ['./workspace-discovery-phase-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceDiscoveryPhaseTooltipComponent {

  @Input() icon_size = 10;
  @Input() icon_color = '#64748B';
  
}
