/* eslint-disable */
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@penji/client-v3/shared/ui/layout';
import { RouterUtils } from '@penji/shared/utils';
import { CustomerTeamCanActiveGuard } from './customer-team-can-active.guard';
import { MoveV2Guard } from './move-v2.guard.service';
import { WorkspaceGuard } from './workspace.guard.service';
import { LayoutBlankComponent } from '@penji/client-v3/shared/ui/layout-blank';
import { CheckOnboardingGuard } from './check-onboarding.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/dashboard']);

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@penji/shared/auth/auth-ui/login').then(mod => mod.LoginComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: 'penji-login',
    loadComponent: () => import('@penji/shared/auth/auth-ui/penji-login').then(mod => mod.PenjiLoginComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: 'create-account/:token/:email',
    loadComponent: () => import('@penji/shared/auth/auth-ui/create-account').then(mod => mod.CreateAccountComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: '',
    loadComponent: () => import('@penji/client-v3/root').then(mod => mod.RootComponent),
    canActivate: [AngularFireAuthGuard, CustomerTeamCanActiveGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: `t/:${RouterUtils.Configuration.client_team_id}`,
        component: LayoutComponent,
        canActivate: [AngularFireAuthGuard, WorkspaceGuard],
        children: [
          {
            path: '',
            redirectTo: 'projects/unlimited/list/active',
            pathMatch: 'full'
          },
          {
            path: 'dashboard',
            loadComponent: () => import('@penji/client-v3/dashboard/ui/dashboard-layout').then(mod => mod.DashboardLayoutComponent),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'workflows',
            loadComponent: () => import('@penji/client-v3/workflows/ui/workflows-layout').then(mod => mod.WorkflowsLayoutComponent),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'projects',
            loadChildren: () => import('@penji/client-v3/projects/features').then(mod => mod.FeaturesModule),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: `project-detail/:${RouterUtils.Configuration.project_id}`,
            loadComponent: () => import('@penji/client-v3/projects/ui/project-detail-dashboard').then(mod => mod.ProjectDetailDashboardComponent)
          },
          {
            path: 'brands',
            loadChildren: () => import('@penji/client-v3/brands/features').then(mod => mod.FeaturesModule),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'teams',
            loadComponent: () => import('@penji/client-v3/team-members/ui/team-member-layout').then(mod => mod.TeamMemberLayoutComponent),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'settings',
            loadComponent: () => import('@penji/client-v3/profiles/ui/profile-layout').then(mod => mod.ProfileLayoutComponent),
            loadChildren: () => import('@penji/client-v3/profiles/features').then(mod => mod.FeaturesModule),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'courses',
            loadChildren: () => import('@penji/client-v3/courses/features').then(mod => mod.FeaturesModule),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'discover',
            loadComponent: () => import('@penji/client-v3/discover/ui/discover-list-layout').then(mod => mod.DiscoverListLayoutComponent),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'rewards',
            loadComponent: () => import('@penji/client-v3/reward/ui/reward-layout').then(mod => mod.RewardLayoutComponent),
            canActivate: [AngularFireAuthGuard],
          },
          {
            path: 'notification',
            loadComponent: () => import('@penji/client-v3/notifications/ui/notification-dashboard').then(mod=>mod.NotificationDashboardComponent)
          }
        ]
      },
    ]
  },
  {
    path: `t/:${RouterUtils.Configuration.client_team_id}`,
    component: LayoutBlankComponent,
    canActivate: [AngularFireAuthGuard],
    children: [
      {
        path: 'view-design',
        loadChildren: () => import('@penji/client-v3/view-design/features').then(mod => mod.FeaturesModule),
        canActivate: [AngularFireAuthGuard,]
      },
      {
        path: 'onboarding',
        loadComponent: () => import('@penji/client-v3/pages/ui/onboarding').then(com=>com.OnboardingComponent),
        canActivate: [AngularFireAuthGuard, CheckOnboardingGuard]
      },
    ]
  },
  {
    path: 'test',
    loadComponent: () => import('@penji/client-v3/test').then(com=>com.TestComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'components',
    loadComponent: () => import('@penji/client-v3/shared/ui/components').then(com=>com.ComponentsComponent),
  },
  {
    path: '404',
    loadComponent: () => import('@penji/client-v3/shared/ui/page404').then(com => com.Page404Component)
  },
  {
    path: `move-to-v2/:${RouterUtils.Configuration.client_team_id}`,
    loadComponent: () => import('@penji/client-v3/move-to-v2/ui/move-to-v2-layout').then(com => com.MoveToV2LayoutComponent),
    canActivate: [MoveV2Guard],
  },

  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class ClientShellRoutingModule { }
