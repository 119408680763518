import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { Comment } from '@penji/shared/data-access';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { CommentStoreService } from '@penji/team/view-design/data-access';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { of } from 'rxjs';

const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static PROTOCOL_WHITELIST: any;
  static sanitize(url:any) {
    const value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if(value.startsWith(this.PROTOCOL_WHITELIST[i]))
          return value;

        return `http://${value}`
    }
    return value;
  }
}
Quill.register(CustomLink);

@Component({
  selector: 'penji-view-design-input-reply',
  standalone: true,
  imports: [
    CommonModule,
    NzInputModule,
    NzButtonModule,
    UserItemCompressComponent,
    FormsModule,
    QuillModule
  ],
  templateUrl: './view-design-input-reply.component.html',
  styleUrls: ['./view-design-input-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDesignInputReplyComponent {

  @Input() auth_id?: string;
  @Input() comment?: any;

  private readonly commentStoreSv= inject(CommentStoreService);
  private readonly afs = inject(AngularFirestore);
  cdr = inject(ChangeDetectorRef);

  reply_text = '';

  modules = {
    keyboard: {
      bindings: {
        custom: {
          key: 'enter',
          shiftKey: false,
          handler: (range: any, context: any) => {
            if (this.reply_text&&this.reply_text!=='') {
              this.addReply();
            }
          }
        }
      }
    },
  }

  addReply(){
    if (this.reply_text.trim() == '') {
      return;
    }

    const comment = new Comment();
    comment.content = this.reply_text;
    comment.created_at = firebase.Timestamp.now();
    comment.updated_at = firebase.Timestamp.now();
    comment.owner_id = this.auth_id;
    comment.owner_ref = this.afs.firestore.collection('user').doc(this.auth_id);
    comment.id_link = this.comment.id_link;
    comment.marker = null;
    comment.removed = false;
    comment.version = 2;
    comment.parent_id = this.comment.id;
    comment.project_id = this.comment.project_id ? this.comment.project_id : null;
    comment.discussion_design_id = this.comment.discussion_design_id;
    // console.log(comment);
    this.commentStoreSv.addComment$(of({comment: comment}));
    this.cancelReply();
  }

  cancelReply(){
    this.reply_text = '';
    this.cdr.detectChanges();
  }

}
