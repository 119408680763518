<nz-layout class="main-layout h-screen w-screen bg-gray-100 overflow-y-auto relative dark:bg-gray-700" id="main_layout_clientv3" *ngIf="{show_sider:show_sider$ |async} as vm">
  <nz-header class="main-header bg-white border-b border-gray-200 sticky top-0 left-0 right-0 z-[70] !p-0 dark:bg-gray-700 dark:border-gray-500">
    <div class="container 2xl:max-w-[1920px] w-full flex items-center space-x-7 pr-7 pl-7 min-h-[64px]">
        <!-- <img *ngIf="siderCollapsed" src="../assets/images/menu-unfold.svg" class="w-[24px] dark:hidden">
        <img *ngIf="!siderCollapsed" src="../assets/images/menu-fold.svg" class="w-[24px] dark:hidden">
        <img *ngIf="siderCollapsed" src="../assets/images/menu-unfold-white.svg" class="w-[24px] hidden dark:block">
        <img *ngIf="!siderCollapsed" src="../assets/images/menu-fold-white.svg" class="w-[24px] hidden dark:block"> -->
      <span class="material-icons-outlined cursor-pointer text-[32px] text-gray-500 dark:text-gray-200" (click)="updateSiderCollapsed()" *ngIf="vm.show_sider">
        menu
      </span>
      <penji-header class="block w-full"></penji-header>
    </div>
  </nz-header>
  <nz-layout class="container 2xl:max-w-[1920px] second-layout bg-gray-100 dark:bg-gray-700">
    <ng-container *ngIf="vm.show_sider">
      <penji-sider [siderCollapsed]="siderCollapsed"></penji-sider>
    </ng-container>

    <nz-layout class="inner-layout {{(vm.show_sider && !siderCollapsed)?'ml-[248px]':siderCollapsed?'ml-[80px]':''}} bg-gray-100 transition-all ease-in-out duration-200 dark:bg-gray-700">
      <router-outlet></router-outlet>
    </nz-layout>
  </nz-layout>
</nz-layout>
<ng-container *ngIf="last_login$ | async"></ng-container>

<penji-support-form></penji-support-form>

<ng-template #contentTemplate>
  <ng-container *ngIf="{current_step:current_step$ | async, team_data:team_data$ | async} as vm">
    <div class="w-[470px] relative">
      <span class="material-icons absolute right-4 top-4 z-10 text-gray-600 hover:text-blue-600 cursor-pointer dark:text-gray-200" (click)="close()">
        close
      </span>
      <!-- step 1 -->
      <div [ngClass]="vm.current_step===1?'':'hidden'">
        <div class="p-6 text-center">
          <p class="font-bold text-xl">Chat with AM</p>
        </div>
        <div class="px-6 pb-5 text-center">
          <div class="h-[180px] bg-red-200"></div>
          <p class="my-3">
            Struggling to manage your projects effectively? Chat with our experienced account managers.
          </p>
          <p>
            Our account managers are here ensure your design projects run smoothly.
          </p>
          <button (click)="nextstep()" nz-button nzType="primary" class="mt-5" nzBlock>Proceed</button>
        </div>
      </div>
      <!-- step 2 -->
      <div [ngClass]="vm.current_step===2?'':'hidden'">
        <div class="p-6 flex items-center space-x-3">
          <penji-user-item [user_id]="'R32jSVRVCHZyvdxkYrS2FfYF52j2'" [user_avatar]="true" avatar_size="xl" [clickable]="false"></penji-user-item>
          <div>
            <p class="font-bold text-xl">Chat with AM</p>
            <p class="text-gray-600 text-[14px]">Online • 19:00 - 3:00 EST</p>
          </div>
        </div>
        <div class="px-6 pb-5">
          <form nz-form [formGroup]="validateForm" nzLayout="vertical" class="[&_.ant-form-item]:mb-7 [&_.ant-form-item-label_label]:text-lg [&_.ant-form-item-label_label]:font-bold">
            <nz-form-item>
              <nz-form-label nzRequired>Select your project</nz-form-label>
              <nz-form-control>
                <nz-select formControlName="project_id" nzPlaceHolder="Select project">
                  <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
                  <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
                  <nz-option nzValue="disabled" nzLabel="Disabled" nzDisabled></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label nzRequired>Description</nz-form-label>
              <nz-form-control>
                <quill-editor [preserveWhitespace]="true" [placeholder]="'A short description'"  class="w-full bg-white [&>.ql-container]:border-gray-200 [&>.ql-container>.ql-editor]:!text-[14px]" [styles]="{height: '120px'}" formControlName="description">
                  <div quill-editor-toolbar class="!pl-2 !pr-0 !py-1.5 bg-gray-100 !border-t-0 !border-gray-200">
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                      <button class="ql-link"></button>
                      <button class="ql-list" value="ordered"></button>
                      <button class="ql-list" value="bullet"></button>
                      <select class="ql-align" [title]="'Aligment'">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>
                      <!-- <select class="ql-color" [title]="'Color'">
                      </select>
                      <select class="ql-background" [title]="'BackgroundColor'">
                      </select> -->
                    </span>
                  </div>
                </quill-editor>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item class="!mb-0">
              <button [disabled]="validateForm.invalid || loading_submit" nzBlock
              nz-button nzType="primary" (click)="onSubmit()">Submit</button>
            </nz-form-item>
          </form>
        </div>
      </div>
      <!-- step 3 -->
      <div [ngClass]="vm.current_step===3?'':'hidden'">
        <div class="p-6">
          <p class="font-bold text-xl truncate pr-5">AccruePartners Strategic Annual Business Review</p>
          <p class="text-gray-600 text-[14px]">Online • 19:00 - 3:00 EST</p>
        </div>
        <div class="px-6 pb-5 relative h-[360px] overflow-y-auto">
          Discussion...
          <quill-editor [preserveWhitespace]="true" [placeholder]="'Message...'"  class="absolute bottom-5 left-6 right-6 z-50 bg-white [&>.ql-container]:border-gray-200 [&>.ql-container>.ql-editor]:!text-[14px]" [(ngModel)]="text">
            <div quill-editor-toolbar class="!pl-2 !pr-0 !py-1.5 bg-gray-100 !border-t-0 !border-gray-200">
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-link"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
                <select class="ql-align" [title]="'Aligment'">
                  <option selected></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
                <!-- <select class="ql-color" [title]="'Color'">
                </select>
                <select class="ql-background" [title]="'BackgroundColor'">
                </select> -->
              </span>
            </div>
          </quill-editor>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>


