<ng-container *ngIf="link_title && link_title; else copylink">
  <a title="Copy" class="flex items-center text-gray-500 hover:!text-blue-500 dark:text-white" (click)="onClick()">
    <ng-container *ngIf="copy_done$ | async as copy_done; else icon_copy">
        <ng-container *ngTemplateOutlet="icon_check"></ng-container>
    </ng-container>

  </a>
</ng-container>

<ng-template #copylink>
  <a title="Copy" class="[&>nz-text-copy>button]:flex [&>nz-text-copy>button]:text-gray-500 hover:[&>nz-text-copy>button]:!text-blue-500 dark:hover:[&>nz-text-copy>button]:!text-blue-500 [&>nz-text-copy>button]:items-center dark:[&>nz-text-copy>button]:text-white" nz-typography nzCopyable
    [nzCopyText]="base_url"
    [nzCopyIcons]="[icon_copy, icon_check]"></a>
</ng-template>


<ng-template #icon_copy>
    <span class="material-icons-outlined dark:text-white dark:hover:text-blue-500 {{icon_size}}">content_copy</span>
</ng-template>
<ng-template #icon_check>
  <span class="material-icons-outlined text-green-500 {{icon_size}}">check</span>
</ng-template>

<a #linkElement href="{{base_url}}" class="fixed z-[-1] opacity-0 text-[0.1px]">{{link_title}}</a>
