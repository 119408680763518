<ng-container *ngIf="show_time; else noShow">
  <ng-container *ngIf="overdue; else countdown">
    <ng-container *ngIf="temp$ | async"></ng-container>
    <span class="text-red-500"
      *ngIf="projectSV.getElapsedTime(project_data.time_change) as elapsed">
      {{elapsed.hours - 24}}:{{elapsed.minutes}}:{{elapsed.seconds}} late
    </span>
  </ng-container>
  <ng-template #countdown>
    <nz-countdown [nzValue]="deadline" [nzValueStyle]="{ color: '#868E96', 'font-size': '.95rem' }" class="dark:[&_.ant-statistic-content]:!text-gray-300"></nz-countdown>
  </ng-template>
</ng-container>
<ng-template #noShow>
  <ng-container *ngIf="project_data.status === 6; else noActive">
    <span class="text-gray-400 text-sm dark:text-white" *ngIf="project_data.complete_at">
      <span class="text-green-500">Completed at:</span>
      {{project_data.complete_at && project_data.complete_at.seconds ? (project_data.complete_at.toDate() | date:'MM/d/yyyy') : project_data.complete_at ? (project_data.complete_at | date:'MM/d/yyyy'): 'N/A'  }}
    </span>
  </ng-container>
  <ng-template #noActive>
    <span class="text-gray-400 text-sm dark:text-gray-300">Not active yet</span>
  </ng-template>
</ng-template>

