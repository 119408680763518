<ng-container *ngIf="design_status$ | async as design_status">
  <ng-container [ngSwitch]="design_status">
    <ng-container *ngSwitchCase="'verified'">
      <penji-project-design-status-item [design_status]="design_status"></penji-project-design-status-item>
    </ng-container>
    <ng-container *ngSwitchCase="'reject'">
      <a nz-dropdown [nzDropdownMenu]="menu" class="flex items-center text-gray-500 dark:text-gray-200">
        <span class="material-icons-outlined text-[20px]">more_vert</span>
        <penji-project-design-status-item [design_status]="design_status"></penji-project-design-status-item>
      </a>

    </ng-container>
    <ng-container *ngSwitchDefault>
      <a nz-dropdown [nzDropdownMenu]="menu" class="flex items-center text-gray-500 dark:text-gray-200">
        <span class="material-icons-outlined text-[20px]">
        more_vert
        </span>
        <penji-project-design-status-item [design_status]="design_status"></penji-project-design-status-item>
      </a>
    </ng-container>
  </ng-container>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ng-container *ngIf="auth_id$ | async as auth_id">
      <ul nz-menu nzSelectable class="[&_li]:px-4 [&_li]:py-2.5 [&_li]:space-x-2 [&_li_.material-icons-outlined]:text-[18px] [&_.ant-dropdown-menu-item-divider]:p-0">
        <ng-container [ngSwitch]="design_status">
          <ng-container *ngSwitchCase="'verified'">
            <penji-project-design-status-item [design_status]="design_status"></penji-project-design-status-item>
          </ng-container>

          <ng-container *ngSwitchCase="'reject'">
            <ng-container *ngIf="auth_id === discussion?.owner_id">
              <span style_type="dropdown">
                <li nz-menu-item (click)="onUndoRejectDesign()" class="text-gray-600 dark:text-gray-200 hover:text-blue-600">
                  <span class="material-icons-outlined">refresh</span>
                  <span>Undo</span>
                </li>
              </span>
            </ng-container>
            <ng-container *ngIf="auth_id !== discussion?.owner_id">
              <span penjiPermission="_project-verify-designs" style_type="dropdown">
                <li nz-menu-item (click)="onUndoRejectDesign()" class="text-gray-600 dark:text-gray-200 hover:text-blue-600">
                  <span class="material-icons-outlined">refresh</span>
                  <span>Undo</span>
                </li>
              </span>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'be-scheduled-6pm'">
            <ng-container *ngIf="auth_id === discussion?.owner_id">
              <span style_type="dropdown">
                <li nz-menu-item (click)="onUndoVerifyDesign()" class="text-gray-600 dark:text-gray-200 hover:text-blue-600">
                  <span class="material-icons-outlined">
                  restart_alt
                  </span>
                  <span>Undo</span>
                </li>
              </span>
            </ng-container>
            <ng-container *ngIf="auth_id !== discussion?.owner_id">
              <span penjiPermission="_project-verify-designs" style_type="dropdown">
                <li nz-menu-item (click)="onUndoVerifyDesign()" class="text-gray-600 dark:text-gray-200 hover:text-blue-600">
                  <span class="material-icons-outlined">
                  restart_alt
                  </span>
                  <span>Undo</span>
                </li>
              </span>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'be-scheduled-now'">
            <ng-container *ngIf="auth_id === discussion?.owner_id">
              <span style_type="dropdown">
                <li nz-menu-item (click)="onUndoVerifyDesign()" class="text-gray-600 dark:text-gray-200 hover:text-blue-600">
                  <span class="material-icons-outlined">
                  restart_alt
                  </span>
                  <span>Undo</span>
                </li>
              </span>
            </ng-container>
            <ng-container *ngIf="auth_id !== discussion?.owner_id">
              <span penjiPermission="_project-verify-designs" style_type="dropdown">
                <li nz-menu-item (click)="onUndoVerifyDesign()" class="text-gray-600 dark:text-gray-200 hover:text-blue-600">
                  <span class="material-icons-outlined">
                  restart_alt
                  </span>
                  <span>Undo</span>
                </li>
              </span>
            </ng-container>

          </ng-container>

          <ng-container *ngSwitchCase="'to-verify'">
            <ng-container *ngIf="auth_id === discussion?.owner_id">
              <span style_type="dropdown">
                <li nz-menu-item (click)="onVerify(1)" class="text-blue-500 hover:!bg-blue-100">
                  <span class="material-icons-outlined">
                  check
                  </span>
                  <span>Verify Now</span>
                </li>
              </span>
              <span style_type="dropdown">
                <li nz-menu-item (click)="onVerify(2)" class="text-yellow-500 hover:!bg-yellow-100">
                  <span class="material-icons-outlined">
                  calendar_month
                  </span>
                  <span>Schedule at 6PM</span>
                </li>
              </span>
              <li nz-menu-divider></li>
              <span style_type="dropdown">
                <li nz-menu-item (click)="onRejectDesign()" class="hover:!bg-red-100 text-red-500">
                  <span class="material-icons-outlined">
                  error
                  </span>
                  <span>Reject</span>
                </li>
              </span>
            </ng-container>
            <ng-container *ngIf="auth_id !== discussion?.owner_id">
              <span penjiPermission="_project-verify-designs" style_type="dropdown">
                <li nz-menu-item (click)="onVerify(1)" class="text-blue-500 hover:!bg-blue-100">
                  <span class="material-icons-outlined">
                  check
                  </span>
                  <span>Verify Now</span>
                </li>
              </span>
              <span penjiPermission="_project-verify-designs" style_type="dropdown">
                <li nz-menu-item (click)="onVerify(2)" class="text-yellow-500 hover:!bg-yellow-100">
                  <span class="material-icons-outlined">
                  calendar_month
                  </span>
                  <span>Schedule at 6PM</span>
                </li>
              </span>
              <li nz-menu-divider></li>
              <span penjiPermission="_project-verify-designs" style_type="dropdown">
                <li nz-menu-item (click)="onRejectDesign()" class="hover:!bg-red-100 text-red-500">
                  <span class="material-icons-outlined">
                  error
                  </span>
                  <span>Reject</span>
                </li>
              </span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
      </ul>
    </ng-container>
  </nz-dropdown-menu>
</ng-container>
