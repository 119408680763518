/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@penji/shared/environments';
import { DocumentSnapshot } from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EMPTY, catchError, of, pipe, retry, switchMap, throttleTime, throwError } from 'rxjs';
import { Payment } from '../models/payment.model';
import { UserData } from '../models/user.model';
import { LogService } from './log.service';
import * as firebase from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends LogService<UserData> {
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  nzMessageService = inject(NzMessageService);
  getListCustomerAlgolia(filter_obj: any) {
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          const params = { ...filter_obj };
          for (const key in params) {
            if (key === 'team_designer_id' || key === 'team_active') {
              params[key] = params[key].split(',');
            }
            if (key === 'membership') {
              if (params[key] === 'starter') {
                params[key] = ['starter_monthly_2023', 'starter_quarterly_2023', 'starter_yearly_2023'];
              } else if (params[key] === 'marketer') {
                params[key] = ['marketer_monthly_2023', 'marketer_quarterly_2023', 'marketer_yearly_2023'];
              } else if (params[key] === 'agency') {
                const arr_agency = [
                  '1-agency-business',
                  '10-agency-business',
                  '15-agency-business',
                  '50-agency-business',
                  'agency-1-business',
                  'agency-business',
                  'penji_698',
                  'penji_899',
                  'penji_agency_quarterly_2427',
                  'penji_agency_yearly_7116',
                  'penji_agency_yearly_7116_new',
                  'penji_agency_yearly_9168',
                  'price_1InajTI0Oq9o6u7MzyVC5J1M',
                  'price_1InakOI0Oq9o6u7MeTzcMb35',
                  'price_1J3HPgI0Oq9o6u7MdoFZP00T',
                  'price_1J3HRDI0Oq9o6u7Mh0xhbEwf',
                  'price_1J3HSRI0Oq9o6u7MI4J0mR6f',
                  'yearly_8991_penji_daytime_2022',
                  'monthly_999_penji_daytime_2022',
                  'monthly_plan_agency_2023',
                  'yearly_plan_agency_2023',
                  'monthly_plan_agency_2023_v1',
                  'yearly_plan_agency_2023_v1',
                  'agency_monthly_2023',
                  'agency_quarterly_2023',
                  'agency_yearly_2023',
                ];
                params[key] = arr_agency;
              }
            }
            if (key === 'status' && params[key] === 'hibernating') {
              params['status'] = 'active';
              params['hibernating'] = new Date().setDate(new Date().getDate() - 15);
            }
          }
          // console.log(params);
          return this.http.post(`${this.api_url}/filter-customer`, params, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }
  async getListCustomerByListIds(ids: string[]) {
    const promisses: any[] = [];
    ids.forEach(item => {
      promisses.push(this.afs.firestore.doc(`user/${item}`).get());
    })
    if (promisses.length > 0) {
      const result = await Promise.all(promisses);
      if (result.length > 0) {
        return result.map((doc: DocumentSnapshot) => {
          return { ...doc.data(), id: doc.id } as UserData;
        })
      }
    }
    return [];
  }
  async getListCustomerPaymentByListIds(ids: string[]) {
    const promisses: any[] = [];
    ids.forEach(item => {
      promisses.push(this.afs.firestore.doc(`payment/${item}`).get());
    })
    if (promisses.length > 0) {
      const result = await Promise.all(promisses);
      if (result.length > 0) {
        return result.map((doc: DocumentSnapshot) => {
          const data = doc.data();
          if (data)
            return { ...data, id: doc.id } as Payment;
          return {} as Payment
        })
      }
    }
    return [];
  }
  updateCustomer(data: UserData) {
    // console.log(UserData.parseObject(data));
    return this.afs
      .collection('user')
      .doc(data.id)
      .update(UserData.parseObject(data))
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ...new UserData, ...data };
        this.createLog();
        this.nzMessageService.success('updated successfully!');
        return { flag: true, message: 'Your customer successfully updated!' };
      })
      .catch((err) => {
        console.log(err);
        this.nzMessageService.error(err.message);
        return { flag: false, message: err.message };
      });
  }
  getDetailPaymemtOfCustomer(customer_id: string) {
    return this.afs.firestore
      .collection('payment')
      .doc(customer_id)
      .get()
      .then((payment) => {
        // console.log(payment.data());
        const data = payment.data();
        if (data)
          return { ...payment.data(), id: customer_id } as Payment;
        return {} as Payment;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  exportCustomerData(filter_params: any) {
    const obj_filter = { ...filter_params, "type": "customer" };
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          return this.http.post(
            `http://localhost:3000/api/algolia/export-data`,
            obj_filter,
            httpOptions
          ).pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getClientDataDetail(client_id: string) {
    return this.afs.doc(`search_client_data/${client_id}`).valueChanges({ idField: 'id' }).pipe(catchError(() => EMPTY));
  }

  async addClientDataTags(client_id: string, tag: string) {
    let re = /\ /gi;
    let parstTag = tag.trim().toLowerCase().replace(re, '_');
    const batch = this.afs.firestore.batch();
    var tagRef = this.afs.firestore.collection('common_tags').doc(parstTag);
    batch.set(tagRef, { 'count_tags': firebase.increment(1), title: tag }, { merge: true });

    tagRef = this.afs.firestore.collection('search_client_data').doc(client_id);
    batch.set(tagRef, { 'client_tags': firebase.arrayUnion(tag) }, { merge: true });

    return await batch.commit().
      then(() => true)
      .catch(err => {
        console.log(err);
        return false;
      });
  }


  async removeClientDataTags(client_id: string, tag: string) {
    let re = /\ /gi;
    let parstTag = tag.trim().toLowerCase().replace(re, '_');
    const batch = this.afs.firestore.batch();
    var tagRef = this.afs.firestore.collection('common_tags').doc(parstTag);
    const result = await tagRef.get();
    if (result.exists) {
      if (result.data()) {
        batch.set(tagRef, { 'count_tags': firebase.increment(-1) }, { merge: true });
      }
    }
    tagRef = this.afs.firestore.collection('search_client_data').doc(client_id);
    batch.set(tagRef, { 'client_tags': firebase.arrayRemove(tag) }, { merge: true });

    return await batch.commit().
      then(() => true)
      .catch(err => {
        console.log(err);
        return false;
      });
  }

  getWorkSpaceTagsDetail(client_team_id: string) {
    return this.afs.doc(`workspace_tags/${client_team_id}`).valueChanges({ idField: 'id' }).pipe(catchError(() => EMPTY));
  }

  async addWorkSpaceTags(client_team_id: string, tag: string) {
    let re = /\ /gi;
    let parstTag = tag.trim().toLowerCase().replace(re, '_');
    const batch = this.afs.firestore.batch();
    let tagRef = this.afs.firestore.collection('common_tags').doc(parstTag);
    batch.set(tagRef, { 'count_tags': firebase.increment(1), title: tag }, { merge: true });

    tagRef = this.afs.firestore.collection('workspace_tags').doc(client_team_id);
    batch.set(tagRef, { 'tags': firebase.arrayUnion(tag) }, { merge: true });

    return await batch.commit().
      then(() => true)
      .catch(err => {
        console.log(err);
        return false;
      });
  }


  async removeWorkSpaceTags(client_team_id: string, tag: string) {
    let re = /\ /gi;
    let parstTag = tag.trim().toLowerCase().replace(re, '_');
    const batch = this.afs.firestore.batch();
    var tagRef = this.afs.firestore.collection('common_tags').doc(parstTag);
    const result = await tagRef.get();
    if (result.exists) {
      if (result.data()) {
        batch.set(tagRef, { 'count_tags': firebase.increment(-1) }, { merge: true });
      }
    }
    tagRef = this.afs.firestore.collection('workspace_tags').doc(client_team_id);
    batch.set(tagRef, { 'tags': firebase.arrayRemove(tag) }, { merge: true });

    return await batch.commit().
      then(() => true)
      .catch(err => {
        console.log(err);
        return false;
      });
  }
}
