/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges } from '@angular/core';
import { WorkSpace } from '@penji/shared/data-access';
import { WorkSpaceStoreService } from '@penji/team/workspace/data-access';
import { WorkspaceItemV2Component } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-item-v2';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MomentModule } from 'ngx-moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-workspace-list-by-uid',
  standalone: true,
  imports: [
    CommonModule,
    NzTagModule,
    NzSkeletonModule,
    MomentModule,
    NzToolTipModule,
    NzEmptyModule,
    WorkspaceItemV2Component
  ],
  templateUrl: './workspace-list-by-uid.component.html',
  styleUrls: ['./workspace-list-by-uid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceListByUidComponent implements OnChanges {

  @Input() uid?: string;
  @Input() workspace_list_type?: string | 'tag' | 'item' = 'item';

  private readonly workspaceStore = inject(WorkSpaceStoreService);

  error$ = this.workspaceStore.error$;
  loading$ = this.workspaceStore.loading$;

  workspace_by_customer$: Observable<WorkSpace[]> | undefined;

  ngOnChanges() {
    if (this.uid) {
      this.workspace_by_customer$ = this.workspaceStore.getWorkSpaceByCustomer(this.uid);
    }
  }
  trackBy(index: any, item: any) {
    return item.id;
  }
}
