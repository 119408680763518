/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Media, MediaService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';

export interface mediaState extends EntityState<Media> {
  isLoading: boolean;
  error: string;
  disable_previous: boolean,
  disable_next: boolean,
}
export const adapter = createEntityAdapter<Media>();
export const initialState: mediaState = adapter.getInitialState({
  isLoading: false,
  error: '',
  disable_previous: false,
  disable_next: false,
})
@Injectable()
export class FileManagerStoreService extends ComponentStore<mediaState>{
  limit = 30;

  mediaSV = inject(MediaService);
  constructor() {
    super(initialState);
  }
  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as Media[];
    return list.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
  });
  readonly loading$ = this.select(s => s.isLoading);
  disable_previous$ = this.select((s) => s.disable_previous);
  disable_next$ = this.select((s) => s.disable_next);
  loadMedia$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ isLoading: true, disable_previous: false, disable_next: false })
      }),
      switchMap((params: any) => {
        let where_query: Array<WhereQueryInterface> | undefined;
        let start_after;
        let end_before;
        let order_by: string | undefined = 'created_at';
        let order_desc: boolean | undefined = true;
        if (params) {
          for (const k in params) {
            if (k == 'start_after') {
              start_after = params[k];
            } else if (k == 'end_before') {
              end_before = params[k];
            } else if (k == 'order_by') {
              order_by = params[k];
            } else if (k == 'order_desc') {
              order_desc = JSON.parse(params[k]);
            } else if (k !== 'user_id') {
              if (where_query) {
                where_query.push({
                  field_name: k,
                  field_operator: '==',
                  field_value: params[k]
                });
              } else {
                where_query = [{
                  field_name: k,
                  field_operator: '==',
                  field_value: params[k]
                }];
              }
            }
          }
        } else {
          return of([] as Media[]);
        }
        return this.mediaSV.getListMedias(params['user_id'], this.limit, where_query, start_after, end_before, order_by, order_desc).then(rs => {
          if (rs && rs.length > 0) {
            // const promise = [
            //   this.mediaSV.getListMedias(params['user_id'], 1, where_query, undefined, rs[0].id, order_by, order_desc),
            //   this.mediaSV.getListMedias(params['user_id'], 1, where_query, rs[rs.length - 1].id, undefined, order_by, order_desc)
            // ]
            // return Promise.all(promise).
            //   then((before_after) => {
            //     if (before_after[0] && before_after[0].length > 0) {
            //       this.patchState({ disable_previous: false });
            //     } else {
            //       this.patchState({ disable_previous: true });
            //     }
            //     if (before_after[1] && before_after[1].length > 0) {
            //       this.patchState({ disable_next: false });
            //     } else {
            //       this.patchState({ disable_next: true });
            //     }
            //     return rs;
            //   })
            return rs;
          } else {
            return [];
          }
        }).catch(err => {
          return [] as Media[];
        })

      }),
      map(final_list => {
        if (final_list && final_list.length > 0) {
          this.setState((state) => adapter.setAll(final_list, state));
          this.patchState({ isLoading: false });
        }
        if (final_list.length == 0 || final_list.length < this.limit) {
          this.patchState({ isLoading: false });
          if (final_list.length == 0) {
            this.patchState({ disable_previous: true, disable_next: true });
          }
        }
      }),
      catchError(err => {
        this.patchState({ isLoading: false });
        return EMPTY;
      })
    )
  })

  updateMedia$ = this.effect((media$: Observable<Media>) => {
    return media$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      mergeMap((media: Media) => {
        return combineLatest(this.mediaSV.updateMedia(media), of(media));
      }),
      map(([rs, media]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({ id: media.id, changes: media }, state));
          this.patchState({ isLoading: false });
        } else {
          this.patchState({ isLoading: false });
        }
      }),
      catchError(err => {
        this.patchState({ isLoading: false });
        return EMPTY;
      })
    )
  });
  removeMedia$ = this.effect((media$: Observable<Media>) => {
    return media$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      mergeMap((media: Media) => {
        return this.mediaSV.deleteMediaRef(media.owner_id, media.id).then(rs => {
          if (rs.flag) {
            this.setState((state) => adapter.removeOne(media.id, state));
            this.patchState({ isLoading: false });
          } else {
            // console.log('cannot this file!');
            this.patchState({ isLoading: false, error: 'cannot this file!' });
          }
        })
      }),
      catchError(err => {
        this.patchState({ isLoading: false });
        return EMPTY;
      })
    )
  });
}
