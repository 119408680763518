<ng-container *ngIf="{list_comment:list_comment$ | async, auth_id:auth_id$ | async, loading:loading$ | async, project_data:project_data$ | async} as vm">
  <ng-container *ngIf="!vm.loading; else loading">
    <ng-container *ngIf="vm.list_comment && vm.list_comment.length > 0; else no_comment">
      <p *ngIf="vm.list_comment && vm.list_comment.length > 0" class="font-semibold text-gray-700 mb-2 px-4 dark:text-white">Revisions: <span class="font-semibold text-blue-500">(<penji-revision-by-idlink [id_link]="id_link"></penji-revision-by-idlink>)</span></p>
      <ng-container *ngFor="let comment of vm.list_comment; trackBy: trackBy; let i=index">
        <ng-container *ngIf="comment&&!comment.removed">
          <div class="relative comment-content border-[3px] border-b-[2px] rounded-sm border-transparent border-b-gray-50 bg-white last-of-type:border-b-0 hover:bg-gray-100 hover:shadow-sm [&.focus]:shadow-sm [&.focus]:border-b-[3px] [&.focus]:!border-blue-500 [&.focus]:bg-white dark:hover:bg-gray-500 dark:[&.focus]:bg-gray-500 dark:bg-gray-600 dark:border-b-gray-500" [id]="comment?.marker?.id?comment?.marker?.id: 'annotation-'+comment.id">
            <div class="absolute z-50 inset-0 mark-click cursor-pointer" (click)="onClickComment(comment)"></div>
            <penji-view-design-comment-item [auth_id]="vm.auth_id??''" [comment]="comment"></penji-view-design-comment-item>
            <div class="add-reply hidden" *ngIf="vm.project_data && vm.project_data.in_queue === false && vm.project_data.status && vm.project_data.status < 6">
              <penji-view-design-input-reply [auth_id]="vm.auth_id??''" [comment]="comment"></penji-view-design-input-reply>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div class="px-4">
      <nz-skeleton [nzAvatar]="true" [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 2, width: '100%' }"></nz-skeleton>
    </div>
  </ng-template>
  <ng-template #no_comment>
    <nz-empty [nzNotFoundContent]="null" nzNotFoundImage="simple" class="[&_.ant-empty-image]:h-12 mt-10"></nz-empty>
    <p class="text-center text-gray-600 dark:text-white">There are no revisions</p>
  </ng-template>
</ng-container>
