/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Project } from '@penji/shared/data-access';
import { PermissionDirective } from '@penji/shared/directives';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { UserSelectComponent } from '@penji/team/shared/shared-ui/user-select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'penji-project-reassign',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzPopoverModule,
    UserSelectComponent,
    PermissionDirective,
  ],
  templateUrl: './project-reassign.component.html',
  styleUrls: ['./project-reassign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectReassignComponent {

  @Input() project_data!: Project;
  @Output() list_user_id_output = new EventEmitter();

  ProjectStoreService = inject(ProjectStoreService);
  visible = false;
  list_user_id: string[] = [];

  ngOnChanges(): void {
    if (this.project_data && this.project_data.assign_by)
      this.list_user_id = this.project_data.assign_by;
  }

  getListUserId(list_id: string[]) {
    const project_change = new Project();
    project_change.id = this.project_data.id;
    project_change.team_id = this.project_data.team_id;
    project_change.assign_by = list_id;
    this.list_user_id_output.emit(list_id);
    this.list_user_id = list_id;
    this.ProjectStoreService.updateProject$(project_change);
    this.visible = false;
  }
}
