import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BrandStoreService } from '@penji/client-v3/brands/data-access';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { WorkspaceStoreService } from '@penji/client-v3/workspace/data-access';
import { TeamService, WorkspaceService } from '@penji/shared/data-access';
import { RouterUtils } from '@penji/shared/utils';
import { Observable, combineLatest, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceGuard implements CanActivate {
  private readonly router = inject(Router);
  private readonly workspaceSV = inject(WorkspaceService);
  private readonly teamSV = inject(TeamService);
  private readonly customerTeamStore = inject(CustomerTeamStoreService);
  private readonly membershipStore = inject(MembershipStoreService)
  private readonly workspaceStore = inject(WorkspaceStoreService);
  private readonly brandStore = inject(BrandStoreService);
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<boolean> {
    const client_team_id = route.params[RouterUtils.Configuration.client_team_id];
    // console.log(client_team_id);
    if (client_team_id) {
      return combineLatest(
        this.teamSV.getTeamDetail(client_team_id), this.workspaceSV.listForPermission$).pipe(
          map(([team_detail, list]) => {
            // console.log(team_detail)

            const workspace = list.find(f => f.team_id === client_team_id);
            if (workspace) {
              // console.log(team_detail)
              if (team_detail.moved_to_v2) {
                if (team_detail.onboarding == true) {
                  this.router.navigate([`/t/${client_team_id}/onboarding`]);
                  return false;
                } else {
                  this.customerTeamStore.getTeamDetail$(client_team_id);
                  this.membershipStore.getTeamMembership$(client_team_id);
                  this.workspaceStore.loadWorkSpace$();
                  this.brandStore.loadAllBrand$(client_team_id);
                  return true;
                } 
              } 
              else {
                this.router.navigate([`/move-to-v2/${client_team_id}`]);
                return false;
              }
            }
            else {
              this.router.navigate(['/404']);
              return false;
            }
          })
        )
    } else {
      return of(false)
    }
  }
}
