import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TeamService, WorkspaceService } from '@penji/shared/data-access';
import { RouterUtils } from '@penji/shared/utils';
import { combineLatest, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MoveV2Guard implements CanActivate {
    private readonly router = inject(Router);
    private readonly workspaceSV = inject(WorkspaceService);
    private readonly teamSV = inject(TeamService)

    canActivate(route: ActivatedRouteSnapshot) {
        //console.log(route.params[RouterUtils.Configuration.client_team_id]);
        const client_team_id = route.params[RouterUtils.Configuration.client_team_id];
        this.teamSV.getTeamDetail(client_team_id);

        return combineLatest(this.teamSV.getTeamDetail(client_team_id), this.workspaceSV.listForPermission$).pipe(
            map(([team_detail, list])=> {
                const workspace = list.find(f=>f.team_id === client_team_id);
                if(workspace){
                    // console.log(team_detail)
                    if(team_detail.moved_to_v2)
                        this.router.navigate([`/t/${client_team_id}/`])
                    return true;
                }
                else
                    this.router.navigate(['/404']);
                return false;
            })
        )
    }
}
