import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { ProfileConfirmCheckoutComponent } from '@penji/client-v3/profiles/ui/profile-confirm-checkout';
import { PlanDescriptionComponent } from '@penji/client-v3/shared/ui/plan-description';
import { Plan } from '@penji/shared/data-access';
import { list_all_plan } from '@penji/shared/environments';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'penji-profile-change-plan',
  standalone: true,
  imports: [
    CommonModule, 
    NzButtonModule, 
    PlanDescriptionComponent,
    NzSkeletonModule,
    NzEmptyModule
  ],
  templateUrl: './profile-change-plan.component.html',
  styleUrls: ['./profile-change-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileChangePlanComponent {
  @Input() plan_duration = 'monthly';
  
  private readonly modal = inject(NzModalService);
  private readonly membershipStore = inject(MembershipStoreService);

  list_all_plan: { [key: string]: Plan } = list_all_plan;
  showmore$ = new BehaviorSubject<boolean>(true);
  membership$ = this.membershipStore.list_sub_design$;
  today = new Date();

  clickShowMore(show: boolean){
    this.showmore$.next(!show);
  }
  openConfirmCheckout(plan: Plan) {
    this.modal.closeAll()
    this.modal.create({
      nzCentered: true, 
      nzContent: ProfileConfirmCheckoutComponent,
      nzBodyStyle: {
        padding: '20px',
        overflow: 'hidden',
        background: '#fff',
        height: '100vh'
      },
      nzWidth: '100vw',
      nzStyle: { top: '0', padding: '0', maxWidth: 'none'},
      nzTitle: 'Checkout',
      nzFooter: null,
      nzClassName: 'modal-confirm-checkout [&_.ant-modal-title]:text-center [&_.ant-modal-content]:!rounded-none',
      nzWrapClassName: 'overflow-hidden',
      nzComponentParams: {
        plan: plan
      },
    });
  }
}
