/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Membership, MembershipService, Team, TeamService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, concatMap, distinctUntilChanged, map, of, switchMap, take, tap } from 'rxjs';
interface WorkSpaceDetailState extends EntityState<Team> {
  loading: boolean,
  error: string
}
const adapter = createEntityAdapter<Team>();
const initialState: WorkSpaceDetailState = adapter.getInitialState({
  loading: false,
  error: ''
})
@Injectable({
  providedIn: 'root'
})
export class WorkSpaceDetailStoreService extends ComponentStore<WorkSpaceDetailState>{
  private readonly teamSV = inject(TeamService);
  private readonly membershipSV = inject(MembershipService);
  constructor() { super(initialState); }

  loading$ = this.select((s) => s.loading);
  error$ = this.select((s) => s.error);
  data$ = (client_team_id: string) => {
    return this.select(s => s.entities[client_team_id] as Team).pipe(
      tap(team => {
        if (!team && client_team_id) {
          this.addTeam(client_team_id);
        }
      }))
  };
  addTeam(client_team_id: string) {
    this.patchState({ loading: true });
    const where_query: WhereQueryInterface[] = [
      {
        field_name: 'team_id', field_operator: '==', field_value: client_team_id
      }
    ];
    Promise.all([
      this.teamSV.getTeamDetail2(client_team_id),
      this.membershipSV.getListMembership(-1, where_query)
    ]).then(snapshot => {
      this.patchState({ loading: false })
      if (snapshot[0].id) {
        const team = snapshot[0];
        team.membership = snapshot[1];
        if (team) {
          this.patchState(state => adapter.upsertOne(team, state));
        }
      }
    }).catch(err => {
      console.log(err);
    })
  }
  // Update
  updateTeamDesignerId$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) => {
        return this.teamSV.changeDesignerTeamForCustomer(data.workspace_data.id, data.team_designer_id_new).then(
          (rs) => {
            if (rs.flag) {
              data.workspace_data.team_designer_id = data.team_designer_id_new;
              this.setState((state) =>
                adapter.updateOne(
                  { id: data.workspace_data.id, changes: data.workspace_data },
                  state
                )
              );
            }
            return EMPTY;
          },
          catchError(() => {
            return EMPTY;
          })
        )
      }
      )
    );
  });

  updateTeam$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) => {
        return this.teamSV.updateTeam(data.id, '', data, false).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) =>
                adapter.updateOne(
                  { id: data.id, changes: data },
                  state
                )
              );
            }
            this.patchState({ error: rs.message });
            return EMPTY;
          },
          catchError((err) => {
            this.patchState({ error: err as string });
            return EMPTY;
          })
        )
      }
      )
    );
  });

  updateMembershipMetaData(membership_data: Membership, client_team_id: string) {
    this.membershipSV.updateMembership(membership_data).then(async rs => {
      if (rs.flag) {
        const team = await this.data$(client_team_id).pipe(take(1)).toPromise();
        if (team) {
          const team_data = { id: client_team_id, membership: team.membership } as Team;
          const index = team_data.membership?.findIndex(m => m.id === membership_data.id);
          if (index! >= 0) {
            team_data.membership![index!].metadata = { ...team_data.membership![index!].metadata, ...membership_data.metadata }
          } else {
            console.log('Could NOT find membership');
          }

          this.setState((state) =>
            adapter.updateOne(
              { id: client_team_id, changes: team_data },
              state
            )
          );
        } else {
          console.log('Could NOT find team data');
        }
      } else {
        console.log(rs.message);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  updateAssignedDesigner$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) => {
        return this.teamSV.updateDesignersLikeForCustomer(data.id, data.list_id).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) =>
                adapter.updateOne(
                  { id: data.id, changes: { clients_like: data.list_id } },
                  state
                )
              );
            }
            return EMPTY;
          },
          catchError(() => {
            return EMPTY;
          })
        )
      }
      )
    );
  });

  updateAvoidedDesigner$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) => {
        return this.teamSV.updateDesignersHateForCustomer(data.id, data.list_id).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) =>
                adapter.updateOne(
                  { id: data.id, changes: { clients_hate: data.list_id } },
                  state
                )
              );
            }
            return EMPTY;
          },
          catchError(() => {
            return EMPTY;
          })
        )
      }
      )
    );
  });

  updateLabel$ = this.effect((data$: Observable<any>) => {
    return data$.pipe(
      concatMap((data) => {
        return this.teamSV.updateCustomerAccountLabel(data.id, data).then(
          (rs) => {
            if (rs.flag) {
              this.setState((state) =>
                adapter.updateOne(
                  { id: data.id, changes: { label_info: data } },
                  state
                )
              );
            }
            return EMPTY;
          },
          catchError(() => {
            return EMPTY;
          })
        )
      })
    );
  });

}
