<form nzLayout="vertical" nz-form [formGroup]="validateForm" class="p-6" (submit)="onSubmit()">
    <div nz-row>
        <div class="w-full">
            <h5 class="mb-6 dark:text-white">{{form_type==='add' ? 'Add' : 'Edit'}} a overtime</h5>
        </div>
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label class="w-full">Overtime At</nz-form-label>
            <nz-date-picker class="w-full" formControlName="overtime_at_tmp" nzFormat="yyyy-MM-dd" nzPlaceHolder="Overtime at entry"
                [nzDisabledDate]="disabled_overtime_at" [nzDisabled]="form_type==='edit'?true:false">
            </nz-date-picker>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label class="w-full">Member</nz-form-label>
            <div class="flex items-center space-x-1">
                <penji-hr-management-assigned
                    [assign_by]="validateForm.get('uid')?.value ? [validateForm.get('uid')?.value]:[]"
                    [reassign_on]="(uid||form_type==='edit')?false:true"
                    (list_user_id_output)="getListUserId($event)"></penji-hr-management-assigned>
            </div>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="24">
            <nz-form-label class="w-full">Overtime Link</nz-form-label>
            <ng-container *ngFor="let link of validateForm.get('overtime_link')?.value; let i = index">
                <div class="w-full flex items-center space-x-2 mb-2">
                    <input class="w-10/12" nz-input [attr.id]="i" [attr.name]="i" [attr.value]="link" disabled />
                    <button class="w-2/12 text-sm text-center px-0" nz-button nzType="default" nzDanger
                        (click)="removeLink(link)">Remove</button>
                </div>
            </ng-container>
            <div class="w-full flex items-center space-x-2" [formGroup]="_addLinkForm">
                <input class="w-10/12" nz-input placeholder="Paste project link" formControlName="_link" />
                <button class="w-2/12 text-sm text-center px-0" nz-button nzType="primary" (click)="addLink()"
                    [disabled]="_addLinkForm.invalid" type="button">Save</button>
            </div>
        </nz-form-item>
        <div class="w-full text-right">
            <button type="submit" nz-button nzSize="large" nzType="primary"
                [disabled]="validateForm.invalid || loading">
                {{form_type==='add' ? 'Add' : 'Save changes'}}
            </button>
        </div>
    </div>
</form>
<!-- <pre>{{validateForm.value | json}}</pre> -->