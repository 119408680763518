import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'penji-workspace-customer-class-tooltip',
  standalone: true,
  imports: [CommonModule, NzPopoverModule],
  templateUrl: './workspace-customer-class-tooltip.component.html',
  styleUrls: ['./workspace-customer-class-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceCustomerClassTooltipComponent {

  @Input() icon_size = 10;
  @Input() icon_color = '#64748B';

}
