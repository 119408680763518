import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { ThemeService, UserData } from '@penji/shared/data-access';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
  selector: 'penji-theme-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzRadioModule,
    NzDropDownModule,
    RouterModule,
    NzToolTipModule,
  ],
  templateUrl: './theme-dropdown.component.html',
  styleUrls: ['./theme-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeDropdownComponent implements OnInit {
  router = inject(Router);
  private readonly authStore = inject(AuthStore);
  themeSV = inject(ThemeService);
  theme$ = new BehaviorSubject<any>('');
  auth_profile$: Observable<any> | undefined;
  ngOnInit(): void {
    this.auth_profile$ =  this.authStore.authProfile$.pipe(
      tap(user => {
        if(user) {
          const temp = this.themeSV.setThemes(user.theme ?? 'light', 'auth');
          this.theme$.next(temp.value);
        }
      })
    )
  }
  selectTheme(key: string, user: UserData) {
    user.theme = key;
    this.authStore.updateAuthProfile$(user);
  }
}
