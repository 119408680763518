import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnInit, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectsStoreService } from '@penji/client-v3/projects/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Ticket } from '@penji/shared/data-access';
import { TicketCateStoreService, TicketStoreService } from '@penji/team/ticket/data-access';
import * as firebase from 'firebase/firestore';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Observable, debounceTime, distinctUntilChanged, map, of, tap } from 'rxjs';

@Component({
  selector: 'penji-support-form',
  standalone: true,
  imports: [
    CommonModule,
    NzPopoverModule,
    NzDropDownModule,
    NzBadgeModule,
    NzEmptyModule,
    CdkDrag,
    NzFormModule,
    NzInputModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzSelectModule
  ],
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TicketStoreService, TicketCateStoreService, ProjectsStoreService]
})
export class SupportFormComponent  implements OnInit, AfterViewInit{

  private readonly fb = inject(FormBuilder);
  private readonly authStore = inject(AuthStore);
  private readonly ticketStore = inject(TicketStoreService);
  private readonly ticketCateStore = inject(TicketCateStoreService);
  private readonly projectStoreSV = inject(ProjectsStoreService);

  selected_ticket_sub_cate_title = '';
  list_sub_ticket_cate: Array<object | null> = [];
  listMainTicketCate$: Observable<any | null>;
  listSubTicketCate$: Observable<any | null>;

  authProfile$ = this.authStore.authProfile$;
  validateForm!: UntypedFormGroup;
  btnSubmit = false;
  visible = false;

  project_search_list$ = this.projectStoreSV.data$;
  project_search_loading$ = this.projectStoreSV.loading$;
  constructor() {
    this.ticketCateStore.init();
    this.listMainTicketCate$ = this.ticketCateStore.getListMainTicketCate();
    this.listSubTicketCate$ = this.ticketCateStore.getListSubTicketCate().pipe(
      tap(rs => {
        this.list_sub_ticket_cate = rs;
        // console.log(rs)
      })
    );
  }
  @HostListener('window:resize', ['$event'])
  ngOnInit() {
    this.validateForm = this.fb.group({
      id: [""],
      client_id: [""],
      created_at: [null],
      updated_at: [null],
      description: ["", [Validators.required]],
      owner_id: [""],
      project: [null],
      status: [null],
      team_designer_id: [""],
      ticket_main_category_id: ["", [Validators.required]],
      ticket_sub_category_id: ["", [Validators.required]],
      ticket_source: [null, [Validators.required]],
      user_array: [[]],
      viewer: [[]],
      reviewed: [false],
      files: [[]],
    });
  }
  ngAfterViewInit() {
    this.resizeContainer();
  }
  resize(event: any){
    this.resizeContainer();
  }
  closeContainer() {
    this.visible = false;
  }
  resizeContainer() {
    // Get the position from localStorage
    const save_position_y = localStorage.getItem('position_y');
    const window_height = window.innerHeight;
    const box = document.getElementById('customer_support_form');
    if(save_position_y && box){
      const position_y = parseInt(save_position_y);
      if(position_y > window_height){
        box.style.top = window_height - 46 + 'px';
      }else{
        box.style.top = position_y + 'px';
      }
    }
  }
  dragEnded(event: any): void {
    let position_y = event.dropPoint.y - 26;
    if(position_y < 10){
      position_y = 10;
      localStorage.setItem('position_y', position_y.toString());
    }else{
      localStorage.setItem('position_y', position_y.toString());
    }
  }
  changeTicketSubCategory(ticket_sub_category_id: string) {
    this.list_sub_ticket_cate.forEach((sub: any) => {
      if (sub.id == ticket_sub_category_id) {
        this.selected_ticket_sub_cate_title = sub.title;
        this.validateForm.patchValue({
          ticket_source: sub.source,
          ticket_main_category_id: sub.ticket_category_id
        });
      }
    })
  }
  searchProject(keyword: string) {
    this.projectStoreSV.searchProject$(of(keyword).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(keyword => {
        // console.log(keyword);
        if (keyword) {
          return { services: 'all', keyword: keyword};
        }
        return {};
      })
    ))
  }
  submitForm(uid?: string, fname?: string, lname?: string) {
    if (this.validateForm.valid && uid) {
      this.btnSubmit = true;
      this.validateForm.patchValue({
        status: 1,
        owner_id: uid,
        client_id: uid,
        created_at: firebase.Timestamp.now(),
        updated_at: firebase.Timestamp.now(),
        reviewed: false,
        description: '<b>' + fname + ' ' + lname
        + '</b> requested ' + this.selected_ticket_sub_cate_title + ' on popup. <br /><br />'
        + '<b>Client comments: </b>' + this.validateForm.value.description
      });
      this.validateForm.removeControl('ticket_sub_category');
      const ticket = this.validateForm.value as Ticket;
      if(this.validateForm.value.project && this.validateForm.value.project.lenght >0)
        ticket.project_array = [this.validateForm.value.project[0]['project_id']];

      this.ticketStore.addTicket$(ticket);
      this.validateForm.reset();
      this.closeContainer();
      this.btnSubmit = false;
    }
  }
}

