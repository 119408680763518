import { UserItemDecompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-decompress';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { DrawerService, UserData, UserSimple } from '@penji/shared/data-access';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import { MemberCheckinStatusByUidComponent } from '@penji/team/member/member-ui/member-checkin-status-by-uid';
import { MemberCurrentQueueComponent } from '@penji/team/member/member-ui/member-current-queue';
import { MemberTopExpertiseComponent } from '@penji/team/member/member-ui/member-top-expertise';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { UserAvatarComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-avatar';
import { UserNameComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-name';
import { SquadGroupNameComponent } from '@penji/team/squad-group/squad-group-ui/squad-group-name';
import { WorkspaceListByUidComponent } from '@penji/team/workspace/workspace-ui/workspace-list-by-uid';
import { UserRoleComponent } from '@penji/shared/ui/element/user-role';

@Component({
  selector: 'penji-user-popover-info',
  standalone: true,
  imports: [
    CommonModule,
    UserAvatarComponent,
    UserNameComponent,
    DesignerTeamNameComponent,
    UserRoleComponent,
    MemberCurrentQueueComponent,
    MemberTopExpertiseComponent,
    WorkspaceListByUidComponent,
    CopyItemComponent,
    MemberCheckinStatusByUidComponent,
    SquadGroupNameComponent,
    UserItemDecompressComponent
  ],
  templateUrl: './user-popover-info.component.html',
  styleUrls: ['./user-popover-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPopoverInfoComponent {
  @Input() user?: UserSimple;
  @Input() auth?: UserData;
  private readonly drawerSv = inject(DrawerService);

  openDrawer(id: string, type: string) {
    const temp = { type: type, id: id }
    this.drawerSv.openDrawer(temp);
  }
}
