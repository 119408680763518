import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Project, ProjectService } from '@penji/shared/data-access';

@Component({
  selector: 'penji-project-link-title',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './project-link-title.component.html',
  styleUrls: ['./project-link-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLinkTitleComponent implements OnChanges {
  @Input() project_id?: string;

  projectSV = inject(ProjectService);
  project$!: Promise<Project>;
  ngOnChanges() {
    if(this.project_id){
      this.project$ = this.projectSV.getProjectDetailOnce(this.project_id);
    }
  }
}
