import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as mediaActions from './media.action';
import { distinct, map, mergeMap } from "rxjs";
import { Media, MediaService } from "@penji/shared/data-access";

@Injectable()
export class MediaEffect {

  actions = inject(Actions);
  mediaSv = inject(MediaService);

  loadMedia$ = createEffect(() =>  this.actions.pipe(
    ofType(mediaActions.loadMediaRefDB),
    distinct(e => e.media_id),
    mergeMap((action: any) => {
      return this.mediaSv.getMediaRefDetail(action.user_id, action.media_id);
    }),
    map(media => {
      if(media)
        return mediaActions.createMedia({media:media as Media});
      return mediaActions.nothing();
    })
  ));

  deleteMedia$ = createEffect(()=> this.actions.pipe(
    ofType(mediaActions.deleteMediaDB),
    mergeMap((action)=> this.mediaSv.deleteMediaRef(action.user_id, action.media_id)?.then(rs=>{
      if(rs.flag)
        return mediaActions.deleteMedia({media_id:action.media_id?action.media_id:''});
      return mediaActions.nothing();
    }))
  ));


}

