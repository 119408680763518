<div #containerRef class="h-[calc(100vh-250px)] overflow-y-scroll">
    <ng-container *ngIf="{loading:loading$|async, data: data$ | async} as vm">
        <ng-container *ngIf="vm.data">
            <div infiniteScroll [infiniteScrollContainer]="containerRef" [fromRoot]="true" [infiniteScrollDistance]="1"
                (scrolled)="onScroll(vm.data[vm.data.length - 1].doc)" [scrollWindow]="false">
                <ng-container *ngFor="let item of vm.data | groupByDate; let isLast = last">
                    <p class="p-[0.75rem_1rem_0.5rem] text-sm font-semibold text-gray-600">
                        {{ item.date === (today.getTime() | date : 'MMM d, y') ? 'Today': item.date ===
                        ((today.getTime() -
                        24*60*60*1000) | date : 'MMM d, y') ? ' Yesterday' : item.date}}
                    </p>
                    <div class="{{!isLast ? 'border-b-[1px] border-b-gray-200' : ''}} [&_.noty-item]:!border-b-none">
                        <ng-container *ngFor="let noti of item.data">
                            <penji-notification-item [notification]="noti"></penji-notification-item>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="vm.loading">
            <div class="flex justify-center py-10">
                <penji-spinner></penji-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!vm.loading && vm.data && vm.data.length === 0">
            <div class="p-8">
                <img src="../assets/images/noty-empty.png" alt="No Notify" class="mx-auto mb-8 w-[100px]">
                <h4 class="text-base text-gray-600 text-center">You have no notifications</h4>
                <p class="text-sm text-gray-600 text-center">New messages and project updates will appear here.</p>
            </div>
        </ng-container>
    </ng-container>
</div>