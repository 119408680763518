
<ng-container *ngIf="comment">
  <div class="relative">
    <quill-editor [preserveWhitespace]="true" [placeholder]="'Reply'" customToolbarPosition="bottom" [modules]="modules" [(ngModel)]="reply_text"
      class="w-full bg-white [&>.ql-container]:border-gray-200 [&>.ql-container>.ql-editor]:!text-[14px] [&>.ql-container>.ql-editor]:!py-2.5 [&>.ql-container>.ql-editor]:min-h-[42px] [&_.ql-editing]:!left-0 [&_.ql-editing]:!top-1/2 [&_.ql-editing]:!-translate-y-1/2 [&_.ql-editing_input]:!outline-none [&_.ql-picker-options]:!z-[60] dark:[&_.ql-container]:border-gray-400 dark:text-white dark:[&_.ql-blank::before]:text-white dark:bg-gray-700">
      <div quill-editor-toolbar class="!pl-2 !pr-0 !py-1.5 bg-gray-100 !border-t-0 !border-gray-200 dark:bg-gray-600 dark:!border-gray-500">
        <span class="ql-formats !mr-2">
          <penji-user-item-compress [user_id]="auth_id" [show_user_avatar]="true" user_image_size="xs"
            [clickable]="true"></penji-user-item-compress>
        </span>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-link"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <select class="ql-align" [title]="'Aligment'">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <select class="ql-color" [title]="'Color'">
          </select>
          <select class="ql-background" [title]="'BackgroundColor'">
          </select>
        </span>
      </div>
    </quill-editor>

    <a *ngIf="reply_text && reply_text !==''" (click)="addReply()" class="text-gray-400 hover:text-blue-500 absolute right-3
    top-2.5">
      <span class="material-icons-outlined">
        send
      </span>
    </a>
  </div>
</ng-container>
