<svg nz-popover nzPopoverPlacement="right" [nzPopoverContent]="discoveryPhase" [nzPopoverOverlayClassName]="'[&_.ant-popover-inner]:min-w-[460px]'" class="inner-block mx-0.5 cursor-pointer" [attr.width]="icon_size" [attr.height]="icon_size" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 6.5H5.5V7.5H4.5V6.5ZM4.5 2.5H5.5V5.5H4.5V2.5ZM4.995 0C2.235 0 0 2.24 0 5C0 7.76 2.235 10 4.995 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 4.995 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9Z" [attr.fill]="icon_color"/>
</svg>
<ng-template #discoveryPhase>
    <div class="text-gray-800 dark:text-gray-100 pb-3">
        <h6 class="text-xl font-semibold mb-3 dark:text-white">Discovery Phase</h6>
        <p class="text-base font-normal mb-4">The Discovery Phase illustrates the significant stages of the customer journey on Penji, divided into 4 phases:</p>
        <div class="
            grid grid-cols-1 gap-4
        ">
            <div class="flex items-center">
                <penji-workspace-discovery-phase class="mr-3" [phase]="0"></penji-workspace-discovery-phase>
                <div>
                    <p class="text-base font-normal">Phase 1 <span class="font-semibold">(under 31 days)</span> is when customers explore features.</p>
                </div>
            </div>
            <div class="flex items-center">
                <penji-workspace-discovery-phase class="mr-3" [phase]="1"></penji-workspace-discovery-phase>
                <div>
                    <p class="text-base font-normal">Phase 2 <span class="font-semibold">(31-60 days)</span> is when they perceive the value of the service.</p>
                </div>
            </div>
            <div class="flex items-center">
                <penji-workspace-discovery-phase class="mr-3" [phase]="2"></penji-workspace-discovery-phase>
                <div>
                    <p class="text-base font-normal">Phase 3 <span class="font-semibold">(61-90 days)</span> is the decisive period for customer retention.</p>
                </div>
            </div>
            <div class="flex items-center">
                <penji-workspace-discovery-phase class="mr-3" [phase]="3"></penji-workspace-discovery-phase>
                <div>
                    <p class="text-base font-normal">Phase 4 marks stability, at this stage, <span class="font-semibold">you'll receive a monthly bonus.</span></p>
                </div>
            </div>
        </div>
    </div>
</ng-template>