<span penjiPermission="_project-manual-assign-designers" style_type="button">
  <button nz-button nzType="dashed" nzShape="circle" nzSize="small" nz-popover nzPopoverTitle="Assign Designer"
    [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="reassign_content"
    nzPopoverOverlayClassName="popover-filter" class="inline-flex items-center justify-center">
    <span class="material-icons-outlined text-[16px]">add</span>
  </button>
</span>
<ng-template #reassign_content>
  <span (click)="visible = false"
    class="material-icons-outlined text-gray-800 hover:text-gray-500 dark:text-white dark:hover:text-gray-200 cursor-pointer -top-8 dark:-top-[38px] right-4 absolute z-10 text-[25px]">close</span>
  <penji-user-select [assign_team_designer]="project_data.assign_team_designer" [project_cat_id]="project_data.project_cat_id" [list_user_id]="list_user_id" [role]="[2,4,5,7]" [client_team_id]="project_data.team_id"
    (list_user_id_output)="getListUserId($event)"></penji-user-select>
</ng-template>
