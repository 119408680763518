/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@penji/shared/environments';
import { catchError, of, retry, switchMap, take, throttleTime, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  apiURL = environment.api_heroku;
  api_project_v2 = environment.api_project_v2;
  api_team = environment.api_team;
  api_postgresql = environment.api_postgresql;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);

  async changeUserEmail(user_id: string, new_email: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        const data = {
          'token': rs,
          'uid': user_id,
          'email': new_email,
          'password': ''
        }
        return this.http.post(this.apiURL + '/user/update-email-password-client', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            if (rs['success']) {
              return { flag: true, message: 'Email updated successfully!' };
            } else {
              return { flag: false, message: 'Error! Please try it again!' };
            }
          })
          .catch((error: any) => {
            return { flag: false, message: error.message };
          });
      } else {
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token' };
    }
  }

  async changeUserPassword(user_id: string, new_password: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        const data = {
          'token': rs,
          'uid': user_id,
          'email': '',
          'password': new_password
        }
        return this.http.post(this.apiURL + '/user/update-email-password-client', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            if (rs['success']) {
              return { flag: true, message: 'Password updated successfully!' };
            } else {
              return { flag: false, message: 'Error! Please try it again!' };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message };
          });
      } else {
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token' };
    }
  }
  devCreateToken(email: string, password: string) {
    let data = {
      'email': email,
      'password': password
    }
    return this.http.post(this.apiURL + '/user/dev-create-token', data, httpOptions)
      .pipe(
        throttleTime(1500),
        retry(3), // retry a failed request up to 3 times
        catchError(err => {
          console.log(err);
          return throwError(() => new Error(err.message));
        })
      )
      .toPromise()
      .then(res => res)
      .catch(error => error);
  }
  async createPenjiUser(data: any) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        data.token = rs;
        data.permission = {}; // need to remove, api validating the field
        return this.http.post(this.apiURL + '/user/create-new', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            }))
          .toPromise()
          .then((rs: any) => {
            // console.log(rs);
            if (rs['success']) {
              return { flag: true, message: 'User created successfully!', user_id: rs['user_id'] };
            } else {
              return { flag: false, message: rs['err'], user_id: null };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message, user_id: null };
          });
      } else {
        return { flag: false, message: 'Can NOT get token', user_id: null };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token', user_id: null };
    }
  }

  createLinkInvite(token: string, emails: string[], role: string, team_id: string, invited_by: string) {
    let data = {
      'token': token,
      'emails': emails,
      'role': role,
      'team_id': team_id,
      'invited_by': invited_by
    }
    return this.http.post(this.apiURL + '/team/create-link-invite', data, httpOptions)
      .pipe(throttleTime(1500))
      .toPromise().then((rs: any) => {
        if (rs.success) {
          return ({ flag: true, message: 'Inviting user sent!' });
        } else {
          return ({ flag: false, message: rs.message });
        }
      }).catch(error => {
        console.log(error);
        return ({ flag: false, message: error.message });
      });
  }

  async createLinkInviteV2(team_id: string, host_link_invite: string, invited_by: string, emails: string[]) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data = {
          'team_id': team_id,
          'host_link_invite': host_link_invite,
          'invited_by': invited_by,
          'emails': emails
        }

        console.log(data);

        return this.http.post(this.api_team + '/create-link-invite', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              return throwError(() => new Error(err.error.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            console.log(rs);
            if (rs['success']) {
              return { flag: true, message: 'Invited email is sent!' };
            } else {
              return { flag: false, message: rs.message };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message };
          });
      } else {
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token' };
    }
  }

  async createAccountInvited(token: string, email: string, password: string, first_name: string, last_name: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    const data = {
      'email': email,
      'password': password,
      'first_name': first_name,
      'last_name': last_name,
      'token': token
    }

    console.log(data);

    return this.http.post(this.api_team + '/create-account-by-link-invite', data, httpOptions)
      .pipe(
        throttleTime(1500),
        retry(3), // retry a failed request up to 3 times
        catchError(err => {
          console.log(err);
          return throwError(() => new Error(err.error.message));
        })
      )
      .toPromise()
      .then((rs: any) => {
        if (rs['success']) {
          return { flag: true, message: 'User successfully created!' };
        } else {
          return { flag: false, message: rs.message };
        }
      })
      .catch(error => {
        return { flag: false, message: error.message };
      });
  }

  async moveProject(project_id_in: string, project_id_out?: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data = {
          'project_id_out': project_id_out,
          'project_id_in': project_id_in
        }

        return this.http.post(this.api_project_v2 + '/replace-project', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            if (rs['success']) {
              return { flag: true, message: 'Project moved successfully!' };
            } else {
              return { flag: false, message: rs.message };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message };
          });
      } else {
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token' };
    }
  }

  async moveV2(team_id: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data = {
          'team_id': team_id,
        }

        return this.http.post(this.api_project_v2 + '/move-project-to-v2', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            if (rs['success']) {
              return { flag: true, message: 'Team moved successfully!' };
            } else {
              return { flag: false, message: rs.message };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message };
          });
      } else {
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token' };
    }
  }

  getListProductStripe() {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          // console.log(token);
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              }),
            };
            return this.http.post('https://api.penji.co/api-checkout/list-product', {}, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  getListPriceStripe(product_id: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          // console.log(token);
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              }),
            };
            return this.http.post('https://api.penji.co/api-checkout/list-price', { product_id }, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  reactiveSubscriptionByAdmin(client_team_id: string, product_id: string, price_id: string, coupon_id?: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          // console.log(token);
          let data: any = {
            team_id: client_team_id,
            product_id,
            price_id
          }
          if (coupon_id) {
            data['coupon_id'] = coupon_id;
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              }),
            };
            return this.http.post('https://api.penji.co/api-checkout/reactive-subscription-by-admin', data, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }
  addPriceToProduct(product_id: string, amount: number, interval: string){
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          // console.log(token);
          let data: any = {
            product_id,
            amount,
            interval
          }
          try {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              }),
            };
            return this.http.post('https://api.penji.co/api-checkout/create-price', data, httpOptions)
              .pipe(
                throttleTime(500),
                retry(3), // retry a failed request up to 3 times
                catchError(err => {
                  console.log(err);
                  return throwError(() => new Error(err.message));
                }),
              )
              .toPromise()
              .then(res => res)
              .catch(error => error);
          } catch (error: any) {
            throw new Error(error);
          }
        } else {
          return of(null);
        }
      })).pipe(take(1)).toPromise();
  }

  // tracking data
  async getTotalAssign(query_by: string, team_designer_id: string, start_at: string, end_at: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data = {
          'id': team_designer_id,
          'query_by': query_by,
          'start_at': start_at,
          'end_at': end_at
        }

        return this.http.post(this.api_postgresql + '/total-project-active', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              return throwError(() => new Error(err.error.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            // console.log(rs);
            if (rs['success']) {
              return { flag: true, message: 'Total assign successfully got!', data: rs['data'] };
            } else {
              return { flag: false, message: rs.message, data: null };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message, data: null };
          });
      } else {
        return { flag: false, message: 'Can NOT get token', data: null };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token', data: null };
    }
  }

  //tracking data
  async getTotalVerify(query_by: string, team_designer_id: string, start_at: string, end_at: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data = {
          'id': team_designer_id,
          'query_by': query_by,
          'start_at': start_at,
          'end_at': end_at
        }

        return this.http.post(this.api_postgresql + '/total-design-verify', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              return throwError(() => new Error(err.error.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            // console.log(rs);
            if (rs['success']) {
              return { flag: true, message: 'Total assign successfully got!', data: rs['data'] };
            } else {
              return { flag: false, message: rs.message, data: null };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message, data: null };
          });
      } else {
        return { flag: false, message: 'Can NOT get token', data: null };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token', data: null };
    }
  }

  async updateClientToV2(team_id: string) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data = {
          'team_id': team_id,
        }

        return this.http.post(this.api_project_v2 + '/move-project-to-v2', data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              return throwError(() => new Error(err.error.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            // console.log(rs);
            if (rs['success']) {
              return { flag: true, message: 'Total assign successfully got!', data: rs['data'] };
            } else {
              return { flag: false, message: rs.message, data: null };
            }
          })
          .catch(error => {
            return { flag: false, message: error.message, data: null };
          });
      } else {
        return { flag: false, message: 'Can NOT get token', data: null };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token', data: null };
    }
  }

  /************** TRACKING DATA ****************/
  /*
  api_link: total-project-active, total-project-complete, total-project-first-tries, total-design-upload, total-design-reject, total-design-verify, total-project-revision, list-project-complete, list-project-active, list-design-upload
  query_by: designer, team_designer, workspace
  type_complete: 2, 4, 7
  */
  async getTrackingData(api_link: string, id: string, start_at: string, end_at: string, query_by?: string, type_complete?: number) {
    try {
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {

        console.log(rs);

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${rs}`
          })
        }

        const data: any = {
          'id': id,
          'start_at': start_at,
          'end_at': end_at
        }

        if (query_by!==undefined) {
          data['query_by'] = query_by
        }

        if (type_complete!==undefined) {
          data['type_complete'] = type_complete;
        }

        console.log(data);

        return this.http.post(this.api_postgresql + '/' + api_link, data, httpOptions)
          .pipe(
            throttleTime(1500),
            retry(3), // retry a failed request up to 3 times
            catchError(err => {
              console.log(err);
              return throwError(() => new Error(err.error.message));
            })
          )
          .toPromise()
          .then((rs: any) => {
            // console.log(rs);
            if (rs['success']) {
              return { flag: true, message: 'Data successfully got!', data: rs['data'] };
            } else {
              return { flag: false, message: rs.message, data: null };
            }
          })
          .catch(error => {
            console.log(error);
            return { flag: false, message: error.message, data: null };
          });
      } else {
        return { flag: false, message: 'Can NOT get token', data: null };
      }
    } catch {
      return { flag: false, message: 'Can NOT get token', data: null };
    }
  }
}
