import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router, RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MembershipNameComponent } from '@penji/client-v3/membership/membership-name';
import { MediaRefComponent } from '@penji/client-v3/media/ui/media-ref';
import { NotificationLayoutComponent } from '@penji/client-v3/notifications/ui/notification-layout';
import { CheckSubscriptionPermissionDirective } from '@penji/client-v3/shared/data-access';
import { UserItemComponent } from '@penji/client-v3/shared/ui/user-item';
import { AuthStore } from '@penji/shared/auth/data-access';
import { SafeHtmlPipe } from '@penji/shared/pipes';
import { ThemeDropdownComponent } from '@penji/team/shared/shared-ui/theme-dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { CookieService } from 'ngx-cookie-service';
import { SearchComponent } from '@penji/client-v3/shared/ui/search';
import { MembershipSummaryComponent } from '@penji/client-v3/membership/membership-summary';

@Component({
  selector: 'penji-header',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    NzButtonModule,
    NzDropDownModule,
    NzDividerModule,
    NzListModule,
    NzSkeletonModule,
    SafeHtmlPipe,
    RouterModule,
    NotificationLayoutComponent,
    CheckSubscriptionPermissionDirective,
    ThemeDropdownComponent,
    MembershipNameComponent,
    MediaRefComponent,
    SearchComponent,
    MembershipSummaryComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  private readonly afa = inject(AngularFireAuth);
  private readonly authStore$ = inject(AuthStore);
  private readonly router = inject(Router);
  private readonly cookieSV = inject(CookieService);
  
  uid$ = this.afa.authState;
  user$ = this.authStore$.authProfile$;


  logout(){
    this.afa.signOut().then(
      () => {
        this.cookieSV.delete('login_penji', '/', 'penji.co');
        this.router.navigate(['/login']);
      }
    );
  }
}
