<svg nz-popover nzPopoverPlacement="right" [nzPopoverContent]="customerClass" [nzPopoverOverlayClassName]="'[&_.ant-popover-inner]:min-w-[460px]'" class="inner-block mx-0.5 cursor-pointer" [attr.width]="icon_size" [attr.height]="icon_size" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 6.5H5.5V7.5H4.5V6.5ZM4.5 2.5H5.5V5.5H4.5V2.5ZM4.995 0C2.235 0 0 2.24 0 5C0 7.76 2.235 10 4.995 10C7.76 10 10 7.76 10 5C10 2.24 7.76 0 4.995 0ZM5 9C2.79 9 1 7.21 1 5C1 2.79 2.79 1 5 1C7.21 1 9 2.79 9 5C9 7.21 7.21 9 5 9Z" [attr.fill]="icon_color"/>
</svg>
<ng-template #customerClass>
    <div class="text-gray-800 dark:text-gray-100 pb-3">
        <h6 class="text-xl font-semibold mb-3 dark:text-white">Customer Classification</h6>
        <p class="text-base font-normal mb-4">Customer labels serve as indicators for project priority as well as . Labels are denoted by specific letters:</p>
        <ul class="
            [&>li]:text-base [&>li]:flex [&>li]:items-center [&>li:not(:last-child)]:mb-5
            [&_.class-label]:mr-3

            inline-block
            [&_.class-label]:inline-block [&_.class-label]:w-6 [&_.class-label]:h-6 [&_.class-label]:rounded-full  [&_.class-label]:font-semibold
            [&_.class-label]:text-sm [&_.class-label]:leading-6 [&_.class-label]:text-center
        ">
            
            <li><span nz-tooltip nzTooltipTitle="S Class Level" class="class-label outline-double outline-4 outline-offset-0 border-green-500 text-green-500 bg-green-50">S</span>
                Our best customers. On Agency plan or higher.</li>
            <li><span nz-tooltip nzTooltipTitle="A Class Level" class="class-label outline-double outline-4 outline-offset-0 border-blue-500 text-blue-500 bg-blue-50">A</span>Our leads are likely to upgrade S-class customers</li>
            <li><span nz-tooltip nzTooltipTitle="D Class Level" class="class-label outline-double outline-4 outline-offset-0 border-red-500 text-red-500 bg-red-50">D</span>Inexperienced customers working with creatives.</li>
            <li><span nz-tooltip nzTooltipTitle="Unidentified Class Level" class="class-label outline-double outline-4 outline-offset-0 border-gray-500 text-gray-500 bg-gray-50">U</span>Represents unassigned/unlabeled</li>
        </ul>
    </div>
</ng-template>

