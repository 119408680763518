import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';

@Component({
  selector: 'penji-media-cover-v2',
  standalone: true,
  imports: [CommonModule, NzImageModule, NgOptimizedImage],
  templateUrl: './media-cover-v2.component.html',
  styleUrls: ['./media-cover-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[NgOptimizedImage]
})
export class MediaCoverV2Component {
  @Input() media_url?: string;
  @Input() media_size: "medium" | "thumb" | "avatar" | "original" = "avatar";
  @Input() clickable = false;
  @Input() image_auto_fit = false;
  @Input() image_type: 'image' | 'design' = 'image';

  private readonly nzImageService = inject(NzImageService);
  
  media$ = new BehaviorSubject<any>({});
  placeholder = "./assets/images/no-image.png";

  ngOnInit(): void {
    if(this.image_type==='design'){
      this.placeholder = './assets/images/no-design.jpg'
    }
  }
  ngOnChanges() {
    if (this.media_url) {
      switch (this.media_size) {
        case 'medium': {
          this.media$.next({
            url: this.media_url + '?format=webp&size=600x460', 
            alt: this.media_url
          });
          break;
        }
        case 'thumb': {
          this.media$.next({
            url: this.media_url + '?format=webp&size=175x125', 
            alt: this.media_url
          });
          break;
        }
        case 'original': {
          this.media$.next({
            url: this.media_url,
            alt: this.media_url
          });
          break;
        }
        default: {
          this.media$.next({
            url: this.media_url + '?format=webp&size=150x150',
            alt: this.media_url
          });
          break;
        }
      }
    }
  }
  openImage(){
    const images = [
      {
        src: this.media_url + '?format=webp',
        alt: 'Penji image'
      }
    ]
    this.nzImageService.preview(images);
  }
}