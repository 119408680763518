<p class="dark:text-white {{classes}}">
    <ng-container [ngSwitch]="user_role">
        <ng-container *ngSwitchCase="1">Admin</ng-container>
        <ng-container *ngSwitchCase="2">Team Leader</ng-container>
        <ng-container *ngSwitchCase="3">Customer</ng-container>
        <ng-container *ngSwitchCase="4">{{is_pm?'Project Manager':'Designer'}}</ng-container>
        <ng-container *ngSwitchCase="5">Social Media Manager</ng-container>
        <ng-container *ngSwitchCase="7">Supporter</ng-container>
        <ng-container *ngSwitchDefault>N/A</ng-container>
    </ng-container>
</p>