/* eslint-disable */
import { Injectable } from '@angular/core';
import { map, of, switchMap } from 'rxjs';
import { LogService } from './log.service';
import { StylePreference, StylePreferenceTag } from '../models/style-preference.model';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class StylePreferenceService extends LogService<StylePreference> {

  listStylePreference(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>) {
    try {
      let query: Query = this.afs.firestore.collection('style_preference');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      query = query.orderBy('updated_at', 'desc');

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<StylePreference> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as StylePreference;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      }).catch(error => {
        console.log(error);
        return [];
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  listTagByCate(project_category_id: string) {
    try {
      return this.afs.doc(`category_v2/${project_category_id}`).valueChanges().pipe(
        switchMap((cate: any) => {
          if (cate && cate.style_preference_tag && cate.style_preference_tag.length > 0) {
            const promises: any = [];
            cate.style_preference_tag.forEach((tag_id: string) => {
              promises.push(this.afs.firestore.doc(`style_preference_tag/${tag_id}`).get().then(rs=> {
                let data = rs.data() as StylePreferenceTag;
                data.id = rs.id;
                return data;
              }).catch(() => null));
            });
            return Promise.all(promises);
          } else {
            return of([] as StylePreferenceTag[]);
          }
        })
      )
    } catch(err) {
      console.log(err);
      return of([] as StylePreferenceTag[]);
    }

  }

  getStylePreferenceDetail(style_preference_id: string) {
    return this.afs.doc<StylePreference>(`style_preference/${style_preference_id}`).valueChanges();
  }


}
