import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationAllComponent } from '@penji/client-v3/notifications/ui/notification-all';
import { NotificationMessagesComponent } from '@penji/client-v3/notifications/ui/notification-messages';
import { NotificationUploadsComponent } from '@penji/client-v3/notifications/ui/notification-uploads';
import { Notification, NotitficationService } from '@penji/shared/data-access';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BehaviorSubject } from 'rxjs';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

@Component({
  selector: 'penji-notification-layout',
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    NzTabsModule,
    NzListModule,
    RouterModule,
    NotificationAllComponent,
    NotificationUploadsComponent,
    NotificationMessagesComponent,
    NzBadgeModule
  ],
  templateUrl: './notification-layout.component.html',
  styleUrls: ['./notification-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationLayoutComponent {
  private readonly notificationSV = inject(NotitficationService);
  visible = false;
  selectedIndex = 0;
  markAllAsUnReaded$ = new BehaviorSubject<Notification[]>([]);
  total$ = this.notificationSV.getTotalNotification();
  toggleMarkAllAsRead() {
    if (this.markAllAsUnReaded$.value.length > 0)
      this.notificationSV.markAllAsRead(this.markAllAsUnReaded$.value);
  }
  getListUnread(list: any) {
    this.markAllAsUnReaded$.next(list);
  }
  updateTotal(total?: number) {
    if (total && total > 0)
      this.notificationSV.updateTotal();
  }
}
