import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceProjectActiveComponent } from '@penji/team/workspace/workspace-ui/workspace-project-active';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@Component({
  selector: 'penji-workspace-membership-status',
  standalone: true,
  imports: [
    CommonModule, 
    WorkspaceProjectActiveComponent,
    NzDropDownModule
  ],
  templateUrl: './workspace-membership-status.component.html',
  styleUrls: ['./workspace-membership-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceMembershipStatusComponent {

  @Input() membership_status?: string = '';
  @Input() client_team_id?: string;
  @Input() size?: 'sm' | 'lg' = 'sm';

  ngOnChanges() {
    if (this.membership_status) {
      if (this.membership_status.includes('pro') || this.membership_status.includes('starter') || 
      this.membership_status.includes('369') || this.membership_status.includes('399') || this.membership_status.includes('499') || 
      this.membership_status.includes('price_1O9Ws6I0Oq9o6u7MAVQ7bdYX') || this.membership_status.includes('479')) {
        this.membership_status = 'starter';
      } else if (this.membership_status.includes('team') || this.membership_status.includes('marketer') || this.membership_status.includes('market') || this.membership_status.includes('price_1OFqBII0Oq9o6u7MrW0NsT4e')) {
        this.membership_status = 'marketer';
      } else if (this.membership_status.includes('agency') || this.membership_status.includes('899')) {
        this.membership_status = 'agency';
      } else if (this.membership_status.includes('one-off')) {
        this.membership_status = 'one-off';
      } else {
        const arr_agency = [
          '1-agency-business',
          '10-agency-business',
          '15-agency-business',
          '50-agency-business',
          'agency-1-business',
          'agency-business',
          'penji_698',
          'penji_899',
          'penji_agency_quarterly_2427',
          'penji_agency_yearly_7116',
          'penji_agency_yearly_7116_new',
          'penji_agency_yearly_9168',
          'price_1InajTI0Oq9o6u7MzyVC5J1M',
          'price_1InakOI0Oq9o6u7MeTzcMb35',
          'price_1J3HPgI0Oq9o6u7MdoFZP00T',
          'price_1J3HRDI0Oq9o6u7Mh0xhbEwf',
          'price_1J3HSRI0Oq9o6u7MI4J0mR6f',
          'yearly_8991_penji_daytime_2022',
          'monthly_999_penji_daytime_2022',
          'monthly_plan_agency_2023',
          'yearly_plan_agency_2023',
          'monthly_plan_agency_2023_v1',
          'yearly_plan_agency_2023_v1',
          'agency_monthly_2023',
          'agency_quarterly_2023',
          'agency_yearly_2023',
        ];
        if (arr_agency.includes(this.membership_status)) {
          this.membership_status = 'agency';
        } else {
          // this.membership_status = 'invited';
        }

      }

    } else {
      this.membership_status = 'invited';
    }
  }

}
