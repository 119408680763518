<ng-container [ngSwitch]="avatar_shape">
    <ng-container *ngSwitchCase="'square'">
        <div class="rounded-lg overflow-hidden ring-2 ring-white dark:ring-gray-500" [style.width.px]="setImageWidth"
            [style.height.px]="setImageWidth">
            <penji-media-ref [media_ref]="avatar_ref" [media_size]="avatar_size"></penji-media-ref>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'circle'">
        <div class="rounded-full overflow-hidden ring-2 ring-white dark:ring-gray-500" [style.width.px]="setImageWidth"
            [style.height.px]="setImageWidth">
            <penji-media-ref [media_ref]="avatar_ref" [media_size]="avatar_size"></penji-media-ref>
        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <div class="w-full h-full rounded-none overflow-hidden ring-2 ring-white dark:ring-gray-500">
            <penji-media-ref [media_ref]="avatar_ref" [media_size]="avatar_size"></penji-media-ref>
        </div>
    </ng-container>
</ng-container>

