<ng-container *ngIf="PROJECT_STATUS[this.result_status]">
  <span class="{{PROJECT_STATUS[this.result_status]['color']}} text-sm rounded font-semibold inline-flex items-center justify-center gap-x-2 px-2 py-1 min-w-[80px]">
    <!-- icon -->
    <span class="material-icons-outlined" *ngIf="PROJECT_STATUS[this.result_status]['key']==='positive completed'">
      sentiment_very_satisfied
    </span>
    <span class="material-icons-outlined" *ngIf="PROJECT_STATUS[this.result_status]['key']==='neutral completed'">
      sentiment_neutral
    </span>
    <span class="material-icons-outlined" *ngIf="PROJECT_STATUS[this.result_status]['key']==='negative completed'">
      sentiment_dissatisfied
      </span>
    {{((PROJECT_STATUS[this.result_status]['key']==='positive completed' || PROJECT_STATUS[this.result_status]['key']==='neutral completed' || PROJECT_STATUS[this.result_status]['key']==='negative completed') ? 'complete' : PROJECT_STATUS[this.result_status]['key']) | titlecase}}
  </span>
</ng-container>
