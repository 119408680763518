import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Project } from '@penji/shared/data-access';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PermissionDirective } from '@penji/shared/directives';
import * as firebase from 'firebase/firestore';
import { ProjectStoreService } from '@penji/team/project/data-access';

@Component({
  selector: 'penji-project-final-assigned',
  standalone: true,
  imports: [
    CommonModule,
    NzPopoverModule,
    NzButtonModule,
    PermissionDirective
  ],
  templateUrl: './project-final-assigned.component.html',
  styleUrls: ['./project-final-assigned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFinalAssignedComponent {

  @Input() project_data!: Project;

  projectStoreSv = inject(ProjectStoreService);

  visible_popover = false;
  isLoading = false;

  async final() {
    this.visible_popover = false;
    this.isLoading = true;
    const project_change = new Project();
    project_change.id = this.project_data.id;
    project_change.team_id = this.project_data.team_id;
    project_change.final_assigned = 1;
    project_change.updated_at = firebase.Timestamp.now();
    this.projectStoreSv.updateProject$(project_change);
    this.isLoading = false;
  }

  async undo_final() {
    this.visible_popover = false;
    this.isLoading = true;
    const project_change = new Project();
    project_change.id = this.project_data.id;
    project_change.team_id = this.project_data.team_id;
    project_change.final_assigned = 0;
    project_change.updated_at = firebase.Timestamp.now();
    this.projectStoreSv.updateProject$(project_change);
    this.isLoading = false;
  }
  
}
