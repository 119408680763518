<ng-container *ngIf="list_comment$ | async"></ng-container>

<img id="sourceImage" [src]="image_url" class="w-full rounded"/>

<button nz-button nzSize="small" class="absolute left-3 top-3 flex items-center justify-center w-12 transition-all invisible opacity-0 group-hover:opacity-100 group-hover:visible" nz-tooltip [nzTooltipTitle]="visible ? 'Show note' : 'Hide note'" nzType="default" (click)="hideNote()">
  <span class="material-icons-outlined text-lg">
  {{visible ? 'visibility' : 'visibility_off'}}
  </span>
</button>

