<ng-container *ngIf="workspace_data$ | async as workspace_data">
  <ng-container *ngIf="workspace_data.membership && workspace_data.membership.length > 0; else no_data">
    <ng-container *ngFor="let membership of workspace_data.membership.slice(0, 1)">
      <div nz-dropdown [nzDropdownMenu]="workspace_data.membership.length>1?menu:null" class="inline-flex items-end space-x-0.5">
        <penji-workspace-membership-status [membership_status]="membership.price_id" [client_team_id]="client_team_id"></penji-workspace-membership-status>
        <p *ngIf="workspace_data.membership.length > 1" class="inline-flex items-end space-x-0.5">
          <span>...</span>
          <span class="material-icons-outlined text-[18px] text-gray-500">
            expand_more
          </span>
        </p>
      </div>
    </ng-container>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable class="[&_li]:cursor-default [&_li]:!bg-transparent">
        <li nz-menu-item *ngFor="let membership of workspace_data.membership.slice(0, 1)">
          <penji-workspace-membership-status [membership_status]="membership.price_id" [client_team_id]="client_team_id"></penji-workspace-membership-status>
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
  <ng-template #no_data>
    <p class="text-gray-500">N/A</p>
  </ng-template>

</ng-container>
