<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
    <div class="flex justify-between items-center pb-2 pr-20">
        <h6 class="text-gray-600 capitalize dark:text-white">Tracking Data</h6>
    </div>
    <div class="h-[calc(100%-38px)] overflow-y-auto">
        <nz-table #borderedTable nzBordered [nzData]="[{a:'a'}]" nzShowPagination="false">
            <thead>
                <tr>
                    <th nzLeft>
                        <nz-date-picker [(ngModel)]="date" (ngModelChange)="onChangeDate($event)" [nzDisabledDate]="disabledDate" nzFormat="MMM dd, yyyy"></nz-date-picker>
                    </th>
                    <th>Group A</th>
                    <th>Group B</th>
                    <th>West</th>
                </tr>
            </thead>
            <tbody class="[&>tr>td:first-child]:font-semibold [&_td]:dark:text-white [&_tr:hover>td]:dark:!bg-[#64758b] [&_td:hover]:dark:!bg-[#64758b]">
                <ng-container *ngIf="{ data: data$ | async, teams: teams$ | async, total_assign: total_assign$ | async , total_verify: total_verify$ |async} as vm; else noData">
                    <tr>
                        <td>Total designers</td>
                        <td>{{vm.teams ? vm.teams[0].total_designer : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[1].total_designer : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[2].total_designer : '0'}}</td>
                    </tr>
                    <tr>
                        <td>Total clients</td>
                        <td>{{vm.teams ? vm.teams[0].total_active_client_assigned : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[1].total_active_client_assigned : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[2].total_active_client_assigned : '0'}}</td>
                    </tr>
                    <tr>
                        <td>Total projects Limit</td>
                        <td>{{vm.teams ? vm.teams[0].total_out_put_design : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[1].total_out_put_design : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[2].total_out_put_design : '0'}}</td>
                    </tr>
                    <tr>
                        <td>Total projects assigned</td>
                        <!-- <td>{{vm.teams ? vm.teams[0].total_active : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[1].total_active : '0'}}</td>
                        <td>{{vm.teams ? vm.teams[2].total_active : '0'}}</td> -->
                        <td>{{(vm.total_assign && vm.total_assign.assign_project_group_a) ? vm.total_assign.assign_project_group_a : '0'}}</td>
                        <td>{{(vm.total_assign && vm.total_assign.assign_project_group_b) ? vm.total_assign.assign_project_group_b : '0'}}</td>
                        <td>{{(vm.total_assign && vm.total_assign.assign_project_west) ? vm.total_assign.assign_project_west : '0'}}</td>
                    </tr>
                    <tr>
                        <td># of designs verified</td>
                        <td>{{(vm.total_verify && vm.total_verify.verify_group_a) ? vm.total_verify.verify_group_a : '0'}}</td>
                        <td>{{(vm.total_verify && vm.total_verify.verify_group_b) ? vm.total_verify.verify_group_b : '0'}}</td>
                        <td>{{(vm.total_verify && vm.total_verify.verify_group_west) ? vm.total_verify.verify_group_west : '0'}}</td>
                    </tr>
                    <tr>
                      <td># of of projects delivered</td>
                      <td>{{(vm.data && vm.data.delivered_group_a) ? vm.data.delivered_group_a : '0'}}</td>
                      <td>{{(vm.data && vm.data.delivered_group_b) ? vm.data.delivered_group_b : '0'}}</td>
                      <td>{{(vm.data && vm.data.delivered_west) ? vm.data.delivered_west : '0'}}</td>
                  </tr>
                    <tr>
                        <td># of Revisions for Revision Team</td>
                        <td>{{(vm.data && vm.data.revision_group_a) ? vm.data.revision_group_a : '0'}}</td>
                        <td>{{(vm.data && vm.data.revision_group_b) ? vm.data.revision_group_b : '0'}}</td>
                        <td>{{(vm.data && vm.data.revision_west) ? vm.data.revision_west : '0'}}</td>
                    </tr>
                    <tr>
                        <td># of projects Completed</td>
                        <td>{{(vm.data && vm.data.completed_group_a) ? vm.data.completed_group_a : '0'}}</td>
                        <td>{{(vm.data && vm.data.completed_group_b) ? vm.data.completed_group_b : '0'}}</td>
                        <td>{{(vm.data && vm.data.completed_west) ? vm.data.completed_west : '0'}}</td>
                    </tr>
                    <!-- <tr>
                        <td># of First-tries</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr> -->
                </ng-container>
                <ng-template #noData>
                    <tr>
                        <td colspan="4" class="text-center py-8">
                            <nz-spin nzSimple [nzSize]="'large'" class="mx-auto"></nz-spin>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </nz-table>
    </div>
</div>
