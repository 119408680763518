import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProjectLinkTitleComponent } from '@penji/client-v3/shared/ui/project-link-title';
import { UserItemComponent } from '@penji/client-v3/shared/ui/user-item';
import { Notification } from '@penji/shared/data-access';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { MomentModule } from 'ngx-moment';

@Component({
  selector: 'penji-notification-item',
  standalone: true,
  imports: [
    CommonModule, 
    MomentModule,
    NzPipesModule,
    NzTypographyModule,
    ProjectLinkTitleComponent,
    UserItemComponent,
    RouterModule
  ],
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {

  @Input() notification?: Notification;
  @Input() onpage = false;
}
