<ng-container *ngIf="workspace_data && workspace_data.label_info; else no_label">
  <div class="
    grid grid-cols-1 gap-4
    [&>div]:flex [&>div]:items-center [&>div]:justify-between [&>div]:text-gray-800 [&>div]:text-base [&>div]:leading-[18px]
    [&>div>span:first-child]:text-gray-500 dark:[&>div>span:first-child]:text-gray-200
  ">
    <div>
      <span>Experience level:</span>
      <span class="!text-{{workspace_data.label_info.experience_level===3?'green-500':workspace_data.label_info.experience_level===2?'blue-500':workspace_data.label_info.experience_level===1?'orange-500':'red-500'}}">
        <ng-container [ngSwitch]="workspace_data.label_info.experience_level??0">
          <ng-container *ngSwitchCase="3">High</ng-container>
          <ng-container *ngSwitchCase="2">Medium</ng-container>
          <ng-container *ngSwitchCase="1">Low</ng-container>
          <ng-container *ngSwitchDefault>Unidentified</ng-container>
        </ng-container>
      </span>
    </div>
    <div>
      <span>Project volume:</span>
      <span class="!text-{{workspace_data.label_info.project_volume===3?'green-500':workspace_data.label_info.project_volume===2?'blue-500':workspace_data.label_info.project_volume===1?'orange-500':'red-500'}}">
        <ng-container [ngSwitch]="workspace_data.label_info.project_volume??0">
          <ng-container *ngSwitchCase="3">High</ng-container>
          <ng-container *ngSwitchCase="2">Medium</ng-container>
          <ng-container *ngSwitchCase="1">Low</ng-container>
          <ng-container *ngSwitchDefault>Unidentified</ng-container>
        </ng-container>
      </span>
    </div>
    <div>
      <span>Project difficulties:</span>
      <span class="!text-{{workspace_data.label_info.project_difficulties===3?'green-500':workspace_data.label_info.project_difficulties===2?'blue-500':workspace_data.label_info.project_difficulties===1?'orange-500':'red-500'}}">
          <ng-container [ngSwitch]="workspace_data.label_info.project_difficulties??0">
              <ng-container *ngSwitchCase="3">High</ng-container>
              <ng-container *ngSwitchCase="2">Medium</ng-container>
              <ng-container *ngSwitchCase="1">Low</ng-container>
              <ng-container *ngSwitchDefault>Unidentified</ng-container>
          </ng-container>
      </span>
    </div>
    <div>
      <span>Client’s personality:</span>
      <span
          class="!text-{{workspace_data.label_info.personality===3?'green-500':workspace_data.label_info.personality===2?'blue-500':workspace_data.label_info.personality===1?'orange-500':'red-500'}}">
          <ng-container [ngSwitch]="workspace_data.label_info.personality??0">
              <ng-container *ngSwitchCase="3">High</ng-container>
              <ng-container *ngSwitchCase="2">Medium</ng-container>
              <ng-container *ngSwitchCase="1">Low</ng-container>
              <ng-container *ngSwitchDefault>Unidentified</ng-container>
          </ng-container>
      </span>
    </div>
  </div>
</ng-container>
<ng-template #no_label>
  <nz-empty [nzNotFoundContent]="'Customer without a label!'" class="[&>.ant-empty-image]:!h-8 pt-5 text-gray-500" nzNotFoundImage="simple"></nz-empty>
</ng-template>
