<ng-container *ngIf="!move_right; else right">
    <div [ngSwitch]="status" class="[&>div]:inline-flex [&>div]:items-center [&>div]:gap-1">
        <div *ngSwitchCase="'hibernate'">
            <span class="{{color$.getValue()}} capitalize gap-x-2 inline-flex items-center justify-center min-w-[80px] px-2 py-1 rounded text-sm text-white ng-star-inserted cursor-default {{classes}}">{{status}}</span>
            <span nz-tooltip [nzTooltipTitle]="'Last logged in '+ (lastLogin ? (lastLogin.toDate() | amTimeAgo) : '')" class="material-icons-outlined text-[14px] cursor-pointer text-gray-600 dark:text-gray-200">info</span>
        </div>
        <div *ngSwitchCase="'renew'">
            <span class="{{color$.getValue()}} capitalize gap-x-2 inline-flex items-center justify-center min-w-[80px] px-2 py-1 rounded text-sm text-white ng-star-inserted cursor-default {{classes}}">{{status}}</span>
            <span nz-tooltip [nzTooltipTitle]="'Upcoming billing '+ (nextBill ? (nextBill.toDate() | amTimeAgo) : '')" class="material-icons-outlined text-[14px] cursor-pointer text-gray-600 dark:text-gray-200">info</span>
        </div>
        <div *ngSwitchCase="'active'">
            <span class="{{color$.getValue()}} capitalize gap-x-2 inline-flex items-center justify-center min-w-[80px] px-2 py-1 rounded text-sm text-white ng-star-inserted cursor-default {{classes}}">{{status}}</span>
            <span nz-tooltip [nzTooltipTitle]="'Upcoming billing '+ (nextBill ? (nextBill.toDate() | amTimeAgo) : '')" class="material-icons-outlined text-[14px] cursor-pointer text-gray-600 dark:text-gray-200">info</span>
        </div>
        <div *ngSwitchCase="'new'">
            <span class="{{color$.getValue()}} capitalize gap-x-2 inline-flex items-center justify-center min-w-[80px] px-2 py-1 rounded text-sm text-white ng-star-inserted cursor-default {{classes}}">{{status}}</span>
            <span nz-tooltip [nzTooltipTitle]="'Upcoming billing '+ (nextBill ? (nextBill.toDate() | amTimeAgo) : '')" class="material-icons-outlined text-[14px] cursor-pointer text-gray-600 dark:text-gray-200">info</span>
        </div>
        <div *ngSwitchDefault>
            <span class="{{color$.getValue()}} capitalize gap-x-2 inline-flex items-center justify-center min-w-[80px] px-2 py-1 rounded text-sm text-white ng-star-inserted cursor-default {{classes}}">{{status}}</span>
            <span *ngIf="canceledTime" nz-tooltip [nzTooltipTitle]="(canceledTime ? 'Cancelled at '+(canceledTime.toDate() | date : 'mediumDate') : '')" class="material-icons-outlined text-[14px] cursor-pointer text-gray-600 dark:text-gray-200">info</span>
        </div>
    </div>
</ng-container>
<ng-template #right>
    <p class="wsp-status absolute right-0 top-0 bottom-0 z-10 text-white rounded-e text-[11px] w-[18px] flex items-center justify-center {{color$.getValue()}} {{classes}}" [title]="status">
        <span class="rotate-90 whitespace-nowrap">{{status | slice:0:6}}<span *ngIf="status.length>6">..</span></span>
    </p>
</ng-template>