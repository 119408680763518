<div *ngIf="{total: total$ | async } as noti">
    <a nz-dropdown [nzClickHide]="false" [(nzVisible)]="visible" [nzDropdownMenu]="menuNotification" nzTrigger="click"
        [nzOverlayClassName]="'w-[400px]'"
        class="flex items-center justify-center bg-white  dark:bg-gray-600 h-[38px] w-[40px] rounded-md shadow-sm hover:shadow">
        <nz-badge (click)="updateTotal(noti?.total?.total_unread)" [nzCount]="noti?.total?.total_unread"
            [nzOffset]="[-3,8]" nzSize="small" class="inline-flex items-center">
            <span class="material-icons text-[26px] {{noti?.total?.total_unread?'text-purple-600':'text-gray-500 dark:text-gray-200'}}">
              notifications
            </span>
        </nz-badge>
    </a>
    <nz-dropdown-menu #menuNotification="nzDropdownMenu">
        <ng-container *ngIf="visible">
            <ng-container *ngIf="{markAllAsUnReaded: markAllAsUnReaded$ | async} as vm">
                <div class="!bg-white rounded-lg shadow-2xl flex flex-col justify-between dark:!bg-gray-700">
                    <div
                        class="flex items-center justify-between border-b-[1px] border-b-gray-200 p-4 min-w-[17.875rem] dark:border-b-gray-500 dark:text-white">
                        <span class="font-semibold text-lg">Notifications</span>
                        <span
                            class="font-semibold cursor-pointer transition hover:opacity-[.75] {{vm.markAllAsUnReaded?.length === 0 ? 'text-gray-400 dark:text-gray-200' : 'text-blue-600'}}"
                            (click)="toggleMarkAllAsRead()">Mark all as read</span>
                    </div>
                    <nz-tabset [nzAnimated]="false" [(nzSelectedIndex)]="selectedIndex"
                        class="[&_.ant-tabs-ink-bar]:hidden hover:[&_.ant-tabs-nav_.ant-tabs-tab]:text-blue-600 [&_.ant-tabs-tab_.ant-tabs-tab-btn]:font-semibold
                    [&_.ant-tabs-nav-wrap]:justify-center [&_.ant-tabs-nav-wrap]:my-2 [&_.ant-tabs-tab]:!m-0 [&_.ant-tabs-tab]:py-2
                    [&_.ant-tabs-tab]:justify-center [&_.ant-tabs-tab]:min-w-[122px] [&_.ant-tabs-tab.ant-tabs-tab-active]:bg-blue-200 [&_.ant-tabs-tab.ant-tabs-tab-active_.ant-tabs-tab-btn]:text-blue-600
                    [&_.ant-tabs-tab.ant-tabs-tab-active]:rounded dark:[&_.ant-tabs-tab]:text-white dark:[&_.ant-tabs-nav:before]:border-b-gray-500">
                        <nz-tab class="notification-tab text-[red]" nzTitle="All">
                            <ng-template nz-tab>
                                <div class="h-[calc(100vh-250px)]">
                                    <penji-notification-all
                                        (listUnread)="getListUnread($event)" [onpage]="false"></penji-notification-all>
                                </div>
                            </ng-template>
                        </nz-tab>
                        <nz-tab class="notification-tab text-[red]" nzTitle="Uploads">
                            <ng-template nz-tab>
                                <penji-notification-uploads
                                    (listUnread)="getListUnread($event)" [onpage]="false"></penji-notification-uploads>
                            </ng-template>
                        </nz-tab>
                        <nz-tab class="notification-tab text-[red]" nzTitle="Messages">
                            <ng-template nz-tab>
                                <penji-notification-messages
                                    (listUnread)="getListUnread($event)" [onpage]="false"></penji-notification-messages>
                            </ng-template>
                        </nz-tab>
                    </nz-tabset>
                    <div>
                        <a (click)="visible = false" [routerLink]="['./notification']"
                            class="flex items-center justify-center text-center text-blue-600 p-3 hover:bg-gray-100 font-semibold dark:bg-gray-600 dark:hover:bg-gray-500">View
                            all</a>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </nz-dropdown-menu>
</div>
