
<ng-container *ngIf="is_mobile; else desktop">
  <div class="is_mobile h-screen">
    <div class="w-full px-5 py-4 border-b border-b-gray-200 flex justify-center items-center">
      <img src="./assets/images/logo-penji.svg" class="w-[80px] dark:hidden" alt="Penji logo">
      <img src="./assets/images/logo-penji-white.svg" class="w-[80px] hidden dark:block" alt="Penji logo">
    </div>
    <div class="text-center px-8 pb-8 pt-10 h-[calc(100%-64px)] flex flex-col items-center bg-gray-50">
      <img src="./assets/images/is_mobile.png" alt="is mobile" class="mb-4">
      <p class="font-bold text-2xl mb-3">
        Please view on desktop to access Penji
      </p>
      <p class="text-gray-600">
        Our website is optimized for desktop. Experience all its features by accessing it on your computer.
      </p>
    </div>
  </div>
</ng-container>
<ng-template #desktop>
  <router-outlet></router-outlet>
</ng-template>
