/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UserData, UserRealTimeDB } from '../models/user.model';
import { environment } from '@penji/shared/environments';
import { getDatabase, onDisconnect, onValue, ref, serverTimestamp, set, } from "firebase/database";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { catchError, lastValueFrom, map, of, retry, switchMap, take, throwError } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  private readonly nzMessageService = inject(NzMessageService);
  private readonly afd = inject(AngularFireDatabase);
  constructor(private afs: AngularFirestore) { this.initStatusAuthOnline();}

  searchUserAlgolia(keyword: string, roles: number[]) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          return this.http.post(`${this.api_url}/search-user`, { keyword, roles }, httpOptions)
            .pipe(
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              })
            );
        } else {
          return of(null);
        }
      }),
      map((result: any) => {
        // console.log(result);
        if (result)
          return (result.data.hits as UserData[]).map(user => ({ ...user, id: user.objectID } as UserData));
        else
          return [];
      }))
  }
  getUserDetail(user_id: string) {
    return this.afs.collection('user').doc<UserData>(user_id).valueChanges().pipe(map(user => ({ id: user_id, ...user } as UserData)));
  }
  getUserDetailOnce(user_id: string) {
    try {
      return this.afs.firestore.collection('user').doc(user_id).get().then(rs => {
        if (rs.data() as UserData) {
          return { ...rs.data(), id: rs.id } as UserData;
        }
        return null;
      }).catch((error) => {
        console.log("Error getting document:", error);
        return Promise.resolve(null);
      });
    } catch (error) {
      console.log(error);
      return Promise.resolve(null);
    }

  }

  updateUser(user_id: string, user_data: UserData) {
    try {
      return this.afs.doc(`user/${user_id}/`).update(UserData.parseObject(user_data))
        .then(() => ({ flag: true, message: 'User updated successfully!' }))
        .catch(err => {
          return Promise.resolve({ flag: false, message: err.message });
        });
    } catch (err: any) {
      return Promise.resolve({ flag: false, message: err.message });
    }
  }
  updateUserByAuth(user_data: UserData, emit_message: 'yes' | 'no' = 'no') {
    return lastValueFrom(this.afAuth.authState.pipe(
      switchMap(user => {
        if (user)
          return this.afs.doc(`user/${user.uid}/`).update(UserData.parseObject(user_data)).then(() => true);
        return of(false);
      }),
      map(result => {
        if (result) {
          if (emit_message === 'yes') {
            this.nzMessageService.success('User updated successfully!');
          }
          return { flag: true, message: 'User updated successfully!' };
        } else {
          return { flag: false, message: 'User updated fail!' }
        }
      })
    ).pipe(take(1)));
  }
  initStatusAuthOnline() {
    this.afAuth.authState.subscribe(rs => {
      if (rs) {
        // console.log(rs.uid);
        const db = getDatabase();
        const myConnectionsRef = ref(db, `users/${rs.uid}/online`);

        // stores the timestamp of my last disconnect (the last time I was seen online)
        const lastOnlineRef = ref(db, `users/${rs.uid}/lastOnline`);

        const isTypingRef = ref(db, `users/${rs.uid}/isTyping`);
        const ticketIdRef = ref(db, `users/${rs.uid}/ticket_id`);

        const connectedRef = ref(db, '.info/connected');
        onValue(connectedRef, (snap) => {
          if (snap.val() === true) {
            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
            set(myConnectionsRef, true);

            // When I disconnect
            onDisconnect(myConnectionsRef).set(false);

            // When I disconnect, update the last time I was seen online
            onDisconnect(lastOnlineRef).set(serverTimestamp());

            onDisconnect(isTypingRef).remove();
            onDisconnect(ticketIdRef).remove();
          }
        });
      }
    })
  }
  updateIsTypingChat(uid: string, ticket_id: string, status: boolean = false) {
    this.afd.object(`users/${uid}`).update({
      ticket_id: ticket_id,
      isTyping: status
    }).then();
  }
  getUserOnlineByUID(user_id: string) {
    return this.afd.object<UserRealTimeDB>(`users/${user_id}`).valueChanges();
  }
  getListUserOnlineByTicket(ticket_id: string) {
    return this.afd.list<UserRealTimeDB>(`users`, ref=> ref.orderByChild('ticket_id').equalTo(ticket_id)).snapshotChanges().pipe(
      map(action => action.map(res => {
        const data = res.payload.val() as UserRealTimeDB;
        data.id = res.key!;
        return data;
      }))
    );
  }
}

