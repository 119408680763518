<ng-container *ngIf="authProfile$ | async as authProfile">
    <div title="Customer support form" cdkDragLockAxis="y" cdkDrag (cdkDragEnded)="dragEnded($event)"
        id="customer_support_form" nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false" [(nzVisible)]="visible"
        [nzTrigger]="'click'" [nzPlacement]="'topRight'"
        class="fixed overflow-hidden z-[9999999] right-5 top-[60%] w-[68px] h-[46px] transition-[width] ease-in-out duration-300 hover:w-[167px] bg-blue-600 shadow-2.5xl hover:bg-blue-500 hover:shadow-xl rounded-full items-center justify-center text-white cursor-pointer dark:[&_.ant-scroll-number]:shadow-none group">
        <p class="w-[167px] h-[46px] flex items-center space-x-2 justify-center relative">
            <span class="material-icons text-[26px] z-20">
                add_comment
            </span>
            <span class="font-medium text-lg opacity-0 group-hover:opacity-100 transition-all duration-100">Need help?</span>
        </p>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <div
            class="max-h-[90dvh] min-h-[300px] w-[450px] bg-white overflow-y-auto overflow-x-hidden py-3 rounded-lg shadow-2xl relative dark:bg-gray-700">
            <a (click)="closeContainer()" class="absolute right-3.5 top-3.5 text-gray-500 hover:text-blue-600">
                <span class="material-icons-outlined text-[24px]">close</span>
            </a>
            <p class="font-bold text-2xl text-center py-3 dark:text-white">Need help?</p>
            <form nz-form [nzLayout]="'vertical'" [formGroup]="validateForm"
                (ngSubmit)="submitForm(authProfile?.id, authProfile?.user_info?.first_name, authProfile?.user_info?.last_name)"
                class="p-4 [&_label]:font-bold [&_label]:text-[18px] [&_label]:text-black">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>How can we help you?</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select nzPlaceHolder="Select ticket category" formControlName="ticket_sub_category_id"
                            (ngModelChange)="changeTicketSubCategory($event)" nzSize="large">
                            <ng-container
                                *ngIf="{listMainTicketCate: listMainTicketCate$ | async, listSubTicketCate: listSubTicketCate$ | async} as listTicketCate">
                                <ng-container *ngFor="let main_cate of listTicketCate.listMainTicketCate">
                                    <nz-option-group [nzLabel]="main_cate?.title">
                                        <ng-container *ngFor="let sub_cate of listTicketCate.listSubTicketCate">
                                            <ng-container
                                                *ngIf="sub_cate?.ticket_category_id === main_cate?.id && !sub_cate?.hide && sub_cate?.source === 3">
                                                <nz-option [nzValue]="sub_cate?.id"
                                                    [nzLabel]="sub_cate?.title"></nz-option>
                                            </ng-container>
                                        </ng-container>
                                    </nz-option-group>
                                </ng-container>
                            </ng-container>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item
                    *ngIf="validateForm.value.ticket_sub_category_id==='mYSahiYDJqUX3Lib7eYb' || validateForm.value.ticket_sub_category_id==='UV4gwTSWJVl2qdXQtuxO'">
                    <nz-form-label [nzSpan]="24">Select your project</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-select nzShowSearch nzPlaceHolder="Select your project" formControlName="project"
                            [nzShowArrow]="false" (nzOnSearch)="searchProject($event)">
                            <ng-container *ngIf="project_search_list$ | async as project_search_list">
                                <nz-option *ngFor="let project of project_search_list" [nzLabel]="project.title"
                                    [nzValue]="[{client_team_id: project.team_id, project_id: project.id, title: project.title}]"></nz-option>
                            </ng-container>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>Description</nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <nz-textarea-count [nzMaxCharacterCount]="2000">
                            <textarea formControlName="description" nz-input rows="4"
                                placeholder="Enter your description"></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>
                <button nz-button nzType="primary" type="submit" nzBlock class="btn-primary-blue mt-2"
                    [disabled]="btnSubmit || validateForm.invalid">Submit</button>
            </form>
        </div>
    </nz-dropdown-menu>
</ng-container>