// google-auth.service.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { loadGapiInsideDOM } from 'gapi-script';
import { catchError, of, retry, switchMap, take, throttleTime, throwError } from 'rxjs';
declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  afs = inject(AngularFirestore);
  private api_url = "https://api.penji.co/api-user";
  private gapiSetup = false;
  private authInstance: any;
  private apiKey = 'AIzaSyDTMHFI9ecEUZfjjAkSHOSbQbejpp8M_vU';
  private clientId = '898355351636-vfgvcnml2k9qrqsa4o873ah95j4j5vrj.apps.googleusercontent.com';

  async initClient() {
    // console.log("gapiSetup", this.gapiSetup);
    if (!this.gapiSetup) {
      await loadGapiInsideDOM();
      await new Promise((resolve, reject) => {
        gapi.load('client:auth2', {
          callback: resolve,
          onerror: reject
        });
      });
      await gapi.client.init({
        apiKey: this.apiKey,
        clientId: this.clientId,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
        scope: 'https://www.googleapis.com/auth/calendar.events',
        access_type: 'offline',
        prompt: 'consent'
      });
      this.authInstance = gapi.auth2.getAuthInstance();
      this.gapiSetup = true;
    }
  }

  async storeToken(authorizationCode: any) {
    try {
      const token = await this.afAuth.idToken.pipe(take(1)).toPromise();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      };
      const response: any = await this.http.post(`${this.api_url}/exchange-token`, { authorizationCode }, httpOptions)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          catchError(err => {
            console.log(err);
            return throwError(() => new Error(err.message));
          })
        ).toPromise();
      // console.log("response_store_token", response);
      return response;
    } catch (error: any) {
      console.log("Error: store token", error);
      return ({ success: false, data: error as string });
    }
  }

  async refreshToken(refresh_token: any) {
    try {
      const token = await this.afAuth.idToken.pipe(take(1)).toPromise();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      };
      const response: any = await this.http.post(`${this.api_url}/refresh-token`, { refresh_token }, httpOptions)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          catchError(err => {
            console.log(err);
            return throwError(() => new Error(err.message));
          })
        ).toPromise();
      // console.log("response_refresh_token", response);
      if (response.success) {
        return response.data;
      } else {
        console.log("server error");
        return null;
      }
    } catch (error) {
      console.log("Error: refresh token", error);
      return null;
    }
  }

  async getAccessToken(uid: any) {
    try {
      const doc_user = await this.afs.firestore.doc(`user/${uid}`).get();
      if (doc_user.exists) {
        const data_user: any = doc_user.data();
        if (data_user.google_meet) {
          const obj_token = {
            "host_email": data_user.google_meet.host_email,
            "host_name": data_user.google_meet.host_name,
            "access_token": ""
          };
          if (Date.now() < data_user.google_meet.expires_at) {
            obj_token["access_token"] = data_user.google_meet.access_token;
            return obj_token;
          } else {
            const new_access_token = await this.refreshToken(data_user.google_meet.refresh_token);
            obj_token["access_token"] = new_access_token;
            return obj_token;
          }
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async signIn() {
    try {
      await this.initClient();
      const response = await this.authInstance.grantOfflineAccess();
      const rs = await this.storeToken(response.code);
      return rs;
    } catch (error: any) {
      console.error('Google Sign-In error:', error);
      return ({ success: false, data: error.error as string });
    }
  }

  async createGoogleMeetEvent(access_token: string) {
    try {
      const token = await this.afAuth.idToken.pipe(take(1)).toPromise();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      };
      const response: any = await this.http.post(`${this.api_url}/create-google-meet`, { access_token }, httpOptions)
        .pipe(
          retry(3), // retry a failed request up to 3 times
          catchError(err => {
            console.log(err);
            return throwError(() => new Error(err.message));
          })
        ).toPromise();
      // console.log("response_google_meet_link", response);
      if (response.success) {
        return response.data;
      } else {
        console.log("server error");
        return null;
      }
    } catch (error: any) {
      console.log("Error: create google meet link", error);
      return ({ success: false, data: error as string });
    }
  }
}
