<ng-container *ngIf="{ ticketItem: ticketItem$ | async, auth_id: auth_id$ | async } as vm">
    <ng-container [ngSwitch]="clickable">
        <ng-container *ngSwitchCase="true">
            <a
              class="truncate"
              [href]="'/ticket/'+ticket.id"
              [ngStyle]="{'font-size': font_size, 'font-weight': font_weight}"
              (click)="openDrawer($event, ticket!, vm.auth_id?vm.auth_id:'')"
              nz-tooltip [nzTooltipTitle]="vm.ticketItem?.title"
            >
                {{vm.ticketItem?.title}}
            </a>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <p class="truncate" [ngStyle]="{'font-size': font_size, 'font-weight': font_weight}"
              nz-tooltip [nzTooltipTitle]="vm.ticketItem?.title"
            >
                {{ vm.ticketItem?.title }}
            </p>
        </ng-container>
    </ng-container>
</ng-container>
