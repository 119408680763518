<ng-container *ngIf="{auth_profile:auth_profile$ | async, theme:theme$ | async} as vm">
  <ng-container *ngIf="vm.auth_profile">
    <a
      nz-dropdown
      [nzDropdownMenu]="menu"
      nzPlacement="bottomCenter"
      nzTrigger="click"
      nz-tooltip nzTooltipTitle="{{vm.theme === 'dark' ? 'Dark' : vm.theme === 'light' ? 'Light' : 'System'}} mode"
      nzTooltipPlacement="left"
      class="theme-dropdown flex items-center justify-center bg-white dark:bg-gray-600 h-[38px] w-[40px] rounded-md shadow-sm {{vm.theme === 'light' ? '!text-yellow-500' : vm.theme === 'dark' ? '!text-purple-400' : '!text-gray-400 dark:!text-gray-200'}}"
    >
      <span class="material-icons-outlined text-[22px]">
        {{vm.theme === 'light' ? 'light_mode' : vm.theme === 'system' ? 'laptop' : 'dark_mode'}}
      </span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu class="w-[120px] [&_li]:py-2 [&_li]:items-center [&_li]:space-x-2.5 [&_li>span+span]:text-[13px] [&_li>span+span]:font-semibold [&_.material-icons]:text-[20px] [&_li]:text-blue-500 [&_li:not(.theme-active)_.material-icons]:text-gray-400 [&_li:not(.theme-active)]:text-gray-400 dark:[&_li]:!text-blue-500 dark:[&_li:not(.theme-active)]:!text-gray-400 dark:[&_li:not(.theme-active)>span+span]:text-white">
        <li nz-menu-item (click)="selectTheme('light', vm.auth_profile)"
          [ngClass]="{'theme-active': vm.theme === 'light'}"
        >
          <span class="material-icons">light_mode</span>
          <span>Light</span>
        </li>
        <li nz-menu-item (click)="selectTheme('dark', vm.auth_profile)"
          [ngClass]="{'theme-active': vm.theme === 'dark'}"
        >
          <span class="material-icons">dark_mode</span>
          <span>Dark</span>
        </li>
        <li nz-menu-item (click)="selectTheme('system', vm.auth_profile)"
          [ngClass]="{'theme-active': vm.theme === 'system'}"
        >
          <span class="material-icons">laptop</span>
          <span>System</span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-container>
</ng-container>
