import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CategoryStoreService } from '@penji/client-v3/categories/data-access';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { CategoryV2 } from '@penji/shared/data-access';
import { SafeHtmlPipe } from '@penji/shared/pipes';
import { NzListModule } from 'ng-zorro-antd/list';
import { map } from 'rxjs';

@Component({
  selector: 'penji-list-categories',
  standalone: true,
  imports: [
    CommonModule,
    NzListModule,
    SafeHtmlPipe,
  ],
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCategoriesComponent {

  @Output() category_id = new EventEmitter();
  @Input() oneoff: boolean | string = 'all';

  private readonly cateStore = inject(CategoryStoreService);
  private readonly membershipStore = inject(MembershipStoreService);

  membership$ = this.membershipStore.list_sub_design$
  child_open = false;
  current_root!: any;

  data$ = this.cateStore.data$.pipe(
    map(list=>{
      if (this.oneoff != 'all') {
        list = list.filter(l => l.oneoff == this.oneoff);
      }
      list = list.filter(l => l.hide == false);
      return this.groupBy(list);
    })
  );
  isSwitch = false;

  groupBy(xs: CategoryV2[]) {
    return xs.reduce((rv:any, x:any) => {
      (rv[x.parent_id] = rv[x.parent_id] || []).push(x);
      return rv;
    }, {});
  };
  switchList(value: boolean){
    this.isSwitch = value;
  }

  selectCategory(category_id: string) {
    this.category_id.emit(category_id);
  }

  selectRoot(root: any) {
    this.child_open = true;
    this.current_root = root;
  }

  backRoot() {
    this.child_open = false;
  }
}
