/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Ticket, TicketService } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { TicketCateItemComponent } from '@penji/team/shared/shared-ui/ticket-cate-item';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { TicketStoreService } from '@penji/team/ticket/data-access';
import { TicketAssignedComponent } from '@penji/team/ticket/ticket-ui/ticket-assigned';
import { TicketButtonCreateComponent } from '@penji/team/ticket/ticket-ui/ticket-button-create';
import { TicketButtonUpdateComponent } from '@penji/team/ticket/ticket-ui/ticket-button-update';
import { TicketClaimStateComponent } from '@penji/team/ticket/ticket-ui/ticket-claim-state';
import { TicketSourceComponent } from '@penji/team/ticket/ticket-ui/ticket-source';
import * as firebase from 'firebase/firestore';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'penji-w-unclaim-ticket',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzDividerModule,
    TicketCateItemComponent,
    NzButtonModule,
    TicketSourceComponent,
    TicketClaimStateComponent,
    UserItemCompressComponent,
    DesignerTeamNameComponent,
    TicketAssignedComponent,
    TicketButtonUpdateComponent,
    CopyItemComponent,
    InViewportDirective,
    NzSkeletonModule,
    NzAlertModule,
    TicketButtonCreateComponent,
    ContentHeightDirective,
    NzSpinModule,
    NzToolTipModule
  ],
  templateUrl: './w-unclaim-ticket.component.html',
  styleUrls: ['./w-unclaim-ticket.component.sass'],
  providers: [TicketStoreService, NzModalService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WUnclaimTicketComponent {


  router = inject(Router);
  ticketStore = inject(TicketStoreService);
  ticketSV = inject(TicketService);
  authStore = inject(AuthStore);


  query_params: any = {
    'status':'1'
  };
  limit = 50;
  container_height = 0;
  auth_id$ = this.authStore.auth_id$;
  data$ = this.ticketStore.data$;
  loading$ = this.ticketStore.loading$;
  error$ = this.ticketStore.error$;

  visible = false;
  show_view_port: any = {};

  query_params$ = new BehaviorSubject<{}>({});

  constructor() {
    const obj = { 'limit': this.limit };
    this.query_params = { ...this.query_params, ...obj };
    // console.log(this.query_params);
    this.query_params$.next(this.query_params);
    this.ticketStore.loadTicket$(this.query_params$);
  }

  getHeight(height: any) {
    this.container_height = height;
  }
  viewAll() {
    let link = '/ticket';
    // console.log(this.query_params);
    if (this.query_params) {
      let i = 0;
      for (const k in this.query_params) {
        if (k != 'limit') {
          if (i == 0) {
            link = `${link}?`;
          } else {
            link = `${link}&`;
          }
          // console.log(this.query_params[k]);
          link = `${link}${k}=${this.query_params[k]}`;
          i++;
        }
      }
    }
    this.router.navigateByUrl(link);
  }
  viewPort(event: InViewportAction, id: string): void {
    if (event.visible) {
      this.show_view_port[id] = true;
    }
  }
  trackBy(index: any, item: any) {
    return item.id;
  }
  outputPage(value: any) {
    // console.log(value);
    if (value) {
      this.query_params = { ...this.query_params, ...value };
      // console.log(this.query_params);
    } else {
      delete this.query_params['end_before'];
      delete this.query_params['start_after'];
    }
    this.query_params$.next(this.query_params);
  }


}
