/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@penji/shared/environments';
import { EMPTY, catchError, retry, switchMap, take, throttleTime, throwError } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class PeopleService {

  afs = inject(AngularFirestore);
  afAuth = inject(AngularFireAuth);
  http = inject(HttpClient);
  private api_url = environment.api_link;

  invitedPeople(team_id: string, email: string, role: string, invited_by: string) {
    return this.afAuth.idToken.pipe(
      switchMap(token => {
        if (token) {
          const data = {
            'token': token,
            'emails': email,
            'role': role,
            'team_id': team_id,
            'invited_by': invited_by
          }
          return this.http.post(this.api_url + '/team/create-link-invite', data, httpOptions)
            .pipe(
              throttleTime(1500),
              retry(3), // retry a failed request up to 3 times
              catchError(err => {
                console.log(err);
                return throwError(() => new Error(err.message));
              }),
            )
            .toPromise()
            .then(res => {
              // console.log(res);
              return ({ flag: true, message: 'Successfully', data: res })
            })
            .catch(error => ({ flag: false, message: error.message, data: {} }));
        } else {
          return EMPTY;
        }
      })
    ).pipe(take(1)).toPromise();
  }
}
