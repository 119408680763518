import { Component } from '@angular/core';

@Component({
  selector: 'penji-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = 'Client app v3.2';
  private mobileRegex = /Android.*Mobile|iPhone|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
  is_mobile = false;
  
  ngOnInit() {
    this.is_mobile = this.mobileRegex.test(navigator.userAgent);
  }
} 
