<ul>
    <ng-container *ngFor="let link of overtime_link; let i = index">
        <li>
            <a [href]="link" class="flex items-center space-x-4 leading-loose text-gray-500">
                <span>{{i+1}}. {{ link }}</span>
                <span class="material-icons-outlined text-base">
                visibility
                </span>
            </a>
        </li>
    </ng-container>
</ul>