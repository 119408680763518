
<input type="file" [accept]="'.'+file_ext.join(',.')" #file (input)="onFileChange($event)"
  [multiple]="(max_file_upload!==1 ? true : false)" class="!hidden"
  [disabled]="files.length < max_file_upload || max_file_upload === 0 ? false : true" 
>

<ng-container [ngSwitch]="type_upload">
  <ng-container *ngSwitchCase="'button'">
    <button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomCenter" nzTrigger="click" nz-button nzType="default"
      [nzSize]="size_upload"
      class="button-upload-form flex items-center justify-center space-x-1 border-dashed !font-regular {{type_upload==='image'?'btn-default-purple':type_upload==='source'?'btn-default-green':type_upload==='font'?'btn-default-blue':''}}"
      [disabled]="files.length < max_file_upload || max_file_upload === 0 ? false : true" type="button">
      <span class="material-icons-outlined text-[20px]">
        upload
      </span>
      <span>Upload</span>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <span nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomCenter" nzTrigger="click"
      class="button-upload-form material-icons-outlined text-gray-600 hover:text-blue-600 {{files.length < max_file_upload || max_file_upload === 0?'cursor-pointer':'cursor-not-allowed opacity-30'}} {{size_upload==='small'?'text-[18px]':size_upload==='default'?'text-[18px]':'text-[22px]'}}">
      attach_file
    </span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="group border-2 rounded-md border-dashed px-8 py-4 flex justify-between items-center {{type_upload==='image'?'border-purple-600 bg-purple-50 dark:bg-purple-400':type_upload==='source'?'border-green-500 bg-green-50 dark:bg-green-400':type_upload==='font'?'border-blue-600 bg-blue-50 dark:bg-blue-400':'border-gray-200 bg-white dark:bg-gray-600 dark:border-gray-500'}} {{files.length < max_file_upload || max_file_upload === 0?'cursor-pointe':'cursor-not-allowed opacity-50'}} {{check_drag_drop?'!border-blue-500':''}}" (dragover)="allowDrop($event)" (dragleave)="dragleave($event)" (drop)="onDrop($event)">
      <ng-container *ngIf="check_drag_drop; else no_drag">
        <div class="flex items-center justify-center pointer-events-none gap-3 text-blue-600 dark:text-white min-h-[50px] w-full">
          <svg width="30px" height="30px" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fill="none" d="M0 0h24v24H0z"/>
                <path fill-rule="nonzero" d="M14 6h2v2h5a1 1 0 0 1 1 1v7.5L16 13l.036 8.062 2.223-2.15L20.041 22H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zm8 11.338V21a1 1 0 0 1-.048.307l-1.96-3.394L22 17.338zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z"/>
            </g>
          </svg>
          <b>Drag and drop here</b>
        </div>
      </ng-container>
      <ng-template #no_drag>
        <div>
          <p class="flex items-center space-x-2 mb-1.5">
            <span class="material-icons-outlined text-gray-400 dark:text-white text-[26px] -mt-0.5">
              upload
            </span>
            <span class="font-semibold text-gray-700 dark:text-white text-[17px]">
              Drag and drop files here or upload
            </span>
          </p>
          <p class="flex items-center !text-gray-500 dark:!text-gray-200 text-sm">
            <span>Accepted file types:</span>
            <span class="max-w-[260px] truncate" nz-tooltip [nzTooltipTitle]="tooltipTitle">
              <span class="uppercase ml-1" *ngFor="let item of file_ext; let last = last">{{item}}<span
                  *ngIf="!last">,</span> </span>
            </span>
            <span class="ml-1 dark:!text-gray-200">with max size of {{ size_limit | nzBytes }}.</span>
          </p>
        </div>
        <ng-template #tooltipTitle>
          <span class="uppercase first-of-type:ml-0 ml-1" *ngFor="let item of file_ext; let last = last">{{item}}<span
              *ngIf="!last">,</span> </span>
        </ng-template>
  
        <button nz-button nzType="primary" class="btn-primary-blue !font-normal !font-regular text-[15px] px-5"
          [disabled]="files.length < max_file_upload || max_file_upload === 0?false:true" nz-dropdown
          [nzDropdownMenu]="menu" nzPlacement="bottomRight" nzTrigger="click" type="button">Upload</button>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSelectable
    class="[&_li]:py-2.5 [&_li]:space-x-2 [&_li_.material-icons-outlined]:text-[20px] [&_li_.material-icons-outlined]:text-gray-400 hover:[&_li]:text-blue-600 [&_li:hover_*]:!text-blue-600 dark:[&_li_.material-icons-outlined]:text-gray-200 dark:[&_li]:text-white dark:focus:[&_li]:bg-gray-500">
    <li nz-menu-item (click)="OpenMediaFromLibrary()">
      <span class="material-icons-outlined !text-[22px]">
        layers
      </span>
      <span>Upload from library</span>
    </li>
    <li nz-menu-item (click)="file.click()">
      <span class="material-icons-outlined">
        computer
      </span>
      <span>Upload from your computer</span>
    </li>
  </ul>
</nz-dropdown-menu>

<ng-container *ngIf="{upload_progress:upload_progress$ | async, upload_progress_completed:upload_progress_completed$ | async} as vm">
  <ng-container *ngIf="vm.upload_progress && vm.upload_progress_completed">
    <ng-container
      *ngIf="(files.length >= max_file_upload && max_file_upload !== 0) && (vm.upload_progress_completed.length === vm.upload_progress.length)">
      <p class="text-yellow-600 text-sm py-1 px-3 bg-red-100 mt-2 rounded border border-yellow-300 inline-flex">
        Warning: you can add {{ max_file_upload }} {{max_file_upload>1?'files':'file'}}!
      </p>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="vm.upload_progress && vm.upload_progress.length > 0">
    <ng-container [ngSwitch]="item_view">
      <ng-container *ngSwitchCase="'short'">
        <div id="short-upload-form" class="short-upload-form flex gap-4 flex-wrap drap-drop-list" cdkDropList
          (cdkDropListDropped)="drop($event, vm.upload_progress)" [cdkDropListData]="vm.upload_progress">
          <ng-container *ngFor="let item of vm.upload_progress;">
            <div cdkDrag cdkDragHandle class="drap-drop-box">
              <penji-file-upload-item [file]="item" item_view="short"></penji-file-upload-item>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <nz-collapse nzGhost
          class="[&_.ant-collapse-header]:!px-0 [&_.ant-collapse-header]:!pt-4 [&_.ant-collapse-header]:!pb-0 [&_.ant-collapse-header>div]:!flex [&_.ant-collapse-content-box]:!p-0 [&_.ant-collapse-content-box]:relative hover:[&_.ant-collapse-header]:text-blue-600 hover:[&_.ant-collapse-header>p>span]:text-blue-600 dark:[&_.ant-collapse-header>p>span]:!text-blue-500">
          <nz-collapse-panel [nzHeader]="header_col" [nzActive]="true" [nzExpandedIcon]="icon">
            <a nz-button nzSize="small" nzType="link" (click)="inputSort()" class="flex items-center absolute right-[-13px] top-[-25px]">
              <span class="material-icons-outlined">
                swap_vert
              </span>Sort by name
            </a>
            <div class="drap-drop-list" cdkDropList (cdkDropListDropped)="drop($event, vm.upload_progress)"
              [cdkDropListData]="vm.upload_progress">
              <ng-container *ngFor="let item of vm.upload_progress;">
                <div cdkDrag cdkDragHandle class="drap-drop-box cursor-move">
                  <penji-file-upload-item [file]="item"></penji-file-upload-item>
                </div>
              </ng-container>
            </div>

          </nz-collapse-panel>
          <ng-template #header_col>
            <p class="flex items-center space-x-2.5 pl-2" *ngIf="vm.upload_progress_completed">
              <span *ngIf="vm.upload_progress_completed.length !== vm.upload_progress.length"
                class="w-[18px] h-[18px] flex border-[2px] border-l-gray-400 border-r-gray-400  border-transparent rounded-full animate-[spin_2s_linear_infinite]"></span>
              <span class="text-blue-800">{{vm.upload_progress_completed.length}} of {{vm.upload_progress.length}} files
                uploaded</span>
            </p>
          </ng-template>
          <ng-template #icon>
            <span class="material-icons-outlined dark:text-gray-200">
              expand_more
            </span>
          </ng-template>
        </nz-collapse>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

