/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CategoryNameByIdComponent } from '@penji/client-v3/categories/ui/category-name-by-id';
import { CustomerTeamStoreService } from '@penji/client-v3/customer-team/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Project, ProjectService, Team, TeamService, Ticket, UserData, UserService } from '@penji/shared/data-access';
import { SpinnerComponent } from '@penji/shared/ui/element/spinner';
import { UserRoleComponent } from '@penji/shared/ui/element/user-role';
import { ProjectStatusComponent } from '@penji/team/project/project-ui/project-status';
import { InputComponent } from '@penji/team/shared/shared-ui/input';
import { TicketCateItemComponent } from '@penji/team/shared/shared-ui/ticket-cate-item';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { WorkspaceItemV2Component } from '@penji/team/workspace/workspace-ui/workspace-item/workspace-item-v2';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, of, share, switchMap, tap } from 'rxjs';

@Component({
  selector: 'penji-search',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    SpinnerComponent,
    RouterModule,
    TicketCateItemComponent,
    UserRoleComponent,
    NzEmptyModule,
    NzDropDownModule,
    NzSkeletonModule,
    UserItemCompressComponent,
    ProjectStatusComponent,
    WorkspaceItemV2Component,
    CategoryNameByIdComponent
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {

  // For select user
  @Input() auto_focus = true;
  search_control: FormControl = new FormControl('');
  isLoading = false;
  list$: Observable<{
    list_project: Project[]
  }> | undefined;
  private readonly afs = inject(AngularFirestore);
  private readonly auth = inject(AuthStore);
  private readonly router = inject(Router);
  private readonly projectSV = inject(ProjectService);
  private readonly customerTeamStore = inject(CustomerTeamStoreService);
  index_move = 0;
  check = false;
  result_all = false;
  client_team_id = '';
  keyword = '';
  @HostListener('keydown', ['$event'])
  onKeydown(e: any) {
    if (e.keyCode === 13 || e.keyCode === 40 || e.keyCode === 38) {
      const matches = document.querySelectorAll('.item-down');
      if (matches.length > 0) {
        if (this.index_move === 0 && !this.check) {
          matches[0].classList.add('focus');
          this.check = true;
        } else {
          matches.forEach((el) => {
            el.classList.remove('focus')
          })
          switch (e.keyCode) {
            case 40:
              if (this.index_move < matches.length - 1) {
                this.index_move++;
                matches[this.index_move].classList.add('focus');
                matches[this.index_move].scrollIntoView({
                  behavior: 'smooth',
                  block: "center"
                });
              } else {
                matches[matches.length - 1].classList.add('focus');
              }
              break;
            case 38:
              if (this.index_move >= 1) {
                this.index_move--;
                matches[this.index_move].classList.add('focus');
                matches[this.index_move].scrollIntoView({
                  behavior: 'smooth',
                  block: "center"
                });
              } else {
                matches[0].classList.add('focus');
              }
              break;
            case 13:
              // @ts-ignore
              matches[this.index_move].click();
              break;
          }
        }
      }

    }
  }

  cancelFocus() {
    this.check = false;
    this.index_move = 0;
    const matches = document.querySelectorAll('.item-down');
    if (matches.length > 0) {
      matches.forEach((el) => {
        el.classList.remove('focus');
      })
    }
    setTimeout(() => {
      document.getElementById('top-dropdown-search')?.scrollIntoView({
        behavior: 'smooth',
        block: "center"
      });
    }, 500);
  }

  ngOnInit(): void {

    this.list$ = this.onSearch(this.search_control);

  }
  onSearch(fc: FormControl, list_recent: any[] = []) {
    return fc.valueChanges.pipe(
      tap(() => {
        this.isLoading = true;
        this.cancelFocus();
      }),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(keyword => {
        if (keyword.trim() != '') {
          this.keyword = keyword;
          if (list_recent.length > 0) {
            const filter_list = list_recent.find(res => res.keyword == keyword.trim());
            if (filter_list) {
              return combineLatest(
                of('recent'),
                of(keyword),
                of(filter_list.list_project as Project[]));
            }
          }
          return combineLatest(
            of('new'),
            of(keyword),
            this.customerTeamStore.team_data$.pipe(
              switchMap(team => {
                if (team && team.id) {
                  this.client_team_id = team.id;
                  return this.projectSV.searchProjectAlgoliaForClient(team.id, keyword);
                }
                else {
                  return of([]);
                }
              })
            )
          );
        } else {
          return combineLatest(
            of(''),
            of(''),
            of([]));
        }
      }),
      map(([type, keyword, list_project]) => {
        if (type == 'new') {
          list_recent = [...list_recent, ...[{ keyword: keyword, list_project: list_project }]];
        }
        if (list_project.length > 0) this.result_all = true;
        else this.result_all = false;
        this.isLoading = false;
        return { list_project: list_project };
      }),
      share()
    )
  }

}
