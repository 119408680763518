/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageSize, Project } from '@penji/shared/data-access';
import { DesignerTeamLeaderComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-leader';
import { ProjectReassignComponent } from '@penji/team/project/project-ui/project-reassign';
import { UserArrayItemComponent } from '@penji/team/shared/shared-ui/user-array-item';

@Component({
  selector: 'penji-project-assigned',
  standalone: true,
  imports: [
    CommonModule,
    ProjectReassignComponent,
    UserArrayItemComponent,
    DesignerTeamLeaderComponent
  ],
  templateUrl: './project-assigned.component.html',
  styleUrls: ['./project-assigned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectAssignedComponent {
  @Input() reassign_on = false;
  @Input() project_data!: Project;
  @Input() user_image_size: ImageSize = 'sm';
  list_user_id: string[] = [];

  ngOnChanges(): void {
    if(this.project_data && this.project_data.assign_by)
      this.list_user_id = this.project_data.assign_by;
  }

  getListUserId(list_id: string[]){
    this.list_user_id = list_id;
  }

}
