import { DocumentData, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';
export class Project {
    id?: string;
    index?: number;
    action?: 'added' | 'modified' | 'removed';
    assign_by?: string[];
    assign_team_designer?: string;
    assigned!: boolean;
    brand_id?: string;
    brand_ref?: DocumentReference;
    change_revision_time?: Timestamp;
    circle_type?: number;
    colors?: string[];
    complete_at?: Timestamp | any;
    completed_by?: string;
    created_at?: Timestamp;
    created_by?: string;
    custom_page?: any;
    deadline_at?: Timestamp;
    deadline_hours?: number;
    description!: string;
    dimension_height?: string;
    dimension_width?: string;
    dimensions?: any;
    dimensions_mobile?: any;
    doc?: QueryDocumentSnapshot<DocumentData>;
    empty_designer?: boolean;
    eta!: Timestamp;
    example_app_link?: string;
    example_web_link?: string;
    feedback?: string;
    files?: any[];
    has_pm?: boolean;
    in_queue!: boolean;
    is_send_mail!: boolean;
    last_design?: DocumentReference;
    lastest_feedback?: string[];
    number_of_designs?: string;
    owner_id!: string;
    owner_ref!: DocumentReference;
    pause?: boolean;
    pending_review?: boolean;
    point?: number;
    presentation_number?: number;
    presentation_slides?: any[];
    project_cat_id!: string;
    project_cat_ref!: DocumentReference;
    project_class_id!: string;
    question?: boolean;
    reject?: boolean;
    related_project?: DocumentReference;
    require_illustration?: string;
    required_file?: string[];
    reviewed?: boolean;
    revision_count?: number;
    start_design?: boolean;
    start_design_at?: Timestamp;
    started_at?: Timestamp;
    status?: number;
    status_addition?: number;
    style_preference_new?: string[];
    team_id!: string;
    team_ref!: DocumentReference;
    time_change?: Timestamp;
    title!: string;
    total_design!: number;
    total_design_index!: number;
    type_complete?: number;
    updated_at?: Timestamp;
    updated_by?: string;
    url?: any[];
    UrlUpsplash?: any[];
    v3?: boolean;
    verify_progress?: boolean;
    verify_type?: number;
    version?: number;
    vote?: number;
    weight?: number;
    length?: any;
    reason?: string[];
    feedback_type?: string;
    log_type = 'project';
    last_message?: string;
    last_discussion_id?: string;
    payment_v2?: boolean;
    loved_designer?: string[];
    creative_input?: string;
    project_type?: string
    last_role?: number;
    last_user?: string;
    temp_overdue?: boolean;
    project_id?: string;
    final_assigned?: number;
    quick_revision?: number;
}
export class Share {
    id?: string;
    image_url?: string;
    project_ref?: DocumentReference;
    created_at?: Timestamp;
}

export interface ProjectDataState extends EntityState<Project>{
  loading: boolean;
  error: string;
}
