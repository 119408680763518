import { Timestamp } from '@firebase/firestore-types';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';

export class Overtime {
    id!: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    action?: 'added' | 'modified' | 'removed';
    created_at!: Timestamp;
    overtime_at!: Timestamp;
    approved!: number; //0,1,2
    uid!: string;
    team_designer_id?: string;
    overtime_link!: string[];
    why_unapproved?: string;
    log_type = 'overtime';
}
export class OvertimeOld {
    id!: string;
    uid!: string;
    team_designer_id?: string;
    view!: boolean;
    approved!: boolean;
    created_at!: Timestamp;
    date_over_time!: string;
    link_over_time!: any;
    why_unapproved?: string;
    log_type = 'overtime';
}

// convert over_time to overtime_v2 ===================================
// export class Overtime {
//     id: string; -> (move)
//     uid: string; -> (move)
//     view: boolean; -> (delete)
//     approved: boolean; -> approved (view==false -> default: 0, approved==true: 1, approved==false: 2)
//     created_at: Timestamp; -> (move)
//     date_over_time: string; -> overtime_at (string -> Timestamp)
//     link_over_time: []; -> overtime_link (map -> array)
//     why_unapproved?: string; -> (move)
//     log_type = 'overtime'; -> (add)
// }
// end convert over_time to overtime_v2 ===================================