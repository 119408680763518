<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
  <div class="flex justify-between items-center pb-2">
    <h6 class="text-gray-600 capitalize dark:text-white">List New customer 30 days</h6>
  </div>
  <ng-container *ngIf="{list_customer:list_customer$ | async, loading:isLoading$ | async} as vm">
    <ng-container *ngIf="vm.list_customer && !vm.loading; else loading">
      <nz-table #table_list [nzData]="vm.list_customer.data" [nzBordered]="false" [nzFrontPagination]="false"
        [nzShowPagination]="false" [nzSize]="'middle'" class="theme theme-default"
        [nzScroll]="{ x: '36.146vw', y: container_height - 120 + 'px' }">
        <thead>
          <tr>
            <th nzLeft nzWidth="240px">Customer Name</th>
            <th nzWidth="120px">Membership</th>
            <th nzWidth="130px" nz-tooltip nzTooltipTitle="Latest 7 days feedback" nzEllipsis>Latest 7 days feedback
            </th>
            <!-- <th nzWidth="110px">Ticket</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
            <tr>
              <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
                <td nzLeft>
                  <penji-user-item-compress [user_id]="item?.id" [show_user_avatar]="true" [show_user_name]="true"
                    [clickable]="true"></penji-user-item-compress>
                </td>
                <td>
                  <!-- <penji-customer-membership-status
                    [membership_status]="item?.payment ? item?.payment?.one_off_design ? 'one-off' : item?.payment?.plan: ''"></penji-customer-membership-status> -->
                </td>
                <td>
                  <!-- <penji-last-seven-days-feedback [client_team_id]="item.team_active!"></penji-last-seven-days-feedback> -->
                </td>
                <!-- <td></td> -->
              </ng-container>
            </tr>
            <ng-template #loading_tr>
              <td [colSpan]="3" inViewport (inViewportAction)="viewPort($event, item.id!)">
                <nz-skeleton [nzActive]="true" [nzTitle]="false"
                  [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
              </td>
            </ng-template>
          </ng-container>

        </tbody>
      </nz-table>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>


</div>
