import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@penji/shared/auth/data-access';
import { RequestOff, UserData } from '@penji/shared/data-access';
import { HrManagementAssignedComponent } from '@penji/team/hr-management/hr-management-ui/hr-management-assigned';
import { RequestOffStoreService } from '@penji/team/request-off/data-access';
import { differenceInCalendarDays } from 'date-fns';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'penji-request-off-create-update',
  standalone: true,
  imports: [
    CommonModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    NzSelectModule,
    HrManagementAssignedComponent
  ],
  templateUrl: './request-off-create-update.component.html',
  styleUrls: ['./request-off-create-update.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestOffCreateUpdateComponent implements OnInit {
  @Input() form_type?: string = 'add';
  @Input() request_off?: RequestOff;
  @Input() uid?: string;
  loading = false;
  fb = inject(FormBuilder);
  ref = inject(NzModalRef);
  modal = inject(NzModalService);
  authSV = inject(AuthService);

  validateForm!: UntypedFormGroup;
  today: Date = new Date();
  disabledDayOff = (current: Date): boolean => differenceInCalendarDays(current, this.today) < 0;

  visible = false;
  memberDetail$ = new BehaviorSubject<UserData | any>(null);

  constructor(
    private requestoffStore: RequestOffStoreService,
  ) {
    this.validateForm = this.fb.group({
      id: [""],
      created_at: [null],
      off_at: [null],
      off_at_tmp: [null, [Validators.required]],
      pto: [2],
      reason: ["", [Validators.required]],
      approved: [0],
      uid: ["", [Validators.required]],
      why_unapproved: [""],
    });
  }

  ngOnInit() {
    if (this.form_type == 'add') {
      this.validateForm.patchValue({
        created_at: firebase.Timestamp.now(),
        off_at_tmp: null,
        uid: this.uid ? this.uid : ""
      });
      if (this.uid) this.getListUserId([this.uid]);
    } else if (this.form_type == 'edit') {
      this.validateForm.patchValue({
        id: this.request_off?.id ? this.request_off?.id : "",
        created_at: this.request_off?.created_at ? this.request_off?.created_at : null,
        off_at_tmp: this.request_off?.off_at ? (new Date(this.request_off?.off_at.seconds * 1000)) : null,
        pto: this.request_off?.pto ? this.request_off?.pto : 0,
        reason: this.request_off?.reason ? this.request_off?.reason : "",
        approved: this.request_off?.approved ? this.request_off?.approved : 0,
        uid: this.request_off?.uid ? this.request_off?.uid : "",
        why_unapproved: this.request_off?.why_unapproved ? this.request_off?.why_unapproved : ""
      });
      if (this.request_off?.uid) this.getListUserId([this.request_off?.uid]);
    }
  }

  async getListUserId(list_out_assign_by: string[]) {
    if (list_out_assign_by && list_out_assign_by.length > 0) {
      this.validateForm.patchValue({ uid: list_out_assign_by[0] });
      const member = await this.authSV.getUserDetail(list_out_assign_by[0]);
      if (member) this.memberDetail$.next(member);
      else this.memberDetail$.next(null);
      this.visible = false;
    }
  }

  onSubmit() {
    this.loading = true;
    this.validateForm.patchValue({
      off_at: this.validateForm.controls['off_at_tmp'].value ?
        firebase.Timestamp.fromDate(new Date((this.validateForm.controls['off_at_tmp'].value).setHours(12, 0, 0))) : null
    });
    // remove Date format, just keep Timestamp format
    this.validateForm.removeControl('off_at_tmp');
    if (this.form_type == 'edit') {
      this.requestoffStore.updateRequestOff$(this.validateForm.value);
      this.loading = false;
    } else {
      this.requestoffStore.addRequestOff$(this.validateForm.value);
      this.loading = false;
    }
    this.ref.destroy();
  }
}
