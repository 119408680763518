<ng-container *ngIf="{checkin_status: checkin_status$ | async} as vm">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'dot'">
            <div class="w-full h-full flex items-center justify-center absolute inset-0 bg-gray-300 bg-opacity-80 rounded-full text-red-500"
                *ngIf="disabled">
                <span class="material-icons-outlined text-[18px]">person_off</span>
            </div>
            <penji-member-checkin-status [checkin_status]="vm.checkin_status!" [type]="'dot'"
                class="absolute -bottom-2 left-0 z-30"></penji-member-checkin-status>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
            <penji-member-checkin-status [checkin_status]="vm.checkin_status!" [checkin_at]="(checkin_time$ | async)"
                [checkout_at]="(checkout_time$ | async)" [type]="'text'"></penji-member-checkin-status>
        </ng-container>
        <ng-container *ngSwitchCase="'full'">
            <penji-member-checkin-status [checkin_status]="vm.checkin_status!" [checkin_at]="(checkin_time$ | async)"
                [checkout_at]="(checkout_time$ | async)" [type]="'full'"></penji-member-checkin-status>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="w-full flex items-start space-x-2">
                <penji-member-checkin-status [checkin_status]="vm.checkin_status!"
                    [type]="'default'"></penji-member-checkin-status>
            </div>
        </ng-container>
    </ng-container>
</ng-container>