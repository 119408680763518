/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Discussion, DiscussionService } from '@penji/shared/data-access';
import { PermissionDirective } from '@penji/shared/directives';
import { ProjectMessageStoreService } from '@penji/team/project/data-access';
import { ProjectDesignStatusItemComponent } from '@penji/team/project/project-ui/project-design-status-item';
import { ViewDesignStoreService } from '@penji/team/view-design/data-access';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { BehaviorSubject, of } from 'rxjs';

@Component({
  selector: 'penji-project-design-status',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    ProjectDesignStatusItemComponent,
    NzDropDownModule,
    PermissionDirective,
    NzPopconfirmModule
  ],
  templateUrl: './project-design-status.component.html',
  styleUrls: ['./project-design-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDesignStatusComponent {
  @Input() client_team_id?: string;
  @Input() project_id?: string;
  @Input() discussion_id?: string;
  @Input() discussion?: Discussion;
  private readonly authStore = inject(AuthStore);
  private readonly discussionSV = inject(DiscussionService);
  private readonly afs = inject(AngularFirestore);
  private projectStoreMessageSV = inject(ProjectMessageStoreService);
  private viewDesignStoreSV = inject(ViewDesignStoreService);
  private readonly cdr = inject(ChangeDetectorRef);
  auth_id$ = this.authStore.auth_id$;
  design_status$ = new BehaviorSubject<"to-verify" | 'verified' | "be-scheduled-now" | "be-scheduled-6pm" | "reject">('to-verify');

  ngOnChanges(): void {
    if (this.discussion) {
      if (!this.discussion.hidden && this.discussion.verify_type && this.discussion.verify_type <= 2 && !this.discussion.verify_progress) {
        this.design_status$.next('verified');
      }
      else if (this.discussion.hidden && this.discussion.verify_progress) {
        if (this.discussion.verify_type == 2)
          this.design_status$.next('be-scheduled-6pm');
        else
          this.design_status$.next('be-scheduled-now');
      }
      else if (this.discussion.reject) {
        this.design_status$.next('reject');
      }
      else {
        this.design_status$.next('to-verify');
      }

    }
  }
  async onVerify(verify_type = 2) {
    if (this.client_team_id && this.project_id, this.discussion_id, this.discussion) {
      const data = {
        'created_at': firebase.Timestamp.now(),
        'project_id': this.project_id,
        'discussion_id': this.discussion_id,
        'discussion_ref': this.afs.firestore.doc(`team/${this.client_team_id}/project/${this.project_id}/discussion/${this.discussion_id}`),
        'link': this.discussion?.link,
        'upload_design_by_uid': this.discussion?.user_id,
        'verify_now': verify_type === 2 ? false : true,
        'verify_type': verify_type
      };
      await this.discussionSV.verfyDesign(this.client_team_id, this.project_id, data);
      const temp_discussion = new Discussion();
      temp_discussion.id = this.discussion_id!;
      temp_discussion.updated_at = firebase.Timestamp.now();
      temp_discussion.verify_type = verify_type;
      temp_discussion.verify_progress = true;
      this.projectStoreMessageSV.crudDiscussion$({ action: 'update', client_team_id: this.client_team_id, project_id: this.project_id, discussion: temp_discussion });
      this.viewDesignStoreSV.updateDesign$(of(temp_discussion));
    }
  }
  async onRejectDesign() {
    if (this.client_team_id && this.project_id, this.discussion_id) {
      await this.discussionSV.rejectDesign(this.client_team_id, this.project_id, this.discussion_id);
      const temp_discussion = new Discussion();
      temp_discussion.id = this.discussion_id;
      temp_discussion.updated_at = firebase.Timestamp.now();
      temp_discussion.reject = true;
      this.projectStoreMessageSV.crudDiscussion$({ action: 'update', client_team_id: this.client_team_id, project_id: this.project_id, discussion: temp_discussion });
      this.viewDesignStoreSV.updateDesign$(of(temp_discussion));
    }
  }
  async onUndoVerifyDesign() {
    if (this.client_team_id && this.project_id, this.discussion_id) {
      await this.discussionSV.undoVerifyDesign(this.client_team_id, this.project_id, this.discussion_id);
      const temp_discussion = new Discussion();
      temp_discussion.id = this.discussion_id;
      temp_discussion.updated_at = firebase.Timestamp.now();
      temp_discussion.verify_progress = false;
      this.projectStoreMessageSV.crudDiscussion$({ action: 'update', client_team_id: this.client_team_id, project_id: this.project_id, discussion: temp_discussion });
      this.viewDesignStoreSV.updateDesign$(of(temp_discussion));
    }
  }
  async onUndoRejectDesign() {
    if (this.client_team_id && this.project_id, this.discussion_id) {
      await this.discussionSV.undoRejectDesign(this.client_team_id, this.project_id, this.discussion_id);
      const temp_discussion = new Discussion();
      temp_discussion.id = this.discussion_id;
      temp_discussion.updated_at = firebase.Timestamp.now();
      temp_discussion.reject = false;
      this.projectStoreMessageSV.crudDiscussion$({ action: 'update', client_team_id: this.client_team_id, project_id: this.project_id, discussion: temp_discussion });
      this.viewDesignStoreSV.updateDesign$(of(temp_discussion));
    }
  }

}
