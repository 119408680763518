import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { BehaviorSubject, delay, tap, timer } from 'rxjs';


type CopyType = 'project' | 'design' | 'customer' | 'workspace' | 'member' | 'ticket' | 'squad-group' | 'designer-team' | 'template-answer';

@Component({
  selector: 'penji-copy-item',
  standalone: true,
  imports: [
    CommonModule,
    NzTypographyModule,

  ],
  templateUrl: './copy-item.component.html',
  styleUrls: ['./copy-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyItemComponent {
  @ViewChild('linkElement') linkElement!: ElementRef;
  @Input() link_title: string | undefined = '';
  @Input() id: string | undefined = '';
  @Input() copy_type: CopyType = 'project';
  @Input() query_params: any;
  @Input() icon_size = 'text-[16px]';
  base_url = '';
  copy_done$ = new BehaviorSubject<boolean>(false);

  ngOnChanges(): void {
    this.base_url = window.location.origin;
    switch(this.copy_type) {
      case 'design': {
        this.base_url = `${this.base_url}/view-design?project_id=${this.query_params['project_id']}&discussion_id=${this.query_params['discussion_id']}&id_link=${this.query_params['id_link']}`;
        break;
      }
      case 'customer': {
        this.base_url = this.base_url+'/customer/'+this.id;
        break;
      }
      case 'member': {
        this.base_url = this.base_url+'/member/'+this.id;
        break;
      }
      case 'ticket': {
        this.base_url = this.base_url+'/ticket/'+this.id;
        break;
      }
      case 'squad-group': {
        this.base_url = this.base_url+'/squad-group/'+this.id;
        break;
      }
      case 'designer-team': {
        this.base_url = this.base_url+'/designer-team/'+this.id;
        break;
      }
      case 'template-answer': {
        this.base_url = this.base_url+'/template-answer/'+this.id;
        break;
      }
      case 'workspace': {
        this.base_url = this.base_url+'/workspace/'+this.id;
        break;
      }
      default: {
        this.base_url = this.base_url+'/project/'+this.id;
        break;
      }
    }
    // this.onCopy(this.base_url)
  }
  onClick(): void {
    // console.log(this.linkElement.nativeElement)
    const link = this.linkElement.nativeElement;
    const range = document.createRange();
    range.selectNode(link);
    const selection = window.getSelection();
    if(selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    const successful = document.execCommand('copy');
    if (successful) {
      this.copy_done$.next(true)
      timer(3000)
      .pipe(
        delay(3000), // Introduce a 3-second delay
        tap(() => this.copy_done$.next(false)) // Update the value to false without subscribing
      )
      .toPromise();

    } else {
      console.error('Failed to copy link to clipboard.');
    }

  }
}
