import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStore } from '@penji/shared/auth/data-access';
import { Store, select } from '@ngrx/store';
import { UserSimple } from '@penji/shared/data-access';
import { Observable, Subject, map, takeUntil, tap } from 'rxjs';
import * as userActions from '@penji/shared/data-access';
import { UserRoleComponent } from '@penji/shared/ui/element/user-role';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MediaRefComponent } from '@penji/client-v3/media/ui/media-ref';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

type ImageSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'max';
const ImageSizesInPx: { [key in ImageSize]: number } = {
  sm: 28,
  md: 32,
  lg: 38,
  xl: 44,
  xxl: 50,
  max: 96
};

@Component({
  selector: 'penji-user-item',
  standalone: true,
  imports: [
    CommonModule,
    UserRoleComponent,
    NzToolTipModule,
    MediaRefComponent,
    NzTypographyModule,
    NzSkeletonModule
  ],
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserItemComponent {

  @Input() user_id?: string;
  @Input() user_name = false;
  @Input() user_avatar = false;
  @Input() user_email = false;
  @Input() user_role = false;
  @Input() clickable = true;
  @Input() show_watch = true;
  @Input() avatar_size: ImageSize =  'md';
  @Input() font_size: 11 | 13 | 15 | 17 | 20 = 15;
  @Input() font_weight: 'bold' | 'regular' | 'medium' | 'semibold' = 'semibold';
  @Input() media_size: "medium" | "thumb" | "avatar" | "original" = "avatar";
  @Input() width_name = 'max-w-[150px]';

  authStore = inject(AuthStore);
  store = inject(Store);

  user$?: Observable<UserSimple> | undefined;
  auth_profile$ = this.authStore.authProfile$;
  take$ = new Subject<void>();


  get setImageSize(): number {
    return ImageSizesInPx[this.avatar_size]
  }
  ngOnChanges() {
    if (this.user_id) {
      this.store.dispatch(
        userActions.loadUserDB({ uid: this.user_id ?? '' })
      );
      this.user$ = this.store.pipe(select(userActions.getUser(this.user_id))).pipe(
        takeUntil(this.take$),
        map(
          (user) => {
            if(user)
              this.take$.complete();
            return user
          }
        )
      );
    }
  }
}
