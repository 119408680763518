import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageSize } from '@penji/shared/data-access';
import { UserInfoItemComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-info-item';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';



@Component({
  selector: 'penji-user-array-item',
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    NzButtonModule,
    UserInfoItemComponent
  ],
  templateUrl: './user-array-item.component.html',
  styleUrls: ['./user-array-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserArrayItemComponent {

  @Input() uid_array?: string[];
  @Input() user_image_size: ImageSize = 'sm';
}
