import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, QueryList, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Comment } from '@penji/shared/data-access';
import { QuillViewHtmlComponent } from '@penji/shared/ui/element/quill-view-html';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { UserItemCompressComponent } from '@penji/team/shared/shared-ui/user-item-v2/user-item-compress';
import { CommentStoreService } from '@penji/team/view-design/data-access';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { Observable, of } from 'rxjs';

const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static PROTOCOL_WHITELIST: any;
  static sanitize(url:any) {
    const value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if(value.startsWith(this.PROTOCOL_WHITELIST[i]))
          return value;

        return `http://${value}`
    }
    return value;
  }
}
Quill.register(CustomLink);
@Component({
  selector: 'penji-view-design-comment-item',
  standalone: true,
  imports: [
    CommonModule,
    NzTimelineModule,
    NzFormModule,
    NzModalModule,
    NzButtonModule,
    UserItemCompressComponent,
    NzInputModule,
    QuillModule,
    QuillViewHtmlComponent,
    FormsModule,
    NzPopconfirmModule
  ],
  templateUrl: './view-design-comment-item.component.html',
  styleUrls: ['./view-design-comment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewDesignCommentItemComponent {

  @Input() auth_id?: string;
  @Input() comment!: Comment;

  private readonly commentStoreSv= inject(CommentStoreService);
  private readonly projectStoreSV = inject(ProjectStoreService);

  @ViewChildren(QuillEditorComponent) editorComponents?: QueryList<QuillEditorComponent>;

  project_data$ = this.projectStoreSV.current_project_data$;
  list_reply$: Observable<any> | undefined;
  check_id = '';
  text_edit = '';
  

  ngOnInit(): void {
    if(this.comment&&this.comment.id)
      this.list_reply$ = this.commentStoreSv.data_comment_by_commentid$(this.comment.id);
  }

  deleteComment(comment: Comment) {
    comment.removed = true;
    this.commentStoreSv.deleteComment$(of({comment: comment}));
    
  }
  onEditComment(comment: any) {
    if(this.text_edit && this.text_edit !=='')
      comment.content = this.text_edit;

    this.commentStoreSv.updateComment$(of({comment: comment}));

    this.cancelEdit();
  }

  onEditorContentChanged(event: any){
    this.text_edit = event.html;
  }
  cancelEdit() {
    this.check_id = '';
    this.text_edit = '';
  }

  editComment(comment: any) {
    this.cancelEdit();
    this.check_id = comment.id;
    setTimeout(() => {
      this.editorComponents?.forEach(cp=>{
        cp.quillEditor.focus();
      });
    }, 150);
  }
  cancel(): void { console.log('cancel')}

}
