import { UserData } from "@penji/shared/data-access";

export interface AuthState {
    auth_profile: UserData | null,
    refresh_token: string | null,
}

export const inititialState: AuthState = {
    auth_profile: null,
    refresh_token: null,
};