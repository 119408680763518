<ng-container *ngIf="squad_group$ | async as squad_group">
  <ng-container *ngIf="clickable; else clickdisable">
    <a (click)="openDrawer(squad_group.id)">
      <span class="capitalize font-semibold text-gray-600 {{classes || ''}} dark:text-white hover:!text-blue-600" nz-tooltip
        [nzTooltipTitle]="squad_group.name?squad_group.name:'N/A'">
        {{squad_group.name}}
      </span>
    </a>
  </ng-container>
  <ng-template #clickdisable>
    <span class="capitalize font-semibold text-gray-600 {{classes || ''}} dark:text-white" nz-tooltip
      [nzTooltipTitle]="squad_group.name?squad_group.name:'N/A'">
      {{squad_group.name}}
    </span>
  </ng-template>
</ng-container>
