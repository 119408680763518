import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'penji-request-off-type',
  standalone: true,
  imports: [
    CommonModule,
    
  ],
  templateUrl: './request-off-type.component.html',
  styleUrls: ['./request-off-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestOffTypeComponent {
  @Input() style: 'text' | 'design' = 'design';
  @Input() pto: number | undefined = 0;
}
