/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { Permission, initDesignerPermission, initHRPermission, initLeaderPermission, initPermission, initPmPermission, initSMMPermission, initSquadLeaderPermission, initSupportPermission } from '../models/permission.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { LogService } from './log.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
    providedIn: 'root'
})
export class PermissionService extends LogService<Permission> {
    private readonly nzMessageService = inject(NzMessageService);
    private readonly afa = inject(AngularFireAuth);
    data$ = this.afa.authState.pipe(
        switchMap(user => {
            return this.afs.collection('permission').doc<Permission>(user?.uid).valueChanges().pipe(map(rs => ({ id: user?.uid, ...rs } as Permission)));
        })
    )
    constructor() {
        super();
        this.data$.subscribe();
    }
    createPermission(user_id: string, type: 'leader' | 'designer' | 'support' | 'pm' | 'squad-leader' | 'hr' | 'smm' | '' = '') {
        let permission: Permission;
        if (type === 'leader')
            permission = initLeaderPermission as Permission;
        else if (type === 'designer')
            permission = initDesignerPermission as Permission;
        else if (type === 'support')
            permission = initSupportPermission as Permission;
        else if (type === 'pm')
            permission = initPmPermission as Permission;
        else if (type === 'squad-leader')
            permission = initSquadLeaderPermission as Permission;
        else if (type === 'hr')
            permission = initHRPermission as Permission;
        else if (type === 'smm')
            permission = initSMMPermission as Permission;
        else 
            permission = initPermission as Permission;
        return this.afs.firestore.collection('permission').doc(user_id).set({
            ...permission
        }, { merge: true }).then(() => {
            this.log_model.action = 'create';
            this.log_model.data = { ...new Permission, ...permission, user_id: user_id } as any;
            // console.log(this.log_model.data);
            // this.createLog();
            this.nzMessageService.success('create successfully permission!');
            return { flag: true, message: 'create successfully permission!' };
        })
    }
    updatePermission(user_id: string, key: string, value: boolean) {
        return this.afs.firestore.collection('permission').doc(user_id).set({ [key]: value }, { merge: true }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new Permission, ...{ [key]: value }, user_id: user_id } as any;
            this.createLog();
            this.nzMessageService.success('update successfully permission!');
            return { flag: true, message: 'update successfully permission!' };
        })
    }
    updateExtraAction(user_id: string, key: string, value: boolean) {

        return this.afs.firestore.collection('permission').doc(user_id).update({ [`extra-action.${key}`]: value }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new Permission, ...{ [`extra_action.${key}`]: value }, user_id: user_id } as any;
            this.createLog();
            this.nzMessageService.success('update successfully permission!');
            return { flag: true, message: 'update successfully permission!' };
        })
    }
    getPermissionUser(user_id: string) {
        return this.afs.collection('permission').doc<Permission>(user_id).valueChanges().pipe(map(rs => ({ id: user_id, ...rs } as Permission)));
    }
}



