import { DocumentData, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';

export class DesignerTeam {
    id!: string;
    doc?: QueryDocumentSnapshot<DocumentData>;
    avatar?: DocumentReference;
    created_at!: Timestamp;
    team_name!: string;
    auto_assign!: boolean;
    disabled?: boolean;
    leader_id!: string;
    leader_ref!: DocumentReference;
    one_off_design?: boolean;
    team_type?: number;
    total_active?: number;
    total_active_client?: number;
    total_active_client_assigned?: number;
    total_awaiting?: number;
    total_complete?: number;
    total_designer?: number;
    total_inactive_client_assigned?: number;
    total_new?: number;
    total_onhold?: number;
    total_out_put_design?: number;
    total_pending?: number;
    total_queue?: number;
    total_pm?: number;
    total_rating?: number;
    total_revision?: number;
    log_type = 'designer-team';
}

export interface DesignerTeamState extends EntityState<DesignerTeam>{
  loading: boolean;
  error: string;
}
