/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Discussion, DiscussionService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';

interface DiscussionState extends EntityState<Discussion> {
    loading: boolean,
    error: string,
    anchor_id: string
}
const adapter = createEntityAdapter<Discussion>();
const initialState: DiscussionState = adapter.getInitialState({
    loading: false,
    error: '',
    anchor_id: ''
})
@Injectable()
export class ProjectMessageStoreService extends ComponentStore<DiscussionState> {

    private readonly afs = inject(AngularFirestore);
    private discussionSV = inject(DiscussionService);
    constructor() { super(initialState); }
    limit = 10;
    data$ = this.select(s => {
        const all_project_message = Object.values(s.entities) as Discussion[];
        const init_message = all_project_message.filter(m => m.realtime == false).sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
        const realtime_message = all_project_message.filter(m => m.realtime == true).sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
        return [...init_message, ...realtime_message];
    });
    anchor_id$ = this.select(s => s.anchor_id);
    loading$ = this.select((s) => s.loading);
    error$ = this.select((s) => s.error);

    loadDiscussion$ = this.effect((params$: Observable<any>) => {
        return params$.pipe(
          tap(() => {
            this.patchState({ loading: true, error: '', })
          }),
          switchMap((params: any) => {
            if (params['payload'] == 'init') {
              this.setState((state) => adapter.removeAll(state));
              return combineLatest(of(params['payload']), this.discussionSV.listDiscussion(this.limit, params['where_query']));
            } else if (params['payload'] == 'more') {
              return combineLatest(of(params['payload']), this.discussionSV.listDiscussion(this.limit, params['where_query'], params['start_after']));
            }
            else if (params['payload'] == 'jump') {
              // console.log('jump', params);
              this.setState((state) => adapter.removeAll(state));
              return combineLatest(of(params['payload']), this.afs.firestore.doc(`discussion_v2/${params['discussion_id']}`).get().then(doc => {
                return Promise.all([
                  this.discussionSV.listDiscussion(5, params['where_query'], doc as any),
                  this.discussionSV.listDiscussion(5, params['where_query'], undefined, undefined, undefined, doc as any)
                ]).then(rs => {
                  return [...rs[0], ...rs[1]];
                });
              }));
    
            }
            else if (params['payload'] == 'more-down') {
              return combineLatest(of(params['payload']), this.discussionSV.listDiscussion(this.limit, params['where_query'], undefined, undefined, undefined, params['end_before']));
            }
            return combineLatest(of(params['payload']), of([]));
          }),
          switchMap(([type, list]) => {
            if (list.length == 0) {
              this.patchState({ loading: false, error: '', })
            } else {
              if (type === 'more-down') {
                this.patchState({ anchor_id: list[list.length - 1].id });
              } else {
                this.patchState({ anchor_id: list[0].id });
              }
            }
            return list;
          }),
          map((discussion) => {
            if (discussion) {
              if (discussion.payload_type == 'added') {
                this.setState((state) => adapter.setOne(discussion, state));
                this.patchState({ loading: false, error: '', })
              }
            }
          }),
          catchError(err => {
            this.patchState({ loading: false, error: err as string });
            return EMPTY;
          })
        )
      });
    
    loadDiscussionRealtime$ = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            distinctUntilChanged((prev, curr) => prev['payload'] === curr['payload'] && prev['where_query'] === curr['where_query']),
            switchMap(params => {
                // console.log(params);
                if (params['where_query'] && params['payload'] == 'realtime') {
                    return combineLatest(
                        of(params),
                        this.discussionSV.listDiscussion(1, params['where_query']).then(rs => {
                            if (rs.length > 0)
                                return rs[0].doc;
                            else
                                return null;
                        }));
                }
                return combineLatest(of(params), of(null));
            }),
            switchMap(([params, last_doc]) => {
                // console.log(params);
                if (params['where_query'] && params['payload'] == 'realtime') {
                    return this.discussionSV.listDiscussionRealtime(params['where_query'], last_doc);
                }
                return of([]);
            }),
            switchMap(list => {
                if (list.length == 0) {
                    this.patchState({ loading: false, error: '', })
                } else {
                    this.patchState({ anchor_id: list[0].id });
                }
                return list;
            }),
            map((discussion) => {
                if (discussion) {
                    if (discussion.payload_type == 'added') {
                        this.setState((state) => adapter.setOne(discussion, state));
                        this.patchState({ loading: false, error: '', })
                    } else if (discussion.payload_type === 'modified') {
                        this.setState((state) => adapter.upsertOne(discussion, state));
                        this.patchState({ loading: false, error: '', })
                    } else if (discussion.payload_type === 'removed') {
                        this.setState((state) => adapter.removeOne(discussion.id, state));
                        this.patchState({ loading: false, error: '', })
                    }
                }
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    });
    crudDiscussion$ = this.effect((params$: Observable<{ action?: string, client_team_id?: string, project_id?: string, discussion?: Discussion }>) => {
        return params$.pipe(
            switchMap((params) => {
                // console.log(params);
                if (params.action && params.client_team_id && params.project_id && params.discussion) {
                    if (params.action === 'create')
                        return combineLatest(of(params), this.discussionSV.addDiscussion(params.client_team_id, params.project_id, params.discussion));
                    else if (params.action === 'update') {
                        // console.log(params);
                        return combineLatest(of(params), this.discussionSV.updateDiscussion(params.client_team_id, params.project_id, params.discussion));
                    } else if (params.action === 'delete') {
                        return combineLatest(of(params), this.discussionSV.deleteDiscussion(params.client_team_id, params.project_id, params.discussion.id));
                    }
                }
                return of();

            }),
            map(([params, rs]) => {
                if (rs.flag) {
                    if (params && params.client_team_id && params.project_id && params.discussion) {
                        if (params.action === 'update') {
                            this.setState((state) => adapter.updateOne({ id: params.discussion!.id, changes: params.discussion! }, state));
                        } else if (params.action === 'delete') {
                            this.setState((state) => adapter.removeOne(params.discussion!.id, state));
                        }
                    }
                    this.patchState({ loading: false, error: '' });
                } else {
                    this.patchState({ loading: false, error: rs.message });
                }
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    });
    listDiscussionPin(project_id: string) {
        return this.discussionSV.listPinnedRealtime(project_id);
    }
}
