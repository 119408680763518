

<ng-container *ngIf="workspace_data$ | async as workspace_data">
  <ng-container *ngIf="workspace_data">
    <ng-container [ngSwitch]="avatar_shape">
      <ng-container *ngSwitchCase="'square'">
          <div class="rounded overflow-hidden ring-2 ring-gray-100 relative" [style.width.px]="setImageWidth"
              [style.height.px]="setImageWidth">
              <penji-media-ref [media_ref]="avatar_ref" [media_size]="avatar_size" [clickable]="clickable"></penji-media-ref>
              <span *ngIf="workspace_data.membership && (workspace_data.membership.length >0 && workspace_data.membership[0]?.active === 0 || workspace_data.membership.length === 0)" [style.fontSize.px]="setImageWidth-10" class="material-icons-outlined absolute inset-0 z-10 text-red-400 flex items-center justify-center bg-white/70">work_off</span>
          </div>
      </ng-container>
      <ng-container *ngSwitchCase="'circle'">
          <div class="rounded-full overflow-hidden ring-2 ring-gray-100 relative" [style.width.px]="setImageWidth"
              [style.height.px]="setImageWidth">
              <penji-media-ref [media_ref]="avatar_ref" [media_size]="avatar_size" [clickable]="clickable"></penji-media-ref>
              <span *ngIf="workspace_data.membership && (workspace_data.membership.length >0 && workspace_data.membership[0]?.active === 0 || workspace_data.membership.length === 0)" [style.fontSize.px]="setImageWidth-10" class="material-icons-outlined absolute inset-0 z-10 text-red-400 flex items-center justify-center bg-white/70">work_off</span>
          </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
          <div class="w-full h-full rounded-none overflow-hidden ring-2 ring-gray-100 relative">
              <penji-media-ref [media_ref]="avatar_ref" [media_size]="avatar_size" [clickable]="clickable"></penji-media-ref>
              <span *ngIf="workspace_data.membership && (workspace_data.membership.length >0 && workspace_data.membership[0]?.active === 0 || workspace_data.membership.length === 0)" [style.fontSize.px]="setImageWidth-10" class="material-icons-outlined absolute inset-0 z-10 text-red-400 flex items-center justify-center bg-white/70">work_off</span>
          </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
