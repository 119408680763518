<ng-container *ngIf="authProfile$ | async as auth">
    <ng-container [ngSwitch]="ticket.status">
        <ng-container *ngSwitchCase="1">
            <button nz-button nzType="primary" [nzSize]="size" (click)="updateTicket(2, auth?.id)"
                [nzLoading]="loading">Claim</button>
        </ng-container>
        <ng-container *ngSwitchCase="2">
            <ng-container *ngIf="ticket.user_claimed_id === auth?.id; else someone">
                <button nz-button nzType="default" [nzSize]="size" (click)="updateTicket(1, auth?.id)"
                    [nzLoading]="loading">Unclaim</button>
            </ng-container>
            <ng-template #someone>
              <penji-user-info-item [user_id]="ticket.user_claimed_id" [show_user_avatar]="true" [show_user_available_status]="true" [clickable]="true"></penji-user-info-item>
            </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="3">
            <button nz-button nzType="default" [nzSize]="size" [disabled]="true">Solved</button>
        </ng-container>
        <ng-container *ngSwitchCase="4">
            <button nz-button nzType="default" [nzSize]="size" [disabled]="true">Duplicated</button>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <button nz-button nzType="default" [nzSize]="size" [nzLoading]="true">Loading</button>
        </ng-container>
    </ng-container>
</ng-container>
