/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { WorkSpace, WorkSpacestate, WorkspaceService } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, map, mergeMap, of, switchMap, tap } from 'rxjs';

const adapter: EntityAdapter<WorkSpace> = createEntityAdapter<WorkSpace>();
export const initialState: WorkSpacestate = adapter.getInitialState({
  loading: false,
  error: '',
});

@Injectable({
  providedIn: 'root'
})
export class WorkspaceStoreService extends ComponentStore<WorkSpacestate> {

  private readonly workSpaceSV = inject(WorkspaceService);
  constructor() { super(initialState); }
  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as WorkSpace[];
    return list;
  });
  readonly detail$ = (team_id:string) => this.select(s=>{
    const list = Object.values(s.entities) as WorkSpace[];
    // console.log(list);
    return list.find(f=>f.team_id === team_id) ?? new WorkSpace;
  })
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);

  loadWorkSpace$ = this.effect(none$ => {
    return none$.pipe(
      tap(() => {
        this.patchState({ loading: true })
      }),
      switchMap(() => {
        return this.workSpaceSV.getListWorkSpace();
      }),
      map(list => {
        if (list && list.length > 0) {
          this.setState((state) => adapter.setAll(list, state));
          this.patchState({ loading: false });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false });
        return EMPTY;
      })
    )
  })
  createWorkSpace$ = this.effect((workspace$: Observable<WorkSpace>) => {
    return workspace$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((workspace: WorkSpace) => {
        if(workspace && workspace.user_id)
          return this.workSpaceSV.addWorkSpace(workspace.user_id!, workspace.title!, workspace.logo!);
        return EMPTY;
      }),
      map(rs => {
        if (rs.flag) {
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
  updateWorkSpace$ = this.effect((workspace$: Observable<WorkSpace>) => {
    return workspace$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      switchMap((workspace: WorkSpace) => {
        // console.log('update workspace: ',workspace);
        if(workspace && workspace.team_id)
          return combineLatest(this.workSpaceSV.updateWorkSpace(workspace.team_id, workspace.title!, workspace.logo!), of(workspace));
        return EMPTY;
      }),
      map(([rs, workspace]) => {
        if (rs.flag) {
          this.setState(state=>adapter.upsertOne(workspace, state));
          this.patchState({loading: false, error: ''});
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });
}
