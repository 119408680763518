import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Category } from '@penji/shared/data-access';
import * as categorySelectors from '@penji/team/categories/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-category-title',
  standalone: true,
  imports: [CommonModule, NzToolTipModule],
  templateUrl: './category-title.component.html',
  styleUrls: ['./category-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTitleComponent {

  @Input() category_id?: string = '';
  @Input() classes? = 'text-gray-500';
  store = inject(Store);
  category$ : Observable<Category> | undefined;

  ngOnChanges(): void {
    if(this.category_id){
      this.category$ = this.store.pipe(select(categorySelectors.getCategory(this.category_id)));
    }
  }

}
